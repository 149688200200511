<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-money me-2"></i>Unpaid Loans in this Payslip
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :value="employeeLoans"
                        paginator
                        :rows="10"
                        filter-display="row"
                        v-model:filters="filters"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column
                            field="LOANID"
                            header="Loan ID"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="LOANTYPE"
                            header="Loan Type"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DATE_GRANTED"
                            header="Date Granted"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DATE"
                            header="Date"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="INIT_AMT"
                            header="Initial Amt."
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="PRINCIPAL"
                            header="Principal"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DESCRIPTION"
                            header="Description"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="AMT_WEEK"
                            header="Amt. per Week"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="NUM_WEEKS"
                            header="# of Weeks"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="STATUS"
                            header="Status"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No data yet.
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useEmployeeLoans } from '@/composables/data/employeeLoans';
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    isOpen: Boolean,
    payslipId: Number,
});

const emit = defineEmits(['close']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    'LOANID',
    'LOANTYPE',
    'DATE_GRANTED',
    'DATE',
    'INIT_AMT',
    'PRINCIPAL',
    'DESCRIPTION',
    'AMT_WEEK',
    'NUM_WEEKS',
    'STATUS'
]);

const { employeeLoans, getUnpaidLoansInPayslip } = useEmployeeLoans();

async function fetchPayslipUnpaidLoans() {
    employeeLoans.value = null;

    if(!props.payslipId) {
        return;
    }

    loadingFlags.add('fetchPayslipUnpaidLoans');
    try {
        await getUnpaidLoansInPayslip(props.payslipId);
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchPayslipUnpaidLoans');
}

watch(() => [props.isOpen, props.payslipId], () => {
    if(props.isOpen) {
        fetchPayslipUnpaidLoans();
    }
});
</script>
