<template>
    <div class="row">
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i> Update Voucher
                </legend>

                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher ID"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.VOUCHER_ID"
                            id-prefix="voucherId"
                            :errors="errors.VOUCHER_ID"
                            disabled
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher Date"
                            is-horizontal
                            type="date"
                            class="mb-md-1 mb-2"
                            v-model="voucher.VOUCHER_DATE"
                            id-prefix="voucherDate"
                            :errors="errors.VOUCHER_DATE"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher Amount"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.VOUCHER_AMT"
                            id-prefix="voucherAmt"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.VOUCHER_AMT"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Description"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.DESCRIPTION"
                            id-prefix="description"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.DESCRIPTION"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormTextarea
                            label="Memo"
                            id-prefix="memo"
                            v-model="voucher.MEMO"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            :errors="errors.MEMO"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormSelect
                            label="Payee Type"
                            is-horizontal
                            v-model="voucher.PAYEE_TYPE"
                            :options="payeeTypes"
                            :option-to-string="(payeeType) => payeeType.name"
                            :option-value="(payeeType) => payeeType.value"
                            :errors="errors.PAYEE_TYPE"
                            class="mb-md-1 mb-2"
                            label-class="col-xl-4 col-md-5 label-sm"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-4">
                        <button class="btn btn-primary btn-sm px-2"
                            @click="searchPayee">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Payee ID"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.PAYEE_ID"
                            id-prefix="payeeId"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.PAYEE_ID"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Payee Name"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.PAYEE_NAME"
                            id-prefix="payeeName"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.PAYEE_NAME"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Approved By"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="voucher.APPROVED_BY"
                            id-prefix="approvedBy"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors.APPROVED_BY"
                            disabled
                        />
                    </div>
                    <div class="col-xl-4">
                        <button class="btn btn-primary btn-sm px-2"
                            :disabled="!voucher.APPROVED_BY"
                            @click="deleteApproval">
                            <i class="icon ph-bold ph-trash me-2"></i>Delete Approval
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 mt-3">
                        <div class="row">
                            <div class="col-xl-8 offset-xl-4">
                                <button class="btn btn-primary btn-sm"
                                    @click="handleSubmit">
                                    <i class="icon ph-bold ph-pencil-line me-2"></i>UPDATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectAccount
        :is-open="isSelectingAccount"
        :on-close="(_) => (isSelectingAccount = false)"
        :onSelectAccount="handleSelectAccount"
    />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        @close="_ => isSelectingEmployee = false"
        @select="(employee) => {
            handleSelectEmployee(employee);
            isSelectingEmployee = false;
        }"
    />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';
import FormTextarea from '@/components/utils/FormTextarea.vue';

import { useFlashMessages } from "@/composables/flashmessages";
import handleFetchErrors from "@/utils/handleFetchErrors";

const { addFlashMessage } = useFlashMessages();

const voucher = ref({
    VOUCHER_ID: null,
    VOUCHER_DATE: null,
    VOUCHER_AMT: null,
    DESCRIPTION: null,
    MEMO: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
    APPROVED_BY: null,
    PAYEE_NAME: null,
});

const errors = ref({});

const isSelectingAccount = ref(false);
const isSelectingEmployee = ref(false);

const payeeTypes = [
    { name: 'External', value: 'E'},
    { name: 'Internal', value: 'I'}
]

onMounted(async () => {
    const voucher_id = new URLSearchParams(window.location.search).get('VOUCHER_ID');
    try {
        const response = await axios.get(route('api.vouchers.show', { id: voucher_id }));
        voucher.value = {
            VOUCHER_ID: response.data.VOUCHER_ID,
            VOUCHER_DATE: response.data.VOUCHER_DATE,
            VOUCHER_AMT: response.data.VOUCHER_AMT,
            DESCRIPTION: response.data.DESCRIPTION,
            MEMO: response.data.MEMO,
            PAYEE_TYPE: response.data.PAYEE_TYPE,
            PAYEE_ID: response.data.PAYEE_ID,
            APPROVED_BY: response.data.APPROVED_BY ?? null,
            PAYEE_NAME: response.data.PAYEE_NAME,
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching voucher.');
    }
});

function searchPayee() {
    switch (voucher.value.PAYEE_TYPE) {
        case 'E':
            isSelectingAccount.value = true;
            break;
        case 'I':
            isSelectingEmployee.value = true;
            break;
        default:
            break;
    }
}

function handleSelectAccount(selectedAccount) {
    voucher.value.PAYEE_ID = selectedAccount.ACCOUNT_ID;
    voucher.value.PAYEE_NAME = selectedAccount.ACCOUNT_NAME;
}

function handleSelectEmployee(selectedEmployee) {
    voucher.value.PAYEE_ID = selectedEmployee.EMPID;
    voucher.value.PAYEE_NAME = `${selectedEmployee.FNAME} ${selectedEmployee.LNAME}`;
}

async function handleSubmit() {
    try {
        if (voucher.value.APPROVED_BY) {
            addFlashMessage('ERROR','Cannot update when approved');
            return;
        }

        const response = await axios.patch(route('api.vouchers.update', {
            id: voucher.value.VOUCHER_ID
        }), {
            MEMO: voucher.value.MEMO,
            PAYEE_ID: voucher.value.PAYEE_ID,
            PAYEE_TYPE: voucher.value.PAYEE_TYPE,
            VOUCHER_AMT: voucher.value.VOUCHER_AMT,
            DESCRIPTION: voucher.value.DESCRIPTION,
            VOUCHER_DATE: voucher.value.VOUCHER_DATE,
        });
        addFlashMessage('SUCCESS', response.data.message);
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            addFlashMessage('ERROR', `An error occurred while saving voucher. Please contact your administrator.`);
        }
    }
}

async function deleteApproval() {
    try {
        const response = await axios.delete(route('api.voucher-accounting-approval.destroy',{
            id: voucher.value.VOUCHER_ID
        }));
        if (response.data.message === 'Success') {
            voucher.value.APPROVED_BY = null;
            addFlashMessage('SUCCESS', 'Accounting approval deleted.');
            return;
        }
        addFlashMessage('ERROR', 'Failed to delete approval.');
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while deleting approval.');
        scrollToTop();
    }
}
</script>
