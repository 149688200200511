<style scoped>
#vouchersTableContainer {
    max-height: 500px;
    cursor: pointer;
}
</style>

<template>
    <Modal dialog-class="modal-xl" :id="props.id">
        <template #header>
            <h5 class="modal-title" id="modalSearchLabel">
                <i class="icon ph-bold ph-receipt me-2"></i>Search Voucher
            </h5>
        </template>

        <FlashMessageAlerts
            :messages="flashMessages"
            :dismissible="true"
            class="mb-3"
        />

        <form @submit.prevent="fetchVouchers" class="mb-3">
            <div class="row justify-content-center mb-2 gx-5">
                <div class="col">
                    <div class="mb-3 row align-items-center">
                        <label for="startDateFilter" class="form-label label-sm col-lg-3">Start Date</label>
                        <div class="col-lg-9">
                            <input
                                type="date"
                                v-model="startDateFilter"
                                id="startDateFilter"
                                class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3 row align-items-center">
                        <label for="endDateFilter" class="form-label label-sm col-lg-3">End Date</label>
                        <div class="col-lg-9">
                            <input
                                type="date"
                                v-model="endDateFilter"
                                id="endDateFilter"
                                class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary btn-sm">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                    </button>
                </div>
            </div>
        </form>

        <DataTable
            :loading="isLoading"
            :value="vouchers"
            :total-records="vouchers?.length"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="filters"
            v-model:selection="selectedVoucher"
            selection-mode="single"
            :pt="{ table: { class: ' table table-bordered table-hover' } }"
            class="mb-3">
            <Column
                field="VOUCHER_ID"
                header="Voucher ID"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="VOUCHER_DATE"
                header="Voucher Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="VOUCHER_AMT"
                header="Voucher Amount"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DEBIT"
                header="Debit"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="CREDIT"
                header="Credit"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DESCRIPTION"
                header="Description"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PAYEE_DATE"
                header="Payee Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PREPARED_BY"
                header="Prepared By"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No date.
                </div>
            </template>
        </DataTable>

        <button type="button"
            class="btn btn-outline-secondary btn-sm me-2 mb-2"
            ref="closeButton"
            data-bs-dismiss="modal">
            <i class="icon ph-bold ph-x-circle me-2"></i>Close
        </button>
        <button type="button"
            class="btn btn-primary btn-sm me-2 mb-2"
            :disabled="selectedVoucher === null"
            @click="selectVoucherButtonOnClick">
            <i class="icon ph-bold ph-receipt me-2"></i>Select Voucher
        </button>
    </Modal>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useVouchers } from "@/composables/data/vouchers";
import Modal from "@/components/utils/Modal.vue";
import FlashMessageAlerts from "@/components/utils/FlashMessageAlerts.vue";
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import moment from "moment";

const props = defineProps({
    id: String
});

const emit = defineEmits(['select', 'error']);

const { filters } = useDataTableParams([
    'VOUCHER_ID', 'VOUCHER_DATE', 'VOUCHER_AMT', 'DEBIT', 'CREDIT', 'DESCRIPTION', 'PAYEE_DATE', 'PREPARED_BY'
]);

const { vouchers, getFinalVouchers } = useVouchers();

// TODO: Move to topmost parent component of admin, then propagate messages by emits
const flashMessages = ref([]);

const closeButton = ref(null);

const startDateFilter = ref(null);
const endDateFilter = ref(null);

const isLoading = ref(false);

const selectedVoucher = ref(null);

onMounted(() => {
    startDateFilter.value = moment().subtract(1, 'months').format("YYYY-MM-DD");
    endDateFilter.value = moment().format("YYYY-MM-DD");
    fetchVouchers();
});

async function fetchVouchers() {
    isLoading.value = true;
    try {
        await getFinalVouchers({
            START_DATE: startDateFilter.value,
            END_DATE: endDateFilter.value,
        });
    } catch (e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function selectVoucherButtonOnClick() {
    emit('select', selectedVoucher.value);
    closeButton.value.click();
}
</script>
