import { ref } from "vue";

import constructFetchError from "./constructFetchError";

export function useCommentDates() {
    const commentDates = ref(null);

    const getCommentDates = async () => {
        try {
            const commentDatesResponse = await axios.get(route('api.comments.dates.index'));
            commentDates.value = commentDatesResponse.data.data.map(commentDate => ({
                date: commentDate
            }));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching comment dates.');
        }
    };
    
    return {
        commentDates,
        getCommentDates,
    };
}
