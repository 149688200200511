import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconNotInMainItems() {
    const reconNotInMainItems = ref(null);
    const totalRecords = ref(null);

    const getReconNotInMainItems = async (params) => {
        try {
            const getReconNotInMainItemsResponse = await axios.get(route('api.recon-not-in-main-items.index', params));
            reconNotInMainItems.value = getReconNotInMainItemsResponse.data.data;
            totalRecords.value = getReconNotInMainItemsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while getting recon not in main items.');
        }
    };
    
    return {
        reconNotInMainItems,
        totalRecords,
        getReconNotInMainItems,
    };
}
