import { defineStore } from "pinia";

export const useLoadingFlagsStore = defineStore('loadingFlags', {
    state: () => {
        return {
            flags: new Set()
        };
    },
    actions: {
        add(name) {
            this.flags.add(name);
        },
        delete(name) {
            this.flags.delete(name);
        },
        has(name) {
            return this.flags.has(name);
        },
    }
});
