<template>
    <fieldset class="mb-3">
        <legend>
            Events
        </legend>
        <CompanyEventsTable v-model="selectedCompanyEvent"
            :refresh-flag="tableRefreshFlag" />
    </fieldset>
    <div class="row">
        <div class="col-lg-4">
            <CreateOrUpdateEventForm v-model="selectedCompanyEvent"
                @success="(_) => {
                    tableRefreshFlag = !tableRefreshFlag;
                }" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import CompanyEventsTable from '@/components/utils/tables/CompanyEventsTable.vue';
import CreateOrUpdateEventForm from './CreateOrUpdateEventForm.vue';

const selectedCompanyEvent = ref(null);

const tableRefreshFlag = ref(false);
</script>
