<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search Style
        </legend>
        <DataTable lazy
            paginator
            :rows="10"
            :total-records="totalRecords"
            :value="styles"
            v-model:filters="filters"
            filter-display="row"
            :globalFilterFields="['STYLE']"
            v-model:selection="selectedStyle"
            selection-mode="single"
            @page="(event) => onPage(event)"
            @filter="(event) => onFilter(event)"
            class="mb-3"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="STYLE"
                header="Style"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="form-control"
                        placeholder="Search by Style"
                    />
                </template>
            </Column>
        </DataTable>
        <div class="text-end">
            <button type="button"
                class="btn btn-primary btn-sm"
                @click="stylesWithoutPriceReportButtonOnClick">
                <i class="icon ph-bold ph-device-mobile-slash me-2"></i>Styles w/o Price
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const emit = defineEmits(["select"]);

const filters = ref({
    STYLE: { value: null, matchMode: "contains" },
});

const params = ref({});

const totalRecords = ref(0);
const styles = ref();

const selectedStyle = ref({});

watch(selectedStyle, () => {
    emit("select", selectedStyle.value);
});

async function loadStyles() {
    const stylesResponse = await axios.get(
        route("api.styles.index", params.value)
    );
    totalRecords.value = stylesResponse.data.meta.total;
    styles.value = stylesResponse.data.data;
}

onMounted(async () => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };

    await loadStyles();
});

const onPage = (event) => {
    params.value = event;
    loadStyles();
};

const onFilter = (_) => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;
    loadStyles();
};

function priceListReportButtonOnClick() {
    window.open(route("api.styles.price-list-report"), "_blank");
}

function stylesWithoutPriceReportButtonOnClick() {
    window.open(
        route("api.reports.inventory.styles-without-price-report"),
        "_blank"
    );
}
</script>
