<template>
    <div class="row g-3 my-3">
        <div class="col-xl-8">
            <DetailTableCard
                :sample-id="sampleId"
                v-model:sample-quantity="sampleQuantity"
                v-model="selectedSampleDetail"
                :refresh-flag="refreshFlag"
            />
        </div>
        <div class="col-xl-4">
            <CreateOrUpdateDetailCard
                :sample-id="sampleId"
                :sample-quantity="sampleQuantity"
                v-model="selectedSampleDetail"
                @success="(_) => {
                    refreshFlag = !refreshFlag;
                }"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import DetailTableCard from "./DetailTableCard.vue";
import CreateOrUpdateDetailCard from "./CreateOrUpdateDetailCard.vue";

const sampleId = ref(null);
const sampleQuantity = ref(100);

const selectedSampleDetail = ref(null);

const refreshFlag = ref(false);

onMounted(() => {
    sampleId.value = Number(
        new URLSearchParams(window.location.search).get("SAMPLE_ID")
    );
});
</script>
