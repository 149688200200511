<template>
    <DataTable
        :loading="loadingFlags.has('fetchNewBarcodeOutputs')"
        lazy
        :value="newBarcodeOutputs"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => {
            onPage(e);
            fetchNewBarcodeOutputs();
        }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        :pt="{ table: { class: 'table table-bordered table-hover' } }">
        <Column
            field="barcode"
            header="Barcode"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
                bodyCell: { class: 'barcode' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Barcode"
                />
            </template>
        </Column>
        <Column
            field="color"
            header="Color"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Color"
                />
            </template>
        </Column>
        <Column
            field="price"
            header="Price"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Date"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-barcode me-2"></i>No generated barcodes.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useNewBarcodeOutputs } from '@/composables/data/newBarcodeOutputs';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    'barcode',
    'color',
    'price',
]);

const {
    newBarcodeOutputs,
    totalRecords,
    getNewBarcodeOutputs,
} = useNewBarcodeOutputs();

const fetchTimeout = ref(null);

async function fetchNewBarcodeOutputs() {
    newBarcodeOutputs.value = null;

    loadingFlags.add("fetchNewBarcodeOutputs");
    try {
        await getNewBarcodeOutputs(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchNewBarcodeOutputs");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchNewBarcodeOutputs, 700);
}

onMounted(fetchNewBarcodeOutputs);

watch(() => props.refreshFlag, fetchNewBarcodeOutputs);
</script>
