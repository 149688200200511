<template>
    <div class="row">
        <div class="col-xl-6 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-pencil-line me-2"></i> Update Comment Details
                </legend>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="date"
                            label="Comment Timestamp"
                            v-model="form.COMMENT_TIMESTAMP"
                            is-horizontal
                            id-prefix="commentTimestamp"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="date"
                            label="Comment Rpt. Date (Original)"
                            v-model="form.COMMENT_RPT_DATE_ORIG"
                            is-horizontal
                            id-prefix="commentRptDateOrig"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="date"
                            label="Comment Rpt. Date"
                            v-model="form.COMMENT_RPT_DATE"
                            is-horizontal
                            id-prefix="commentRptDate"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            :errors="errors.COMMENT_RPT_DATE"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            label="Location"
                            v-model="form.LOCATION"
                            is-horizontal
                            id-prefix="location"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            :errors="errors.LOCATION"
                            disabled
                        />
                    </div>
                    <div class="col-xl-4">
                        <button type="button"
                            class="btn btn-sm btn-primary px-2"
                            @click="
                                (_) => {
                                    isSelectLocationPopupOpen = true;
                                }
                            ">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>search
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            label="Comment Text"
                            v-model="form.COMMENT_TEXT"
                            is-horizontal
                            id-prefix="commentText"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            :errors="errors.COMMENT_TEXT"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            label="Payee Type"
                            v-model="form.PAYEE_TYPE"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            is-horizontal
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            label="Payee ID"
                            v-model="form.PAYEE_ID"
                            is-horizontal
                            id-prefix="payeeId"
                            class="mb-md-1 mb-2"
                            label-class="col-md-6 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-md-6 col-12"
                            disabled
                        />
                    </div>
                </div>
                <hr class="my-3" />
                <div class="text-end mt-3">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedComment"
                        @click="updateComment">
                        <i class="icon ph-bold ph-pencil-line me-2"></i>Update Comment
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-chat-circle-dots me-2"></i>Select Comment
                </legend>
                <CommentsTable
                    v-model="selectedComment"
                    :refresh-flag="tableRefreshFlag"
                />
                <div class="text-end mt-3">
                    <button type="button"
                        class="btn btn-danger btn-sm"
                        :disabled="!selectedComment"
                        @click="closeComment">
                        <i class="icon ph-bold ph-x me-2"></i>Close Comment
                    </button>
                </div>
            </fieldset>
        </div>
    </div>

    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        :filters="locationFilters"
        @close="
            (_) => {
                isSelectLocationPopupOpen = false;
            }"
        @select="selectedLocation => {
            form.LOCATION = selectedLocation.LOCATION;
            location = selectedLocation;
            isSelectLocationPopupOpen = false;
        }" />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useComments } from '@/composables/data/comments';
import CommentsTable from '@/components/utils/tables/CommentsTable.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { putComment, postCommentClosure } = useComments();

const selectedComment = ref(null);

const form = ref({
    COMMENT_ID: null,
    COMMENT_TIMESTAMP: null,
    COMMENT_RPT_DATE_ORIG: null,
    COMMENT_RPT_DATE: null,
    LOCATION: null,
    COMMENT_TEXT: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
});

const location = ref(null);

const errors = ref({});

const isSelectLocationPopupOpen = ref(false);
const locationFilters = ref({});

const tableRefreshFlag = ref(false);

watch(selectedComment, () => {
    form.value = Object.assign({
            COMMENT_ID: null,
            COMMENT_TIMESTAMP: null,
            COMMENT_RPT_DATE_ORIG: null,
            COMMENT_RPT_DATE: null,
            LOCATION: null,
            COMMENT_TEXT: null,
            PAYEE_TYPE: null,
            PAYEE_ID: null,
        }, selectedComment.value);

    if (form.value.COMMENT_TEXT) {
        form.value.COMMENT_TEXT = [...form.value.COMMENT_TEXT.matchAll(/(\([^\)]+\)\s)?\((.*)\)/g)].at(-1)?.at(-1) ?? form.value.COMMENT_TEXT;
    }

    // Location Filters depend on comment text type.
    // DEV NOTE: There is no appropriate TYPE field since old system. (There is a COMMENT_TYPE but it is not for this context.)
    locationFilters.value = {};
    if (selectedComment.value) {
        if (selectedComment.value.COMMENT_TEXT.startsWith('Initial Inventory') ||
            selectedComment.value.COMMENT_TEXT.startsWith('Return') ||
            selectedComment.value.COMMENT_TEXT.startsWith('Replacement')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'in',
                    value: ['S', 'H'],
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Delivery')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'S',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Pull-out') ||
            selectedComment.value.COMMENT_TEXT.startsWith('New Stock')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'H',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Sales')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'P',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Reconciliation')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'R',
                },
            };
        }
    }

    errors.value = {};
});

async function updateComment() {
    errors.value = {};

    loadingFlags.add('updateComment');
    try {
        await putComment(selectedComment.value.COMMENT_ID, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated comment.');
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateComment');
}

async function closeComment() {
    if (window.prompt("Are you sure? Type 'CLOSE' to proceed.") != 'CLOSE') {
        return;
    }

    loadingFlags.add('closeComment');
    try {
        await postCommentClosure(selectedComment.value.COMMENT_ID);
        toasts.add('SUCCESS', 'Success', 'Successfully closed comment.');
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('closeComment');
}
</script>
