<template>
    <fieldset class="mb-3">
        <legend>
            Tabulation Year Equivalences
        </legend>
        <TabulationYearEquivalencesTable
            :refresh-flag="refreshFlag"
        />
    </fieldset>
    <div class="row">
        <div class="col-lg-4">
            <CreateFormCard
                @success="(_) => {
                    refreshFlag = !refreshFlag;
                }"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import TabulationYearEquivalencesTable from '@/components/utils/tables/TabulationYearEquivalencesTable.vue';
import CreateFormCard from './CreateFormCard.vue';

const refreshFlag = ref(false);
</script>
