<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-trash me-2"></i>Delete Barcode
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row mb-3">
                        <div class="col-xl-12">
                            <div class="row g-3 mb-3">
                                <div class="col-md-6">
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodDisplay"
                                        disabled
                                        label="Time Period"
                                        id-prefix="timePeriodDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="styleDisplay"
                                        disabled
                                        label="Style"
                                        id-prefix="styleDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="dateDisplay"
                                        disabled
                                        label="Date"
                                        id-prefix="dateDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="serialDisplay"
                                        disabled
                                        label="Serial"
                                        id-prefix="serialDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodStartDateDisplay"
                                        disabled
                                        label="Start Date"
                                        id-prefix="timePeriodStartDateDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodEndDateDisplay"
                                        disabled
                                        label="End Date"
                                        id-prefix="timePeriodEndDateDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="locationDisplay"
                                        disabled
                                        label="Location"
                                        id-prefix="locationDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="seqNoDisplay"
                                        disabled
                                        label="Seq. No."
                                        id-prefix="seqNoDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12">
                            <ReconLostCommentsTable
                                :start-date="timePeriod?.START_DATE"
                                :end-date="timePeriod?.END_DATE"
                                v-model="selectedComment"
                            />
                        </div>
                    </div>
                    <div class="text-end py-4">
                        <button type="button"
                            class="btn btn-danger"
                            :disabled="!selectedComment || isDeleting"
                            @click="deleteBarcode">
                            <span v-if="isDeleting"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-trash me-2"></i>Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useTimePeriods } from "@/composables/data/timePeriods";
import { useReconActions } from "@/composables/data/reconActions";
import FormInput from "@/components/utils/FormInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import ReconLostCommentsTable from "./ReconLostCommentsTable.vue";

const props = defineProps({
    timePeriod: Number,
    location: String,
    reconRawFull: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["close", "done", "error"]);

const { timePeriod, getTimePeriod } = useTimePeriods();

const timePeriodDisplay = computed(() => timePeriod.value?.TIME_PERIOD);
const timePeriodStartDateDisplay = computed(() => timePeriod.value?.START_DATE);
const timePeriodEndDateDisplay = computed(() => timePeriod.value?.END_DATE);
const locationDisplay = computed(
    () => props.reconRawFull?.LOCATION || props.location
);
const styleDisplay = computed(() => props.reconRawFull?.STYLE);
const dateDisplay = computed(() => props.reconRawFull?.DATE);
const serialDisplay = computed(() => props.reconRawFull?.SERIAL);
const seqNoDisplay = computed(() => props.reconRawFull?.SEQ_NO);

const selectedComment = ref(null);

const { postBarcodeMissingDeletion } = useReconActions();

const isDeleting = ref(false);

async function fetchTimePeriod() {
    timePeriod.value = null;

    if (!props.timePeriod) {
        return;
    }

    try {
        await getTimePeriod(props.timePeriod);
    } catch (e) {
        emit("error", e.message);
    }
}

watch(() => props.timePeriod, fetchTimePeriod);

async function deleteBarcode() {
    if (window.prompt('Type "DELETE BC" to continue.') != "DELETE BC") {
        return;
    }

    isDeleting.value = true;
    try {
        const response = await postBarcodeMissingDeletion(
            props.reconRawFull.id,
            selectedComment.value.COMMENT_ID
        );
        emit("done", response.data.message);
    } catch (e) {
        emit("error", e.message);
    }
    isDeleting.value = false;
}
</script>
