<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-check-square me-2"></i>Top Attendance (WEEKLY)
        </legend>
        <p class="text-primary">**(please select time period)</p>
        <DataTable :loading="isLoading"
            :value="topFiveWeeklyAttendance"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="DEPT" header="Dept." />
            <Column field="FNAME" header="First Name" />
            <Column field="LNAME" header="Last Name" />
            <Column field="DOW" header="Days of Work" />
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button type="button"
                class="btn btn-primary btn-sm"
                :disabled="!timePeriod || isLoading"
                @click="fetchTopFiveAttendance">
                <span v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true">
                </span>
                <i class="icon ph-bold ph-gear me-2"></i>Generate
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref } from 'vue';
import { useDaysOfWork } from '@/composables/data/daysOfWork';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    timePeriod: Number,
});

const emit = defineEmits(['error']);

const { topFiveWeeklyAttendance, getTopFiveWeeklyAttendance } = useDaysOfWork();

const isLoading = ref(false);

async function fetchTopFiveAttendance() {
    topFiveWeeklyAttendance.value = null;
    if (!props.timePeriod) {
        return;
    }
    isLoading.value = true;
    try {
        await getTopFiveWeeklyAttendance(props.timePeriod);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}
</script>
