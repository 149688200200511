<template>
    <div class="row g-3">
        <div class="col-lg-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i> Select a Location
                </legend>
                <LocationTable
                    v-model="location"
                    @error="
                        (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }
                    "
                />
            </fieldset>
            <LocationChargesTableCard
                :location="location?.LOCATION"
                v-model="selectedLocationCharge"
                :refresh-flag="locationChargesTableRefreshFlag"
            />
        </div>
        <div class="col-lg-4">
            <ModifyLocationChargeFormCard
                :location="location?.LOCATION"
                :location-charge="selectedLocationCharge"
                @success="
                    (message) => {
                        locationChargesTableRefreshFlag = !locationChargesTableRefreshFlag;
                        addFlashMessage('SUCCESS', message);
                        scrollToTop();
                    }
                "
                @warning="
                    (message) => {
                        addFlashMessage('WARNING', message);
                        scrollToTop();
                    }
                "
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }
                "
                @reset="
                    (_) => {
                        selectedLocationCharge = null;
                    }
                "
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";

import LocationTable from "@/components/utils/tables/LocationTable";

import LocationChargesTableCard from "./LocationChargesTableCard.vue";
import ModifyLocationChargeFormCard from "./ModifyLocationChargeFormCard.vue";

import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const location = ref(null);
const selectedLocationCharge = ref(null);

const locationChargesTableRefreshFlag = ref(false);
</script>
