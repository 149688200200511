<template>
    <div class="row mb-3">
        <div class="col-xl-3 mb-xl-0 mb-3">
            <fieldset class="h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-info me-2"></i>Details
                </legend>
                <div>
                    <div class="row">
                        <div class="col-lg-9">
                            <FormSelect
                                v-model="startTimePeriod"
                                label="Start Date"
                                id-prefix="startDate"
                                :options="timePeriods"
                                :option-to-string="(timePeriod) => timePeriod.START_DATE"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                select-class="form-select-sm"
                                select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-9">
                            <FormSelect
                                v-model="endTimePeriod"
                                label="End Date"
                                id-prefix="endDate"
                                :options="timePeriods"
                                :option-to-string="(timePeriod) => timePeriod.END_DATE"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                select-class="form-select-sm"
                                select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-9">
                            <FormInput
                                v-model="locationName"
                                label="Location"
                                id-prefix="location"
                                disabled
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                            />
                        </div>
                        <div class="col-lg-3 text-end mb-lg-0 mb-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm px-1"
                                @click="
                                    (_) => {
                                        isSelectLocationPopupOpen = true;
                                    }
                                ">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-9">
                            <FormInput
                                v-model="employeeId"
                                label="Employee"
                                id-prefix="employee"
                                disabled
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                            />
                            <div class="mb-1" v-if="employee">
                                <p class="form-text">
                                    {{ employee.FNAME }} {{ employee.LNAME }}
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 text-end mb-lg-0 mb-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!startTimePeriod || !endTimePeriod || !location"
                                @click="
                                    (_) => {
                                        isSelectEmployeePopupOpen = true;
                                    }
                                ">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="my-3" />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary btn-sm px-1 mb-1"
                        :disabled="!startTimePeriod || !endTimePeriod || !location"
                        @click="generateSpreadsheet">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate Report
                    </button>
                    <button type="button"
                        class="btn btn-primary btn-sm px-1 ms-1 mb-1"
                        :disabled="!startTimePeriod || !endTimePeriod || !location"
                        @click="generateSummarySpreadsheet">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate Summary Report
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-9">
            <CommissionsTableCard
                :start-time-period="startTimePeriod?.TIME_PERIOD"
                :end-time-period="endTimePeriod?.TIME_PERIOD"
                :location="locationName"
                :employee-id="employeeId"
                @update-net-amt="
                    (netAmount) => {
                        netAmountFromCommissions = netAmount;
                    }
                "
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }
                "
            />
        </div>
    </div>

    <div class="row g-3 mb-3">
        <div class="col-xl-5">
            <div class="row g-3 mb-3">
                <div class="col-xl-6">
                    <SoldFromOtherStoreTableCard
                        :start-time-period="startTimePeriod?.TIME_PERIOD"
                        :end-time-period="endTimePeriod?.TIME_PERIOD"
                        :location="locationName"
                        :employee-id="employeeId"
                        @error="
                            (message) => {
                                addFlashMessage('ERROR', message);
                                scrollToTop();
                            }
                        "
                    />
                </div>
                <div class="col-xl-6">
                    <LocationsNotInCommissionsTableCard
                        :start-time-period="startTimePeriod?.TIME_PERIOD"
                        :end-time-period="endTimePeriod?.TIME_PERIOD"
                        :location="locationName"
                        :employee-id="employeeId"
                        @error="
                            (message) => {
                                addFlashMessage('ERROR', message);
                                scrollToTop();
                            }
                        "
                    />
                </div>
            </div>
            <CommissionsFromOtherStoreTableCard
                :start-time-period="startTimePeriod?.TIME_PERIOD"
                :end-time-period="endTimePeriod?.TIME_PERIOD"
                :location="locationName"
                :employee-id="employeeId"
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }
                "
            />
        </div>
        <div class="col-xl-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-arrows-in-line-horizontal me-2"></i>Add Comm/Less Comm
                </legend>
                <!-- Note: There are 6 commission fields in the database, that's why it's hardcoded like this -->
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC1"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription1"
                            :errors="errors.COMM_DESC1"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC1"
                                    disabled
                                    id-prefix="commissionLocation1"
                                    :errors="errors.COMM_LOC1"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC1 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(1);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR1"
                            id-prefix="year1"
                            :errors="errors.YEAR1"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_1"
                            id-prefix="commission1"
                            :errors="errors.COMM_1"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC2"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription2"
                            :errors="errors.COMM_DESC2"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC2"
                                    disabled
                                    id-prefix="commissionLocation2"
                                    :errors="errors.COMM_LOC2"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC2 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(2);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR2"
                            id-prefix="year2"
                            :errors="errors.YEAR2"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_2"
                            id-prefix="commission2"
                            :errors="errors.COMM_2"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC3"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription3"
                            :errors="errors.COMM_DESC3"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC3"
                                    disabled
                                    id-prefix="commissionLocation3"
                                    :errors="errors.COMM_LOC3"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC3 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(3);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR3"
                            id-prefix="year3"
                            :errors="errors.YEAR3"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_3"
                            id-prefix="commission3"
                            :errors="errors.COMM_3"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC4"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription4"
                            :errors="errors.COMM_DESC4"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC4"
                                    disabled
                                    id-prefix="commissionLocation4"
                                    :errors="errors.COMM_LOC4"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC4 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(4);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR4"
                            id-prefix="year4"
                            :errors="errors.YEAR4"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_4"
                            id-prefix="commission4"
                            :errors="errors.COMM_4"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC5"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription5"
                            :errors="errors.COMM_DESC5"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC5"
                                    disabled
                                    id-prefix="commissionLocation5"
                                    :errors="errors.COMM_LOC5"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC5 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(5);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR5"
                            id-prefix="year5"
                            :errors="errors.YEAR5"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_5"
                            id-prefix="commission5"
                            :errors="errors.COMM_5"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-3 mb-2">
                    <div class="col-3">
                        <FormSelect
                            v-model="commissionForm.COMM_DESC6"
                            :options="commissionDescriptionOptions"
                            id-prefix="commissionDescription6"
                            :errors="errors.COMM_DESC6"
                            hide-label
                            select-class="form-select-sm"
                        />
                    </div>
                    <div class="col-4">
                        <div class="row g-2">
                            <div class="col">
                                <FormInput
                                    v-model="commissionForm.COMM_LOC6"
                                    disabled
                                    id-prefix="commissionLocation6"
                                    :errors="errors.COMM_LOC6"
                                    hide-label
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="col-auto px-0 add-location-comm d-flex">
                                <button type="button"
                                    class="btn btn-outline-danger btn-sm me-1 px-2"
                                    @click="
                                        (_) => {
                                            commissionForm.COMM_LOC6 = null;
                                        }
                                    ">
                                    <i class="icon ph-bold ph-x-circle"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-primary btn-sm px-2"
                                    @click="
                                        (_) => {
                                            selectLocationForCommission(6);
                                        }
                                    ">
                                    <i class="icon ph-bold ph-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <FormInput
                            type="number"
                            v-model="commissionForm.YEAR6"
                            id-prefix="year6"
                            :errors="errors.YEAR6"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-3">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="commissionForm.COMM_6"
                            id-prefix="commission6"
                            :errors="errors.COMM_6"
                            hide-label
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <hr class="my-3" />
                <div class="row g-3 justify-content-end">
                    <div class="col-md-6">
                        <FormInput
                            v-model="finalAmountDisplay"
                            type="number"
                            step=".01"
                            disabled
                            label="Final"
                            id-prefix="final"
                            is-horizontal
                            label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                        />
                    </div>
                    <div class="col-md-3 text-end">
                        <button type="button"
                            class="btn btn-primary"
                            :disabled="!employeeId"
                            @click="saveCommission">
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save Data
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        @select="
            (selectedLocation) => {
                location = selectedLocation;
                isSelectLocationPopupOpen = false;
            }
        "
        @close="
            (_) => {
                isSelectLocationPopupOpen = false;
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <!-- For selecting location of each commission -->
    <SelectLocationPopup
        :is-open="isSelectCommissionLocationPopupOpen"
        @select="
            (selectedLocation) => {
                commissionForm[`COMM_LOC${indexOfCommissionLocationToSet}`] =
                    selectedLocation.LOCATION;
                isSelectCommissionLocationPopupOpen = false;
            }
        "
        @close="
            (_) => {
                isSelectCommissionLocationPopupOpen = false;
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <SelectEmployeeWithCommissionsInStorePopup
        :start-time-period="startTimePeriod?.TIME_PERIOD"
        :end-time-period="endTimePeriod?.TIME_PERIOD"
        :location="locationName"
        :is-open="isSelectEmployeePopupOpen"
        @select="
            (selectedEmployee) => {
                employee = selectedEmployee;
                isSelectEmployeePopupOpen = false;
            }
        "
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useFlashMessages } from '@/composables/flashmessages';
import { useTimePeriods } from '@/composables/data/timePeriods';
import { useEmployeeCommissions } from '@/composables/data/employeeCommissions';
import CommissionsTableCard from './CommissionsTableCard.vue';
import SoldFromOtherStoreTableCard from './SoldFromOtherStoreTableCard.vue';
import CommissionsFromOtherStoreTableCard from './CommissionsFromOtherStoreTableCard.vue';
import LocationsNotInCommissionsTableCard from './LocationsNotInCommissionsTableCard.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';
import SelectEmployeeWithCommissionsInStorePopup from './SelectEmployeeWithCommissionsInStorePopup.vue';

import moment from 'moment';

import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { timePeriods, getTimePeriods } = useTimePeriods();

const isSelectLocationPopupOpen = ref(false);
const isSelectEmployeePopupOpen = ref(false);

const commissionDescriptionOptions = ['ADD_COMMISSION', 'LESS_COMMISSION', 'ADD_MISSING', 'LESS_MISSING'];

const { actualCommission, getActualCommission, putActualCommission } = useEmployeeCommissions();

const isSelectCommissionLocationPopupOpen = ref(false);
const indexOfCommissionLocationToSet = ref(null);

function getInitialCommissionForm() {
    return {
        COMM_DESC1: null,
        COMM_LOC1: null,
        YEAR1: null,
        COMM_1: null,
        COMM_DESC2: null,
        COMM_LOC2: null,
        YEAR2: null,
        COMM_2: null,
        COMM_DESC3: null,
        COMM_LOC3: null,
        YEAR3: null,
        COMM_3: null,
        COMM_DESC4: null,
        COMM_LOC4: null,
        YEAR4: null,
        COMM_4: null,
        COMM_DESC5: null,
        COMM_LOC5: null,
        YEAR5: null,
        COMM_5: null,
        COMM_DESC6: null,
        COMM_LOC6: null,
        YEAR6: null,
        COMM_6: null,
    };
}

const commissionForm = ref(getInitialCommissionForm());
const errors = ref({});

const netAmountFromCommissions = ref(null); // This is set via an update event on CommissionsTableCard
const finalAmount = computed(() => (netAmountFromCommissions.value || 0) +
    (commissionForm.value.COMM_1 || 0) + (commissionForm.value.COMM_2 || 0) +
    (commissionForm.value.COMM_3 || 0) + (commissionForm.value.COMM_4 || 0) +
    (commissionForm.value.COMM_5 || 0) + (commissionForm.value.COMM_6 || 0));
const finalAmountDisplay = computed(() => finalAmount.value.toFixed(2));

onMounted(async () => {
    try {
        await getTimePeriods({
            filters: {
                PAYTYPE: {
                    value: 'MONTHLY',
                    matchMode: 'equals'
                },
                START_DATE: {
                    value: moment().format('YYYY-MM-DD'),
                    matchMode: 'lte',
                }
            },
            multiSortMeta: [
                {
                    field: 'TIME_PERIOD',
                    order: -1,
                }
            ]
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
});

const startTimePeriod = ref(null);
const endTimePeriod = ref(null);
const location = ref(null);
const employee = ref(null);

const locationName = computed(() => location.value?.LOCATION);
const employeeId = computed(() => employee.value?.EMPID);

watch([startTimePeriod, endTimePeriod], () => {
    if (!startTimePeriod.value || !endTimePeriod.value) {
        return;
    }

    if (startTimePeriod.value.TIME_PERIOD > endTimePeriod.value.TIME_PERIOD) {
        addFlashMessage('ERROR', 'Please specify an end date after the start date.');
        scrollToTop();
        setTimeout(() => {
            startTimePeriod.value = null;
            endTimePeriod.value = null;
        });
    }
});

watch([startTimePeriod, endTimePeriod, location], () => {
    employee.value = null;
});

watch(employee, async () => {
    commissionForm.value = getInitialCommissionForm();

    if (!startTimePeriod.value || !endTimePeriod.value || !location.value || !employee.value)
        return;

    try {
        await getActualCommission(employee.value.EMPID, startTimePeriod.value.START_DATE,
            endTimePeriod.value.END_DATE, location.value.LOCATION);

        const year1 = actualCommission.value.YEAR1 != null ?
            moment(actualCommission.value.YEAR1, 'YYYY-MM-DD').year() : null;
        const year2 = actualCommission.value.YEAR2 != null ?
            moment(actualCommission.value.YEAR2, 'YYYY-MM-DD').year() : null;
        const year3 = actualCommission.value.YEAR3 != null ?
            moment(actualCommission.value.YEAR3, 'YYYY-MM-DD').year() : null;
        const year4 = actualCommission.value.YEAR4 != null ?
            moment(actualCommission.value.YEAR4, 'YYYY-MM-DD').year() : null;
        const year5 = actualCommission.value.YEAR5 != null ?
            moment(actualCommission.value.YEAR5, 'YYYY-MM-DD').year() : null;
        const year6 = actualCommission.value.YEAR6 != null ?
            moment(actualCommission.value.YEAR6, 'YYYY-MM-DD').year() : null;

        commissionForm.value =  {
            ...actualCommission.value,
            YEAR1: year1 != 1900 ? year1 : null,
            YEAR2: year2 != 1900 ? year2 : null,
            YEAR3: year3 != 1900 ? year3 : null,
            YEAR4: year4 != 1900 ? year4 : null,
            YEAR5: year5 != 1900 ? year5 : null,
            YEAR6: year6 != 1900 ? year6 : null,
        };
    } catch(e) {
        if (e.status == 404) return;
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
});

function selectLocationForCommission(index) {
    indexOfCommissionLocationToSet.value = index;
    isSelectCommissionLocationPopupOpen.value = true;
}

async function saveCommission() {
    errors.value = {};
    try {
        await putActualCommission(employee.value.EMPID, startTimePeriod.value.START_DATE,
            endTimePeriod.value.END_DATE, location.value.LOCATION, commissionForm.value);
        addFlashMessage('SUCCESS', 'Successfully saved commission.');
    } catch(e) {
        errors.value = e.errors ?? {};
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
}

function generateSpreadsheet() {
    window.open(route('api.locations.commissions-spreadsheet.show', {
        location: location.value.LOCATION,
        START_TIME_PERIOD: startTimePeriod.value.TIME_PERIOD,
        END_TIME_PERIOD: endTimePeriod.value.TIME_PERIOD
    }));
}

function generateSummarySpreadsheet() {
    window.open(route('api.locations.commission-summary-spreadsheet.show', {
        location: location.value.LOCATION,
        START_TIME_PERIOD: startTimePeriod.value.TIME_PERIOD,
        END_TIME_PERIOD: endTimePeriod.value.TIME_PERIOD
    }));
}
</script>
