<template>
    <div class="row g-3 mb-3">
        <div class="col-md-6">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Leaves This Year
                </legend>
                <DataTable
                    :loading="loadingFlags.has('fetchLeaves')"
                    :value="otherEmployeeLeaves"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-sm table-bordered table-hover' } }"
                    class="mb-3"
                >
                    <Column
                        field="NAME"
                        header="Name"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE"
                        header="Date"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="RATE"
                        header="Rate"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="HOURS"
                        header="Hours"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center">
                            <template v-if="!employee">
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-user me-2"></i>Please select an employee.
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-island me-2"></i>No leaves.
                                </div>
                            </template>
                        </div>
                    </template>
                </DataTable>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar-check me-2"></i>Leaves Inputted Now
                </legend>
                <DataTable
                    :loading="loadingFlags.has('fetchLeaves')"
                    :value="currentEmployeeLeaves"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-sm table-bordered table-hover' } }"
                >
                    <Column
                        field="NAME"
                        header="Name"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' }
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE"
                        header="Date"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' }
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="RATE"
                        header="Rate"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' }
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="HOURS"
                        header="Hours"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' }
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center">
                            <template v-if="!employee">
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-user me-2"></i>Please select an employee.
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-island me-2"></i>No leaves.
                                </div>
                            </template>
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-md-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-island me-2"></i>Leaves Taken This Year: {{ timePeriodYear }}
                </legend>
                <template v-if="loadingFlags.has('fetchLeaves')">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Calculating leaves...
                </template>
                <template v-else>
                    <p class="form-text">
                        Please click on Check Leaves button to see taken leaves
                        and updated calculation.
                    </p>
                    <FormInput
                        label="Incentives (SALES)"
                        v-model="incentivesSalesTotalHours"
                        is-horizontal
                        disabled
                        id-prefix="incentivesSales"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        label="Incentives (REG)"
                        v-model="incentivesRegTotalHours"
                        is-horizontal
                        disabled
                        id-prefix="incentivesReg"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        label="Years Stayed"
                        v-model="yearsStayed"
                        is-horizontal
                        disabled
                        id-prefix="yearsStayed"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="row">
                        <div class="col-xl-4 col-md-5">All Leaves</div>
                        <div class="col-xl-8 col-md-7 col-12">
                            <div class="row g-1">
                                <div class="col-6">
                                    <FormInput
                                        label="Total Leaves (Days)"
                                        v-model="totalLeavesDays"
                                        hide-label
                                        disabled
                                        id-prefix="totalLeavesDays"
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                </div>
                                <div class="col-6">
                                    <FormInput
                                        label="Total Leaves (Hours)"
                                        v-model="totalLeavesHours"
                                        hide-label
                                        disabled
                                        id-prefix="totalLeavesHours"
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormInput
                        label="Available Leaves this Time Period"
                        v-model="availableLeaves"
                        is-horizontal
                        disabled
                        id-prefix="availableLeaves"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        label="Total Tabulation"
                        v-model="totalTabulation"
                        is-horizontal
                        disabled
                        id-prefix="totalTabulation"
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                </template>
                <button
                    type="button"
                    class="btn btn-primary btn-sm mt-4"
                    :disabled="!employee"
                    @click="fetchLeaves">
                    <i class="icon ph-bold ph-checks me-2"></i>Check Leaves
                </button>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import FormInput from '@/components/utils/FormInput.vue';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import moment from 'moment';
import axios from 'axios';

const props = defineProps({
    employee: Object,
    timePeriod: Object,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const employeeLeaves = ref(null);

const otherEmployeeLeaves = ref(null);
const currentEmployeeLeaves = ref(null);

const timePeriodYear = computed(() => {
    if (!props.timePeriod) {
        return null;
    }
    timePeriodYear.value = moment(props.timePeriod.START_DATE).year();
});

const incentivesSalesTotalHours = ref(null);
const incentivesRegTotalHours = ref(null);
const yearsStayed = ref(null);
const totalLeavesDays = ref(null);
const totalLeavesHours = computed(() => totalLeavesDays.value ? totalLeavesDays.value * 8 : null);
const availableLeaves = defineModel('availableLeavesDays');
const totalTabulation = ref(null);

watch(() => props.employee, () => {
    if (props.employee) {
        fetchLeaves();
    }
});

async function fetchLeaves() {
    employeeLeaves.value = null;

    if (!props.employee || !props.timePeriod) {
        return;
    }

    loadingFlags.add('fetchLeaves');
    try {
        const leavesResponse = await axios.get(route('api.employees.hours.index', [props.employee.EMPID]), {
            params: {
                JONO: 'LEAVE',
                YEAR: new Date(props.timePeriod.START_DATE).getFullYear()
            }
        });
        employeeLeaves.value = leavesResponse.data.data
            .filter(employeeLeave => employeeLeave.HOURS > 0)
            .map(employeeLeave => ({ ...employeeLeave, NAME: `${props.employee.FNAME} ${props.employee.LNAME}`}));

        // Segregate display of leaves outside time period and leaves within time period
        const timePeriodStartDate = moment(props.timePeriod.START_DATE, 'YYYY-MM-DD');
        const timePeriodEndDate = moment(props.timePeriod.END_DATE, 'YYYY-MM-DD');

        otherEmployeeLeaves.value = employeeLeaves.value.filter(leave => {
            const leaveDate = moment(leave.DATE, 'YYYY-MM-DD');
            return leaveDate.isBefore(timePeriodStartDate) || leaveDate.isAfter(timePeriodEndDate);
        });
        currentEmployeeLeaves.value = employeeLeaves.value.filter(leave => {
            const leaveDate = moment(leave.DATE, 'YYYY-MM-DD');
            return leaveDate.isSameOrAfter(timePeriodStartDate) && leaveDate.isSameOrBefore(timePeriodEndDate);
        });

        // Days of work
        const getDaysOfWorkResponse = await axios.get(route('api.employees.days-of-work.show', {
            employee: props.employee.EMPID,
            date: props.timePeriod.END_DATE,
        }));
        totalTabulation.value = parseFloat(getDaysOfWorkResponse.data.data.toFixed(2));

        incentivesSalesTotalHours.value = otherEmployeeLeaves.value.filter((leave) =>
            leave.JONO === 'LEAVE' && leave.STYLE === 'INCENTIVE (SALES)'
        ).reduce((totalHours, leave) => totalHours += leave.HOURS, 0);

        incentivesRegTotalHours.value = otherEmployeeLeaves.value.filter((leave) =>
            leave.JONO === 'LEAVE' && leave.STYLE === 'INCENTIVE (REG)'
        ).reduce((totalHours, leave) => totalHours += leave.HOURS, 0);

        yearsStayed.value = moment().diff(props.employee.HIREDATE, 'years');

        // Total Leaves
        const getTotalLeavesResponse = await axios.get(route('api.employees.total-leaves.show', {
            employee: props.employee.EMPID,
            date: props.timePeriod.END_DATE,
        }));
        totalLeavesDays.value = getTotalLeavesResponse.data.data;

        if (props.employee.DEPT.includes("SALES") || props.employee.DEPT.includes("SC")) {
            availableLeaves.value = (totalLeavesHours.value - incentivesSalesTotalHours.value) / 8;
        } else {
            availableLeaves.value = (totalLeavesHours.value - incentivesRegTotalHours.value) / 8;
        }
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.response?.data.message ?? e.message);
    }
    loadingFlags.delete('fetchLeaves');
}
</script>
