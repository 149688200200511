<template>
    <DataTable :loading="isTableLoading"
        lazy
        :value="comments"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="(e) => { onPage(e); fetchLostComments(); }"
        v-model:filters="filters"
        filter-display="row"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="(e) => { onSort(e); fetchLostComments(); }"
        v-model:selection="selectedComment"
        selection-mode="single"
        :class="class"
        :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
        <Column
            field="COMMENT_ID"
            header="Comment ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="COMMENT_TEXT"
            header="Text"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="COMMENT_RPT_DATE"
            header="Date"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="LOCATION"
            header="Location"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">No data.</div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useComments } from "@/composables/data/comments";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    startDate: String,
    endDate: String,
    class: String,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "COMMENT_ID",
    "COMMENT_TEXT",
    "COMMENT_RPT_DATE",
    "LOCATION",
]);

const { comments, totalRecords, getComments } = useComments();

const selectedComment = defineModel();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchLostComments() {
    comments.value = null;

    if (!props.startDate || !props.endDate) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getComments({
            ...params.value,
            filters: {
                ...params.value.filters,
                COMMENT_RPT_DATE: {
                    value: [props.startDate, props.endDate],
                    matchMode: "between",
                },
                COMMENT_STATUS: {
                    value: "O",
                    matchMode: "equals",
                },
                LOCATION: {
                    value: "LOST",
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        emit("error", e.message);
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLostComments, 300);
}

onMounted(fetchLostComments);
watch(() => [props.startDate], fetchLostComments);
</script>
