<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Replacement Barcodes
        </legend>
        <DataTable
            :loading="isLoading"
            lazy
            :value="inventoryRecords"
            :total-records="inventoryTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchReplacementBarcodes(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:selection="selectedRecord"
            selection-mode="single"
            :pt="{ table: { class: tableClass } }">
            <Column
                field="STYLE"
                header="Style"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Style"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Date"
                    />
                </template>
            </Column>
            <Column
                field="SERIAL"
                header="Serial"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Serial"
                    />
                </template>
            </Column>
            <Column
                field="SEQ_NO"
                header="Seq. No"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Seq. No"
                        disabled
                    />
                </template>
            </Column>
            <Column
                field="EVENT_DT"
                header="Event Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Event Date"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Color"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <hr class="my-3" />
            <button
                type="button"
                class="btn btn-primary"
                :disabled="!selectedRecord"
                @click="(_) => { isMovementsTablePopupOpen = true; }">
                <i class="icon ph-bold ph-arrows-out-cardinal me-2"></i>View Movements
            </button>
        </div>
    </fieldset>
    <MovementsTablePopup
        :barcode="selectedRecordBarcode"
        :is-open="isMovementsTablePopupOpen"
        @close="(_) => { isMovementsTablePopupOpen = false; }"
        @error="(message) => { isMovementsTablePopupOpen = false; emit('error', message); }"
    />
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import MovementsTablePopup from "./MovementsTablePopup.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
    tableClass: String,
    style: String,
    class: String,
});

const emit = defineEmits(["error"]);

const tableClass = computed(() => {
    let tableClass = "table table-bordered table-hover";
    if (props.tableClass) {
        tableClass += " " + props.tableClass;
    }
    return tableClass;
});

const { params, filters, onPage, onFilter } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "SEQ_NO",
    "EVENT_DT",
    "COLOR",
]);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } =
    useInventory();

const selectedRecord = ref(null);

const selectedRecordBarcode = computed(() => {
    if (!selectedRecord.value) {
        return null;
    }
    return {
        STYLE: selectedRecord.value.STYLE,
        DATE: selectedRecord.value.DATE,
        SERIAL: selectedRecord.value.SERIAL,
    };
});

const fetchTimeout = ref(null);

const isLoading = ref(false);

const isMovementsTablePopupOpen = ref(false);

async function fetchReplacementBarcodes() {
    inventoryRecords.value = null;

    if (!props.location) {
        return;
    }

    isLoading.value = true;
    try {
        await getInventoryRecords({
            ...params.value,
            filters: {
                ...params.value.filters,
                // Fetch only first logs of each barcode (to get unique barcodes,
                // similar to old code setting PREV_LOC = '' or no previous location)
                SEQ_NO: {
                    value: 1,
                    matchMode: "equals",
                },
                LOCATION: {
                    value: props.location,
                    matchMode: "equals",
                },
            },
            isRR: true,
        });
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReplacementBarcodes, 300);
}

watch(() => props.location, fetchReplacementBarcodes);

watch(
    () => props.style,
    async () => {
        filters.value.STYLE.value = props.style;
        onFilter();
        await fetchReplacementBarcodes();
    }
);
</script>
