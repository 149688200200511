<template>
    <div class="row mb-3">
        <div class="col-lg-4 mb-lg-0 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clock me-2"></i>Employee Hours NIDEKA
                </legend>
                <p>Last File Uploaded: {{ lastFileUploadedName ?? "No file yet." }}</p>
                <div class="mb-3">
                    <label for="csvFileInput" class="form-label">Upload a file</label>
                    <input
                        type="file"
                        required
                        ref="csvFileInput"
                        accept=".csv"
                        @change="csvFileOnChange"
                        id="csvFileInput"
                        class="form-control form-control-sm"
                    />
                </div>
                <div>
                    <button type="submit"
                        class="btn btn-primary btn-sm me-2 mb-1"
                        @click="uploadCsvFile">
                        <i class="icon ph-bold ph-download-simple me-2"></i>Import File
                    </button>
                    <button type="button"
                        class="btn btn-outline-secondary btn-sm me-2 mb-1"
                        @click="clearFlashMessages">
                        <i class="icon ph-bold ph-x me-2"></i>Dismiss Notifications
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-cloud-warning me-2"></i>Import Errors
                </legend>
                <DataTable
                    :value="importErrors"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="status" header="Status">
                        <template #body="{ data }">
                            <span v-if="data.status == 'ERROR'" class="badge bg-danger">Error</span>
                        </template>
                    </Column>
                    <Column field="field" header="Field" />
                    <Column field="message" header="Message" />
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-warning me-2"></i>No errors.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <FormSelect
                    v-model="selectedPaytype"
                    label="Paytype"
                    id-prefix="paytype"
                    :options="['BIMONTHLY', 'MONTHLY', 'WEEKLY']"
                    class="mb-3"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                    select-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                    select-class="form-select-sm"
                />
                <TimePeriodTable
                    show-from-current-date
                    :paytype="selectedPaytype ?? 'WEEKLY'"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    :hide-properties="new Set(['STATUS'])"
                    v-model="selectedTimePeriod"
                />
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clock-user me-2"></i>Current Imported Hours
                </legend>
                <div class="text-end mb-3">
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        @click="deleteImportedHours">
                        <i class="icon ph-bold ph-clock-clockwise me-2"></i>Reset Imported Hours
                    </button>
                </div>
                <DataTable
                    :loading="loadingFlags.has('fetchNidekaLogs')"
                    :value="nidekaLogs"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="EMPID" header="Employee ID" />
                    <Column field="nideka_map.employee.EMPID" header="NIDEKA ID" />
                    <Column field="nideka_map.employee.FNAME" header="First Name" />
                    <Column field="nideka_map.employee.LNAME" header="Last Name" />
                    <Column field="DATE_TIME" header="Date/Time" />
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data yet.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useFlashMessages } from "@/composables/flashmessages";
import FormSelect from "@/components/utils/FormSelect.vue";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable.vue";

import axios from "axios";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { addFlashMessage, clearFlashMessages } = useFlashMessages();

const selectedPaytype = ref(null);
const selectedTimePeriod = ref(null);

const csvFileInput = ref();
const csvFile = ref(null);

const lastFileUploadedName = ref(null);

const importErrors = ref(null);

const nidekaLogs = ref(null);

function csvFileOnChange(event) {
    const file = event.target.files[0];
    csvFile.value = file;
}

onMounted(async () => {
    loadingFlags.add('fetchLatestCSV');

    try {
        const latestCSVResponse = await axios.get(
            route("api.nideka-csvs.latest")
        );
        lastFileUploadedName.value = latestCSVResponse.data.data.filename;
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching latest CSV information: ' + (e.response?.data.message ?? e.message));
    }

    loadingFlags.delete('fetchLatestCSV');
});

async function uploadCsvFile() {
    importErrors.value = null;

    loadingFlags.add('uploadCsvFile');
    try {
        const formData = new FormData();
        formData.append("csv", csvFile.value);
        const response = await axios.post(route('api.nideka-csvs.store'), formData);
        toasts.add('SUCCESS', 'Success', response.data.message);
        if (response.data.errors) {
            importErrors.value = Object.entries(response.data.errors).reduce((importErrors, [field, errors]) => {
                return [
                    ...importErrors,
                    ...errors.map(error => ({
                        status: 'ERROR',
                        field: field,
                        message: error
                    }))
                ];
            }, []);
        }
        csvFileInput.value.value = null;
        csvFile.value = null;
        lastFileUploadedName.value = response.data.data.filename;
        fetchNidekaLogs();
    } catch (e) {
        console.log(e);
        if (e.response) {
            if (e.response.status == 413) {
                toasts.add('ERROR', 'Error', 'File is too large.');
            } else {
                toasts.add('ERROR', 'Error', e.response.data.message);
                if (e.response.data.errors) {
                    Object.entries(e.response.data.errors).forEach(([field, errors]) => {
                        errors.forEach(error => {
                            addFlashMessage('ERROR', error);
                        });
                    });
                }
            }
        } else {
            toasts.add('ERROR', 'Error', 'Error while submitting CSV. ' +
                'Please contact your administrator.');
        }
    }
    loadingFlags.delete('uploadCsvFile');
}

async function fetchNidekaLogs() {
    nidekaLogs.value = null;

    if (!selectedTimePeriod.value) {
        return;
    }

    loadingFlags.add('fetchNidekaLogs');
    try {
        const nidekaLogsResponse = await axios.get(route('api.nideka-logs.index', {
            TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD
        }));
        nidekaLogs.value = nidekaLogsResponse.data.data;
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching logs: ' + (e.response?.data.message ?? e.message));
    }
    loadingFlags.delete('fetchNidekaLogs');
}

watch(selectedTimePeriod, fetchNidekaLogs);

async function deleteImportedHours() {
    if (prompt("Are you sure? Enter 'DELETE' to continue.") !== 'DELETE') {
        return;
    }

    loadingFlags.add('deleteImportedHours');
    try {
        const response = await axios.delete(route('api.nideka-logs.all.destroy'));
        toasts.add('SUCCESS', 'Success', response.data.message);
        fetchNidekaLogs();
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while clearing logs: ' + (e.response?.data.message ?? e.message));
    }
    loadingFlags.delete('deleteImportedHours');
}
</script>
