<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-package me-2"></i> Landed Cost Entered
        </legend>
        <DataTable
            lazy
            :loading="isLoading"
            :value="landedCosts"
            :total-records="landedCostsTotalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchLandedCosts();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:selection="selectedLandedCost"
            selection-mode="single"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchLandedCosts();
                }
            "
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COUNT"
                header="Count"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LANDED_COST"
                header="Landed Cost"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i> No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-primary me-1 mb-1 btn-sm"
                :disabled="!date"
                @click="
                    (_) => {
                        isExportChoicePopupOpen = true;
                    }
                "
            >
                <i class="icon ph-bold ph-notepad me-2"></i>Generate Report
            </button>
            <button
                type="button"
                class="btn btn-primary me-1 mb-1 btn-sm"
                :disabled="!selectedLandedCost"
                @click="updateNewLandedCost"
            >
                <i class="icon ph-bold ph-coins me-2"></i>Update Landed Cost
            </button>
            <button
                type="button"
                class="btn btn-primary me-1 mb-1 btn-sm"
                :disabled="isLoading"
                @click="fetchLandedCosts"
            >
                <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                >
                </span>
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Refresh
            </button>
        </div>
    </fieldset>

    <ExportChoicePopup
        title="Export Landed Costs"
        :is-open="isExportChoicePopupOpen"
        @close="
            (_) => {
                isExportChoicePopupOpen = false;
            }
        "
        @export-pdf="
            (_) => {
                isExportChoicePopupOpen = false;
                isSpecifyMarginsPopupOpen = true;
            }
        "
        @export-spreadsheet="
            (_) => {
                isExportChoicePopupOpen = false;
                exportSpreadsheet();
            }
        "
    />

    <SpecifyMarginsPopup
        :is-open="isSpecifyMarginsPopupOpen"
        @close="
            (_) => {
                isSpecifyMarginsPopupOpen = false;
            }
        "
        @done="
            (margins) => {
                exportPDF(margins);
            }
        "
    />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLandedCosts } from "@/composables/data/landedCosts";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import ExportChoicePopup from "@/components/utils/popups/ExportChoicePopup.vue";
import SpecifyMarginsPopup from "@/components/utils/popups/SpecifyMarginsPopup.vue";

const props = defineProps({
    date: String,
    class: String,
    refreshFlag: Boolean,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "COUNT",
    "LANDED_COST",
]);

const {
    landedCosts,
    landedCostsTotalRecords,
    getLandedCosts,
    updateLandedCost,
} = useLandedCosts();

const selectedLandedCost = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const isExportChoicePopupOpen = ref(false);
const isSpecifyMarginsPopupOpen = ref(false);

async function fetchLandedCosts() {
    landedCosts.value = null;

    isLoading.value = true;
    try {
        await getLandedCosts(params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLandedCosts, 300);
}

onMounted(fetchLandedCosts);

watch(
    () => props.date,
    () => {
        filters.value.DATE.value = props.date;
        tableOnFilter();
    }
);

async function updateNewLandedCost() {
    try {
        const newLandedCost = window.prompt("Input new landed cost:");
        await updateLandedCost(
            selectedLandedCost.value.STYLE,
            selectedLandedCost.value.DATE,
            newLandedCost
        );
        emit(
            "update",
            `Successfully updated landed cost ${newLandedCost} for ` +
                `STYLE = ${selectedLandedCost.value.STYLE} DATE = ${selectedLandedCost.value.DATE}.`
        );
        fetchLandedCosts();
    } catch (e) {
        emit("error", e.message);
    }
}

watch(() => props.refreshFlag, fetchLandedCosts);

function exportPDF(margins) {
    window.open(
        route("api.landed-costs.by-date.pdf.show", {
            date: props.date,
            margins,
        })
    );
}

function exportSpreadsheet() {
    window.open(
        route("api.landed-costs.by-date.spreadsheet.show", {
            date: props.date,
        })
    );
}
</script>
