<template>
    <fieldset :class="class">
        <legend>II. INVENTORY_CHECK</legend>
        <DataTable
            :loading="isTableLoading"
            lazy
            :value="inventoryChecks"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchInventoryChecks(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="(e) => { onSort(e); fetchInventoryChecks(); }"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="INVENTORY_DONE"
                header="Inventory Done"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LOCATION"
                header="Location"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryChecks } from "@/composables/data/inventoryChecks";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import scrollToTop from "@/utils/scrollToTop";
import { constructBarcode } from "@/utils/barcodes";

const props = defineProps({
    bagIdentifier: Object,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "INVENTORY_DONE",
    "LOCATION",
]);

const { inventoryChecks, totalRecords, getInventoryChecks } = useInventoryChecks();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchInventoryChecks() {
    inventoryChecks.value = null;

    if (!props.bagIdentifier || !props.bagIdentifier.DATE) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getInventoryChecks({
            ...params.value,
            filters: {
                ...params.value.filters,
                RAW: {
                    value: constructBarcode(
                        props.bagIdentifier.STYLE,
                        props.bagIdentifier.DATE,
                        props.bagIdentifier.SERIAL
                    ),
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter() {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryChecks, 300);
}

watch(() => props.bagIdentifier, fetchInventoryChecks);

watch(() => props.refreshFlag, fetchInventoryChecks);
</script>
