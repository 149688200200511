<template>
    <fieldset :class="class">
        <legend>III. INVENTORY_RECON_DELETE</legend>
        <DataTable
            :loading="isTableLoading"
            lazy
            :value="inventoryReconDeletes"
            :total-records="inventoryReconDeletesTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchInventoryReconDeletes(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="(e) => { onSort(e); fetchInventoryReconDeletes(); }"
            v-model:selection="selectedRecord"
            selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SERIAL"
                header="Serial"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SEQ_NO"
                header="Seq"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PREV_LOC"
                header="Prev. Loc."
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LOCATION"
                header="Location"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PRICE"
                header="Price"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COMMENT_ID"
                header="Comment"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryReconDeletes } from "@/composables/data/inventoryReconDeletes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    location: String,
    style: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "SEQ_NO",
    "COLOR",
    "PREV_LOC",
    "LOCATION",
    "PRICE",
    "COMMENT_ID",
]);

const {
    inventoryReconDeletes,
    inventoryReconDeletesTotalRecords,
    getInventoryReconDeletes,
} = useInventoryReconDeletes();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

const selectedRecord = defineModel();

const selectedBagIdentifier = defineModel('selectedBagIdentifier');

async function fetchInventoryReconDeletes() {
    inventoryReconDeletes.value = null;

    if (!props.location || !props.style) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getInventoryReconDeletes(
            props.location,
            props.style,
            params.value
        );
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryReconDeletes, 300);
}

watch(() => [props.style, props.refreshFlag], fetchInventoryReconDeletes);

watch(selectedRecord, () => {
    if (!selectedRecord.value) {
        return;
    }

    selectedBagIdentifier.value = {
        STYLE: selectedRecord.value.STYLE,
        DATE: selectedRecord.value.DATE,
        SERIAL: selectedRecord.value.SERIAL,
        SEQ_NO: selectedRecord.value.SEQ_NO,
    };
});
</script>
