import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function usePageLocks() {
    const pageLock = ref(null);

    const getPageLock = async (page) => {
        try {
            const getPageLockResponse = await axios.get(route('api.page-locks.show', {
                page_lock: page,
            }));
            pageLock.value = getPageLockResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching page lock.');
        }
    };
    
    return {
        pageLock,
        getPageLock,
    };
}
