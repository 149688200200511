<template>
    <div class="row g-3">
        <div class="col-xl-4">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select Location
                </legend>
                <LocationTable v-model="selectedLocation" />
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-tea-bag me-2"></i>Select Style
                </legend>
                <SelectStyleUsedInLocationCard :location="selectedLocation" />
            </fieldset>
        </div>
        <div class="col-xl-3">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <TimePeriodTable
                    v-model="selectedTimePeriod"
                    :show-from-current-date="true"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    paytype="MONTHLY"
                />
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-list me-2"></i>Masterlist Generations
                </legend>
                <ReconMasterlistGenerationsTableCard
                    :refresh-flag="reconMasterlistGenerationsTableRefreshFlag"
                    @error="
                        (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }
                    "
                />
            </fieldset>
        </div>
        <div class="col-xl-5">
            <div class="row g-3">
                <div class="col-6">
                    <fieldset class="mb-3">
                        <legend>
                            <i class="icon ph-bold ph-list me-2"></i>Masterlist
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod || isGenerating"
                                @click="
                                    (_) => {
                                        generateMasterlist();
                                    }
                                ">
                                <span v-if="isGenerating"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                1. GENERATE MASTER LIST (monthly)
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-detail-report'
                                        );
                                    }
                                ">
                                2a. VIEW MASTER LIST (detail)
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-report'
                                        );
                                    }
                                ">
                                2b. VIEW MASTER LIST (summary)
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-by-style-type-report'
                                        );
                                    }
                                ">
                                2c. VIEW MASTER LIST (summary+)
                            </button>
                        </div>
                    </fieldset>
                    <fieldset class="mb-3">
                        <legend>
                            <i class="icon ph-bold ph-storefront me-2"></i>KIMBEL
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-detail-report',
                                            'K'
                                        );
                                    }
                                ">
                                3a. VIEW MASTER LIST (detail) <br /> [KIMBEL]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-report',
                                            'K'
                                        );
                                    }
                                ">
                                3b. VIEW MASTER LIST (summary) <br /> [KIMBEL]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-by-style-type-report',
                                            'K'
                                        );
                                    }
                                ">
                                3c. VIEW MASTER LIST (summary+) <br /> [KIMBEL]
                            </button>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-storefront me-2"></i>LEB-ASI
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-detail-report',
                                            'L'
                                        );
                                    }
                                ">
                                4a. VIEW MASTER LIST (detail) <br /> [LEB-ASI]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-report',
                                            'L'
                                        );
                                    }
                                ">
                                4b. VIEW MASTER LIST (summary) <br /> [LEB-ASI]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-by-style-type-report',
                                            'L'
                                        );
                                    }
                                ">
                                4c. VIEW MASTER LIST (summary+) <br /> [LEB-ASI]
                            </button>
                        </div>
                    </fieldset>
                </div>
                <div class="col-6">
                    <fieldset class="mb-3">
                        <legend>
                            <i class="icon ph-bold ph-star me-2"></i>Bestsellers
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateBestsellersReport(
                                            'by_month_and_employee'
                                        );
                                    }
                                ">
                                1. by MONTH, EMP, STYLE
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateBestsellersReport('by_month');
                                    }
                                ">
                                2. by MONTH, STYLE
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateBestsellersReport(
                                            'by_date_and_employee'
                                        );
                                    }
                                ">
                                3. by DATE, EMP, STYLE
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateBestsellersReport('by_date');
                                    }
                                ">
                                4. by DATE, STYLE
                            </button>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-storefront me-2"></i>MATIBAY
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-detail-report',
                                            'M'
                                        );
                                    }
                                ">
                                5a. VIEW MASTER LIST (detail) <br /> [MATIBAY]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-report',
                                            'M'
                                        );
                                    }
                                ">
                                5b. VIEW MASTER LIST (summary) <br /> [MATIBAY]
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm px-1"
                                :disabled="!selectedTimePeriod"
                                @click="
                                    (_) => {
                                        generateMasterlistReport(
                                            'recon-masterlist-summary-by-style-type-report',
                                            'M'
                                        );
                                    }
                                ">
                                5c. VIEW MASTER LIST (summary+) <br /> [MATIBAY]
                            </button>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useFlashMessages } from '@/composables/flashmessages';
import { useReconMasterlists } from '@/composables/data/reconMasterlists';
import LocationTable from '@/components/utils/tables/LocationTable.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import SelectStyleUsedInLocationCard from './SelectStyleUsedInLocationCard.vue';
import ReconMasterlistGenerationsTableCard from './ReconMasterlistGenerationsTableCard.vue';
import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { postReconMasterlistGeneration } = useReconMasterlists();

const selectedTimePeriod = ref(null);
const selectedLocation = ref(null);

const reconMasterlistGenerationsTableRefreshFlag = ref(false);

const isGenerating = ref(false);

async function generateMasterlist() {
    isGenerating.value = true;
    try {
        const response = await postReconMasterlistGeneration(selectedTimePeriod.value);
        addFlashMessage('SUCCESS', response.data.message);
        reconMasterlistGenerationsTableRefreshFlag.value = !reconMasterlistGenerationsTableRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    scrollToTop();
    isGenerating.value = false;
}

function generateMasterlistReport(reportResource, companyCode = null) {
    window.open(route(`api.time-periods.${reportResource}.show`, {
            time_period: selectedTimePeriod.value.TIME_PERIOD,
            COMPANY_CODE: companyCode,
        }));
}

function generateBestsellersReport(reportType) {
    window.open(route(`api.time-periods.accounting-bestsellers-report.show`, {
            time_period: selectedTimePeriod.value.TIME_PERIOD,
            LOCATION: selectedLocation.value?.LOCATION,
            TYPE: reportType
        }));
}
</script>
