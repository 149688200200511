<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Style Robinsons
        </legend>
        <form>
            <div class="pb-1">
                <FormSelect
                    label="Color"
                    v-model="form.COLOR"
                    :options="colors"
                    is-horizontal
                    id-prefix="color"
                    class="mb-md-1 mb-2"
                    :errors="errors.COLOR"
                    @keydown-enter="focusNextInput('genericRobinsonsSkuFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    label="Generic SKU"
                    type="text"
                    v-model="form.ROBINSONS_SKU"
                    is-horizontal
                    id-prefix="genericRobinsonsSku"
                    class="mb-md-1 mb-2"
                    :errors="errors.ROBINSONS_SKU"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>

            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-primary me-1 btn-sm"
                    :disabled="!style"
                    @click="updateRobinsonsGenericSku">
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleRobinsonsGenericSkus } from "@/composables/data/styleRobinsonsGenericSkus";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

const props = defineProps({
    style: String,
    colors: Array,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { styleRobinsonsGenericSku, getStyleRobinsonsGenericSku, postStyleRobinsonsGenericSku } = useStyleRobinsonsGenericSkus();

const form = ref({
    COLOR: null,
    ROBINSONS_SKU: null,
});

const errors = ref({});

watch(
    () => props.style,
    async () => {
        errors.value = {};
        styleRobinsonsGenericSku.value = null;

        if (!props.style) {
            return;
        }

        loadingFlags.add("fetchStyleRobinsonsGenericSku");
        try {
            await getStyleRobinsonsGenericSku(props.style);
            form.value = styleRobinsonsGenericSku.value;
        } catch (e) {
            if (e.status != 404) {
                toasts.add('ERROR', 'Error', e.message);
            }
        }
        loadingFlags.delete("fetchStyleRobinsonsGenericSku");
    }
);

async function updateRobinsonsGenericSku() {
    errors.value = {};

    loadingFlags.add("updateRobinsonsGenericSku");
    try {
        const newRobinsonsGenericSku = await postStyleRobinsonsGenericSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated Robinsons Generic SKU to ${newRobinsonsGenericSku.ROBINSONS_SKU}.`
        );
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateRobinsonsGenericSku");
}
</script>
