import { ref } from 'vue';

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useLegacyBarcodes() {
    const legacyBarcodes = ref(null);
    const totalRecords = ref(null);

    const getLegacyBarcodes = async (params) => {
        try {
            const getLegacyBarcodesResponse = await axios.get(route('api.legacy-barcodes.index', params));
            legacyBarcodes.value = getLegacyBarcodesResponse.data.data;
            totalRecords.value = getLegacyBarcodesResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching legacy barcodes.');
        }
    };

    const getNewBarcode = async (legacyBarcode) => {
        try {
            const getNewBarcodeResponse = await axios.get(route('api.legacy-barcodes.show', {
                legacy_barcode: legacyBarcode
            }));
           return getNewBarcodeResponse.data.data.BARCODE_NEW;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching legacy barcode.');
        }
    };
    
    return {
        legacyBarcodes,
        totalRecords,
        getLegacyBarcodes,
        getNewBarcode,
    };
}
