<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-dots-three-outline me-2"></i>Commissions from Other Store
        </legend>
        <DataTable :loading="isLoading"
            :value="employeeCommissionsFromOtherStore"
            paginator
            :rows="10"
            v-model:filters="filters"
            filter-display="row"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column
                field="LOCATION"
                header="Location"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PERIOD"
                header="Period"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
                </template>
            </Column>
            <Column
                field="COMMISSION_AMT"
                header="Commission Amt"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
                </template>
            </Column>
            <Column
                field="MISSING_AMT"
                header="Missing Amt"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
                </template>
            </Column>
            <Column
                field="NET"
                header="Net"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeCommissions } from '@/composables/data/employeeCommissions';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    startTimePeriod: Number,
    endTimePeriod: Number,
    location: String,
    employeeId: Number,
    class: String,
});

const emit = defineEmits(['error']);

const { filters } = useDataTableParams(['LOCATION', 'PERIOD', 'COMMISSION_AMT', 'MISSING_AMT', 'NET']);

const { employeeCommissionsFromOtherStore, getEmployeeCommissionsFromOtherStore } = useEmployeeCommissions();

const isLoading = ref(false);

watch(() => props.employeeId, async () => {
    employeeCommissionsFromOtherStore.value = null;

    if (!props.employeeId || !props.startTimePeriod || !props.endTimePeriod || !props.location) {
        return;
    }

    isLoading.value = true;
    try {
        await getEmployeeCommissionsFromOtherStore(props.employeeId, props.startTimePeriod, props.endTimePeriod, props.location);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
});
</script>
