<template>
    <div class="row">
        <div class="col-xl-4 mb-xl-0 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Yearly Time Period
                </legend>
                <TimePeriodTable
                    v-model="timePeriod"
                    :show-from-current-date="true"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    paytype="YEARLY"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                />
            </fieldset>
        </div>
        <div class="col-xl-8">
            <div class="row">
                <div class="col-lg-6 mb-lg-0 mb-3">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-calendar me-2"></i>Generate Yearly Reports
                        </legend>
                        <FormSelect
                            v-model="paytype"
                            label="Paytype"
                            id-prefix="paytype"
                            :options="['WEEKLY', 'BIMONTHLY - SALES/ROVING', 'BIMONTHLY - OFFICE' ]"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                        />
                        <FormSelect
                            v-model="companyCode"
                            label="Company"
                            id-prefix="company"
                            :options="companies"
                            :option-value="(company) => company.COMPANY_CODE"
                            :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                        />
                        <hr class="my-3" />
                        <div class="text-end">
                            <button type="button"
                                class="btn btn-primary btn-sm mb-1 px-1 me-1"
                                :disabled="!timePeriod || !paytype || isGenerating"
                                @click="
                                    (_) => {
                                        generateSpreadsheetReport(
                                            'days-of-work'
                                        );
                                    }
                                ">
                                <span v-if="isGenerating"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-calendar me-2"></i>Days of Work Report
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm mb-1 px-1 me-1"
                                :disabled="!timePeriod || !paytype || isGenerating"
                                @click="
                                    (_) => {
                                        generateSpreadsheetReport(
                                            'service-incentives'
                                        );
                                    }
                                ">
                                <span v-if="isGenerating"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-handshake me-2"></i>Service Incentive Report
                            </button>
                        </div>
                    </fieldset>
                </div>
                <div class="col-lg-6">
                    <TopAttendanceTableCard
                        :time-period="timePeriod?.TIME_PERIOD"
                        @error="
                            (message) => {
                                addFlashMessage('ERROR', message);
                                scrollToTop();
                            }
                        "
                    />
                </div>
                <div class="col-12 mt-3">
                    <GeneratedReportsStatusesTableCard
                        :refresh-flag="reportGenerationsRefreshFlag"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useFlashMessages } from '@/composables/flashmessages';
import { useCompanies } from '@/composables/data/companies';
import { useAccountingYearlyReportGenerations } from '@/composables/data/accountingYearlyReportGenerations';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import TopAttendanceTableCard from './TopAttendanceTableCard.vue';
import GeneratedReportsStatusesTableCard from './GeneratedReportsStatusesTableCard.vue';
import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const { companies, getCompanies } = useCompanies();

const { postAccountingYearlyReportGeneration } = useAccountingYearlyReportGenerations();

const timePeriod = ref(null);
const paytype = ref(null);
const companyCode = ref(null);

const reportGenerationsRefreshFlag = ref(false);

const isGenerating = ref(false);

async function fetchCompanies() {
    try {
        await getCompanies();
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
}

onMounted(fetchCompanies);

async function generateSpreadsheetReport(reportType) {
    isGenerating.value = true;
    try {
        const response = await postAccountingYearlyReportGeneration(
            timePeriod.value.TIME_PERIOD, paytype.value, companyCode.value, reportType);
        addFlashMessage('SUCCESS', response.data.message);
        reportGenerationsRefreshFlag.value = !reportGenerationsRefreshFlag.value;
    } catch(e) {
        addFlashMessage('ERROR', e.message);
    }
    isGenerating.value = false;
    scrollToTop();
}
</script>
