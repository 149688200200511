<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-pencil-ruler me-2"></i>{{ title }}
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <FormSelect
                        :options="Object.keys(EMIT_MAPPING)"
                        required
                        label="Type"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-9 col-12"
                        is-horizontal
                        label-class="col-xl-4 col-md-3 label-sm align-items-center"
                        v-model="choice"
                    />

                    <div class="text-end mt-3">
                        <button type="button"
                            class="btn btn-primary"
                            @click="emit(EMIT_MAPPING[choice])">
                            <i class="icon ph-bold ph-gear me-2"></i>Select
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import FormSelect from "../FormSelect.vue";

const props = defineProps({
    title: {
        type: String,
        default: "Export Report"
    },
    isOpen: Boolean,
});

const emit = defineEmits(["export-pdf", "export-spreadsheet", "close"]);

const EMIT_MAPPING = {
    "PDF": "export-pdf",
    "Spreadsheet": "export-spreadsheet",
};

const choice = ref(null);
</script>

<style scoped>
.container {
    max-width: 500px;
}
</style>
