<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Upload Images
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <p>
                        <b>NOTE:</b> Make sure filename matches the
                        corresponding Sample.
                    </p>
                    <div class="mb-2">
                        <label for="imageFileInput"
                            class="form-label">
                            Images
                        </label>
                        <input type="file"
                            multiple
                            required
                            ref="imageFilesInput"
                            accept="image/png, image/gif, image/jpeg"
                            @change="(e) => { imageFiles = e.target.files; }"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="isUploading"
                            @click="uploadImages">
                            <span v-if="isUploading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-upload-simple me-2"></i>
                            Upload
                        </button>
                    </div>
                </div>
                <div v-if="isUploading" class="text-center mb-3">
                    <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true">
                    </span>
                    Currently uploading sample images...
                </div>
                <hr />
                <div class="row g-3 modal-padding-container">
                    <div class="col-md-6">
                        <h5 class="text-success">Successfully Uploaded</h5>
                        <DataTable
                            :value="successImages"
                            paginator
                            :rows="10"
                            :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                            <Column field="name" header="Name" />
                            <Column field="status" header="Status">
                                <template #body="{ data }">
                                    <span v-if="data.status == 'SUCCESS'"
                                        class="badge bg-success">
                                        Success
                                    </span>
                                </template>
                            </Column>
                            <Column field="timestamp" header="Finished On" />
                            <template #footer>
                                {{ successImages.length ?? 0 }} total images
                            </template>
                            <template #empty>
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                                </div>
                            </template>
                        </DataTable>
                    </div>
                    <div class="col-md-6">
                        <h5 class="text-danger">
                            For Correction of Name (no SAMPLE found)
                        </h5>
                        <DataTable
                            :value="errorImages"
                            paginator
                            :rows="10"
                            edit-mode="cell"
                            @cell-edit-complete="onErrorTableCellEditComplete"
                            :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}">
                            <Column field="name" header="Name">
                                <template #editor="{ data, field }">
                                    <FormInput
                                        type="text"
                                        v-model="data[field]"
                                        input-class="form-control-sm"
                                        hide-label
                                        is-horizontal
                                    />
                                </template>
                            </Column>
                            <Column field="status" header="Status">
                                <template #body="{ data }">
                                    <span v-if="data.status == 'NO_SAMPLE'"
                                        class="badge bg-warning">
                                        No Sample
                                    </span>
                                    <span v-else-if="data.status == 'FATAL'"
                                        class="badge bg-danger">
                                        Fatal Error (Investigate)
                                    </span>
                                    <span v-else-if="data.status == 'UPLOADING'"
                                        class="badge bg-primary">
                                        Uploading
                                    </span>
                                </template>
                            </Column>
                            <template #footer>
                                {{ errorImages.length ?? 0 }} total images
                            </template>
                            <template #empty>
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                                </div>
                            </template>
                        </DataTable>
                        <div class="text-end">
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                :disabled="isUploading"
                                @click="uploadErrorImages">
                                <span v-if="isUploading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>
                                Reupload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useSampleImages } from "@/composables/data/sampleImages";
import FormInput from "@/components/utils/FormInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import moment from "moment";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["error", "close"]);

const { postSampleImage } = useSampleImages();

const imageFilesInput = ref(null);
const imageFiles = ref(null);

const successImages = ref(null);
const errorImages = ref(null);

const uploadingStatuses = ref([]);

const isUploading = computed(
    () =>
        uploadingStatuses.value.filter((uploadingStatus) => uploadingStatus)
            .length > 0
);

function postImages(imageFiles) {
    uploadingStatuses.value = [];

    let index = 0;
    for (const imageFile of imageFiles) {
        uploadingStatuses.value.push(true);
        const currentIndex = index;

        setTimeout(async () => {
            try {
                const formData = new FormData();
                formData.append("image", imageFile);
                await postSampleImage(imageFile.name.split("_")[0], formData);
                successImages.value.unshift({
                    name: imageFile.name,
                    status: "SUCCESS",
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
                const sameErrorImage =
                    errorImages.value.filter(
                        (withError) => withError.name == imageFile.name
                    )[0] ?? null;
                if (sameErrorImage)
                    errorImages.value.splice(
                        errorImages.value.indexOf(sameErrorImage),
                        1
                    );
            } catch (e) {
                const sameErrorImage =
                    errorImages.value.filter(
                        (withError) => withError.name == imageFile.name
                    )[0] ?? null;
                if (sameErrorImage)
                    errorImages.value.splice(
                        errorImages.value.indexOf(sameErrorImage),
                        1
                    );

                if (e.status == 404) {
                    errorImages.value.push({
                        name: imageFile.name,
                        status: "NO_SAMPLE",
                        actualFile: imageFile,
                    });
                } else {
                    errorImages.value.push({
                        name: imageFile.name,
                        status: "FATAL",
                        actualFile: imageFile,
                    });
                }
            }

            uploadingStatuses.value[currentIndex] = false;
        }, 0);

        index++;
    }
}

async function uploadImages() {
    successImages.value = [];
    errorImages.value = [];
    await postImages(imageFiles.value);
}

function onErrorTableCellEditComplete(event) {
    const { data, newValue, field } = event;
    data[field] = newValue;

    data.actualFile = new File(
        [data.actualFile.slice(0, data.actualFile.size, data.actualFile.type)],
        newValue,
        { type: data.actualFile.type }
    );
}

async function uploadErrorImages() {
    errorImages.value = errorImages.value.map((errorImage, index) => ({
        ...errorImage,
        index,
        status: "UPLOADING",
    }));
    const toReupload = [
        ...errorImages.value.map((errorImage) => {
            errorImage.actualFile.index = errorImage.index;
            return errorImage.actualFile;
        }),
    ];
    await postImages(toReupload);
}

watch(
    () => props.isOpen,
    () => {
        if (props.isOpen) {
            imageFiles.value = null;

            successImages.value = [];
            errorImages.value = [];
        }
    }
);
</script>
