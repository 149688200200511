<template>
    <FormSelect
        v-model="departmentGroup"
        :options="departmentGroups"
        :option-to-string="option => option.name"
        label="Department Group"
        id-prefix="departmentGroup"
        :class="class"
    />
</template>

<script setup>
import { watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDepartmentGroups } from '@/composables/data/departmentGroups';

import FormSelect from '../FormSelect.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    paytype: String,
    class: String,
});

const { departmentGroups, getDepartmentGroups } = useDepartmentGroups();

const departmentGroup = defineModel();

async function fetchDepartmentGroups() {
    departmentGroups.value = null;
    departmentGroup.value = null;

    if (!props.paytype) {
        return;
    }
    
    loadingFlags.add("fetchDepartmentGroups");
    try {
        await getDepartmentGroups({
            rows: 0,
            filters: {
                paytype: {
                    value: props.paytype,
                    matchMode: 'equals',
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchDepartmentGroups");
}

watch(() => props.paytype, fetchDepartmentGroups);
</script>
