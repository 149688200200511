import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleSmSkus() {
    const styleSmSkus = ref(null);
    const totalRecords = ref(null);

    const getStyleSmSkus = async (params) => {
        try {
            const styleSmSkusResponse = await axios.get(route('api.style-sm-skus.index', params));
            styleSmSkus.value = styleSmSkusResponse.data.data;
            totalRecords.value = styleSmSkusResponse.data.meta?.total ?? styleSmSkus.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching SM SKUs.');
        }
    };

    const postStyleSmSku = async (styleSmSku) => {
        try {
            const postStyleSmSkuResponse = await axios.post(route('api.style-sm-skus.store'), styleSmSku);
            return postStyleSmSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting SM SKU.');
        }
    };

    const putStyleSmSku = async (styleSmSkuId, styleSmSku) => {
        try {
            const putStyleSmSkuResponse = await axios.put(route('api.style-sm-skus.update', {
                style_sm_sku: styleSmSkuId,
            }), styleSmSku);
            return putStyleSmSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating SM SKU.');
        }
    };
    
    return {
        styleSmSkus,
        totalRecords,
        getStyleSmSkus,
        postStyleSmSku,
        putStyleSmSku,
    };
}
