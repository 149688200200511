<template>
    <DataTable
        :loading="loadingFlags.has('fetchJoborders')"
        lazy
        :value="joborders"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => {
                onPage(e);
                fetchJoborders();
            }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedJoborder"
        data-key="JOBORDER_ID"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="JOBORDER_ID"
            header="ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="
                        (_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }
                    "
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="JONO"
            header="JONO"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="STYLE"
            header="Style"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="QTY"
            header="Qty"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="GL_ID"
            header="GL ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="START_DATE"
            header="Start Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="END_DATE"
            header="End Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useToastsStore } from "@/stores/toasts";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useJoborders } from "@/composables/data/joborders";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    joborderId: Number,
    refreshFlag: null,
});

const { params, filters, onPage, onFilter } = useDataTableParams([
    "JOBORDER_ID",
    "JONO",
    "STYLE",
    "QTY",
    "GL_ID",
    "START_DATE",
    "END_DATE",
]);

const { joborders, totalRecords, getJoborders } = useJoborders();

const selectedJoborder = defineModel();

const fetchTimeout = ref(null);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

async function fetchJoborders() {
    joborders.value = null;

    loadingFlags.add("fetchJoborders");
    try {
        await getJoborders(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchJoborders");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchJoborders, 300);
}

onMounted(fetchJoborders);

watch(() => props.refreshFlag, fetchJoborders);
watch(
    () => props.joborderId,
    () => {
        if (props.joborderId) {
            filters.value.JOBORDER_ID = {
                matchMode: "equals",
                value: props.joborderId,
            };
        } else {
            filters.value.JOBORDER_ID = {
                matchMode: "contains",
                value: null,
            };
        }
        tableOnFilter();
    }
);
</script>
