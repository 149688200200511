<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-warning-circle me-2"></i>MONTHLY DISCREPANCY REPORT
        </legend>
        <div class="row">
            <div class="col-xl-5 mb-xl-0 mb-3">
                <FormSelect
                    label="Start Date:"
                    v-model="startDate"
                    :options="timePeriods"
                    :option-to-string="(timePeriod) => timePeriod.START_DATE"
                    :option-value="(timePeriod) => timePeriod.START_DATE"
                    class="mb-md-1 mb-2"
                    select-class="form-select-sm"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm align-items-center"
                    select-container-class="col-xl-8 col-md-7 col-12"
                    id-prefix="startDate"
                    :errors="errors.START_DATE"
                />
                <FormSelect
                    label="End Date:"
                    v-model="endDate"
                    :options="timePeriods"
                    :option-to-string="(timePeriod) => timePeriod.END_DATE"
                    :option-value="(timePeriod) => timePeriod.END_DATE"
                    class="mb-3"
                    select-class="form-select-sm"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm align-items-center"
                    select-container-class="col-xl-8 col-md-7 col-12"
                    id-prefix="endDate"
                    :errors="errors.END_DATE"
                />
                <LocationTable
                    v-model="location"
                    @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
                />
            </div>
            <div class="col-xl-7">
                <button class="btn btn-primary btn-sm mb-2 px-2"
                    :disabled="!location || !startDate || !endDate"
                    @click="fetchReports">
                    <i class="icon ph-bold ph-book-open me-2"></i>Display Report
                </button>
                <DataTable
                    :loading="isLoading"
                    :value="inventoryRecords"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="SOLD_MONTH" header="SOLD_MONTH" />
                    <Column field="QTY" header="QTY" />
                    <Column field="AMOUNT" header="AMOUNT" />
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Loading report...
                    </template>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </fieldset>
</template>

<script setup>
import { onMounted, ref } from "vue";
import LocationTable from "@/components/utils/tables/LocationTable";
import FormSelect from "@/components/utils/FormSelect.vue";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useTimePeriods } from "@/composables/data/timePeriods";
import { useInventory } from "@/composables/data/inventory";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const startDate = ref(null);
const endDate = ref(null);
const location = ref(null);
const isLoading = ref(false);
const errors = ref({});

const { inventoryRecords, getMonthlyDiscrepancies } = useInventory();
const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: "MONTHLY",
    });
});

async function fetchReports() {
    errors.value = {};
    isLoading.value = true;
    try {
        await getMonthlyDiscrepancies(
            location.value.LOCATION,
            startDate.value,
            endDate.value
        );
    } catch (e) {
        inventoryRecords.value = [];
        console.log(e);
        if (e.status == 422) {
            errors.value = e.errors;
        } else {
            handleFetchErrors(e, "Error while fetching reports.");
            scrollToTop();
        }
    }
    isLoading.value = false;
}
</script>
