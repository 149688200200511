<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-calendar me-2"></i>Change Sold Date
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <form @submit.prevent="updateSoldDateOfBarcodeSequence"
                        class="my-3">
                        <FormInput
                            type="text"
                            v-model="soldDate"
                            label="New Sold Date"
                            class="mb-2"
                            id-prefix="soldDate"
                            :errors="errors.SOLD_DT"
                        />
                        <div class="text-end">
                            <button type="submit"
                                class="btn btn-primary">
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";

const { updateSoldDate } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const soldDate = ref(null);

const errors = ref({});

async function updateSoldDateOfBarcodeSequence() {
    errors.value = {};

    loadingFlags.add("updateSoldDateOfBarcodeSequence");
    try {
        await updateSoldDate(
            props.inventoryRecord.STYLE,
            props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL,
            props.inventoryRecord.SEQ_NO,
            soldDate.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated sold date.");
        emit('success');
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSoldDateOfBarcodeSequence");
}

watch(
    () => props.isOpen,
    () => {
        soldDate.value = null;
    }
);
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
