<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-archive-box me-2"></i>Inventory Check Description
        </legend>
        <DataTable :loading="loadingFlags.has('fetchInventoryCheckDescriptions')"
            lazy
            :value="inventoryCheckDescriptions"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchInventoryCheckDescriptions(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="(e) => { onSort(e); fetchInventoryCheckDescriptions(); }"
            v-model:selection="selectedInventoryCheckDescription"
            selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="id"
                header="ID"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input=" (_) => { filterModel.matchMode = 'contains'; filterCallback(); }"
                        class="form-control"
                        placeholder="Search by ID"
                    />
                </template>
            </Column>
            <Column
                field="LOCATION"
                header="Location"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Location"
                    />
                </template>
            </Column>
            <Column
                field="INVENTORY_DONE"
                header="Inventory Done"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Inventory Done"
                    />
                </template>
            </Column>
            <Column
                field="DESCRIPTION"
                header="Description"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                    bodyCell: { class: 'barcode' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Description"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer">
            <slot name="footer"></slot>
        </template>
    </fieldset>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryCheckDescriptions } from "@/composables/data/inventoryCheckDescriptions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
    displayCurrentSelectedFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "id",
    "LOCATION",
    "INVENTORY_DONE",
    "DESCRIPTION",
]);

const {
    inventoryCheckDescriptions,
    totalRecords,
    getInventoryCheckDescriptions,
} = useInventoryCheckDescriptions();

const fetchTimeout = ref(null);

const selectedInventoryCheckDescription = defineModel();

async function fetchInventoryCheckDescriptions() {
    inventoryCheckDescriptions.value = null;
    
    loadingFlags.add("fetchInventoryCheckDescriptions");
    try {
        await getInventoryCheckDescriptions({
            ...params.value,
            hardFilters: {
                LOCATION: {
                    value: props.location,
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchInventoryCheckDescriptions");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryCheckDescriptions, 800);
}

onMounted(fetchInventoryCheckDescriptions);

watch(() => [props.location, props.refreshFlag], fetchInventoryCheckDescriptions);

watch(() => props.displayCurrentSelectedFlag, () => {
    filters.value.id = {
        value: selectedInventoryCheckDescription.value.id,
        matchMode: 'equals',
    };
    fetchInventoryCheckDescriptions();
});
</script>
