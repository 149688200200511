<template>
    <div class="row g-3">
        <div class="col-lg-4 col-md-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <TimePeriodTable
                    show-from-current-date
                    paytype="MONTHLY"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    v-model="timePeriod"
                />
            </fieldset>
        </div>
        <div class="col-lg-4 col-md-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clipboard-text me-2"></i>Reports
                </legend>
                <div class="d-grid gap-3">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!timePeriod"
                        @click="generateReport('absent-hours-on-month-spreadsheet')">
                        <i class="icon ph-bold ph-clock me-2"></i>Absent Hours On Month
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

const timePeriod = ref(null);

function generateReport(report) {
    window.open(route(`api.time-periods.${report}.show`, {
        time_period: timePeriod.value.TIME_PERIOD
    }));
}
</script>
