<template>
    <div class="row mb-3">
        <div class="col-lg-4 mb-lg-0 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clock me-2"></i>Employee Hours i-Time
                </legend>
                <p>Last File Uploaded: {{ lastFileUploadedName ?? "No file yet." }}</p>
                <form @submit.prevent="importItimeFile">
                    <div class="mb-3">
                        <label for="itimeFileInput" class="form-label">Upload a file</label>
                        <input
                            type="file"
                            ref="itimeFileInput"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="itimeFileInput"
                            class="form-control form-control-sm"
                            @change="itimeFileOnChange"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        class="btn btn-primary me-2 mb-1 btn-sm"
                    >
                        <i class="icon ph-bold ph-floppy-disk-back me-2"></i>Save
                    </button>
                </form>
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-cloud-warning me-2"></i>Import Warnings
                </legend>
                <DataTable
                    :value="importWarnings"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column header="Status">
                        <template #body>
                            <span class="badge bg-warning">Warning</span>
                        </template>
                    </Column>
                    <Column field="line" header="Line" />
                    <Column field="message" header="Message" />
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-warning me-2"></i>No warnings.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-clock-user me-2"></i> Current Imported Hours
        </legend>
        <div class="text-end mb-3">
            <button
                type="button"
                class="btn btn-outline-secondary btn-sm"
                @click="deleteImportedHours"
            >
                <i class="icon ph-bold ph-clock-clockwise me-2"></i>Reset Imported Hours
            </button>
        </div>
        <DataTable
            :loading="loadingFlags.has('fetchSmItimes')"
            lazy
            :value="smItimes"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchSmItimes(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="EMPLOYEE_NUMBER" header="Employee Number" />
            <Column field="mapping.employee.EMPID" header="Employee ID" />
            <Column field="mapping.employee.FNAME" header="First Name" />
            <Column field="mapping.employee.LNAME" header="Last Name" />
            <Column field="DATE" header="Date" />
            <Column field="IN1" header="In 1" />
            <Column field="OUT1" header="Out 1" />
            <Column field="IN2" header="In 2" />
            <Column field="OUT2" header="Out 2" />
            <Column field="IN3" header="In 3" />
            <Column field="OUT3" header="Out 3" />
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useSmItimes } from "@/composables/data/smItimes";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, onPage } = useDataTableParams([
    'EMPLOYEE_NUMBER',
    'mapping.employee.EMPID',
    'mapping.employee.FNAME',
    'mapping.employee.LNAME',
    'DATE',
    'IN1',
    'OUT1',
    'IN2',
    'OUT2',
    'IN3',
    'OUT3',
]);

const importWarnings = ref(null);

const lastFileUploadedName = ref(null);
const itimeFileInput = ref();
const itimeFile = ref(null);

const { smItimes, totalRecords, getSmItimes, deleteAllSmItimes } = useSmItimes();

onMounted(async () => {
    try {
        const latestSpreadsheetResponse = await axios.get(
            route('api.itime-spreadsheets.latest'));
        lastFileUploadedName.value = latestSpreadsheetResponse.data.data.filename;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching latest spreadsheet information.');
        scrollToTop();
    }

    fetchSmItimes();
});

function itimeFileOnChange(event) {
    const file = event.target.files[0];
    itimeFile.value = file;
}

async function fetchSmItimes() {
    smItimes.value = null;

    loadingFlags.add("fetchSmItimes");
    try {
        await getSmItimes(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchSmItimes");
}

async function importItimeFile() {
    importWarnings.value = null;

    loadingFlags.add("importItimeFile");
    try {
        const formData = new FormData();
        formData.append("spreadsheet", itimeFile.value);
        const response = await axios.post(route('api.itime-spreadsheets.store'), formData);
        toasts.add("SUCCESS", "Success", "Successfully uploaded ITime spreadsheet.");

        if (response.data.warnings) {
            toasts.add("WARNING", "Warning", "There are warnings, please check.");
            importWarnings.value = response.data.warnings.reduce((allWarnings, importWarningsOnLine) => {
                return allWarnings.concat([
                    ...importWarningsOnLine.warnings.map(importWarning => ({
                        line: importWarningsOnLine.line,
                        message: importWarning
                    }))
                ]);
            }, []);
        }

        itimeFileInput.value.value = null;
        itimeFile.value = null;
        lastFileUploadedName.value = response.data.data.filename;
        
        fetchSmItimes();
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.response?.data.message ?? e.message);
    }
    loadingFlags.delete("importItimeFile");
}

async function deleteImportedHours() {
    if (prompt("Are you sure? Enter 'DELETE' to continue.") !== 'DELETE') {
        return;
    }

    loadingFlags.add("deleteImportedHours");
    try {
        const response = await deleteAllSmItimes();
        toasts.add("SUCCESS", "Success", response.data.message);
        fetchSmItimes();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteImportedHours");
}
</script>
