import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useEmployeesWithRetroactiveHours() {
    const employeesWithRetroactiveHours = ref(null);
    const totalRecords = ref(null);

    const getEmployeesWithRetroactiveHours = async (startDate, endDate, paytype, departmentGroupId, params) => {
        try {
            const getEmployeesWithRetroactiveHoursResponse = await axios.get(route('api.employee-hours.retroactive-hours.index', {
                start_date: startDate,
                end_date: endDate,
                paytype: paytype,
                department_group_id: departmentGroupId,
                ...params,
            }));
            employeesWithRetroactiveHours.value = getEmployeesWithRetroactiveHoursResponse.data.data;
            totalRecords.value = getEmployeesWithRetroactiveHoursResponse.data.meta?.total ?? employees.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employees with retroactive hours.');
        }
    };
    
    return {
        employeesWithRetroactiveHours,
        totalRecords,
        getEmployeesWithRetroactiveHours,
    };
}
