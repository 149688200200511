<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-user me-2"></i>Change Seller
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container my-3">
                    <DataTable
                        :loading="loadingFlags.has('fetchAvailableEmployees')"
                        lazy
                        :value="employeeLocations"
                        :total-records="totalRecords"
                        paginator
                        :rows="10"
                        @page="e => { onPage(e); fetchAvailableEmployees(); }"
                        filter-display="row"
                        v-model:filters="filters"
                        @filter="tableOnFilter"
                        sort-mode="multiple"
                        v-model:multi-sort-meta="multiSortMeta"
                        @sort="e => { onSort(e); fetchAvailableEmployees(); }"
                        selection-mode="single"
                        v-model:selection="selectedEmployeeLocation"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column
                            field="employee.EMPID"
                            header="EMPID"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by EMPID"
                                />
                            </template>
                        </Column>
                        <Column
                            field="employee.FNAME"
                            header="First Name"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by First Name"
                                />
                            </template>
                        </Column>
                        <Column
                            field="employee.LNAME"
                            header="Last Name"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Last Name"
                                />
                            </template>
                        </Column>
                        <Column
                            field="LOCATION"
                            header="Location"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Location"
                                />
                            </template>
                        </Column>
                        <Column
                            field="START_DATE"
                            header="Start Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Start Date"
                                />
                            </template>
                        </Column>
                        <Column
                            field="END_DATE"
                            header="End Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by End Date"
                                />
                            </template>
                        </Column>
                        <template #footer>
                            {{ totalRecords ?? 0 }} available
                        </template>
                        <template #empty>
                            <div class="text-center">
                                No data
                            </div>
                        </template>
                    </DataTable>
                    <div class="text-end">
                        <button type="submit"
                            class="btn btn-primary"
                            :disabled="!selectedEmployeeLocation"
                            @click="updateSellerOfBarcodeSequence">
                            <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import { useEmployeeLocations } from "@/composables/data/employeeLocations";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const { updateSeller } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "employee.EMPID",
    "employee.FNAME",
    "employee.LNAME",
    "LOCATION",
    "START_DATE",
    "END_DATE",
]);

const { employeeLocations, totalRecords, getEmployeeLocations } = useEmployeeLocations();

const fetchTimeout = ref(null);

const selectedEmployeeLocation = ref(null);

async function fetchAvailableEmployees() {
    employeeLocations.value = null;

    if (!props.inventoryRecord) {
        return;
    }

    loadingFlags.add("fetchAvailableEmployees");
    try {
        await getEmployeeLocations({
            ...params.value,
            hardFilters: {
                LOCATION: {
                    value: props.inventoryRecord.PREV_LOC,
                    matchMode: 'equals',
                },
                START_DATE: {
                    value: props.inventoryRecord.SOLD_DT,
                    matchMode: 'lte',
                },
                END_DATE: {
                    value: props.inventoryRecord.SOLD_DT,
                    matchMode: 'gte',
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchAvailableEmployees");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchAvailableEmployees, 700);
}

async function updateSellerOfBarcodeSequence() {
    loadingFlags.add("updateSellerOfBarcodeSequence");
    try {
        await updateSeller(
            props.inventoryRecord.STYLE,
            props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL,
            props.inventoryRecord.SEQ_NO,
            selectedEmployeeLocation.value.employee.EMPID
        );
        toasts.add("SUCCESS", "Success", "Successfully updated seller.");
        emit("success");
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSellerOfBarcodeSequence");
}

watch(() => props.isOpen, () => {
    if (props.isOpen) {
        selectedEmployeeLocation.value = null;
        fetchAvailableEmployees();
    }
});
</script>

<style scoped>
.container {
    max-width: 900px;
}
</style>
