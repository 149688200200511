<template>
    <div
        class="modal fade"
        id="modalSelectLocation"
        tabindex="-1"
        aria-labelledby="modalSelectLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-map-pin me-2"></i>Select Location
                    </h4>
                    <button
                        id="close-search-location"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body d-flex flex-column">
                    <SelectLocation v-model="selectedLocation"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { watch } from 'vue';
import SelectLocation from '@/components/utils/LocationSelectTableCard.vue';
const selectedLocation = defineModel();
watch(selectedLocation, () => {
    const closeButton = document.getElementById('close-search-location');
    if (closeButton)
        closeButton.click();
})
</script>
