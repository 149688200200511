<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Create/Update Purchase Order</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <FormInput type="number" v-model="form.ORDER_PO_ID" disabled
                label="Order ID" id-prefix="orderPoId"
                class="mb-3" />
            <FormInput type="text" v-model="form.PURCHASE_ORDER_NUMBER"
                label="PO Number" id-prefix="purchaseOrderNumber"
                class="mb-3"
                :errors="errors.PURCHASE_ORDER_NUMBER" />
            <FormInput type="date" v-model="form.DATE_ARRIVED"
                label="Date Arrived" id-prefix="dateArrived"
                class="mb-3"
                :errors="errors.DATE_ARRIVED" />
            <FormInput type="text" v-model="form.SUPPLIER_ID" disabled
                label="Supplier ID" id-prefix="supplierId"
                class="mb-1"
                :form-text="selectedRelationshipsDisplay.supplier?.SUPPLIER_NAME"
                :errors="errors.SUPPLIER_ID" />
            <div class="text-end">
                <button type="button" class="btn btn-sm btn-primary"
                    @click="_ => { isSelectOrderSupplierPopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormSelect v-model="form.STATUS"
                :options="STATUS_OPTIONS"
                :option-value="option => option.value"
                :option-to-string="option => option.label"
                label="Status" id-prefix="status"
                :errors="errors.STATUS" />
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary me-2"
                        :disabled="loadingFlags.has('insertPurchaseOrder') || loadingFlags.has('updatePurchaseOrder')"
                        @click="_ => {
                            if(!purchaseOrder) {
                                insertPurchaseOrder();
                            } else {
                                updatePurchaseOrder();
                            }
                        }">
                        <span v-if="loadingFlags.has('insertPurchaseOrder') || loadingFlags.has('updatePurchaseOrder')"
                            class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        Save
                    </button>
                    <button type="button" class="btn btn-outline-secondary"
                        @click="reset">
                        Clear
                    </button>
                </div>
            </template>
        </Card>
    </div>

    <SelectOrderSupplierPopup :is-open="isSelectOrderSupplierPopupOpen"
        @close="_ => { isSelectOrderSupplierPopupOpen = false; }"
        @select="selectedOrderSupplier => {
            form.SUPPLIER_ID = selectedOrderSupplier.SUPPLIER_ID;
            selectedRelationshipsDisplay.supplier = selectedOrderSupplier;
            isSelectOrderSupplierPopupOpen = false;
        }"
        @error="message => {
            isSelectOrderSupplierPopupOpen = false;
            addFlashMessage('ERROR', message);
            scrollToTop();
        }" />
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import { usePurchaseOrders } from '@/composables/data/purchaseOrders';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectOrderSupplierPopup from '@/components/utils/popups/SelectOrderSupplierPopup.vue';

const props = defineProps({
    isOpen: Boolean
});

const emit = defineEmits(['success', 'close']);

const toasts = useToastsStore();

const STATUS_OPTIONS = [
    {
        value: 'I',
        label: 'Initial',
    },
    {
        value: 'R',
        label: 'Received',
    },
    {
        value: 'A',
        label: 'Approved',
    },
    {
        value: 'F',
        label: 'Final',
    },
    {
        value: 'C',
        label: 'Cancelled',
    },
];

const { postPurchaseOrder, putPurchaseOrder } = usePurchaseOrders();

const purchaseOrder = defineModel();

const form = ref({
    ORDER_PO_ID: null,
    PURCHASE_ORDER_NUMBER: null,
    DATE_ARRIVED: null,
    SUPPLIER_ID: null,
    STATUS: null
});

const selectedRelationshipsDisplay = ref({});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();

const isSelectOrderSupplierPopupOpen = ref(false);

watch(purchaseOrder, () => {
    errors.value = {};
    selectedRelationshipsDisplay.value.supplier = purchaseOrder.value?.supplier;
    form.value = Object.assign({
        ORDER_PO_ID: null,
        PURCHASE_ORDER_NUMBER: null,
        DATE_ARRIVED: null,
        SUPPLIER_ID: null,
        STATUS: null
    }, purchaseOrder.value);
});

function reset() {
    purchaseOrder.value = null;
    if(!purchaseOrder.value) {
        form.value = {
            ORDER_PO_ID: null,
            PURCHASE_ORDER_NUMBER: null,
            DATE_ARRIVED: null,
            SUPPLIER_ID: null,
            STATUS: null
        };
    }
    errors.value = {};
}

async function insertPurchaseOrder() {
    errors.value = {};

    loadingFlags.add('insertPurchaseOrder');
    try {
        const newPurchaseOrder = await postPurchaseOrder(form.value);
        toasts.add('SUCCESS', 'success', 'Successfully created purchase order.');
        purchaseOrder.value = newPurchaseOrder;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'error', e.message);
    }
    loadingFlags.delete('insertPurchaseOrder');
}

async function updatePurchaseOrder() {
    errors.value = {};

    loadingFlags.add('updatePurchaseOrder');
    try {
        const newPurchaseOrder = await putPurchaseOrder(purchaseOrder.value.ORDER_PO_ID, form.value);
        toasts.add('SUCCESS', 'success', 'Successfully updated purchase order.');
        purchaseOrder.value = newPurchaseOrder;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'error', e.message);
    }
    loadingFlags.delete('updatePurchaseOrder');
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 50%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
