<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-info me-2"></i>Valid Variables
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                ></button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <p><b>Note:</b> Avoid circular references in formulas.</p>
                    <ul>
                        <li>DISCOUNTS</li>
                        <li>COMMISSIONS</li>
                        <li>SHIPPING</li>
                        <li>STORAGE</li>
                        <li>UTILITIES</li>
                        <li>TELEPHONE</li>
                        <li>SUPPLIES</li>
                        <li>SALE_EVENTS</li>
                        <li>CREDITABLE_TAX</li>
                        <li>UNIFORM</li>
                        <li>PRICE_TAG</li>
                        <li>E2E_CHARGE</li>
                        <li>STORE_MAIN</li>
                        <li>MAIL_POST</li>
                        <li>SAL_WAGES</li>
                        <li>BANK_CHARGES</li>
                        <li>TRANSPORT</li>
                        <li>REPRESENT</li>
                        <li>MISCL</li>
                        <li>ADS</li>
                        <li>AR_CASH</li>
                        <li>FLOATING_ADJ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);
</script>

<style scoped>
.container {
    max-width: 500px;
}
</style>
