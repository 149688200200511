<style scoped>
.style-image {
    max-height: 180px;
    max-width: 100%;
}
</style>

<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-image me-2"></i>Image</legend>
        <form @submit.prevent="imageFileOnSubmit"
            class="d-flex flex-column justify-content-between h-100">
            <div>
                <div v-if="style" class="mb-3">
                    <span v-if="isImageLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true">
                    </span>
                    <img v-if="!isUploading"
                        :src="imageURL"
                        :alt="style?.STYLE + ' style image'"
                        class="style-image"
                        @load=" (_) => { isImageLoading = false; }"
                        @error="setDefaultImage"
                    />
                </div>
                <div class="mb-3">
                    <label for="imageFileInput" class="form-label">Image</label>
                    <input type="file"
                        required
                        ref="imageFileInput"
                        accept="image/png, image/gif, image/jpeg"
                        @change="imageFileOnChange"
                        :errors="errors.image"
                        id="imageFileInput"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-outline-success btn-sm me-2 mb-1"
                    @click=" (_) => { isBulkImageUploadPopupOpen = true; }">
                    <i class="icon ph-bold ph-cloud-arrow-up me-2"></i>Bulk
                    Upload
                </button>
                <button type="submit"
                    class="btn btn-primary btn-sm me-2 mb-1"
                    :disabled="!style || isUploading">
                    <span v-if="isUploading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true">
                    </span>
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save Image
                </button>
            </div>
        </form>
    </fieldset>

    <BulkImageUploadPopup
        :is-open="isBulkImageUploadPopupOpen"
        @close="
            (_) => {
                isBulkImageUploadPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref, watch } from 'vue';

import { useToastsStore } from '@/stores/toasts';
import { useStyles } from '@/composables/data/styles';

import BulkImageUploadPopup from './BulkImageUploadPopup.vue';

import scrollToTop from "@/utils/scrollToTop";

const toasts = useToastsStore();

const props = defineProps({
    style: Object,
    class: String,
});

const { style, getStyle } = useStyles();

const imageURL = ref(null);

const errors = ref({});

const imageFileInput = ref(null);
const imageFile = ref();

const isUploading = ref(false);
const isImageLoading = ref(false);

const isBulkImageUploadPopupOpen = ref(false);

function imageFileOnChange(event) {
    const file = event.target.files[0];
    imageFile.value = file;
}

async function imageFileOnSubmit() {
    isUploading.value = true;
    try {
        const formData = new FormData();
        formData.append("image", imageFile.value);

        await axios.post(
            route("api.styles.image.store", {
                style: props.style.STYLE,
            }),
            formData
        );
        toasts.add("SUCCESS", "Success", "Successfully saved image.");

        imageFileInput.value.value = null;
        imageFile.value = null;
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.response?.data.message ?? e.message);
    }
    isUploading.value = false;
    scrollToTop();
    loadStyleImage();
}

async function loadStyleImage() {
    if (!props.style) {
        return;
    }

    try {
        isImageLoading.value = true;
        await getStyle(props.style);

        if (!style.value.image_url) {
            setDefaultImage();
        } else {
            imageURL.value = style.value.image_url;
        }
    } catch(e) {
        console.log(e);
        setDefaultImage();
        if (!e.response || e.response.status != 404) {
            toasts.add('ERROR', 'Error', e.message);
        }
    }
}

function setDefaultImage() {
    const noImageURL = `${Ziggy.url}/img/bagnoimage.jpg`;
    if (imageURL.value != noImageURL) {
        isImageLoading.value = true;
        imageURL.value = `${Ziggy.url}/img/bagnoimage.jpg`;
    }
}

watch(() => props.style, loadStyleImage);
</script>
