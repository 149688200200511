<template>
    <DataTable
        :loading="loadingFlags.has('fetchEmployeesWithRetroactiveHours')"
        lazy
        :value="employeesWithRetroactiveHours"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchEmployeesWithRetroactiveHours(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="e => { onSort(e); fetchEmployeesWithRetroactiveHours(); }"
        :pt="{ table: { class: 'table table-bordered table-hover' }}"
    >
        <Column
            field="EMPID"
            header="Employee ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="FNAME"
            header="First Name"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="LNAME"
            header="Last Name"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column header="Rate">
            <template #body="{ data }">
                {{ data.employee_rates.slice(-1)[0]?.RATE }}
            </template>
        </Column>
        <Column header="COLA">
            <template #body="{ data }">
                {{ data.employee_rates.slice(-1)[0]?.COLA }}
            </template>
        </Column>
        <Column
            field="HOURS_SUM_HOURS_HONORARIUM"
            header="Honorarium"
        />
        <Column
            field="HOURS_SUM_HOURS_REGULAR"
            header="Regular"
        />
        <Column
            field="HOURS_SUM_HOURS_OVERTIME"
            header="Overtime"
        />
        <Column
            field="HOURS_SUM_HOURS_HOLIDAY_REGULAR"
            header="Holiday (Reg)"
        />
        <Column
            field="HOURS_SUM_HOURS_HOLIDAY_OVERTIME"
            header="Holiday (Ovr)"
        />
        <Column
            field="HOURS_SUM_HOURS_SPECIAL"
            header="Special"
        />
        <Column
            field="HOURS_SUM_HOURS_SPECIAL_OVERTIME"
            header="Special (Ovr)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_REGULAR"
            header="Regular (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_OVERTIME"
            header="Overtime (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_HOLIDAY_REGULAR"
            header="Holiday (Reg) (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_HOLIDAY_OVERTIME"
            header="Holiday (Ovr) (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_SPECIAL"
            header="Special (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X270_SPECIAL_OVERTIME"
            header="Special (Ovr) (x270)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_REGULAR"
            header="Regular (x480)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_OVERTIME"
            header="Overtime (x480)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_HOLIDAY_REGULAR"
            header="Holiday (Reg) (x480)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_HOLIDAY_OVERTIME"
            header="Holiday (Ovr) (x480)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_SPECIAL"
            header="Special (x480)"
        />
        <Column
            field="HOURS_SUM_HOURS_X480_SPECIAL_OVERTIME"
            header="Special (Ovr) (x480)"
        />
        <template #footer>
            {{ totalRecords ?? 0 }} total records
        </template>
    </DataTable>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeesWithRetroactiveHours } from '@/composables/data/employeesWithRetroactiveHours';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    startDate: String,
    endDate: String,
    paytype: String,
    departmentGroupId: Number,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'EMPID',
    'FNAME',
    'LNAME',
    'HOURS_SUM_HOURS_HONORARIUM',
    'HOURS_SUM_HOURS_REGULAR',
    'HOURS_SUM_HOURS_OVERTIME',
    'HOURS_SUM_HOURS_HOLIDAY_REGULAR',
    'HOURS_SUM_HOURS_HOLIDAY_OVERTIME',
    'HOURS_SUM_HOURS_SPECIAL',
    'HOURS_SUM_HOURS_SPECIAL_OVERTIME',
    'HOURS_SUM_HOURS_X270_REGULAR',
    'HOURS_SUM_HOURS_X270_OVERTIME',
    'HOURS_SUM_HOURS_X270_HOLIDAY_REGULAR',
    'HOURS_SUM_HOURS_X270_HOLIDAY_OVERTIME',
    'HOURS_SUM_HOURS_X270_SPECIAL',
    'HOURS_SUM_HOURS_X270_SPECIAL_OVERTIME',
    'HOURS_SUM_HOURS_X480_REGULAR',
    'HOURS_SUM_HOURS_X480_OVERTIME',
    'HOURS_SUM_HOURS_X480_HOLIDAY_REGULAR',
    'HOURS_SUM_HOURS_X480_HOLIDAY_OVERTIME',
    'HOURS_SUM_HOURS_X480_SPECIAL',
    'HOURS_SUM_HOURS_X480_SPECIAL_OVERTIME',
]);

const {
    employeesWithRetroactiveHours,
    totalRecords,
    getEmployeesWithRetroactiveHours,
} = useEmployeesWithRetroactiveHours();

const tableFilters = defineModel('filters');
const tableSorts = defineModel('multiSortMeta');

const fetchTimeout = ref(null);

async function fetchEmployeesWithRetroactiveHours() {
    employeesWithRetroactiveHours.value = null;

    if (!props.startDate || !props.endDate || !props.paytype) {
        return;
    }

    loadingFlags.add("fetchEmployeesWithRetroactiveHours");
    try {
        await getEmployeesWithRetroactiveHours(
            props.startDate,
            props.endDate,
            props.paytype,
            props.departmentGroupId,
            params.value,
        );
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEmployeesWithRetroactiveHours");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployeesWithRetroactiveHours, 800);
}

watch(
    () => [
        props.startDate,
        props.endDate,
        props.paytype,
        props.departmentGroupId,
        props.refreshFlag
    ],
    fetchEmployeesWithRetroactiveHours
);

watch(filters, () => {
    tableFilters.value = Object.assign({}, filters.value);
});

watch(multiSortMeta, () => {
    tableSorts.value = Array.from(multiSortMeta.value);
})
</script>
