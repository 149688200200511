import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSmItimes() {
    const smItimes = ref(null);
    const totalRecords = ref(null);

    const getSmItimes = async (params) => {
        try {
            const getSmItimesResponse = await axios.get(route('api.sm-itimes.index', params));
            smItimes.value = getSmItimesResponse.data.data;
            totalRecords.value = getSmItimesResponse.data.meta?.total ?? smItimes.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching SM Itimes.');
        }
    };

    const deleteAllSmItimes = async () => {
        try {
            const deleteAllSmItimesResponse = await axios.delete(route('api.sm-itimes.destroy-all'));
            return deleteAllSmItimesResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching SM Itimes.');
        }
    };
    
    return {
        smItimes,
        totalRecords,
        getSmItimes,
        deleteAllSmItimes,
    };
}
