import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useTabulationYearEquivalences() {
    const tabulationYearEquivalences = ref(null);
    const totalRecords = ref(null);

    const getTabulationYearEquivalences = async (params) => {
        try {
            const getTabulationYearEquivalencesResponse = await axios.get(route('api.tabulation-year-equivalences.index', params));
            tabulationYearEquivalences.value = getTabulationYearEquivalencesResponse.data.data;
            totalRecords.value = getTabulationYearEquivalencesResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching year equivalences.');
        }
    };

    const postTabulationYearEquivalence = async (tabulationYearEquivalence) => {
        try {
            const postTabulationYearEquivalenceResponse = await axios.post(
                route('api.tabulation-year-equivalences.store'),
                tabulationYearEquivalence
            );
            return postTabulationYearEquivalenceResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching year equivalences.');
        }
    };

    return {
        tabulationYearEquivalences,
        totalRecords,
        getTabulationYearEquivalences,
        postTabulationYearEquivalence,
    };
}
