<template>
    <SuppliersTableCard
        v-model="selectedOrderSupplier"
        :refresh-flag="tableRefreshFlag"
        class="mb-3" />
    <div class="row">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Create/Update Supplier
                </template>
                <FormInput type="number" v-model="orderSupplier.SUPPLIER_ID" disabled
                    label="Supplier ID" id-prefix="supplierId"
                    class="mb-3" />
                <FormInput type="text" v-model="orderSupplier.SUPPLIER_NAME"
                    label="Supplier Name" id-prefix="supplierName"
                    class="mb-3"
                    :errors="errors.SUPPLIER_NAME" />
                <FormInput type="text" v-model="orderSupplier.SUPPLIER_LOCATION"
                    label="Supplier Location" id-prefix="supplierLocation"
                    class="mb-3"
                    :errors="errors.SUPPLIER_LOCATION" />
                <FormInput type="text" v-model="orderSupplier.CONTACT_PERSON"
                    label="Contact Person" id-prefix="contactPerson"
                    class="mb-3"
                    :errors="errors.CONTACT_PERSON" />
                <FormInput type="text" v-model="orderSupplier.CONTACT_NUMBER"
                    label="Contact Number" id-prefix="contactNumber"
                    class="mb-3"
                    :errors="errors.CONTACT_NUMBER" />
                <FormInput type="email" v-model="orderSupplier.CONTACT_EMAIL"
                    label="Contact Email" id-prefix="contactEmail"
                    :errors="errors.CONTACT_EMAIL" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="orderSupplier.SUPPLIER_ID"
                            @click="insertOrderSupplier">
                            Insert
                        </button>
                        <button type="button" class="btn btn-primary me-1"
                            :disabled="!orderSupplier.SUPPLIER_ID"
                            @click="updateOrderSupplier">
                            Update
                        </button>
                        <button type="button" class="btn btn-outline-secondary"
                            @click="reset">
                            Reset
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import { useOrderSuppliers } from '@/composables/data/orderSuppliers';

import SuppliersTableCard from './SuppliersTableCard.vue';

import Card from '@/components/utils/Card';
import FormInput from '@/components/utils/FormInput';

const toasts = useToastsStore();

const { postOrderSupplier, putOrderSupplier } = useOrderSuppliers();

const selectedOrderSupplier = ref(null);

const tableRefreshFlag = ref(null);

const orderSupplier = ref({
    SUPPLIER_ID: null,
    SUPPLIER_NAME: null,
    SUPPLIER_LOCATION: null,
    CONTACT_PERSON: null,
    CONTACT_NUMBER: null,
    CONTACT_EMAIL: null,
});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();

watch(selectedOrderSupplier, () => {
    orderSupplier.value = Object.assign({
        SUPPLIER_ID: null,
        SUPPLIER_NAME: null,
        SUPPLIER_LOCATION: null,
        CONTACT_PERSON: null,
        CONTACT_NUMBER: null,
        CONTACT_EMAIL: null,
    }, selectedOrderSupplier.value);
    errors.value = {};
});

async function insertOrderSupplier() {
    errors.value = {};

    loadingFlags.add('insertOrderSupplier');
    try {
        const newOrderSupplier = await postOrderSupplier(orderSupplier.value);
        toasts.add('SUCCESS', 'Success', 'Successfully created order supplier.');
        selectedOrderSupplier.value = newOrderSupplier;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('insertOrderSupplier');
}

async function updateOrderSupplier() {
    errors.value = {};

    loadingFlags.add('updateOrderSupplier');
    try {
        const newOrderSupplier = await putOrderSupplier(selectedOrderSupplier.value.SUPPLIER_ID, orderSupplier.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated order supplier.');
        selectedOrderSupplier.value = newOrderSupplier;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateOrderSupplier');
}

function reset() {
    selectedOrderSupplier.value = null;
}
</script>
