<style scoped>
table {
    position: relative;
    margin-bottom: 0 !important;
}

thead {
    background-color: white;
}

#employeesTableContainer {
    max-height: 400px;
    overflow-y: scroll;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-8">
            <EmployeeFilterSection
                @time-period-select="timePeriodSelect"
                @employee-select="employeeSelect"
                @clear="clear"
                class="h-100"
            />
        </div>
        <div class="col-xl-4">
            <div class="row align-items-center">
                <div class="col-12">
                    <SelectedEmployeeSection
                        :employee="selectedEmployee"
                        class="mb-3"
                    />
                </div>
                <div class="col-xl-12 col-md-6 mb-xl-3 mb-md-0 mb-3">
                    <ActionButtonsSection
                        :employee="selectedEmployee"
                        :enter-employee-hours-details-modal-id="enterEmployeeHoursDetailsModalId"
                        :show-hours-modal-id="showHoursModalId"
                        :is-update-disabled="isUpdateDisabled"
                        @update-button-click="updateHours"
                        v-model:resetCoffeeBreakFlag="enterHoursCoffeeBreaksRefreshFlag"
                    />
                </div>
                <div class="col-xl-12 col-md-6">
                    <CheckTimeSection
                        :employee="selectedEmployee"
                        :time-period="selectedTimePeriod"
                        @error="
                            (message) => {
                                addFlashMessage('ERROR', message);
                                scrollToTop();
                            }
                        "
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="row g-3 mb-3" id="employeeHoursRow">
        <div class="col-md-9">
            <EnterEmployeeHoursSection
                :employee="selectedEmployee"
                :time-period="selectedTimePeriod"
                :dates-in-time-period="datesInTimePeriod"
                :employee-jobs="employeeJobs"
                :available-leaves-days="availableLeavesDays"
                @employee-jobs-change="employeeJobsChange"
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }
                "
                class="h-100"
            />
        </div>
        <div class="col-md-3">
            <TotalHoursSection
                :time-period="selectedTimePeriod"
                :employee="selectedEmployee"
                :refresh-flag="totalHoursRefreshFlag"
            />
        </div>
    </div>

    <CheckLeavesSection
        :employee="selectedEmployee"
        :time-period="selectedTimePeriod"
        v-model:available-leaves-days="availableLeavesDays"
        class="mb-3"
    />

    <EnterEmployeeHoursDetailsModal
        :employee="selectedEmployee"
        :time-period="selectedTimePeriod"
        :dates-in-time-period="datesInTimePeriod"
        :id="enterEmployeeHoursDetailsModalId"
        :reset-coffee-break-flag="enterHoursCoffeeBreaksRefreshFlag"
        @populate="onPopulate"
    />

    <ShowHoursModal
        :employee="selectedEmployee"
        :time-period="selectedTimePeriod"
        :dates-in-time-period="datesInTimePeriod"
        @complete-hours="(job) => onCompleteHoursWeekly(job)"
        :id="showHoursModalId"
    />
</template>

<script setup>
import { ref, computed, watch } from "vue";

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import axios from "axios";

import EmployeeFilterSection from "./EmployeeFilterSection.vue";
import SelectedEmployeeSection from "./SelectedEmployeeSection.vue";
import TotalHoursSection from "./TotalHoursSection.vue";
import ActionButtonsSection from "./ActionButtonsSection.vue";
import EnterEmployeeHoursSection from "./EnterEmployeeHoursSection.vue";
import CheckTimeSection from "./CheckTimeSection.vue";
import CheckLeavesSection from "./CheckLeavesSection.vue";

import EnterEmployeeHoursDetailsModal from "./EnterEmployeeHoursDetailsModal.vue";
import ShowHoursModal from "./ShowHoursModal.vue";

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const selectedTimePeriod = ref(null);
const selectedEmployee = ref(null);

const datesInTimePeriod = computed(() => {
    if (!selectedTimePeriod.value) {
        return;
    }

    const startDate = Date.parse(selectedTimePeriod.value.START_DATE);
    const endDate = Date.parse(selectedTimePeriod.value.END_DATE);
    let dates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
});

const employeeJobs = ref([]);

const isFreshForm = ref(false);
const isUpdateDisabled = ref(false);

const enterEmployeeHoursDetailsModalId = "enterEmployeeHoursDetailsModal";
const showHoursModalId = "showHoursModal";

const availableLeavesDays = ref(null);

const totalHoursRefreshFlag = ref(false);

const enterHoursCoffeeBreaksRefreshFlag = ref(false);

const loadingFlags = useLoadingFlagsStore();

function timePeriodSelect(timePeriod) {
    selectedTimePeriod.value = timePeriod;
    selectedEmployee.value = null;
}

function employeeSelect(employee) {
    selectedEmployee.value = employee;
    employeeJobs.value = [];
    isFreshForm.value = true;
    isUpdateDisabled.value = true;
}

function clear() {
    selectedTimePeriod.value = null;
    selectedEmployee.value = null;
    employeeJobs.value = [];
    isFreshForm.value = true;
    isUpdateDisabled.value = true;
}

function employeeJobsChange(jobs) {
    employeeJobs.value = jobs;
}

async function updateHours() {
    const hours = [];
    const sequenceNumbers = datesInTimePeriod.value.reduce((accumulator, value) => {
        return { ...accumulator, [value]: 1 }
    }, {});

    // Validate leaves first!
    const totalLeavesHoursTaken = employeeJobs.value.filter((employeeJob) => employeeJob.JONO == 'LEAVE')
        .reduce((total, employeeJob) =>
            total + Object.values(employeeJob.HOURS_PER_DAY).reduce(
                (subtotal, hoursOnDay) => subtotal + Number(hoursOnDay.hours), 0),
            0);

    if (totalLeavesHoursTaken && totalLeavesHoursTaken > availableLeavesDays.value * 8) {
        alert(`The employee does not have enough leaves: INPUTTED (${totalLeavesHoursTaken / 8} days) `  +
            `vs. AVAILABLE (${availableLeavesDays.value} days). Please check your encoded leaves.`);
        return;
    }

    loadingFlags.add('updateHours');

    employeeJobs.value.forEach(job => {
        datesInTimePeriod.value.forEach(date => {
            const id = job.HOURS_PER_DAY[date].id;
            const inputHours = job.HOURS_PER_DAY[date].hours;
            if (inputHours !== '') {
                const jobHours = {
                    DATE: date.toISOString().split('T')[0],
                    EMPID: selectedEmployee.value.EMPID,
                    SEQ_NO: sequenceNumbers[date],
                    JONO: job.JONO,
                    STYLE: job.STYLE,
                    RATE: job.RATE,
                    HOURS: inputHours
                };
                if (id) jobHours.id = id;
                sequenceNumbers[date] += 1;
                hours.push(jobHours);
            }
        });
    });

    try {
        await axios.put(
            route('api.employees.hours.time-period.update',
            [selectedEmployee.value.EMPID, selectedTimePeriod.value.TIME_PERIOD]),
            { hours: hours });
        addFlashMessage('SUCCESS', 'Successfully updated hours of employee.');
        totalHoursRefreshFlag.value = !totalHoursRefreshFlag.value;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating hours.');
    }
    scrollToTop();
    loadingFlags.delete('updateHours');
    isUpdateDisabled.value = true;
}

function onCompleteHoursWeekly(job) {
    const hoursPerDay = datesInTimePeriod.value.reduce((hoursPerDay, date) => {
        return { ...hoursPerDay, [date]: { hours: "" }}
    }, {});
    hoursPerDay[datesInTimePeriod.value.slice(-1)[0]].hours = 48;
    employeeJobs.value = [
        {
            ...job,
            HOURS_PER_DAY: hoursPerDay
        }
    ];
}

function onPopulate(employeeTimes) {
    const employee = selectedEmployee.value;

    const newEmployeeJobs = [];

    function createJob(jono, style, rate) {
        const newJob = {
            JONO: jono,
            STYLE: style,
            RATE: rate,
            HOURS_PER_DAY: datesInTimePeriod.value.reduce((hoursPerDay, date) => {
                return {...hoursPerDay, [date]: {hours: ""}}
            }, {})
        };
        newEmployeeJobs.push(newJob);
        return newJob;
    }

    function getJob(jono, style, rate) {
        return newEmployeeJobs.find((job) =>
            job.JONO === jono && job.STYLE === style && job.RATE === rate
        );
    }

    Object.keys(employeeTimes).forEach((date) => {
        const employeeDateHours = employeeTimes[date];
        const department = employee.DEPT;
        const dayType = employeeDateHours.DAY_TYPE;
        const employeeStatus = employeeDateHours.EMPLOYEE_STATUS;

        let jono = null;
        let style = null;
        let rate = null;

        if (dayType === "R" && employeeStatus === "A") {
            jono = "ABSENT";
            style = "UNEXCUSED";
            rate = "ABSENT";
            let job = getJob(jono, style, rate);
            if (job === undefined) {
                job = createJob(jono, style, rate);
            }
            job.HOURS_PER_DAY[date].hours = employeeDateHours.ABSENT / 60;
            return;
        }

        // Cases based on department
        else {
            if (department === "ACCOUNTING") {
                jono = "ACCTNG";
                style = "ACCTNG";

                if (employeeStatus === "OD") {
                    jono = "OFFICE";
                    style = "ACCOUNTING";
                }
            } else if (department.includes("ADMIN")) {
                jono = "OFFICE";
                style = "ADMIN";
            } else if (department === "MARKETING") {
                jono = "MKTNG";
                style = "MKTNG";

                if (employeeStatus === "OD") {
                    jono = "OFFICE";
                    style = "MARKETING";
                }
            } else if (department === "SALES" || department.includes("SC")) {
                jono = "SALES";
                style = department.includes("SC") ?
                department.split(/\(|\)/)[1] :
                "SALES";

                if (employeeStatus === "OD") {
                    jono = "OFFICE";
                    style = "SALES";
                }
            } else {
                jono = "NONE";
                style = "NONE";
            }

            if (dayType === "R") {
                if (employeeStatus === "P" || employeeStatus === "OD" || employeeStatus === "A") {
                    if (employeeDateHours.TOTAL && employeeDateHours.TOTAL > 0) {
                        let job = getJob(jono, style, "REGULAR");
                        if (job === undefined) {
                            job = createJob(jono, style, "REGULAR");
                        }
                        job.HOURS_PER_DAY[date].hours = employeeDateHours.TOTAL;
                    }
                    if (employeeDateHours.OT && employeeDateHours.OT > 0 && employeeDateHours.CH) {
                        let job = getJob(jono, style, "OVERTIME");
                        if (job === undefined) {
                            job = createJob(jono, style, "OVERTIME");
                        }
                        job.HOURS_PER_DAY[date].hours = employeeDateHours.OT;
                    }
                    if (employeeDateHours.ABSENT && employeeDateHours.ABSENT > 0) {
                        jono = "ABSENT";
                        style = "UNEXCUSED";
                        let job = getJob(jono, style, "ABSENT");
                        if (job === undefined) {
                            job = createJob(jono, style, "ABSENT");
                        }
                        job.HOURS_PER_DAY[date].hours = employeeDateHours.ABSENT / 60;
                    }
                    if (employeeDateHours.LATE && employeeDateHours.LATE > 0) {
                        jono = "ABSENT";
                        style = "UNEXCUSED";
                        let job = getJob(jono, style, "ABSENT");
                        if (job === undefined) {
                            job = createJob(jono, style, "ABSENT");
                        }
                        job.HOURS_PER_DAY[date].hours = employeeDateHours.LATE / 60;
                    }
                } else if (employeeStatus === "LW") {
                    jono = "LEAVE";
                    if (department === "SALES" || department.includes("SC")) {
                        style = "INCENTIVE (SALES)";
                    } else {
                        style = "INCENTIVE (REG)";
                    }
                    let job = getJob(jono, style, "REGULAR");
                    if (job === undefined) {
                        job = createJob(jono, style, "REGULAR");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.TOTAL;
                }
            } else if (dayType === "H") {
                if (employeeDateHours.TOTAL && employeeDateHours.TOTAL > 0) {
                    let job = getJob(jono, style, "HOL - REG");
                    if (job === undefined) {
                        job = createJob(jono, style, "HOL - REG");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.TOTAL;

                    job = getJob("HOLIDAY", "HOLIDAY", "HOL - REG");
                    if (job === undefined) {
                        job = createJob("HOLIDAY", "HOLIDAY", "HOL - REG");
                    }
                    job.HOURS_PER_DAY[date].hours = 8;
                }
                if (employeeDateHours.OT && employeeDateHours.OT > 0 && employeeDateHours.CH) {
                    let job = getJob(jono, style, "HOL - OVR");
                    if (job === undefined) {
                        job = createJob(jono, style, "HOL - OVR");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.OT;
                }
            } else if (dayType === "S") {
                if (employeeDateHours.TOTAL && employeeDateHours.TOTAL > 0) {
                    let job = getJob(jono, style, "SPECIAL");
                    if (job === undefined) {
                        job = createJob(jono, style, "SPECIAL");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.TOTAL;
                }
                if (employeeDateHours.OT && employeeDateHours.OT > 0 && employeeDateHours.CH) {
                    let job = getJob(jono, style, "SPC - OVR");
                    if (job === undefined) {
                        job = createJob(jono, style, "SPC - OVR");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.OT;
                }
                if (employeeDateHours.ABSENT && employeeDateHours.ABSENT > 0) {
                    jono = "ABSENT";
                    style = "UNEXCUSED";
                    let job = getJob(jono, style, "ABSENT");
                    if (job === undefined) {
                        job = createJob(jono, style, "ABSENT");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.ABSENT / 60;
                }
                if (employeeDateHours.LATE && employeeDateHours.LATE > 0) {
                    jono = "ABSENT";
                    style = "UNEXCUSED";
                    let job = getJob(jono, style, "ABSENT");
                    if (job === undefined) {
                        job = createJob(jono, style, "ABSENT");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.LATE / 60;
                }
            } else if (dayType === "HH") {
                if (employeeDateHours.TOTAL && employeeDateHours.TOTAL > 0) {
                    jono = "HOLIDAY";
                    style = "HOLIDAY";
                    let job = getJob(jono, style, "HOL - REG");
                    if (job === undefined) {
                        job = createJob(jono, style, "HOL - REG");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.TOTAL;
                }
                if (employeeDateHours.OT && employeeDateHours.OT > 0 && employeeDateHours.CH) {
                    jono = "HOLIDAY";
                    style = "HOLIDAY";
                    let job = getJob(jono, style, "HOL - OVR");
                    if (job === undefined) {
                        job = createJob(jono, style, "HOL - OVR");
                    }
                    job.HOURS_PER_DAY[date].hours = employeeDateHours.OT;
                }
            }
        }
    });

    employeeJobs.value = newEmployeeJobs;

    addFlashMessage("SUCCESS", `Successfully populated hours.
        Check the hours section, change as necessary, then click on Update button to save.`);
}

watch(employeeJobs, () => {
    if (isFreshForm.value) {
        isFreshForm.value = false;
        return;
    }
    isUpdateDisabled.value = false;
}, { deep: true });
</script>
