<template>
    <div class="row mb-3">
        <div class="col-xl-4 mb-3">
            <form @submit.prevent="saveJoborder">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-briefcase me-2"></i>Insert Joborder
                    </legend>
                    <div class="row gx-3">
                        <div class="col-xl-4 col-md-5 mb-md-1 mb-2">
                            <FormInput
                                type="text"
                                label="ID"
                                v-model="form.JOBORDER_ID"
                                id-prefix="paytype"
                                is-horizontal
                                label-class="col-xl-4 col-md-3 label-sm align-items-center"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-9 col-12"
                                :errors="errors.JOBORDER_ID"
                                disabled
                                @keydown-enter="focusnext('endDateFormInput')"
                            />
                        </div>
                        <div class="col-xl-8 col-md-7 mb-md-1 mb-2">
                            <div class="row gx-1">
                                <div class="col-auto">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm px-2"
                                        :disabled="!selectedJoborder"
                                        @click="extendJoborder"
                                    >
                                        Extend Till
                                    </button>
                                </div>
                                <div class="col">
                                    <FormInput
                                        type="date"
                                        label="Extend Till"
                                        hide-label
                                        v-model="form.END_DATE"
                                        id-prefix="endDate"
                                        input-class="form-control-sm"
                                        :errors="errors.END_DATE"
                                        @keydown-enter="focusnext('jonoFormInput')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-md-6">
                            <FormInput
                                type="text"
                                label="JONO"
                                v-model="form.JONO"
                                id-prefix="jono"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-3 col-md-2 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-9 col-md-10 col-12"
                                :errors="errors.JONO"
                                @keydown-enter="focusnext('styleFormInput')"
                            />
                        </div>
                        <div class="col-xl-12 col-md-6">
                            <FormInput
                                type="text"
                                label="STYLE"
                                v-model="form.STYLE"
                                id-prefix="style"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-3 col-md-2 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-9 col-md-10 col-12"
                                :errors="errors.STYLE"
                                @keydown-enter="focusnext('glIdFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2">
                        <div class="col-xl-12 col-md-6">
                            <div class="row">
                                <div class="col-xl-3 col-md-2 pe-0">
                                    <label
                                        for="glIdFormInput"
                                        class="col-form-label"
                                    >
                                        GL ID
                                    </label>
                                </div>
                                <div class="col">
                                    <div class="row gx-1">
                                        <div class="col">
                                            <FormInput
                                                type="text"
                                                label="GL ID"
                                                hide-label
                                                v-model="form.GL_ID"
                                                id-prefix="glId"
                                                input-class="form-control-sm"
                                                :errors="errors.GL_ID"
                                                :form-text="`${
                                                    selectedRelationships
                                                        .glAccount?.CLASS ?? ''
                                                } - ${
                                                    selectedRelationships
                                                        .glAccount?.ACCTNAME ??
                                                    ''
                                                }`"
                                                @keydown-enter="focusnext('selectGLButton')"
                                            />
                                        </div>
                                        <div class="col-auto">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm px-1"
                                                id="selectGLButton"
                                                data-bs-toggle="modal"
                                                data-bs-target="#selectGLModal"
                                                @keydown.enter.prevent="focusnext('qtyFormInput')"
                                            >
                                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select GL
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-md-6">
                            <FormInput
                                type="number"
                                label="QTY"
                                v-model="form.QTY"
                                id-prefix="qty"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-3 col-md-2 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-9 col-md-10 col-12"
                                :errors="errors.QTY"
                                @keydown-enter="focusnext(!selectedJoborder ? 'insertButton' : 'updateButton')"
                            />
                        </div>
                    </div>
                    <div class="text-end mt-3">
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm me-2 mb-1"
                            :disabled="selectedJoborder"
                            id="insertButton"
                        >
                            <i class="icon ph-bold ph-plus me-2"></i>Insert
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm me-2 mb-1"
                            :disabled="!selectedJoborder"
                            @click="updateJoborder"
                        >
                            <i class="icon ph-bold ph-pencil-line me-2"></i>Update
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm me-2 mb-1"
                            @click="reset"
                        >
                            <i class="icon ph-bold ph-broom me-2"></i>Clear
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="col-xl-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-briefcase me-2"></i> Joborders
                </legend>
                <JobordersTable
                    v-model="selectedJoborder"
                    :refresh-flag="tableRefreshFlag"
                    :joborder-id="joborderIdFilterForTable"
                />
            </fieldset>
        </div>
    </div>

    <SelectGLModal
        @select-gl="
            (glAccount) => {
                form.GL_ID = glAccount.GL_ID;
                selectedRelationships.glAccount = glAccount;
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useToastsStore } from "@/stores/toasts";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useJoborders } from "@/composables/data/joborders";

import FormInput from "@/components/utils/FormInput.vue";

import SelectGLModal from "./SelectGLModal.vue";

import JobordersTable from "@/components/utils/tables/JobordersTable.vue";

import focusnext from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const tableRefreshFlag = ref(false);

const { postJoborder, putJoborder, postJoborderExtension } = useJoborders();

const selectedJoborder = ref(null);

const form = ref({
    JOBORDER_ID: null,
    JONO: null,
    STYLE: null,
    QTY: null,
    GL_ID: null,
    START_DATE: null,
    END_DATE: null,
});

const selectedRelationships = ref({});

const errors = ref({});

const joborderIdFilterForTable = ref(null); // When inserting a joborder, set this filter so table can focus to it

watch(selectedJoborder, () => {
    form.value = Object.assign(
        {
            JOBORDER_ID: null,
            JONO: null,
            STYLE: null,
            QTY: null,
            GL_ID: null,
            START_DATE: null,
            END_DATE: null,
        },
        selectedJoborder.value
    );
    errors.value = {};
});

function reset() {
    selectedJoborder.value = null;
    form.value = {
        JOBORDER_ID: null,
        JONO: null,
        STYLE: null,
        QTY: null,
        GL_ID: null,
        START_DATE: null,
        END_DATE: null,
    };
    errors.value = {};
    joborderIdFilterForTable.value = null;
}

async function saveJoborder() {
    errors.value = {};

    loadingFlags.add("saveJoborder");
    try {
        const newJoborder = await postJoborder(form.value);
        toasts.add("SUCCESS", "Success", "Successfully created joborder.");
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
        joborderIdFilterForTable.value = selectedJoborder.value.JOBORDER_ID;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("saveJoborder");
}

async function updateJoborder() {
    errors.value = {};

    loadingFlags.add("updateJoborder");
    try {
        const newJoborder = await putJoborder(
            selectedJoborder.value.JOBORDER_ID,
            form.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated joborder.");
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateJoborder");
}

async function extendJoborder() {
    errors.value = {};

    loadingFlags.add("extendJoborder");
    try {
        const newJoborder = await postJoborderExtension(
            selectedJoborder.value.JOBORDER_ID,
            form.value.END_DATE
        );
        toasts.add("SUCCESS", "Success", "Successfully extended joborder.");
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("extendJoborder");
}
</script>
