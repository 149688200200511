<template>
    <form @submit.prevent="addStyle" class="mb-lg-4 mb-3">
        <fieldset>
            <legend>
                <i class="icon ph-bold ph-info me-2"></i>Enter Style Details
            </legend>
            <FormInput
                type="text"
                v-model="styleDetails.style"
                required
                label="Style"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.style"
            />
            <FormInput
                type="text"
                v-model="styleDetails.color"
                required
                label="Color"
                class="mb-md-1 mb-2"
                is-horizontal
                :errors="errors.color"
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="number"
                v-model="styleDetails.quantity"
                required
                label="Quantity"
                class="mb-md-1 mb-2"
                is-horizontal
                min="1"
                :errors="errors.quantity"
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <hr class="my-4" />
            <div class="text-end">
                <button type="reset"
                    class="btn btn-outline-secondary btn-sm me-2 mb-1"
                    @click="reset">
                    <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                </button>
                <button type="submit" class="btn btn-primary btn-sm me-2 mb-1">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Add
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script setup>
import { ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useStyleSmSkus } from '@/composables/data/styleSmSkus';

import FormInput from '@/components/utils/FormInput.vue';

const props = defineProps({
    class: String,
});

const emit = defineEmits(["add"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const errors = ref({});

const { styleSmSkus, getStyleSmSkus } = useStyleSmSkus();

const styleDetails = ref({
    style: null,
    color: null,
    quantity: null,
});

function reset() {
    styleDetails.value = {
        style: null,
        color: null,
        quantity: null,
    };
}

async function addStyle() {
    errors.value = {};

    loadingFlags.add('addStyle');
    try {
        await getStyleSmSkus(styleDetails.value.style, {
            COLOR: styleDetails.value.color,
        });

        if (styleSmSkus.value.length == 0) {
            errors.value.color = ['Color does not exist for given Style.'];
            loadingFlags.delete('addStyle');
            return;
        }

        emit('add', {
            ...styleDetails.value,
            sku: styleSmSkus.value[0].SM_SKU,
        });
        styleDetails.value = {
            style: null,
            color: null,
            quantity: null,
            box: null,
        };
    } catch(e) {
        if (e.status == 404) {
            errors.value.style = ['Style does not exist.'];
        } else {
            toasts.add('ERROR', 'Error', e.message);
        }
    }
    loadingFlags.delete('addStyle');
}
</script>
