<template>
    <Card :class="class">
        <template #header>
            Suppliers Table
        </template>
        <DataTable
            :loading="loadingFlags.has('fetchOrderSuppliers')"
            lazy
            :value="orderSuppliers"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchOrderSuppliers(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            selection-mode="single"
            v-model:selection="orderSupplier"
            data-key="SUPPLIER_ID"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="SUPPLIER_ID" header="ID" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SUPPLIER_NAME" header="Name" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="SUPPLIER_LOCATION" header="Location" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CONTACT_PERSON" header="Contact Person" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CONTACT_NUMBER" header="Contact Number" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="CONTACT_EMAIL" header="Contact Email" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useOrderSuppliers } from '@/composables/data/orderSuppliers';

const props = defineProps({
    refreshFlag: null,
    class: String,
});

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    'SUPPLIER_ID', 'SUPPLIER_NAME', 'SUPPLIER_LOCATION', 'CONTACT_PERSON', 'CONTACT_NUMBER', 'CONTACT_EMAIL'
]);

const { orderSuppliers, totalRecords, getOrderSuppliers } = useOrderSuppliers();

const orderSupplier = defineModel();

const loadingFlags = useLoadingFlagsStore();

const fetchTimeout = ref(false);

async function fetchOrderSuppliers() {
    orderSuppliers.value = null;

    loadingFlags.add('fetchOrderSuppliers');
    try {
        await getOrderSuppliers(params.value);
    } catch(e) {
        toasts.add('ERROR', 'error', e.message);
    }
    loadingFlags.delete('fetchOrderSuppliers');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchOrderSuppliers, 300);
}

onMounted(fetchOrderSuppliers);

watch(() => props.refreshFlag, fetchOrderSuppliers);
</script>
