<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Robinsons SKU
        </legend>
        <DataTable
            :loading="loadingFlags.has('fetchRobinsonsSkus')"
            :value="styleRobinsonsSkus"
            paginator
            :rows="10"
            selection-mode="single"
            v-model:selection="robinsonsSku"
            data-key="id"
            table-class="table table-bordered table-hover"
            class="mb-3">
            <Column field="id" header="ID" />
            <Column field="COLOR" header="Color" />
            <Column field="ROBINSONS_SKU" header="Robinsons SKU" />
            <template #empty>No Robinsons Skus found.</template>
        </DataTable>
        <form>
            <FormSelect
                label="Color"
                v-model="form.COLOR"
                :options="colors"
                is-horizontal
                id-prefix="color"
                class="mb-md-1 mb-2"
                select-class="form-select-sm"
                :errors="errors.COLOR"
                @keydown-enter="focusNextInput('robinsonsSkuFormInput')"
                label-class="col-xl-4 col-md-5 label-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                label="Robinsons SKU"
                type="text"
                v-model="form.ROBINSONS_SKU"
                is-horizontal
                id-prefix="robinsonsSku"
                class="mb-md-1 mb-2"
                input-class="form-control-sm"
                :errors="errors.ROBINSONS_SKU"
                label-class="col-xl-4 col-md-5 label-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button
                    type="button"
                    class="btn btn-primary me-2 mb-1 btn-sm"
                    :disabled="!style || robinsonsSku"
                    @click="insertRobinsonsSku">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Insert
                </button>
                <button
                    type="button"
                    class="btn btn-primary me-2 mb-1 btn-sm"
                    :disabled="!style || !robinsonsSku"
                    @click="updateRobinsonsSku">
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                </button>
                <button
                    type="reset"
                    class="btn btn-outline-danger me-2 mb-1 btn-sm"
                    @click="clear">
                    <i class="icon ph-bold ph-x me-2"></i>Clear
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleRobinsonsSkus } from "@/composables/data/styleRobinsonsSkus";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    style: String,
    colors: Array,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { styleRobinsonsSkus, getStyleRobinsonsSkus, postStyleRobinsonsSku, putStyleRobinsonsSku } = useStyleRobinsonsSkus();

const robinsonsSku = ref(null);

const form = ref({
    COLOR: null,
    ROBINSONS_SKU: null,
});

const errors = ref({});

async function fetchRobinsonsSkus() {
    styleRobinsonsSkus.value = null;

    if (!props.style) {
        return;
    }

    loadingFlags.add("fetchRobinsonsSkus");
    try {
        await getStyleRobinsonsSkus(props.style);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchRobinsonsSkus");
}

watch(robinsonsSku, () => {
    errors.value = {};
    form.value = Object.assign({
        COLOR: null,
        ROBINSONS_SKU: null,
    }, robinsonsSku.value);
});

watch(
    () => props.style,
    async () => {
        errors.value = {};
        robinsonsSku.value = null;
        fetchRobinsonsSkus();
    }
);

async function insertRobinsonsSku() {
    errors.value = {};

    loadingFlags.add("insertRobinsonsSku");
    try {
        const newRobinsonsSku = await postStyleRobinsonsSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully created Robinsons SKU ${newRobinsonsSku.SM_SKU}.`
        );
        robinsonsSku.value = newRobinsonsSku;
        fetchRobinsonsSkus();
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("insertRobinsonsSku");
}

async function updateRobinsonsSku() {
    errors.value = {};

    loadingFlags.add("updateRobinsonsSku");
    try {
        const newRobinsonsSku = await putStyleRobinsonsSku(props.style, robinsonsSku.value.id, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated Robinsons SKU to ${newRobinsonsSku.SM_SKU}.`
        );
        robinsonsSku.value = newRobinsonsSku;
        fetchRobinsonsSkus();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateRobinsonsSku");
}

function clear() {
    robinsonsSku.value = null;
}
</script>
