import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleMgSkus() {
    const styleMgSku = ref(null);

    const getStyleMgSku = async (style) => {
        try {
            const getStyleMgSkuResponse = await axios.get(route('api.styles.style-mg-sku.show', {
                style
            }));
            styleMgSku.value = getStyleMgSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching MG SKU.');
        }
    };

    const putStyleMgSku = async (style, styleMgSku) => {
        try {
            const putStyleMgSkuResponse = await axios.put(route('api.styles.style-mg-sku.update', {
                style
            }), styleMgSku);
            return putStyleMgSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating MG SKU.');
        }
    };
    
    return {
        styleMgSku,
        getStyleMgSku,
        putStyleMgSku,
    };
}
