import { ref } from "vue";

import constructFetchError from "./constructFetchError";

export function useEmployeeLocations() {
    const employeeLocations = ref(null);
    const totalRecords = ref(null);

    const getEmployeeLocations = async (params) => {
        try {
            const getEmployeeLocationsResponse = await axios.get(route('api.employee-locations.index', params));
            employeeLocations.value = getEmployeeLocationsResponse.data.data;
            totalRecords.value = getEmployeeLocationsResponse.data.meta?.total ?? employeeLocations.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee locations.');
        }
    };
    
    return {
        employeeLocations,
        totalRecords,
        getEmployeeLocations,
    };
}
