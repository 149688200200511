import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useNewBarcodeOutputs() {
    const newBarcodeOutputs = ref(null);
    const totalRecords = ref(null);

    const getNewBarcodeOutputs = async (params) => {
        try {
            const getNewBarcodeOutputsResponse = await axios.get(route('api.new-barcode-outputs.index', params));
            newBarcodeOutputs.value = getNewBarcodeOutputsResponse.data.data;
            totalRecords.value = getNewBarcodeOutputsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching new barcode outputs.');
        }
    };

    const postNewBarcodeOutputsMovement = async (commentId) => {
        try {
            const getNewBarcodeOutputsResponse = await axios.post(route("api.new-barcode-outputs.movements.store"), {
                comment_id: commentId,
            });
            return getNewBarcodeOutputsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while writing new barcode outputs to inventory.');
        }
    };

    const postNewBarcodeOutputClearance = async () => {
        try {
            const postNewBarcodeInputClearanceResponse = await axios.post(route("api.new-barcode-outputs.clearances.store"));
            return postNewBarcodeInputClearanceResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while clearing new barcode outputs.');
        }
    };
    
    return {
        newBarcodeOutputs,
        totalRecords,
        getNewBarcodeOutputs,
        postNewBarcodeOutputsMovement,
        postNewBarcodeOutputClearance,
    };
}
