<template>
    <div class="row">
        <div class="col-lg-5 mb-3">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-plus-circle me-2"></i>Create SP
                </legend>
                <div class="d-flex flex-column justify-content-between h-100">
                    <div class="row g-3 pb-5">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="account.ACCOUNT_ID"
                                label="Client"
                                id-prefix="client"
                                :form-text="account.ACCOUNT_NAME"
                                disabled
                                input-class="client-input-min-width form-control-sm"
                                is-horizontal
                                label-class="col-xl-3 col-md-5"
                                input-container-class="col-xl-8 col-md-7 col-12"
                            />
                        </div>
                        <div class="col-auto">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                @click="(_) => { isSelectingAccount = true; }">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>
                                Search Client
                            </button>
                        </div>
                    </div>
                    <div class="text-end mt-0">
                        <hr class="my-4" />
                        <div class="row">
                            <div class="col-xl-6 col-12 mb-xl-0 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-primary me-2 w-100 btn-sm"
                                    :disabled="!account.ACCOUNT_ID"
                                    @click="(_) => { isViewingPaymentDetailsPopup = true; }">
                                    <i class="icon ph-bold ph-eye me-2"></i>
                                    View Payment Details
                                </button>
                            </div>
                            <div class="col-xl-6 col-12 mb-xl-0 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!account.ACCOUNT_ID"
                                    @click="(_) => { isViewingPaymentSummaryPopup = true; }">
                                    <i class="icon ph-bold ph-eye me-2"></i>View
                                    Payment Summary
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-7 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-list-dashes me-2"></i>JONO Form
                </legend>
                <!-- When selecting joborder from table, propagate to this form for updating -->
                <JONOFormCard
                    :account="account"
                    :selected-joborder-from-table="selectedJoborderFromTable"
                    @create-or-update="(joborderNew) => { savedJoborderNew = joborderNew; }"
                />
            </fieldset>
        </div>
        <div class="col-lg-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clipboard me-2"></i>JONO
                </legend>
                <!-- When saving joborder from JONOFormCard, propagate to the table so it gets viewed -->
                <JONOTableCard
                    :account="account"
                    :saved-joborder-new="savedJoborderNew"
                    @select="(joborderNew) => { selectedJoborderFromTable = joborderNew; }"
                    class="mb-3"
                />
            </fieldset>
        </div>
        <div class="col-lg-5">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-list-dashes me-2"></i>
                    Orders by STYLE
                </legend>
                <OrdersByStyleCard
                    :joborder-new="selectedJoborderFromTable"
                    class="mb-3">
                    <template #footer>
                        <div class="text-end">
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                :disabled="!selectedJoborderFromTable"
                                @click="updateOrdersOnClick">
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update Orders
                            </button>
                        </div>
                    </template>
                </OrdersByStyleCard>
            </fieldset>
        </div>
        <PaymentDetailsTablePopup
            :account="account"
            :is-open="isViewingPaymentDetailsPopup"
            @close="(_) => { isViewingPaymentDetailsPopup = false; }"
        />

        <PaymentSummaryTablePopup
            :account="account"
            :is-open="isViewingPaymentSummaryPopup"
            @close="(_) => { isViewingPaymentSummaryPopup = false; }"
        />

        <SelectAccount
            :is-open="isSelectingAccount"
            :on-select-account="(selectedAccount) => { account = selectedAccount; }"
            :on-close="(_) => { isSelectingAccount = false; }"
        />
    </div>
</template>

<script setup>
import { ref } from "vue";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";

import SelectAccount from "@/components/utils/SelectAccount.vue";

import JONOTableCard from "./JONOTableCard.vue";
import JONOFormCard from "./JONOFormCard.vue";
import OrdersByStyleCard from "./utils/OrdersByStyleCard.vue";
import PaymentDetailsTablePopup from "./PaymentDetailsTablePopup.vue";
import PaymentSummaryTablePopup from "./PaymentSummaryTablePopup.vue";

const account = ref({
    ACCOUNT_ID: null,
    ACCOUNT_NAME: null,
});

const isSelectingAccount = ref(false);
const isViewingPaymentDetailsPopup = ref(false);
const isViewingPaymentSummaryPopup = ref(false);

const selectedJoborderFromTable = ref(null);

const savedJoborderNew = ref(null);

function updateOrdersOnClick() {
    if (!selectedJoborderFromTable.value) return;

    window.open(
        route("activity.update-sp-status.update-order-details", {
            JONO: selectedJoborderFromTable.value.JONO,
        }),
        "_self"
    );
}
</script>
