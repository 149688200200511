<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Voucher Images
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 mb-3">
                <div class="modal-padding-container">
                    <div v-if="isLoading"
                        class="spinner-border"
                        role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else-if="voucherImages?.length > 0"
                            class="row g-3">
                        <div v-for="(imageURL, index) in imageURLs"
                            class="col-md-4 text-center">
                            <p v-if="imageLoadingStatuses[index]">
                                <div class="spinner-border"
                                    role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Loading image #{{ index + 1 }}
                            </p>
                            <img :src="imageURL"
                                :alt="voucherId + ' voucher image ' + index"
                                @load="_ => { imageLoadingStatuses[index] = false; }"
                                class="img-fluid"
                                @error="_ => { imageLoadingStatuses[index] = false; }"
                            />
                        </div>
                    </div>
                    <div v-else class="text-danger text-center py-2">
                        <i class="icon ph-bold ph-image me-2"></i>Nothing uploaded.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useVoucherImages } from '@/composables/data/voucherImages';

const props = defineProps({
    voucherId: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close', 'error']);

const { voucherImages, getVoucherImages } = useVoucherImages();

const isLoading = ref(false);

const imageLoadingStatuses = ref(null);
const imageURLs = ref(null);

async function fetchImages() {
    voucherImages.value = null;

    if(!props.voucherId) {
        return;
    }

    isLoading.value = true;
    try {
        await getVoucherImages(props.voucherId);
        setTimeout(() => {
            imageURLs.value = voucherImages.value.map(voucherImage => voucherImage.url);
            imageLoadingStatuses.value = voucherImages.value.map(_ => true);
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

watch(() => [props.isOpen, props.voucherId], () => {
    if(props.isOpen) {
        fetchImages();
    }
});
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
