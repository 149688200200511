<template>
    <div class="row mb-3">
        <div class="col-xl-3 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-storefront me-2"></i>Update Store Summary
                </legend>
                <div class="row">
                    <div class="col-lg-9 col-md-10">
                        <FormInput
                            v-model="timePeriodDisplay"
                            type="text"
                            disabled
                            label="Time Period"
                            id-prefix="timePeriod"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-sm-5 label-sm align-items-center"
                            input-container-class="col-sm-7 col-12"
                            class="mb-md-1 mb-2"
                        />
                    </div>
                    <div class="col-lg-3 col-md-2 text-end ps-lg-0">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="(_) => { isSelectTimePeriodPopupOpen = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9 col-md-10">
                        <FormSelect
                            v-model="companyCode"
                            :options="companies"
                            :option-value="(company) => company.COMPANY_CODE"
                            :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                            label="Company"
                            id-prefix="company"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            select-class="form-select-sm"
                            label-class="col-sm-5 label-sm align-items-center"
                            select-container-class="col-sm-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9 col-md-10">
                        <FormInput
                            v-model="location"
                            type="text"
                            disabled
                            label="Location"
                            id-prefix="location"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            label-class="col-sm-5 label-sm align-items-center"
                            input-container-class="col-sm-7 col-12"
                        />
                    </div>
                    <div class="col-lg-3 col-md-2 text-end ps-lg-0">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!companyCode"
                            @click="(_) => { isSelectLocationPopupOpen = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9 col-md-10">
                        <FormSelect
                            v-model="brand"
                            :options="['KIMBEL', 'KIMCHI', 'SP', 'REPAIR']"
                            label="Brand"
                            id-prefix="brand"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            select-class="form-select-sm"
                            label-class="col-sm-5 label-sm align-items-center"
                            select-container-class="col-sm-7 col-12"
                        />
                    </div>
                </div>
                <hr class="my-3" />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary me-2 mb-1 px-1 btn-sm"
                        :disabled="!timePeriod"
                        @click="generateStoreSummaryPDF">
                        <i class="icon ph-bold ph-file-pdf me-2"></i>Export All to PDF
                    </button>
                    <button type="button"
                        class="btn btn-primary me-2 mb-1 px-1 btn-sm"
                        :disabled="!timePeriod"
                        @click="generateStoreSummarySpreadsheet">
                        <i class="icon ph-bold ph-file-xls me-2"></i>Export All to Spreadsheet
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-9">
            <form @submit.prevent="saveStoreSummary">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-chart-bar me-2"></i>Summary
                    </legend>
                    <div class="row g-3 mb-3">
                        <div class="col-lg-4">
                            <FormInput
                                v-model="storeSummary.TOTAL_SALES"
                                type="number"
                                step=".01"
                                label="Total Sales"
                                id-prefix="totalSales"
                                @change="(_) => { removeAutoComputeWatcher('TOTAL_SALES'); }"
                                :errors="errors.TOTAL_SALES"
                                :disabled="disableFields"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                input-class="form-control-sm"
                            />
                        </div>
                        <div class="col-lg-8">
                            <FormInput
                                v-model="storeSummary.FLOATING_ADJ"
                                type="number"
                                step=".01"
                                label="Floating/Sales Adj (for commission only)"
                                id-prefix="floatingSalesAdj"
                                :errors="errors.FLOATING_ADJ"
                                is-horizontal
                                label-class="col-md-5 label-sm align-items-center"
                                input-container-class="col-md-7 col-12"
                                input-class="form-control-sm"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <fieldset class="mb-3">
                                <legend>
                                    <i class="icon ph-bold ph-minus me-2"></i>Less
                                </legend>
                                <FormInput
                                    v-model="storeSummary.DISCOUNTS"
                                    type="number"
                                    step=".01"
                                    label="Discounts"
                                    id-prefix="discounts"
                                    :errors="errors.DISCOUNTS"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                                <FormInput
                                    v-model="storeSummary.COMMISSIONS"
                                    type="number"
                                    step=".01"
                                    label="Commissions"
                                    id-prefix="commissions"
                                    :errors="errors.COMMISSIONS"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                                <FormInput
                                    v-model="storeSummary.SHIPPING"
                                    type="number"
                                    step=".01"
                                    label="Shipping"
                                    id-prefix="shipping"
                                    :errors="errors.SHIPPING"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                            </fieldset>
                            <fieldset class="mb-3">
                                <legend>
                                    <i class="icon ph-bold ph-coins me-2"></i>Gross Collection
                                </legend>
                                <FormInput
                                    v-model="netSales"
                                    type="number"
                                    step=".01"
                                    disabled
                                    label="Net Sales"
                                    id-prefix="netSales"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                                <FormInput
                                    v-model="collection"
                                    type="number"
                                    step=".01"
                                    disabled
                                    label="Collection"
                                    id-prefix="collection"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                            </fieldset>
                            <fieldset class="mb-3">
                                <legend>
                                    <i class="icon ph-bold ph-check me-2"></i>Net Collection
                                </legend>
                                <FormInput
                                    v-model="arBank"
                                    type="number"
                                    step=".01"
                                    disabled
                                    label="Accts Rcv (Bank)"
                                    id-prefix="accountsReceivableBank"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                                <FormInput
                                    v-model="storeSummary.AR_CASH"
                                    type="number"
                                    step=".01"
                                    label="Accts Rcv (Cash)"
                                    id-prefix="accountsReceivableCash"
                                    :errors="errors.AR_CASH"
                                    :disabled="disableFields"
                                    is-horizontal
                                    class="mb-md-1 mb-2"
                                    label-class="col-md-6 label-sm align-items-center"
                                    input-container-class="col-md-6 col-12"
                                    input-class="form-control-sm"
                                />
                            </fieldset>
                        </div>
                        <div class="col-lg-8">
                            <fieldset>
                                <legend>
                                    <i class="icon ph-bold ph-minus me-2"></i>Less
                                </legend>
                                <div class="row">
                                    <div class="col-md-6">
                                        <FormInput
                                            v-model="storeSummary.STORAGE"
                                            type="number"
                                            step=".01"
                                            label="Storage"
                                            id-prefix="storage"
                                            :errors="errors.STORAGE"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.UTILITIES"
                                            type="number"
                                            step=".01"
                                            label="Utilities"
                                            id-prefix="utilities"
                                            :errors="errors.UTILITIES"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.TELEPHONE"
                                            type="number"
                                            step=".01"
                                            label="Telephone"
                                            id-prefix="telephone"
                                            :errors="errors.TELEPHONE"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.SUPPLIES"
                                            type="number"
                                            step=".01"
                                            label="Supplies"
                                            id-prefix="supplies"
                                            :errors="errors.SUPPLIES"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.SALE_EVENTS"
                                            type="number"
                                            step=".01"
                                            label="Sale Events"
                                            id-prefix="saleEvents"
                                            :errors="errors.SALE_EVENTS"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.CREDITABLE_TAX"
                                            type="number"
                                            step=".01"
                                            label="Creditable Tax"
                                            id-prefix="creditableTax"
                                            :errors="errors.CREDITABLE_TAX"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.UNIFORM"
                                            type="number"
                                            step=".01"
                                            label="Uniform"
                                            id-prefix="uniform"
                                            :errors="errors.UNIFORM"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.PRICE_TAG"
                                            type="number"
                                            step=".01"
                                            label="Price Tag"
                                            id-prefix="priceTag"
                                            :errors="errors.PRICE_TAG"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.E2E_CHARGE"
                                            type="number"
                                            step=".01"
                                            label="E2E Charge"
                                            id-prefix="e2eCharge"
                                            :errors="errors.E2E_CHARGE"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <FormInput
                                            v-model="storeSummary.STORE_MAIN"
                                            type="number"
                                            step=".01"
                                            label="Store Maint"
                                            id-prefix="storeMaintenance"
                                            :errors="errors.STORE_MAIN"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.MAIL_POST"
                                            type="number"
                                            step=".01"
                                            label="Mail/Post"
                                            id-prefix="mailOrPost"
                                            :errors="errors.MAIL_POST"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.SAL_WAGES"
                                            type="number"
                                            step=".01"
                                            label="Sal/Wages"
                                            id-prefix="salaryOrWages"
                                            :errors="errors.SAL_WAGES"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.BANK_CHARGES"
                                            type="number"
                                            step=".01"
                                            label="Bank Charges"
                                            id-prefix="bankCharges"
                                            :errors="errors.BANK_CHARGES"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.TRANSPORT"
                                            type="number"
                                            step=".01"
                                            label="Transport"
                                            id-prefix="transport"
                                            :errors="errors.TRANSPORT"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.REPRESENT"
                                            type="number"
                                            step=".01"
                                            label="Represent"
                                            id-prefix="represent"
                                            :errors="errors.REPRESENT"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.MISCL"
                                            type="number"
                                            step=".01"
                                            label="Misc"
                                            id-prefix="miscellaneous"
                                            :errors="errors.MISCL"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                        <FormInput
                                            v-model="storeSummary.ADS"
                                            type="number"
                                            step=".01"
                                            label="Ads"
                                            id-prefix="advertisements"
                                            :errors="errors.ADS"
                                            :disabled="disableFields"
                                            is-horizontal
                                            class="mb-md-1 mb-2"
                                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                            input-container-class="col-xl-8 col-md-7 col-12"
                                            input-class="form-control-sm"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div class="row mt-3">
                                <div class="col-lg-6">
                                    <FormInput
                                        v-model="totalDeductions"
                                        type="number"
                                        step=".01"
                                        disabled
                                        label="Total Deduct"
                                        id-prefix="totalDeduct"
                                        is-horizontal
                                        label-class="col-xl-4 col-md-5 label-sm align-items-center"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                        input-class="form-control-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div class="text-end">
                        <button type="submit"
                            class="btn btn-primary btn-sm"
                            :disabled="
                                !companyCode ||
                                !timePeriod ||
                                !location ||
                                !brand ||
                                (!canUpdate && !disableFields)
                            ">
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>

    <SelectTimePeriodPopup
        :is-open="isSelectTimePeriodPopupOpen"
        @select="
            (selectedTimePeriod) => {
                timePeriod = selectedTimePeriod;
                isSelectTimePeriodPopupOpen = false;
            }
        "
        @close="(_) => { isSelectTimePeriodPopupOpen = false; }"
    />
    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        :filters="{
            COMPANY_CODE: {
                value: storeSummary.COMPANY_CODE,
                matchMode: 'equals',
            },
            TYPE: {
                value: ['S', 'H'],
                matchMode: 'in',
            },
        }"
        @select="
            (selectedLocation) => {
                location = selectedLocation.LOCATION;
                isSelectLocationPopupOpen = false;
            }
        "
        @close="(_) => { isSelectLocationPopupOpen = false; }"
    />
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useCompanies } from "@/composables/data/companies";
import { useReconFinSummaryLocationBases } from "@/composables/data/reconFinSummaryLocationBases";
import { useVoucherStoreSummaries } from "@/composables/data/voucherStoreSummaries";
import { useLocationCharges } from "@/composables/data/locationCharges";
import FormInput from "@/components/utils/FormInput";
import FormSelect from "@/components/utils/FormSelect";
import SelectTimePeriodPopup from "@/components/utils/popups/SelectTimePeriodPopup.vue";
import SelectLocationPopup from "@/components/utils/popups/SelectLocationPopup.vue";
import PrintModal from "@/components/utils/PrintModal.vue";
import scrollToTop from "@/utils/scrollToTop";
import { evaluate } from "mathjs";

const { addFlashMessage } = useFlashMessages();

const { companies, getCompanies } = useCompanies();

const {
    reconFinSummaryLocationBase,
    getReconFinSummaryLocationBase,
    putReconFinSummaryLocationBase,
} = useReconFinSummaryLocationBases();

const { voucherStoreSummary, getVoucherStoreSummary } =
    useVoucherStoreSummaries();

const { locationCharges, getLocationCharges } = useLocationCharges();

onMounted(async () => {
    try {
        await getCompanies();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
});

const isSelectTimePeriodPopupOpen = ref(false);
const isSelectLocationPopupOpen = ref(false);

const companyCode = ref(null);
const timePeriod = ref(null);
const location = ref(null);
const brand = ref(null);

const timePeriodDisplay = computed(() => timePeriod.value?.TIME_PERIOD);

const canUpdate = ref(false);

const permission = ref(null);
const disableFields = ref(false);

onMounted(checkPermission);

onMounted(() => {
    const time_period_param = new URLSearchParams(window.location.search)
        .get("time_period");
    const location_param = new URLSearchParams(window.location.search)
        .get("location");

    if (time_period_param) {
        timePeriod.value = {
            TIME_PERIOD: time_period_param,
        };
    }

    if (location_param) {
        location.value = location_param;
    }
});

async function checkPermission() {
    try {
        const response = await axios
            .get(route("api.recon-fin-summary-location-base.check-permission"));
        permission.value = response.data.permission;
    } catch (e) {
        console.log(e);
    }
}

watch(permission, () => {
    if (permission.value === "update floating sales") {
        disableFields.value = true;
        return;
    }

    disableFields.value = false;
});

function initializeDefaultStoreSummary() {
    return {
        TOTAL_SALES: null,
        DISCOUNTS: null,
        COMMISSIONS: null,
        SHIPPING: null,
        STORAGE: null,
        UTILITIES: null,
        TELEPHONE: null,
        SUPPLIES: null,
        SALE_EVENTS: null,
        CREDITABLE_TAX: null,
        UNIFORM: null,
        PRICE_TAG: null,
        E2E_CHARGE: null,
        STORE_MAIN: null,
        MAIL_POST: null,
        SAL_WAGES: null,
        BANK_CHARGES: null,
        TRANSPORT: null,
        REPRESENT: null,
        MISCL: null,
        ADS: null,
        AR_CASH: null,
        FLOATING_ADJ: null,
    };
}

const storeSummary = ref(initializeDefaultStoreSummary());
const errors = ref({});

const autoComputeUnwatchers = ref([]);

watch([companyCode, timePeriod], async () => {
    canUpdate.value = false;

    if (!companyCode.value || !timePeriod.value) {
        return;
    }

    try {
        await getVoucherStoreSummary(
            companyCode.value,
            timePeriod.value.TIME_PERIOD
        );
        // Can update store summary if there are no voucher approvals yet
        canUpdate.value =
            !voucherStoreSummary.value.sales_voucher_approval &&
            !voucherStoreSummary.value.collection_coucher_approval;
        if (!canUpdate.value && !disableFields.value) {
            addFlashMessage(
                "WARNING",
                `Store summaries for time period ${timePeriod.value.TIME_PERIOD} ` +
                    `and company code ${companyCode.value} CANNOT BE UPDATED anymore (has approved voucher already).`
            );
            scrollToTop();
        }
    } catch (e) {
        if (e.status == 404) {
            canUpdate.value = true;
        } else {
            canUpdate.value = false;
            addFlashMessage("ERROR", e.message);
            scrollToTop();
        }
    }
});

watch([companyCode, timePeriod, location, brand], async () => {
    storeSummary.value = initializeDefaultStoreSummary();

    // Remove all autocompute watchers
    autoComputeUnwatchers.value.forEach((autoComputeUnwatcher) => {
        autoComputeUnwatcher();
    });

    if (
        !companyCode.value ||
        !timePeriod.value ||
        !location.value ||
        !brand.value
    ) {
        return;
    }

    try {
        await getReconFinSummaryLocationBase(
            companyCode.value,
            timePeriod.value.TIME_PERIOD,
            location.value,
            brand.value
        );
        storeSummary.value = Object.assign(
            {},
            reconFinSummaryLocationBase.value
        );
    } catch (e) {
        if (e.status != 404) {
            addFlashMessage("ERROR", e.message);
            scrollToTop();
        } else if (canUpdate.value) {
            // canUpdate means also can insert (basically, can submit)
            storeSummary.value = {
                TOTAL_SALES: 0,
                DISCOUNTS: 0,
                COMMISSIONS: 0,
                SHIPPING: 0,
                STORAGE: 0,
                UTILITIES: 0,
                TELEPHONE: 0,
                SUPPLIES: 0,
                SALE_EVENTS: 0,
                CREDITABLE_TAX: 0,
                UNIFORM: 0,
                PRICE_TAG: 0,
                E2E_CHARGE: 0,
                STORE_MAIN: 0,
                MAIL_POST: 0,
                SAL_WAGES: 0,
                BANK_CHARGES: 0,
                TRANSPORT: 0,
                REPRESENT: 0,
                MISCL: 0,
                ADS: 0,
                AR_CASH: 0,
                FLOATING_ADJ: 0,
            };

            // Since this is an initial form (insert), then fetch all location charges to prepopulate the fields.
            try {
                await getLocationCharges(location.value, {
                    filters: {
                        START_DATE: {
                            value: timePeriod.value.END_DATE,
                            matchMode: "lte",
                        },
                        END_DATE: {
                            value: timePeriod.value.END_DATE,
                            matchMode: "gte",
                        },
                    },
                });

                for (let locationCharge of locationCharges.value) {
                    if (locationCharge.CHARGE_TYPE == "F") {
                        storeSummary.value[locationCharge.CHARGE_NAME] =
                        parseFloat(locationCharge.CHARGE_VALUE);
                    } else if (locationCharge.CHARGE_TYPE == "C") {
                        autoComputeUnwatchers.value.push(
                            watch(
                                () => {
                                    const fieldsWatched = [];
                                    for (let validVariable of locationCharge.VARIABLES) {
                                        fieldsWatched.push(storeSummary.value[validVariable]);
                                    }

                                    return fieldsWatched;
                                },
                                () => {
                                    let storeSummaryFieldsScope = {};
                                    for (let validVariable of locationCharge.VARIABLES) {
                                        storeSummaryFieldsScope[validVariable] = storeSummary.value[validVariable] || 0;
                                    }
                                    storeSummary.value[locationCharge.CHARGE_NAME] = parseFloat(
                                        evaluate(locationCharge.CHARGE_VALUE, storeSummaryFieldsScope ).toFixed(2)
                                    );
                                }
                            )
                        );
                    }
                }
            } catch (e) {
                if (e.status != 404) {
                    addFlashMessage("ERROR", e.message);
                    scrollToTop();
                }
            }
        }
    }
});

const netSales = computed(() =>
    parseFloat(
        (
            (storeSummary.value.TOTAL_SALES || 0) -
            (storeSummary.value.DISCOUNTS || 0) -
            (storeSummary.value.COMMISSIONS || 0) -
            (storeSummary.value.SHIPPING || 0)
        ).toFixed(2)
    )
);

const collection = computed(() => netSales.value);

const totalDeductions = computed(() =>
    parseFloat(
        (
            (storeSummary.value.STORAGE || 0) +
            (storeSummary.value.UTILITIES || 0) +
            (storeSummary.value.TELEPHONE || 0) +
            (storeSummary.value.SUPPLIES || 0) +
            (storeSummary.value.SALE_EVENTS || 0) +
            (storeSummary.value.CREDITABLE_TAX || 0) +
            (storeSummary.value.UNIFORM || 0) +
            (storeSummary.value.PRICE_TAG || 0) +
            (storeSummary.value.E2E_CHARGE || 0) +
            (storeSummary.value.STORE_MAIN || 0) +
            (storeSummary.value.MAIL_POST || 0) +
            (storeSummary.value.SAL_WAGES || 0) +
            (storeSummary.value.BANK_CHARGES || 0) +
            (storeSummary.value.TRANSPORT || 0) +
            (storeSummary.value.REPRESENT || 0) +
            (storeSummary.value.MISCL || 0) +
            (storeSummary.value.ADS || 0)
        ).toFixed(2)
    )
);

const arBank = computed(() =>
    parseFloat(
        (
            netSales.value -
            totalDeductions.value -
            (storeSummary.value.AR_CASH || 0)
        ).toFixed(2)
    )
);

async function saveStoreSummary() {
    errors.value = {};
    try {
        await putReconFinSummaryLocationBase(
            companyCode.value,
            timePeriod.value.TIME_PERIOD,
            location.value,
            brand.value,
            storeSummary.value
        );
        addFlashMessage("SUCCESS", "Successfully updated store summary.");
    } catch (e) {
        errors.value = e.errors ?? {};
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

function generateStoreSummaryPDF() {
    window.open(
        route("api.time-periods.store-summary-report-pdf.show", {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
        })
    );
}

function generateStoreSummarySpreadsheet() {
    window.open(
        route("api.time-periods.store-summary-report-spreadsheet.show", {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
        })
    );
}
</script>
