<style scoped>
.isloading-gl {
    z-index: 11;
    background: rgb(0, 0, 0, 0.5)
}

.is-list-visible {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}
</style>
<template>
    <div>
        <div v-if="isLoading"
            class="isloading-gl position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div
            v-if="isToastSuccess"
            class="isloading-gl position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3">
            <div class="toast show bg-success w-50"
                role="alert"
                aria-live="assertive"
                aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close">
                    </button>
                </div>
                <div class="toast-body text-white p-4 fs-1.4">{{ toastMessage }}</div>
            </div>
        </div>
        <div v-if="isToastError"
            class="isloading-gl position-fixed bottom-0 end-0 top-0 start-0 d-flex justify-content-center align-items-center p-3">
            <div class="toast show bg-danger w-50"
                role="alert"
                aria-live="assertive"
                aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close">
                    </button>
                </div>
                <div class="toast-body text-white p-4 fs-1.4">{{ toastMessage }}</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-6 mb-lg-0 mb-3">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-funnel me-2"></i>Filter
                    </legend>
                    <div class="row mb-md-1 mb-2">
                        <div class="col-lg-3 col-md-4">
                            <label class="form-label label-sm">Company</label>
                        </div>
                        <div class="col-lg-9 col-md-8 col-12">
                            <select class="form-select form-select-sm"
                                v-model="companyCode">
                                <option v-for="company in companies"
                                    :value="company.COMPANY_CODE">
                                    {{ company.COMPANY_DESCRIPTION }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2">
                        <div class="col-md-3 mb-md-0 mb-2">
                            <select class="form-select form-select-sm"
                                v-model="className"
                                @change="getAcctName()">
                                <option v-for="item in classData" :value="item">{{ item }}</option>
                            </select>
                        </div>
                        <div class="col-md-6 mb-md-0 mb-2">
                            <select class="form-select form-select-sm"
                                v-model="acctName"
                                @change="acctNameAfterUpdate()">
                                <option v-for="item in acctNameData"
                                    :value="item.ACCTNAME">
                                    {{ item.ACCTNAME }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <input type="text"
                                class="form-control form-control-sm"
                                v-model="glId"
                                disabled
                            />
                        </div>
                    </div>
                    <FormInput
                        type="date"
                        label="Start Date"
                        v-model="startDate"
                        is-horizontal
                        id-prefix="startDate"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        :errors="errors.START_DATE"
                        label-class="col-lg-3 col-md-4 label-sm"
                        input-container-class="col-lg-9 col-md-8 col-12"
                    />
                    <FormInput
                        type="date"
                        label="End Date"
                        v-model="endDate"
                        is-horizontal
                        id-prefix="endDate"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        :errors="errors.END_DATE"
                        label-class="col-lg-3 col-md-4 label-sm"
                        input-container-class="col-lg-9 col-md-8 col-12"
                    />
                    <FormInput
                        type="text"
                        label="Cur BAL"
                        v-model="glEBal"
                        disabled
                        is-horizontal
                        id-prefix="glEBal"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        label-class="col-lg-3 col-md-4 label-sm"
                        input-container-class="col-lg-9 col-md-8 col-12"
                    />
                    <div class="row">
                        <div class="col-lg-3 col-md-4">
                            <label class="form-label label-sm">Debit/Credit</label>
                        </div>
                        <div class="col-lg-9 col-md-8 col-12">
                            <select class="form-select-sm form-select"
                                v-model="debitCredit"
                                @change="debitCreditAfterUpdate()">
                                <option value="DEBIT+CREDIT">DEBIT+CREDIT</option>
                                <option value="DEBIT only">DEBIT only</option>
                                <option value="CREDIT only">CREDIT only</option>
                            </select>
                        </div>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-primary btn-sm"
                            @click="getGLBalances()">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                        </button>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-6 pt-2">
                <Card class="h-100">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <div class="row mb-lg-0 mb-4">
                            <div class="col-md-6 mb-md-3 mb-2">
                                <button class="btn btn-primary btn-sm w-100 px-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printSummaryClearedModal">
                                    REPORT<br />(summary) -cleared
                                </button>
                            </div>
                            <div class="col-md-6 mb-md-3 mb-2">
                                <button class="btn btn-primary btn-sm w-100 px-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportPayeeModal">
                                    REPORT<br />(by PAYEE) -both
                                </button>
                            </div>
                            <div class="col-md-6 mb-md-3 mb-2">
                                <button class="btn btn-primary btn-sm w-100 px-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printSummaryAllModal">
                                    REPORT<br />(summary) -all
                                </button>
                            </div>
                            <div class="col-md-6 mb-md-3 mb-2">
                                <button class="btn btn-primary btn-sm w-100 px-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportJonoModal">
                                    REPORT<br />(by JONO) -both
                                </button>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 mb-lg-0 mb-3">
                                <div class="dropdownContainer w-100 mb-1 position-relative ms-0"
                                    ref="dropdownContainer">
                                    <button @click="isListVisible = !isListVisible"
                                        class="form-control-sm form-control w-100 d-flex justify-content-between">
                                        <span>{{ payeeTypeText }}</span>
                                        <span><i class="fa-solid fa-angle-down"></i></span>
                                    </button>
                                    <ul v-if="isListVisible"
                                        class="list-group w-75 rounded-0 is-list-visible">
                                        <li @click="searchPayee('I')"
                                            class="dropdown list-group-item list-group-item-action">
                                            INTERNAL
                                        </li>
                                        <li @click="searchPayee('E')"
                                            class="dropdown list-group-item list-group-item-action">
                                            EXTERNAL
                                        </li>
                                    </ul>
                                </div>
                                <p class="fw-bold mb-0 ms-2" v-if="payName">{{ payName }}</p>
                            </div>
                            <div class="col-lg-6">
                                <button class="btn btn-primary w-100"
                                    @click="reportByPayeeJono()">
                                    REPORT<br />(by PAYEE and JONO) -both
                                </button>
                                <button id="btnInternal"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportPayeeJonoInternalModal"
                                    hidden>
                                </button>
                                <button id="btnExternal"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printReportPayeeJonoExternalModal"
                                    hidden>
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-receipt me-2"></i>Voucher Details
                    </legend>
                    <div class="gl-table">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">VOUCHER_ID</th>
                                    <th scope="col">DESCRIPTION</th>
                                    <th scope="col">VOUCHER_DATE</th>
                                    <th scope="col">AMT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="balance in glBalances"
                                    :class="{
                                        'selected-row':
                                            balance.VOUCHER_ID ===
                                                selectedVoucherId &&
                                            balance.NAME ===
                                                selectedDescription &&
                                            balance.VOUCHER_DATE ===
                                                selectedDate &&
                                            balance.GL_AMT === selectedAmt,
                                    }"
                                    @click="
                                        selectVoucher(
                                            balance.VOUCHER_ID,
                                            balance.NAME,
                                            balance.VOUCHER_DATE,
                                            balance.GL_AMT
                                        )
                                    "
                                    @dblclick="
                                        navigateToRoute(
                                            'update-voucher',
                                            balance.VOUCHER_ID
                                        )
                                    ">
                                    <td>{{ balance.VOUCHER_ID }}</td>
                                    <td>({{ balance.NAME }}) {{ balance.DESCRIPTION }}</td>
                                    <td>{{ balance.VOUCHER_DATE }}</td>
                                    <td>{{ balance.GL_AMT }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
            </div>
        </div>
        <SelectAccount
            :isOpen="isSelectAccountOpen"
            :onClose="() => closeSelectAccount()"
            :onSelectAccount="handleSelectAccount"
        />
        <SelectEmployee
            :isOpen="isSelectEmployeeOpen"
            @close="() => closeSelectEmployee()"
            @select="(employee) => {
                handleSelectEmployee(employee);
                isSelectEmployeeOpen = false;
            }"
        />
        <PrintModal
            :pdf-link="printSummaryClearedPDFLink"
            :spreadsheet-link="printSummaryClearedSpreadsheetLink"
            title="Print Summary Cleared"
            id="printSummaryClearedModal"
        />
        <PrintGLBalancesModal
            :pdf-props="printSummaryAllPDFProps"
            :pdf-link="printSummaryAllPDFLink"
            :spreadsheet-link="printSummaryAllSpreadsheetLink"
            title="Print Summary All"
            id="printSummaryAllModal"
        />
        <PrintGLBalancesModal
            :pdf-props="printReportPayeePDFProps"
            :pdf-link="printReportPayeePDFLink"
            :spreadsheet-link="printReportPayeeSpreadsheetLink"
            title="Print Report by Payee"
            id="printReportPayeeModal"
        />
        <PrintGLBalancesModal
            :pdf-props="printReportJonoPDFProps"
            :pdf-link="printReportJonoPDFLink"
            :spreadsheet-link="printReportJonoSpreadsheetLink"
            title="Print Report by Jono"
            id="printReportJonoModal"
        />
        <PrintGLBalancesModal
            :pdf-props="printReportPayeeJonoInternalPDFProps"
            :pdf-link="printReportPayeeJonoInternalPDFLink"
            :spreadsheet-link="printReportPayeeJonoInternalSpreadsheetLink"
            title="Print Report by Payee and Jono (Internal)"
            id="printReportPayeeJonoInternalModal"
        />
        <PrintGLBalancesModal
            :pdf-props="printReportPayeeJonoExternalPDFProps"
            :pdf-link="printReportPayeeJonoExternalPDFLink"
            :spreadsheet-link="printReportPayeeJonoExternalSpreadsheetLink"
            title="Print Report by Payee and Jono (External)"
            id="printReportPayeeJonoExternalModal"
        />
    </div>
</template>

<script setup>
    import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
    import Card from '@/components/utils/Card.vue';
    import SelectAccount from '@/components/utils/SelectAccount.vue';
    import SelectEmployee from '@/components/utils/SelectEmployee.vue';
    import PrintModal from '@/components/utils/PrintModal.vue';
    import FormInput from '@/components/utils/FormInput.vue';
    import PrintGLBalancesModal from '@/components/vouchers/popups/PrintGLBalancesModal.vue';

    const className = ref('ASSET');
    const acctName = ref('COH');
    const glId = ref('9');
    const companyCode = ref('K');
    const startDate = ref('');
    const endDate = ref('');
    const glEBal = ref('');
    const debitCredit = ref('DEBIT+CREDIT');
    const debitCreditClause = ref('');
    const payeeType = ref('');
    const payeeTypeText = ref('');
    const isListVisible = ref(false);
    const payName = ref('');
    const payId = ref('');
    const classData = ref([]);
    const acctNameData = ref([]);
    const companies = ref([]);
    const glBalances = ref([]);
    const selectedVoucherId = ref('');
    const selectedDate = ref('');
    const selectedDescription = ref('');
    const selectedAmt = ref(0);
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref('');
    const isSelectAccountOpen = ref(false);
    const isSelectEmployeeOpen = ref(false);

    const errors = ref({});

    const closeList = (event) => {
        const dropdownContainer = document.querySelector('.dropdownContainer');
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
            isListVisible.value = false;
        }
    };

    onMounted(() => {
        document.addEventListener('click', closeList);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', closeList);
    });

    onMounted(() => {
        getClass();
        getAcctName();
        getCompany();
        setInitialDates();
        getGLBalances();
        getGLBalanceAmount();
    })

    function setInitialDates() {
        // Initial values for startDate and endDate
        const today = new Date();
        endDate.value = formatDate(today);
        startDate.value = formatDate(getDateDaysAgo(today, 10));
    }

    async function getCompany() {
        try {
            const response = await axios.get(route('api.companies.index'));
            companies.value = response.data.data;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getClass() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                class: true
            });
            classData.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getAcctName() {
        try {
            const response = await axios.post(route('api.gl.filter'), {
                search: className.value !== "" ? className.value : 'empty',
            });
            acctNameData.value = response.data[0];
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function getGLBalances() {
        isLoading.value = true;
        try {
            const response = await axios.post(route('api.voucher.balance'), {
                START_DATE: startDate.value,
                END_DATE: endDate.value,
                COMPANY_CODE: companyCode.value,
                GL_ID: glId.value,
            });
            glBalances.value = response.data;
            isLoading.value = false;
        } catch (e) {
            errors.value = e.response.data.errors;
            isLoading.value = false;
        }
    }

    async function getGLBalanceAmount()
    {
        try {
            const response = await axios.post(route('api.vouchergl.balance'), {
                COMPANY_CODE: companyCode.value,
                GL_ID: glId.value,
            });
            glEBal.value = parseFloat(response.data).toFixed(2);
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function acctNameAfterUpdate() {
        getGlId();
        getGLBalances();
        getGLBalanceAmount();
    }

    function getGlId() {
        const foundGLAccount = acctNameData.value.find(gl => gl.ACCTNAME === acctName.value);
        glId.value = foundGLAccount.GL_ID;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    function getDateDaysAgo(date, days) {
        const result = new Date(date);
        result.setDate(date.getDate() - days);
        return result;
    }

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }

    //functions for select External
    function openSelectAccount() {
        isSelectAccountOpen.value = true;
    }

    function closeSelectAccount() {
        isSelectAccountOpen.value = false;
    }

    function handleSelectAccount(selectedAccount) {
        payeeType.value = 'E';
        payeeTypeText.value = 'EXTERNAL';
        payId.value = selectedAccount.ACCOUNT_ID;
        payName.value = selectedAccount.ACCOUNT_NAME;
    }

    //functions for select Internal
    function openSelectEmployee() {
        isSelectEmployeeOpen.value = true;
    }

    function closeSelectEmployee() {
        isSelectEmployeeOpen.value = false;
    }

    function handleSelectEmployee(selectedEmployee) {
        payeeType.value = 'I';
        payeeTypeText.value = 'INTERNAL';
        payId.value = selectedEmployee.EMPID;
        payName.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
    };

    function searchPayee(type) {
        isListVisible.value = false;
        switch (type) {
            case 'E':
                openSelectAccount();
                break;
            case 'I':
                openSelectEmployee();
                break;
            case '':
                isToastError.value = true;
                toastMessage.value = 'Please select a payee type!'
            default:
                break;
        }
    }

    function debitCreditAfterUpdate() {
        switch (debitCredit.value) {
            case 'DEBIT+CREDIT':
                debitCreditClause.value = "";
                break;
            case 'DEBIT only':
                debitCreditClause.value = " GL_AMT > 0";
                break;
            case 'CREDIT only':
                debitCreditClause.value = " GL_AMT < 0";
                break;
            default:
                break;
        }
    }

    const printSummaryAllPDFLink = computed(() => {
        return 'print.finVoucherAll';
    });

    const printSummaryAllPDFProps = computed(() => {
        return {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value
        };
    });

    const printSummaryAllSpreadsheetLink = computed(() => {
        return route('api.vouchers.summary-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value
        });
    });

    const printSummaryClearedPDFLink = computed(() => {
        return route('print.finVoucherAll', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            CLEARED: true,
        });
    });

    const printSummaryClearedSpreadsheetLink = computed(() => {
        return route('api.vouchers.summary-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            CLEARED: true,
        });
    });

    const printReportPayeePDFLink = computed(() => {
        return 'print.finVoucherAll2';
    });

    const printReportPayeePDFProps = computed(() => {
        return {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value
        };
    });

    const printReportPayeeSpreadsheetLink = computed(() => {
        return route('api.vouchers.payee-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
        });
    });

    const printReportJonoPDFLink = computed(() => {
        return 'print.finVoucherAll3';
    });

    const printReportJonoPDFProps = computed(() => {
        return {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            JONO: true,
        };
    });

    const printReportJonoSpreadsheetLink = computed(() => {
        return route('api.vouchers.jono-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            JONO: true,
        });
    });

    function reportByPayeeJono() {
        if (payeeType.value === 'E') {
            if (payId.value === '') {
                isToastError.value = true;
                toastMessage.value = "Please select an external payee!";
            } else {
                const btnExternal = document.getElementById('btnExternal');
                if (btnExternal) {
                    btnExternal.click();
                }
            }
        } else {
            const btnInternal = document.getElementById('btnInternal');
            if (btnInternal) {
                btnInternal.click();
            }
        }
    }

    const printReportPayeeJonoExternalPDFLink = computed(() => {
        return 'print.finVoucherAll4';
    });

    const printReportPayeeJonoExternalPDFProps = computed(() => {
        return {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
        };
    });

    const printReportPayeeJonoExternalSpreadsheetLink = computed(() => {
        return route('api.vouchers.external-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
        });
    });

    const printReportPayeeJonoInternalPDFLink = computed(() => {
        return 'print.finVoucherAll3';
    });

    const printReportPayeeJonoInternalPDFProps = computed(() => {
        return {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
            JONO: true,
        };
    });

    const printReportPayeeJonoInternalSpreadsheetLink = computed(() => {
        return route('api.vouchers.jono-spreadsheet', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            COMPANY_CODE: companyCode.value,
            GL_ID: glId.value,
            DEBIT_CREDIT_CLAUSE: debitCreditClause.value,
            PAYEE_ID: payId.value,
            JONO: true,
        });
    });

    function selectVoucher(id, description, date, amt) {
        selectedVoucherId.value = id;
        selectedDescription.value = description;
        selectedDate.value = date;
        selectedAmt.value = amt;
    }

    function navigateToRoute(routeName, voucherId) {
        const fullUrl = `${routeName}?VOUCHER_ID=${voucherId}`;
        window.location.href = fullUrl;
    }
</script>

<style scoped>
.gl-table {
    width: 100%;
    height: 20rem;
    overflow-y: auto;
    background-color: #fff;
}

.selected-row,
tr.selected-row:hover td {
    background-color: #2a1770;
    color: #fff;
}

thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}

th {
    border-style: solid;
    border-color: #fff;
    border-width: 0 1px;
}

td {
    max-width: 30vw;
    border-width: 0 1px;
}

td:hover {
    cursor: pointer;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}
</style>
