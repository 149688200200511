<template>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-island me-2"></i>Leave History
        </legend>
        <DataTable
            lazy
            :value="leaveHistories"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchEmployeeLeaveHistories();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="TYPE"
                header="Type"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="START_DATE"
                header="Start Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="END_DATE"
                header="End Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #footer> {{ totalRecords ?? 0 }} total records </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-island me-2"></i>No leaves yet.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
                <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="
                        (_) => {
                            isCreateLeaveHistoryPopupOpen = true;
                        }
                    "
                >
                    <i class="ph ph-plus me-1"></i>Create Leave
                </button>
            </div>
    </fieldset>
    <CreateLeaveHistoryPopup
        :is-open="isCreateLeaveHistoryPopupOpen"
        :employee-id="employeeId"
        @close="
            (_) => {
                isCreateLeaveHistoryPopupOpen = false;
            }
        "
        @done="
            (_) => {
                isCreateLeaveHistoryPopupOpen = false;
                fetchEmployeeLeaveHistories();
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLeaveHistories } from "@/composables/data/leaveHistories";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import CreateLeaveHistoryPopup from "./CreateLeaveHistoryPopup.vue";

const props = defineProps({
    employeeId: Number,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "TYPE",
    "START_DATE",
    "END_DATE",
]);

const { leaveHistories, totalRecords, getEmployeeLeaveHistories } = useLeaveHistories();

const fetchTimeout = ref(null);

const isCreateLeaveHistoryPopupOpen = ref(false);

async function fetchEmployeeLeaveHistories() {
    leaveHistories.value = null;

    if (!props.employeeId) {
        return;
    }

    loadingFlags.add("fetchEmployeeLeaveHistories");
    try {
        await getEmployeeLeaveHistories(props.employeeId, {
            ...params.value,
            multiSortMeta: [
                {
                    field: "start_date",
                    order: -1,
                },
            ],
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEmployeeLeaveHistories");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployeeLeaveHistories, 300);
}

watch(() => props.employeeId, fetchEmployeeLeaveHistories);
</script>
