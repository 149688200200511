<template>
    <div class="row mb-3">
        <div class="col-lg-4 mb-lg-0 mb-3">
            <div class="row">
                <div class="col-lg-12 col-md-6 col-12 mb-3">
                    <fieldset class="h-100">
                        <legend>
                            <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                        </legend>
                        <TimePeriodTable
                            v-model="selectedTimePeriod"
                            show-from-current-date
                            paytype="MONTHLY"
                            :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                            :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                        />
                    </fieldset>
                </div>
                <div class="col-lg-12 col-md-6 col-12 mb-3">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-map-pin me-2"></i>Select Location
                        </legend>
                        <LocationTable v-model="selectedLocation" />
                    </fieldset>
                </div>
                <div class="col-12">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-file-csv me-2"></i>Upload CSV
                        </legend>
                        <div>
                            <label for="dailySalesFileInput">Daily Sales File (csv)</label>
                            <input type="file"
                                accept=".csv"
                                ref="dailySalesFileInput"
                                class="form-control"
                                @change="(e) => { dailySalesFile = e.target.files[0]; }"
                            />
                        </div>
                        <div class="text-end mt-3">
                            <button type="button"
                                class="btn btn-primary"
                                :disabled="!selectedTimePeriod"
                                @click="uploadDailySalesFile">
                                <i class="icon ph-bold ph-file-csv me-2"></i>Upload Sales
                            </button>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-chart-bar me-2"></i>Update Daily Sales
                </legend>
                <form @submit.prevent="updateDailySales">
                    <div class="row g-3"
                        v-if="selectedTimePeriod && selectedLocation">
                        <div class="col-4 px-lg-3">
                            <label for="" class="fw-bold col-form-label">Sold Date</label>
                        </div>
                        <div class="col-4 px-lg-3">
                            <label for="" class="fw-bold col-form-label">Quantity</label>
                        </div>
                        <div class="col-4 px-lg-3">
                            <label for="" class="fw-bold col-form-label">Amount</label>
                        </div>
                    </div>
                    <template v-for="dailySale in dailySales">
                        <div class="row g-3 mb-1">
                            <div class="col-4 px-lg-3">
                                <FormInput
                                    type="date"
                                    disabled
                                    v-model="dailySale.DATE"
                                    label="Date"
                                    :id-prefix="`date-${dailySale.DATE}`"
                                    is-horizontal
                                    input-class="form-control-sm"
                                    input-container-class="col-12"
                                    label-class="d-none"
                                />
                            </div>
                            <div class="col-4 px-lg-3">
                                <FormInput
                                    type="number"
                                    step=".01"
                                    v-model="dailySale.GROSS_QUANTITY"
                                    label="Quantity"
                                    :id-prefix="`qty-${dailySale.DATE}`"
                                    is-horizontal
                                    input-class="form-control-sm"
                                    input-container-class="col-12"
                                    label-class="d-none"
                                />
                            </div>
                            <div class="col-4 px-lg-3">
                                <FormInput
                                    type="number"
                                    step=".01"
                                    v-model="dailySale.GROSS_AMOUNT"
                                    label="Amount"
                                    :id-prefix="`amount-${dailySale.DATE}`"
                                    is-horizontal
                                    input-class="form-control-sm"
                                    input-container-class="col-12"
                                    label-class="d-none"
                                />
                            </div>
                        </div>
                    </template>
                    <hr class="my-4"
                        v-if="selectedTimePeriod && selectedLocation"
                    />
                    <div class="row g-3 px-3">
                        <div class="col-xl-5 col-md-4">
                            <FormInput
                                type="number"
                                step=".01"
                                disabled
                                v-model="totalGrossAmount"
                                label="Total Amt."
                                id-prefix="totalGrossAmount"
                                is-horizontal
                                input-container-class="col-xl-8 col-lg-7 col-12"
                                label-class="col-xl-4 col-lg-5"
                            />
                        </div>
                        <div class="col-xl-5 col-md-4 mb-md-0 mb-3">
                            <FormInput
                                type="number"
                                disabled
                                v-model="totalGrossQuantity"
                                label="Total Qty."
                                id-prefix="totalGrossQuantity"
                                is-horizontal
                                input-container-class="col-xl-8 col-lg-7 col-12"
                                label-class="col-xl-4 col-lg-5"
                            />
                        </div>
                        <div class="col-xl-2 col-md-4 text-end mt-auto">
                            <button type="submit"
                                class="btn btn-primary"
                                :disabled="!selectedTimePeriod || !selectedLocation">
                                <i class="icon ph-bold ph-note-pencil me-2"></i>Update
                            </button>
                        </div>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useSalesActualDetails } from "@/composables/data/salesActualDetails";
import FormInput from "@/components/utils/FormInput";
import LocationTable from "@/components/utils/tables/LocationTable";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable";
import moment from "moment";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const selectedTimePeriod = ref(null);
const selectedLocation = ref(null);

const dailySales = ref(null);

const isLoading = ref(false);

const {
    salesActualDetails,
    getSalesActualDetailsInMonthAndLocation,
    putSalesActualDetailsInMonthAndLocation,
    postSalesActualDetailFile,
} = useSalesActualDetails();

const totalGrossAmount = computed(() =>
    parseFloat(
        salesActualDetails.value
            ?.reduce(
                (total, salesActualDetail) =>
                    total + salesActualDetail.GROSS_AMOUNT,
                0
            )
            .toFixed(2) ?? 0
    )
);
const totalGrossQuantity = computed(() =>
    parseFloat(
        salesActualDetails.value
            ?.reduce(
                (total, salesActualDetail) =>
                    total + salesActualDetail.GROSS_QUANTITY,
                0
            )
            .toFixed(2) ?? 0
    )
);

const dailySalesFileInput = ref(null);
const dailySalesFile = ref(null);

async function fetchDailySales() {
    dailySales.value = null;

    if (!selectedTimePeriod.value || !selectedLocation.value) {
        return;
    }

    isLoading.value = true;

    const timePeriodStartDate = moment(
        selectedTimePeriod.value.START_DATE,
        "YYYY-MM-DD"
    );
    const timePeriodEndDate = moment(
        selectedTimePeriod.value.END_DATE,
        "YYYY-MM-DD"
    );

    try {
        await getSalesActualDetailsInMonthAndLocation(
            timePeriodStartDate.format("YYYY-MM"),
            selectedLocation.value.LOCATION
        );
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
        isLoading.value = false;
        return;
    }

    let currentDate = timePeriodStartDate.clone();

    const newDailySales = [];
    while (currentDate.isSameOrBefore(timePeriodEndDate)) {
        // Fetch current details if exists
        const currentDetail = salesActualDetails.value.filter(
            (salesActualDetail) =>
                moment(salesActualDetail.SOLD_DT, "YYYY-MM-DD").isSame(
                    currentDate
                )
        )[0];

        newDailySales.push({
            DATE: currentDate.format("YYYY-MM-DD"),
            GROSS_AMOUNT: currentDetail?.GROSS_AMOUNT ?? null,
            GROSS_QUANTITY: currentDetail?.GROSS_QUANTITY ?? null,
        });
        currentDate = currentDate.add(1, "days");
    }
    dailySales.value = newDailySales;

    isLoading.value = false;
}

watch([selectedTimePeriod, selectedLocation], fetchDailySales);

async function updateDailySales() {
    try {
        await putSalesActualDetailsInMonthAndLocation(
            moment(selectedTimePeriod.value.START_DATE, "YYYY-MM-DD").format(
                "YYYY-MM"
            ),
            selectedLocation.value.LOCATION,
            dailySales.value
        );
        addFlashMessage("SUCCESS", "Successfully updated daily sales.");
        fetchDailySales();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function uploadDailySalesFile() {
    try {
        const formData = new FormData();
        formData.append("sales_file", dailySalesFile.value);
        await postSalesActualDetailFile(
            moment(selectedTimePeriod.value.START_DATE, "YYYY-MM-DD").format(
                "YYYY-MM"
            ),
            selectedLocation.value.LOCATION,
            formData
        );
        addFlashMessage(
            "SUCCESS",
            "Successfully updated daily sales from file."
        );
        fetchDailySales();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}
</script>
