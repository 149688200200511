<style scoped>
img {
    max-height: 200px;
}
</style>

<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-image me-2"></i>Style Image</legend>
        <div v-if="style != null" class="text-center">
            <span v-if="isImageLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true">
            </span>
            <img :src="imageURL"
                :alt="'Style ' + (style?.STYLE ?? '') + ' image'"
                class="img-fluid"
                @load=" (_) => { isImageLoading = false; } "
                @error="setDefaultImage"
            />
        </div>
        <div v-else class="text-center no-style-message">
            Please select a style.
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useToastsStore } from '@/stores/toasts';
import { useStyles } from '@/composables/data/styles';

import Card from './Card.vue';

const toasts = useToastsStore();

const props = defineProps({
    style: String,
    class: String,
});

const { style, getStyle } = useStyles();

const imageURL = ref(null);
const isImageLoading = ref(false);

watch(() => props.style, async () => {
    if (!props.style) {
        setDefaultImage();
        return;
    }

    try {
        isImageLoading.value = true;
        await getStyle(props.style);

        if (!style.value.image_url) {
            setDefaultImage();
        } else {
            imageURL.value = style.value.image_url;
        }
    } catch (e) {
        console.log(e);
        setDefaultImage();
        if (!e.response || e.response.status != 404) {
            toasts.add('ERROR', 'Error', e.message);
        }
    }
});

function setDefaultImage() {
    isImageLoading.value = true;
    imageURL.value = `${Ziggy.url}/img/bagnoimage.jpg`;
}
</script>
