<template>
    <form @submit.prevent="insertEmployee">
        <div class="row g-3">
            <div class="col-xl-8">
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-user-list me-2"></i>Personal Information
                    </legend>
                    <div class="row gx-4 mb-2">
                        <div class="col-lg-4">
                            <FormInput
                                type="text"
                                label="ID"
                                v-model="employee.EMPID"
                                disabled
                                id-prefix="empid"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                            />
                            <FormSelect
                                label="Paytype"
                                v-model="employee.PAYTYPE"
                                :options="paytypes"
                                :option-to-string="(paytype) => paytype.PAYTYPE"
                                :option-value="(paytype) => paytype.PAYTYPE"
                                id-prefix="paytype"
                                select-class="form-select-sm"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 label-sm"
                                select-container-class="col-xl-8 col-12"
                                :errors="errors.PAYTYPE"
                                @keydown-enter="focusNextInput('atmCodeFormInput')"
                            />
                            <FormSelect
                                label="ATM Code"
                                v-model="employee.ATM_CODE"
                                :options="atmCodes"
                                :option-to-string="(atmCode) => atmCode.verbose"
                                :option-value="(atmCode) => atmCode.id"
                                id-prefix="atmCode"
                                select-class="form-select-sm"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 label-sm"
                                select-container-class="col-xl-8 col-12"
                                :errors="errors.ATM_CODE"
                                @keydown-enter="focusNextInput('fnameFormInput')"
                            />
                        </div>
                        <div class="col-lg-4">
                            <FormInput
                                type="text"
                                label="First Name"
                                v-model="employee.FNAME"
                                id-prefix="fname"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                                :errors="errors.FNAME"
                                @keydown-enter="focusNextInput('mnameFormInput')"
                            />
                            <FormInput
                                type="text"
                                label="Middle Name"
                                v-model="employee.MNAME"
                                id-prefix="mname"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                                :errors="errors.MNAME"
                                @keydown-enter="focusNextInput('lnameFormInput')"
                            />
                            <FormInput
                                type="text"
                                label="Last Name"
                                v-model="employee.LNAME"
                                id-prefix="lname"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                                :errors="errors.LNAME"
                                @keydown-enter="focusNextInput('birthdateFormInput')"
                            />
                        </div>
                        <div class="col-lg-4">
                            <FormInput
                                type="date"
                                label="Birthdate"
                                v-model="employee.BIRTHDATE"
                                id-prefix="birthdate"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                                :errors="errors.BIRTHDATE"
                                @keydown-enter="focusNextInput('phoneFormInput')"
                            />
                            <FormInput
                                type="text"
                                label="Phone"
                                v-model="employee.PHONE"
                                id-prefix="phone"
                                input-class="form-control-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                input-container-class="col-xl-8 col-12"
                                :errors="errors.PHONE"
                                @keydown-enter="focusNextInput('genderFormInput')"
                            />
                            <FormSelect
                                label="Gender"
                                v-model="employee.GENDER"
                                :options="GENDER_OPTIONS"
                                :option-to-string="(option) => option.label"
                                :option-value="(option) => option.value"
                                id-prefix="gender"
                                select-class="form-select-sm"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 label-sm"
                                select-container-class="col-xl-8 col-12"
                                :errors="errors.GENDER"
                                @keydown-enter="focusNextInput('permanentAddressFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row gx-4 mb-2">
                        <div class="col-lg-6">
                            <FormInput
                                type="text"
                                label="Present Address"
                                v-model="employee.PRESENT_ADDRESS"
                                id-prefix="presentAddress"
                                input-class="form-control-sm"
                                class="mb-md-1 mb-2"
                                :errors="errors.PRESENT_ADDRESS"
                                @keydown-enter="focusNextInput('permanentAddressFormInput')"
                            />
                        </div>
                        <div class="col-lg-6">
                            <FormInput
                                type="text"
                                label="Permanent Address"
                                v-model="employee.PERMANENT_ADDRESS"
                                id-prefix="permanentAddress"
                                input-class="form-control-sm"
                                class="mb-md-1 mb-2"
                                :errors="errors.PERMANENT_ADDRESS"
                                @keydown-enter="focusNextInput('civilStatusFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-md-4">
                            <FormSelect
                                label="Civil Status"
                                v-model="employee.CIVIL_STATUS"
                                :options="civilStatuses"
                                :option-to-string="(civilStatus) => civilStatus.verbose"
                                :option-value="(civilStatus) => civilStatus.id"
                                id-prefix="civilStatus"
                                select-class="form-select-sm"
                                class="mb-md-1 mb-2"
                                :errors="errors.CIVIL_STATUS"
                                @keydown-enter="focusNextInput('spouseNameFormInput')"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormInput
                                type="text"
                                label="Spouse Name"
                                v-model="employee.SPOUSE_NAME"
                                id-prefix="spouseName"
                                input-class="form-control-sm"
                                class="mb-md-1 mb-2"
                                :errors="errors.SPOUSE_NAME"
                                @keydown-enter="focusNextInput('motherMaidenNameFormInput')"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormInput
                                type="text"
                                label="Mother's Full Maiden Name"
                                v-model="employee.MOTHER_MAIDEN_NAME"
                                id-prefix="motherMaidenName"
                                input-class="form-control-sm"
                                class="mb-md-1 mb-2"
                                :errors="errors.MOTHER_MAIDEN_NAME"
                                @keydown-enter="focusNextInput('companyFormInput')"
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-info me-2"></i>Company Information
                    </legend>
                    <div class="row gx-5 mb-md-3 mb-0">
                        <div class="col-md-4">
                            <FormSelect
                                label="Company"
                                v-model="employee.COMPANY_CODE"
                                :options="companies"
                                :option-to-string="(company) => company.COMPANY_DESCRIPTION"
                                :option-value="(company) => company.COMPANY_CODE"
                                id-prefix="company"
                                select-class="form-select-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                select-container-class="col-xl-8 col-12"
                                :errors="errors.COMPANY_CODE"
                                @keydown-enter="focusNextInput('titleFormInput')"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                label="Title"
                                v-model="employee.TITLE"
                                :options="titles"
                                :option-to-string="(title) => title.TITLE"
                                :option-value="(title) => title.TITLE"
                                id-prefix="title"
                                select-class="form-select-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-2 label-sm"
                                select-container-class="col-xl-10 col-12"
                                :errors="errors.TITLE"
                                @keydown-enter="focusNextInput('flexFlagFormInput')"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                label="Flex Flag"
                                v-model="employee.FLEX_FLAG"
                                :options="['Y', 'N']"
                                id-prefix="flexFlag"
                                select-class="form-select-sm"
                                is-horizontal
                                class="mb-md-1 mb-2"
                                label-class="col-xl-4 label-sm"
                                select-container-class="col-xl-8 col-12"
                                :errors="errors.FLEX_FLAG"
                                @keydown-enter="focusNextInput('departmentFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row gx-3 mb-md-3 mb-0">
                        <div class="col-md-3">
                            <FormSelect
                                label="Department"
                                v-model="employee.DEPT"
                                :options="departments"
                                :option-to-string="(department) => department.DEPT"
                                :option-value="(department) => department.DEPT"
                                id-prefix="department"
                                class="mb-md-1 mb-2"
                                select-class="form-select-sm"
                                :errors="errors.DEPT"
                                @keydown-enter="focusNextInput('statusFormInput')"
                            />
                        </div>
                        <div class="col-md-2">
                            <FormSelect
                                label="Status"
                                v-model="employee.STATUS"
                                :options="STATUS_OPTIONS"
                                :option-value="(option) => option.value"
                                :option-to-string="(option) => option.label"
                                id-prefix="status"
                                class="mb-md-1 mb-2"
                                select-class="form-select-sm"
                                :errors="errors.STATUS"
                                @keydown-enter="focusNextInput('hiredateFormInput')"
                            />
                        </div>
                        <div class="col-md-2">
                            <FormInput
                                type="date"
                                label="Hire Date"
                                v-model="employee.HIREDATE"
                                id-prefix="hiredate"
                                class="mb-md-1 mb-2"
                                input-class="form-control-sm"
                                :errors="errors.HIREDATE"
                                @keydown-enter="focusNextInput('lastDateFormInput')"
                            />
                        </div>
                        <div class="col-md-2">
                            <FormInput
                                type="date"
                                label="Last Date"
                                v-model="employee.LASTDATE"
                                id-prefix="lastdate"
                                class="mb-md-1 mb-2"
                                input-class="form-control-sm"
                                :errors="errors.LASTDATE"
                                @keydown-enter="focusNextInput('paymentFlagFormInput')"
                            />
                        </div>
                        <div class="col-md-3">
                            <FormSelect
                                label="Payment Flag"
                                v-model="employee.PAYMENT_FLAG"
                                :options="paymentFlags"
                                :option-to-string="(paymentFlag) => paymentFlag.verbose"
                                :option-value="(paymentFlag) => paymentFlag.id"
                                id-prefix="paymentFlag"
                                class="mb-md-1 mb-2"
                                select-class="form-select-sm"
                                :errors="errors.PAYMENT_FLAG"
                                @keydown-enter="focusNextInput('memoFormInput')"
                            />
                        </div>
                    </div>
                    <FormTextarea
                        type="date"
                        label="Memo"
                        v-model="employee.MEMO"
                        id-prefix="memo"
                        :rows="3"
                        :errors="errors.MEMO"
                        @keydown-enter="focusNextInput('sssFormInput')"
                    />
                </fieldset>
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-users-three me-2"></i>Employee Dependents
                    </legend>
                    <div class="employee-dependents">
                        <table class="table table-sm table-hover table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Birthdate</th>
                                    <th scope="col">Gender</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!dependents || dependents.length === 0">
                                    <td colspan="5">
                                        <div class="text-center py-2">
                                            <i class="icon ph-bold ph-database me-2"></i>No dependent.
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else v-for="dependent in dependents">
                                    <td>{{ dependent.NAME }}</td>
                                    <td>{{ dependent.STATUS }}</td>
                                    <td>{{ dependent.BIRTHDATE }}</td>
                                    <td>{{ dependent.GENDER }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-warning-octagon me-2"></i>Employee Violations
                    </legend>
                    <div class="employee-violations">
                        <table class="table table-sm table-hover table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Days</th>
                                    <th scope="col">Memo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!violations || violations.length === 0">
                                    <td colspan="5">
                                        <div class="text-center py-2">
                                            <i class="icon ph-bold ph-database me-2"></i>No violation.
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else v-for="violation in violations">
                                    <td>{{ violation.id }}</td>
                                    <td>{{ violation.VIOLATION_DATE }}</td>
                                    <td>{{ violation.VIOLATION_TYPE }}</td>
                                    <td>{{ violation.VIOLATION_DAYS }}</td>
                                    <td>{{ violation.VIOLATION_MEMO }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
                <LeaveHistoryTableCard :employee-id="employee?.EMPID" />
            </div>
            <div class="col-xl-4">
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-dots-three-circel me-2"></i>Actions
                    </legend>
                    <div class="d-grid gap-1">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#modalSearch"
                            @click="(_) => { isSelectEmployeePopupOpen = true; }"
                        >
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="clearFormEmployee()"
                        >
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset Fields
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm"
                            id="insertButton"
                            :disabled="employee.EMPID != null"
                        >
                            <i class="icon ph-bold ph-plus me-2"></i>Insert New Employee
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm flex-grow-1"
                            @click="updateEmployee()"
                            :disabled="employee.EMPID == null"
                        >
                            <i class="icon ph-bold ph-pencil-simple-line me-2"></i>Update Employee
                        </button>
                    </div>
                </fieldset>
                <Card class="mb-3">
                    <FormInput
                        type="text"
                        label="SSS"
                        v-model="employee.SSS"
                        id-prefix="sss"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.SSS"
                        @keydown-enter="focusNextInput('philhealthFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="Philhealth"
                        v-model="employee.PHILHEALTH"
                        id-prefix="philhealth"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.PHILHEALTH"
                        @keydown-enter="focusNextInput('pagibigFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="PAGIBIG"
                        v-model="employee.PAGIBIG"
                        id-prefix="pagibig"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.PAGIBIG"
                        @keydown-enter="focusNextInput('tinFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="TIN"
                        v-model="employee.TIN"
                        id-prefix="tin"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.TIN"
                        @keydown-enter="focusNextInput('insuranceFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="Insurance"
                        v-model="employee.INSURANCE"
                        id-prefix="insurance"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.INSURANCE"
                        @keydown-enter="focusNextInput('bdoFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="BDO"
                        v-model="employee.BDO_ACCOUNT"
                        id-prefix="bdo"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.BDO_ACCOUNT"
                        @keydown-enter="focusNextInput('bpiFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="BPI"
                        v-model="employee.BPI_ACCOUNT"
                        id-prefix="bpi"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.BPI_ACCOUNT"
                        @keydown-enter="focusNextInput('gcashFormInput')"
                    />
                    <FormInput
                        type="text"
                        label="GCASH"
                        v-model="employee.GCASH_ACCOUNT"
                        id-prefix="gcash"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.GCASH_ACCOUNT"
                        @keydown-enter="focusNextInput('nidekaEmpidFormInput')"
                    />
                </Card>
                <Card class="mb-3">
                    <FormInput
                        type="text"
                        label="NIDEKA"
                        v-model="employee.NIDEKA_EMPID"
                        id-prefix="nidekaEmpid"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.NIDEKA_EMPID"
                        @keydown-enter="focusNextInput('insertButton')"
                    />
                </Card>
            </div>
        </div>
    </form>
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (selectedEmployee) => {
                isSelectEmployeePopupOpen = false;
                fetchEmployeeDetails(selectedEmployee);
            }
        "
    />
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useEmployees } from "@/composables/data/employees";

import axios from "axios";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormTextarea from "@/components/utils/FormTextarea.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import focusNextInput from "@/utils/focusnext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import LeaveHistoryTableCard from "./LeaveHistoryTableCard.vue";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const STATUS_OPTIONS = [
    {
        value: "E",
        label: "Extra",
    },
    {
        value: "F",
        label: "Regular",
    },
    {
        value: "P",
        label: "Probationary",
    },
    {
        value: "R",
        label: "Resigned",
    },
    {
        value: "L",
        label: "On Leave",
    },
    {
        value: "A",
        label: "Absent w/o Leave",
    },
];

const GENDER_OPTIONS = [
    {
        value: "M",
        label: "Male",
    },
    {
        value: "F",
        label: "Female",
    },
];

const atmCodes = ref();
const civilStatuses = ref();
const paymentFlags = ref();

const isSelectEmployeePopupOpen = ref(false);

const departments = ref();
const paytypes = ref();
const titles = ref();
const companies = ref();

const errors = ref({});

const employee = ref({
    EMPID: null,
    PAYTYPE: null,
    FNAME: null,
    MNAME: null,
    LNAME: null,
    GENDER: null,
    DEPT: null,
    BRANCH: "",
    POSITION: "",
    TITLE: null,
    EMP_STATUS: null,
    EMERG_CONTACT: null,
    FLEX_FLAG: null,
    PAYMENT_FLAG: null,
    LOGIN_ID: null,
    LOGIN_INVENTORY: null,
    LOGIN_FINANCE: null,
    LOGIN_ADMIN: null,
    HIREDATE: null,
    LASTDATE: null,
    BIRTHDATE: null,
    PHONE: null,
    SSS: null,
    PHILHEALTH: null,
    PAGIBIG: null,
    TIN: null,
    INSURANCE: null,
    MEMO: null,
    STATUS: null,
    BPI_ACCOUNT: null,
    BDO_ACCOUNT: null,
    GCASH_ACCOUNT: null,
    COMPANY_CODE: "K",
    ATM_CODE: null,
    PRESENT_ADDRESS: null,
    PERMANENT_ADDRESS: null,
    MOTHER_MAIDEN_NAME: null,
    CIVIL_STATUS: null,
    SPOUSE_NAME: null,
    NIDEKA_EMPID: null,
});

const { employee: fetchedEmployee, getEmployee } = useEmployees();

const violations = ref();
const dependents = ref();

async function fetchAtmCodes() {
    loadingFlags.add("fetchAtmCodes");
    try {
        const atmCodesResponse = await axios.get(route("api.atm-codes.index"));
        atmCodes.value = atmCodesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching ATM codes.");
        scrollToTop();
    }
    loadingFlags.delete("fetchAtmCodes");
}

async function fetchCivilStatuses() {
    loadingFlags.add("fetchCivilStatuses");
    try {
        const civilStatusesResponse = await axios.get(
            route("api.civil-statuses.index")
        );
        civilStatuses.value = civilStatusesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching civil statuses.");
        scrollToTop();
    }
    loadingFlags.delete("fetchCivilStatuses");
}

async function fetchPaymentFlags() {
    loadingFlags.add("fetchPaymentFlags");
    try {
        const paymentFlagsResponse = await axios.get(
            route("api.payment-flags.index")
        );
        paymentFlags.value = paymentFlagsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching payment flags.");
        scrollToTop();
    }
    loadingFlags.delete("fetchPaymentFlags");
}

async function fetchDepartments() {
    loadingFlags.add("fetchDepartments");
    try {
        const result = await axios.get(
            route("api.departments.index", {
                rows: 0,
            })
        );
        departments.value = result.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching departments.");
        scrollToTop();
    }
    loadingFlags.delete("fetchDepartments");
}

async function fetchTitles() {
    loadingFlags.add("fetchTitles");
    try {
        const result = await axios.get(route("api.titles.index"), {});
        titles.value = result.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching titles.");
        scrollToTop();
    }
    loadingFlags.delete("fetchTitles");
}

async function fetchPaytypes() {
    loadingFlags.add("fetchPaytypes");
    try {
        const result = await axios.get(route("api.paytypes.index"));
        paytypes.value = result.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching paytypes.");
        scrollToTop();
    }
    loadingFlags.delete("fetchPaytypes");
}

async function fetchCompanies() {
    loadingFlags.add("fetchCompanies");
    try {
        const result = await axios.get(route("api.companies.index"));
        companies.value = result.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching companies.");
        scrollToTop();
    }
    loadingFlags.delete("fetchCompanies");
}

onMounted(async () => {
    fetchAtmCodes();
    fetchCivilStatuses();
    fetchPaymentFlags();
    fetchDepartments();
    fetchTitles();
    fetchPaytypes();
    fetchCompanies();
});

async function fetchDependents() {
    loadingFlags.add("fetchDependents");
    try {
        const dependentsResponse = await axios.get(
            route("api.employees.dependents.index", {
                employee: employee.value.EMPID,
            })
        );
        dependents.value = dependentsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching dependents.");
        scrollToTop();
    }
    loadingFlags.delete("fetchDependents");
}

async function fetchViolations() {
    loadingFlags.add("fetchViolations");
    try {
        const violationsResponse = await axios.get(
            route("api.employees.violations.index", {
                employee: employee.value.EMPID,
            })
        );
        violations.value = violationsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching violations.");
        scrollToTop();
    }
    loadingFlags.delete("fetchViolations");
}

async function fetchEmployeeDetails(selectedEmployee) {
    loadingFlags.add("fetchEmployeeDetails");
    try {
        await getEmployee(selectedEmployee.EMPID);
        employee.value = Object.assign({}, fetchedEmployee.value);
        fetchDependents();
        fetchViolations();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEmployeeDetails");
}

function clearFormEmployee() {
    employee.value = {
        EMPID: null,
        PAYTYPE: null,
        FNAME: null,
        MNAME: null,
        LNAME: null,
        GENDER: null,
        DEPT: null,
        BRANCH: "",
        POSITION: "",
        TITLE: null,
        EMP_STATUS: null,
        EMERG_CONTACT: null,
        FLEX_FLAG: null,
        PAYMENT_FLAG: null,
        LOGIN_ID: null,
        LOGIN_INVENTORY: null,
        LOGIN_FINANCE: null,
        LOGIN_ADMIN: null,
        HIREDATE: null,
        LASTDATE: null,
        BIRTHDATE: null,
        PHONE: null,
        SSS: null,
        PHILHEALTH: null,
        PAGIBIG: null,
        TIN: null,
        INSURANCE: null,
        MEMO: null,
        STATUS: null,
        BPI_ACCOUNT: null,
        BDO_ACCOUNT: null,
        GCASH_ACCOUNT: null,
        COMPANY_CODE: "K",
        ATM_CODE: null,
        PRESENT_ADDRESS: null,
        PERMANENT_ADDRESS: null,
        MOTHER_MAIDEN_NAME: null,
        CIVIL_STATUS: null,
        SPOUSE_NAME: null,
    };

    dependents.value = null;
    violations.value = null;
    errors.value = {};
}

const insertEmployee = async () => {
    errors.value = {};

    loadingFlags.add("insertEmployee");
    try {
        const insertEmployeeResponse = await axios.post(
            route("api.employees.store"),
            employee.value
        );
        employee.value = insertEmployeeResponse.data.data;
        toasts.add("SUCCESS", "Success", "Successfully created employee.");
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, "Error while saving employee.");
    }
    loadingFlags.delete("insertEmployee");
};

const updateEmployee = async () => {
    errors.value = {};

    loadingFlags.add("updateEmployee");
    try {
        const updateEmployeeResponse = await axios.put(
            route("api.employees.update", { employee: employee.value.EMPID }),
            employee.value
        );
        employee.value = updateEmployeeResponse.data.data;
        toasts.add("SUCCESS", "Success", "Successfully updated employee.");
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, "Error while updating employee.");
    }
    loadingFlags.delete("updateEmployee");
};
</script>

<style scoped>
.employee-dependents {
    overflow: auto;
    max-height: 109px;
    background: #fff;
}

.employee-violations {
    overflow: auto;
    max-height: 130px;
    background: #fff;
}

.employee-dependents thead,
.employee-violations thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}
</style>
