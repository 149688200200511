import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleCategories() {
    const styleCategories = ref(null);

    const getStyleCategories = async () => {
        try {
            const getStyleCategoriesResponse = await axios.get(route("api.styles.categories.index"));
            styleCategories.value = getStyleCategoriesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching style categories.');
        }
    };
    
    return {
        styleCategories,
        getStyleCategories,
    };
}
