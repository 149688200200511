<template>
    <div class="row mb-3">
        <div class="col-xl-8">
            <div
                class="alert alert-success mb-2"
                role="alert"
                v-show="displayDeleteLocationSuccessAlert"
            >
                Successfully Deleted Location!
            </div>
            <div
                class="alert alert-success mb-2"
                role="alert"
                v-show="displayUpdateLocationSuccessAlert"
            >
                Successfully Updated Location!
            </div>
            <div
                class="alert alert-success mb-2"
                role="alert"
                v-show="displayInsertLocationSuccessAlert"
            >
                Successfully Inserted Location!
            </div>
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-user me-2"></i> Select Employee
                </legend>
                <div class="row g-3">
                    <div class="col-md-3 d-flex flex-column">
                        <label class="text-nowrap me-2">Employee ID</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="formEmployeeEMPID"
                            disabled
                        />
                    </div>
                    <div class="col-md-3 d-flex flex-column">
                        <label class="text-nowrap me-2">First Name</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="formEmployee.FNAME"
                            disabled
                        />
                    </div>
                    <div class="col-md-3 d-flex flex-column">
                        <label class="text-nowrap me-2">Last Name</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="formEmployee.LNAME"
                            disabled
                        />
                    </div>
                    <div class="col-md-3 d-flex flex-column">
                        <label class="text-nowrap me-2">DEPARMENT:</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="formEmployee.DEPT"
                            disabled
                        />
                    </div>
                    <div class="col-12 text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                                (_) => {
                                    isSelectEmployeePopupOpen = true;
                                }
                            "
                        >
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-user me-2"></i>Location History
                </legend>
                <div class="employee-location-table-wrapper">
                    <table class="table table-sm table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th>LOCATION</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="employeesLocations?.length == 0">
                                <td colspan="3" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-map-pin me-2"></i>No location history.
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="employeeAssigned in employeesLocations"
                                @click="populateFormLocation(employeeAssigned)"
                            >
                                <td>{{ employeeAssigned.LOCATION }}</td>
                                <td>{{ employeeAssigned.START_DATE }}</td>
                                <td>{{ employeeAssigned.END_DATE }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-4">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select Location
                </legend>
                <form
                    @submit.prevent="addLocations"
                    id="employee-locations-form"
                >
                    <input type="hidden" v-model="formLocation.id" />
                    <input type="hidden" v-model="formLocation.EMPID" />
                    <div class="row mb-md-1 mb-2">
                        <label
                            for="location"
                            class="col-xl-4 col-md-5 label-sm col-form-label"
                        >
                            LOCATION
                        </label>
                        <div class="col col-xl-8 col-md-7 col-12">
                            <select
                                name="LOCATION"
                                id="location"
                                class="form-select form-select-sm"
                                v-model="formLocation.LOCATION"
                            >
                                <option
                                    v-for="location in locations"
                                    :value="location.LOCATION"
                                >
                                    {{ location.LOCATION }}
                                </option>
                            </select>
                        </div>
                        <div
                            v-if="errors.LOCATION"
                            key="LOCATION"
                            class="col-12"
                        >
                            <p
                                v-for="error in errors.LOCATION"
                                :key="error"
                                class="text-danger fs-6 mt-2 text-end"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2">
                        <label
                            for="start-date"
                            class="col-xl-4 col-md-5 label-sm col-form-label"
                        >
                            START DATE
                        </label>
                        <div class="col col-xl-8 col-md-7 col-12">
                            <input
                                type="date"
                                class="form-control form-control-sm"
                                name="START_DATE"
                                v-model="formLocation.START_DATE"
                            />
                        </div>
                        <div
                            v-if="errors.START_DATE"
                            key="START_DATE"
                            class="col-12"
                        >
                            <p
                                v-for="error in errors.START_DATE"
                                :key="error"
                                class="text-danger fs-6 mt-2 text-end"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2">
                        <label
                            for="end-date"
                            class="col-xl-4 col-md-5 label-sm col-form-label"
                        >
                            END DATE
                        </label>
                        <div class="col col-xl-8 col-md-7 col-12">
                            <input
                                type="date"
                                name="END_DATE"
                                class="form-control form-control-sm"
                                v-model="formLocation.END_DATE"
                            />
                        </div>
                        <div
                            v-if="errors.END_DATE"
                            key="END_DATE"
                            class="col-12"
                        >
                            <p
                                v-for="error in errors.END_DATE"
                                :key="error"
                                class="text-danger fs-6 mt-2 text-end"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>

                    <div class="d-grid gap-1 mt-3">
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm"
                            :disabled="insertLocationLoading || disableInsertButton"
                        >
                            <span v-show="!insertLocationLoading">
                                <i class="icon ph-bold ph-plus me-2"></i> INSERT TO LIST
                            </span>
                            <div
                                v-show="insertLocationLoading"
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button
                            class="btn btn-primary btn-sm"
                            @click="deleteLocation()"
                            :disabled="deleteLocationLoading || disableDeleteButton"
                        >
                            <span v-show="!deleteLocationLoading">
                                <i class="icon ph-bold ph-trash me-2"></i>DELETE FROM LIST
                            </span>
                            <div
                                v-show="deleteLocationLoading"
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="updateLocation()"
                            :disabled="!formLocation.id"
                        >
                            <i class="icon ph-bold ph-arrow-clockwise me-2"></i>UPDATE
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="resetFields()"
                        >
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>RESET FIELDS
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (employee) => {
                isSelectEmployeePopupOpen = false;
                onEmployeeSelect(employee);
            }
        "
    />
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";

import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

const departments = ref([]);
const errors = ref("");
const formEmployeeEMPID = ref("");
const employeesLocations = ref([]);
const locations = ref([]);
const deleteLocationLoading = ref(false);
const displayDeleteLocationSuccessAlert = ref(false);
const displayInsertLocationSuccessAlert = ref(false);
const insertLocationLoading = ref(false);
const selectedEmployeeID = ref("");
const disableInsertButton = ref(true);
const disableDeleteButton = ref(true);
const disableSelectEmployeeButton = ref(true);
const displayUpdateLocationSuccessAlert = ref(false);
const isSelectEmployeePopupOpen = ref(false);

onMounted(async () => {
    try {
        const result = await axios.get(
            route("api.departments.index", {
                rows: 0,
            })
        );
        departments.value = result.data.data;
    } catch (e) {
        console.log(e);
    }
});

const formEmployee = reactive({
    EMPID: formEmployeeEMPID,
    FNAME: "",
    LNAME: "",
    DEPT: "",
});

const onEmployeeSelect = (selectedEmployee) => {
    disableButton(false, true);
    selectedEmployeeID.value = selectedEmployee.EMPID;
    formEmployee.EMPID = selectedEmployee.EMPID;
    formLocation.EMPID = selectedEmployee.EMPID;
    formEmployee.FNAME = selectedEmployee.FNAME;
    formEmployee.LNAME = selectedEmployee.LNAME;
    formEmployee.DEPT = selectedEmployee.DEPT;
    disableSelectEmployeeButton.value = false;
    isSelectEmployeePopupOpen.value = false;
    fetchEmployeeLocations();
};

async function fetchEmployeeLocations() {
    try {
        const result = await axios.get(
            route("api.employee-locations.show", [selectedEmployeeID.value]), {}
        );
        employeesLocations.value = result.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
}

onMounted(async () => {
    try {
        const result = await axios.get(route("api.locations.index"), {});
        locations.value = result.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
});

const formLocation = reactive({
    id: "",
    EMPID: selectedEmployeeID.value,
    LOCATION: "",
    START_DATE: "",
    END_DATE: "",
});

function disableButton(insert, remove) {
    disableInsertButton.value = insert;
    disableDeleteButton.value = remove;
}

function populateFormLocation(location) {
    disableButton(false, false);
    const selectedLocation = employeesLocations.value.find(
        (loc) => loc.id === location.id
    );
    if (!selectedLocation) {
        return;
    } else {
        formLocation.id = selectedLocation.id;
        formLocation.EMPID = selectedLocation.EMPID;
        formLocation.LOCATION = selectedLocation.LOCATION;
        formLocation.START_DATE = selectedLocation.START_DATE;
        formLocation.END_DATE = selectedLocation.END_DATE;
        disableInsertButton.value = true;
    }
}

function resetFields() {
    formLocation.id = "";
    formLocation.LOCATION = "";
    formLocation.START_DATE = "";
    formLocation.END_DATE = "";
    errors.value = false;
    disableButton(false, true);
    resetAlerts();
}

function clearFields() {
    formLocation.LOCATION = "";
    formLocation.START_DATE = "";
    formLocation.END_DATE = "";
}

const deleteLocation = async () => {
    resetAlerts();
    deleteLocationLoading.value = true;
    errors.value = false;
    try {
        const result = await axios.delete(
            route("api.employee-locations.destroy", [formLocation.id])
        );
        if (result.status === 204) {
            displayDeleteLocationSuccessAlert.value = true;
            clearFields();
            fetchEmployeeLocations();
            disableDeleteButton.value = true;
        }
    } catch (e) {
        console.log(e);
    }
    deleteLocationLoading.value = false;
};

const addLocations = async () => {
    resetAlerts();
    errors.value = false;
    insertLocationLoading.value = true;
    try {
        const result = await axios.post(route("api.employee-locations.store"), {
            EMPID: selectedEmployeeID.value,
            ...formLocation,
        });
        if (result.status === 201) {
            fetchEmployeeLocations();
            displayInsertLocationSuccessAlert.value = true;
            clearFields();
        }
    } catch (e) {
        console.log(e.response);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
    insertLocationLoading.value = false;
};

async function updateLocation() {
    resetAlerts();
    try {
        const result = await axios.patch(
            route("api.employee-locations.update", [formLocation.id]),
            {
                EMPID: selectedEmployeeID.value,
                ...formLocation,
            }
        );
        if (result.status === 201) {
            fetchEmployeeLocations();
            clearFields();
            displayUpdateLocationSuccessAlert.value = true;
        }
    } catch (e) {
        console.log(e);
        errors.value = e.response.data.errors;
    }
}

function resetAlerts() {
    displayInsertLocationSuccessAlert.value = false;
    displayDeleteLocationSuccessAlert.value = false;
    displayUpdateLocationSuccessAlert.value = false;
}
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.select-employee-table-wrapper {
    height: 16rem;
    overflow-y: auto;
}

.employee-location-table-wrapper {
    height: 100%;
    max-height: 20rem;
    overflow-y: auto;
}

thead {
    top: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}

td {
    cursor: pointer;
}
</style>
