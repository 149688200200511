import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useEmployeeHours() {
    const employeeHours = ref(null);
    const totalRecords = ref(null);

    const getEmployeeHours = async (params) => {
        try {
            const getEmployeeHoursResponse = await axios.get(route('api.employee-hours.index', params));
            employeeHours.value = getEmployeeHoursResponse.data.data;
            totalRecords.value = getEmployeeHoursResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee hours.');
        }
    };

    const getEmployeeHoursOnTimePeriod = async (employeeId, timePeriod) => {
        try {
            const getEmployeeHoursOnTimePeriodResponse = await axios.get(route('api.employees.hours.time-period.index', {
                employee: employeeId, // Route param for object autoinjection
                TIME_PERIOD: timePeriod, // Route param for TIME_PERIOD field (cannot be autoinjected as time_period) (all caps as field convention)
            }));
            employeeHours.value = getEmployeeHoursOnTimePeriodResponse.data.data;
            totalRecords.value = getEmployeeHoursOnTimePeriodResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee hours.');
        }
    };

    return {
        employeeHours,
        totalRecords,
        getEmployeeHours,
        getEmployeeHoursOnTimePeriod,
    };
}
