<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-money me-2"></i>Open Loans</legend>
        <DataTable
            :loading="isLoading"
            :value="employeeLoans"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchEmployeeOpenLoans();
                }
            "
            v-model:filters="filters"
            filter-display="row"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="LOANID"
                header="ID"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LOANTYPE"
                header="Type"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="STATUS"
                header="Status"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="INIT_AMT"
                header="Init. Amt."
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="TOTAL_PAYMENT"
                header="Total Payment"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="REMAIN_AMT"
                header="Remaining"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="AMT_WEEK"
                header="Amount per Week"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="NUM_WEEKS"
                header="Total Weeks"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="WEEKS_PASSED"
                header="Weeks Passed"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DISCREPANCY"
                header="Discrepancy"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DESCRIPTION"
                header="Description"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    <template v-if="!employee">
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-users me-2"></i>Please specify an employee.
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-users me-2"></i>No open loans.
                        </div>
                    </template>
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeLoans } from '@/composables/data/employeeLoans';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    employee: Object,
    refreshFlag: null,
    class: String
});

const emit = defineEmits(['error']);

const { filters } = useDataTableParams(['LOANID', 'LOANTYPE', 'DATE', 'STATUS',
    'INIT_AMT', 'TOTAL_PAYMENT', 'REMAIN_AMT', 'AMT_WEEK', 'NUM_WEEKS',
    'WEEKS_PASSED', 'DISCREPANCY', 'DESCRIPTION']);

const { employeeLoans, getEmployeeOpenLoans } = useEmployeeLoans();

const isLoading = ref(false);

async function fetchEmployeeOpenLoans() {
    employeeLoans.value = null;

    if (!props.employee) {
        return;
    }

    isLoading.value = true;
    try {
        await getEmployeeOpenLoans(props.employee.EMPID, {
            INCLUDE_FIELDS: 'TOTAL_PAYMENT'
        });
        employeeLoans.value = employeeLoans.value.map(loan => ({
            ...loan,
            REMAIN_AMT: parseFloat(loan.REMAIN_AMT).toFixed(2),
            DISCREPANCY: parseFloat(loan.DISCREPANCY).toFixed(2),
        }));
    } catch (e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

watch(() => props.employee, fetchEmployeeOpenLoans);
watch(() => props.refreshFlag, fetchEmployeeOpenLoans);
</script>
