<template>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-package me-2"></i> No Landed Cost
        </legend>
        <DataTable
            lazy
            :loading="isLoading"
            :value="noLandedCosts"
            :total-records="noLandedCostsTotalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchNoLandedCosts();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:selection="selectedNoLandedCost"
            selection-mode="single"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchNoLandedCosts();
                }
            "
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i> No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end mt-3">
            <button
                type="button"
                class="btn btn-primary me-1 mb-1 btn-sm"
                :disabled="!selectedNoLandedCost"
                @click="insertLandedCost"
            >
                <i class="icon ph-bold ph-plus me-2"></i>Insert Landed Cost
            </button>
            <button
                type="button"
                class="btn btn-primary me-1 mb-1 btn-sm"
                :disabled="isLoading"
                @click="fetchNoLandedCosts"
            >
                <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                >
                </span>
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Refresh
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLandedCosts } from "@/composables/data/landedCosts";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    date: String,
    refreshFlag: Boolean,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
]);

const {
    noLandedCosts,
    noLandedCostsTotalRecords,
    getNoLandedCosts,
    updateLandedCost,
} = useLandedCosts();

const selectedNoLandedCost = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchNoLandedCosts() {
    noLandedCosts.value = null;

    isLoading.value = true;
    try {
        await getNoLandedCosts(params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchNoLandedCosts, 300);
}

onMounted(fetchNoLandedCosts);

watch(
    () => props.date,
    () => {
        filters.value.DATE.value = props.date;
        tableOnFilter();
    }
);

async function insertLandedCost() {
    try {
        const newLandedCost = window.prompt("Input new landed cost:");
        await updateLandedCost(
            selectedNoLandedCost.value.STYLE,
            selectedNoLandedCost.value.DATE,
            newLandedCost
        );
        emit(
            "update",
            `Successfully inserted landed cost ${newLandedCost} for ` +
                `STYLE = ${selectedNoLandedCost.value.STYLE} DATE = ${selectedNoLandedCost.value.DATE}.`
        );
        fetchNoLandedCosts();
    } catch (e) {
        emit("error", e.message);
    }
}

watch(() => props.refreshFlag, fetchNoLandedCosts);
</script>
