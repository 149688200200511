<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <ReconInfoGenerationsTableCard />
        </div>
        <div class="col-md-8">
            <ReconItemsResolvedTableCard :refresh-flag="reconItemsResolvedTableRefreshFlag"
                v-model="selectedReconItemResolved" />
        </div>
    </div>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <ReconItemsNotFoundInMainTableCard v-model="selectedReconItemNotFoundInMain"
                :refresh-flag="reconItemsNotFoundInMainTableRefreshFlag">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-2"
                            @click="(_) => { isSelectLostCommentPopupOpen = true; }">
                            Move to LOST (1)
                        </button>
                        <button type="button" class="btn btn-primary"
                            @click="unlostBarcode">
                            UNLOST (1)
                        </button>
                    </div>
                </template>
            </ReconItemsNotFoundInMainTableCard>
        </div>
        <div class="col-md-4">
            <ReconItemsLatestLocationNotInMainTableCard v-model="selectedReconItemNotInMain"
                :refresh-flag="reconItemsLatestLocationNotInMainTableRefreshFlag">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="openReturnCommentPopup">
                            Return (2) to MAIN_1
                        </button>
                    </div>
                </template>
            </ReconItemsLatestLocationNotInMainTableCard>
        </div>
        <div class="col-md-4">
            <ReconItemsReplacementTableCard v-model="selectedReconItemReplacement"
                :refresh-flag="reconItemsReplacementTableRefreshFlag">
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary"
                            @click="replaceRRWithOriginalBarcode">
                            Found RR, update (3) with (1)
                        </button>
                    </div>
                </template>
            </ReconItemsReplacementTableCard>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-6">
            <InventoryRecordsOfSelectedBarcodeTableCard
                :style="barcodeToDisplay.style"
                :date="barcodeToDisplay.date"
                :serial="barcodeToDisplay.serial"
                :refresh-flag="barcodeLogsRefreshFlag" />
        </div>
        <div class="col-md-6">
            <InventoryChecksOfSelectedBarcodeTableCard
                :barcode="barcodeToDisplayConstructed"
                :refresh-flag="barcodeLogsRefreshFlag" />
        </div>
    </div>

    <SelectLostCommentForMarkingBarcodePopup :is-open="isSelectLostCommentPopupOpen"
        @close="(_) => { isSelectLostCommentPopupOpen = false; }"
        @select="markBarcodeAsLost" />

    <SelectCommentForReturningBarcodeToMainWarehousePopup :is-open="isSelectReturnCommentPopupOpen"
        @close="(_) => { isSelectReturnCommentPopupOpen = false; }"
        @select="returnBarcodeToMainWarehouse" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useReconMainWarehouseActions } from '@/composables/data/reconMainWarehouseActions';

import ReconInfoGenerationsTableCard from './ReconInfoGenerationsTableCard.vue';
import ReconItemsNotFoundInMainTableCard from './ReconItemsNotFoundInMainTableCard.vue';
import ReconItemsLatestLocationNotInMainTableCard from './ReconItemsLatestLocationNotInMainTableCard.vue';
import ReconItemsReplacementTableCard from './ReconItemsReplacementTableCard.vue';
import ReconItemsResolvedTableCard from './ReconItemsResolvedTableCard.vue';
import InventoryRecordsOfSelectedBarcodeTableCard from './InventoryRecordsOfSelectedBarcodeTableCard.vue';
import InventoryChecksOfSelectedBarcodeTableCard from './InventoryChecksOfSelectedBarcodeTableCard.vue';
import SelectCommentForReturningBarcodeToMainWarehousePopup from './SelectCommentForReturningBarcodeToMainWarehousePopup.vue';

import { constructBarcode, deconstructBarcode } from '@/utils/barcodes';
import SelectLostCommentForMarkingBarcodePopup from './SelectLostCommentForMarkingBarcodePopup.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const {
    postRRToOriginalReplacement,
    postMainWarehouseReturn,
    postMainWarehouseLost,
    postMainWarehouseUnlost,
} = useReconMainWarehouseActions();

const selectedReconItemResolved = ref(null);

const selectedReconItemNotFoundInMain = ref(null);
const selectedReconItemNotInMain = ref(null);
const selectedReconItemReplacement = ref(null);

const reconItemsResolvedTableRefreshFlag = ref(false);
const reconItemsNotFoundInMainTableRefreshFlag = ref(false);
const reconItemsLatestLocationNotInMainTableRefreshFlag = ref(false);
const reconItemsReplacementTableRefreshFlag = ref(false);

const barcodeLogsRefreshFlag = ref(false);

const barcodeToDisplay = ref({
    style: null,
    date: null,
    serial: null,
});

const isSelectReturnCommentPopupOpen = ref(false);
const isSelectLostCommentPopupOpen = ref(false);

const barcodeToDisplayConstructed = computed(() => {
    try {
        return constructBarcode(
            barcodeToDisplay.value.style,
            barcodeToDisplay.value.date,
            barcodeToDisplay.value.serial
        );
    } catch (e) {
        return null;
    }
});

watch(selectedReconItemResolved, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemResolved.value?.style,
        date: selectedReconItemResolved.value?.date,
        serial: selectedReconItemResolved.value?.serial,
    };
});

watch(selectedReconItemNotFoundInMain, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemNotFoundInMain.value?.style,
        date: selectedReconItemNotFoundInMain.value?.date,
        serial: selectedReconItemNotFoundInMain.value?.serial,
    };
});

watch(selectedReconItemNotInMain, () => {
    if (selectedReconItemNotInMain.value) {
        barcodeToDisplay.value = deconstructBarcode(selectedReconItemNotInMain.value?.raw);
    } else {
        barcodeToDisplay.value = {
            style: null,
            date: null,
            serial: null,
        };
    }
});

watch(selectedReconItemReplacement, () => {
    barcodeToDisplay.value = {
        style: selectedReconItemReplacement.value?.style,
        date: selectedReconItemReplacement.value?.date,
        serial: selectedReconItemReplacement.value?.serial,
    };
});

async function markBarcodeAsLost(lostComment) {
    if (!selectedReconItemNotFoundInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (1) to mark as LOST.');
        return;
    }

    const barcodeToMarkAsLost = constructBarcode(
        selectedReconItemNotFoundInMain.value.style,
        selectedReconItemNotFoundInMain.value.date,
        selectedReconItemNotFoundInMain.value.serial,
    );

    if (window.prompt(`This action will mark barcode ${barcodeToMarkAsLost} as LOST. Type 'LOST'.`) != 'LOST') {
        return;
    }

    loadingFlags.add("moveBarcodeToLost");
    try {
        await postMainWarehouseLost(barcodeToMarkAsLost, lostComment.COMMENT_ID);
        toasts.add('SUCCESS', 'Success', `Successfully marked barcode ${barcodeToMarkAsLost} as LOST.`);
        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsNotFoundInMainTableRefreshFlag.value = !reconItemsNotFoundInMainTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
        isSelectLostCommentPopupOpen.value = false;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("moveBarcodeToLost");
}

async function unlostBarcode() {
    if (!selectedReconItemNotFoundInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (1) to UNLOST.');
        return;
    }

    const barcodeToUnlost = constructBarcode(
        selectedReconItemNotFoundInMain.value.style,
        selectedReconItemNotFoundInMain.value.date,
        selectedReconItemNotFoundInMain.value.serial,
    );

    if (window.prompt(`This action will UNLOST barcode ${barcodeToUnlost}. Type 'UNLOST'.`) != 'UNLOST') {
        return;
    }

    loadingFlags.add("unlostBarcode");
    try {
        await postMainWarehouseUnlost(barcodeToUnlost);
        toasts.add('SUCCESS', 'Success', `Successfully unlost barcode ${barcodeToUnlost}.`);
        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsNotFoundInMainTableRefreshFlag.value = !reconItemsNotFoundInMainTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("unlostBarcode");
}

async function replaceRRWithOriginalBarcode() {
    if (!selectedReconItemNotFoundInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (1).');
        return;
    }

    if (!selectedReconItemReplacement.value) {
        toasts.add('ERROR', 'Error', 'Please select a RR barcode from Table (3).');
        return;
    }

    const originalBarcode = constructBarcode(
        selectedReconItemNotFoundInMain.value.style,
        selectedReconItemNotFoundInMain.value.date,
        selectedReconItemNotFoundInMain.value.serial,
    );
    
    const replacementBarcode = constructBarcode(
        selectedReconItemReplacement.value.style,
        selectedReconItemReplacement.value.date,
        selectedReconItemReplacement.value.serial,
    );

    if (!window.confirm(`This action will replace RR ${replacementBarcode} with ${originalBarcode}. Proceed?`)) {
        return;
    }

    loadingFlags.add('replaceRRWithOriginalBarcode');
    try {

        await postRRToOriginalReplacement(originalBarcode, replacementBarcode);
        toasts.add('SUCCESS', 'Success', `Successfully replaced RR ${replacementBarcode} with ${originalBarcode}.`);
    
        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsNotFoundInMainTableRefreshFlag.value = !reconItemsNotFoundInMainTableRefreshFlag.value;
        reconItemsReplacementTableRefreshFlag.value = !reconItemsReplacementTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('replaceRRWithOriginalBarcode');
}

function openReturnCommentPopup() {
    if (!selectedReconItemNotInMain.value) {
        toasts.add('ERROR', 'Error', 'Please select a barcode from Table (2).');
        return;
    }

    isSelectReturnCommentPopupOpen.value = true;
}

async function returnBarcodeToMainWarehouse(comment) {
    isSelectReturnCommentPopupOpen.value = false;
    loadingFlags.add('returnBarcodeToMainWarehouse');
    try {
        await postMainWarehouseReturn(selectedReconItemNotInMain.value.raw, comment.COMMENT_ID);
        toasts.add('SUCCESS', 'Success', `Successfully returned barcode ${selectedReconItemNotInMain.value.raw}.`);

        reconItemsResolvedTableRefreshFlag.value = !reconItemsResolvedTableRefreshFlag.value;
        reconItemsLatestLocationNotInMainTableRefreshFlag.value = !reconItemsLatestLocationNotInMainTableRefreshFlag.value;
        barcodeLogsRefreshFlag.value = !barcodeLogsRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('returnBarcodeToMainWarehouse');
}
</script>
