<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-tag me-2"></i>Change Sale Type
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <form @submit.prevent="updateSaleTypeOfBarcodeSequence"
                        class="my-3">
                        <FormSelect
                            type="date"
                            v-model="saleType"
                            label="New Sale Type"
                            id-prefix="saleType"
                            class="mb-2"
                            :options="saleTypes"
                            :option-value="(saleType) => saleType.value"
                            :option-to-string="(saleType) => [saleType.value, saleType.verbose].join(' | ')"
                            :errors="errors.SALE_TYPE"
                        />

                        <div class="text-end">
                            <button type="submit"
                                class="btn btn-primary"
                                :disabled="isUpdating">
                                <span v-if="isUpdating"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useInventory } from "@/composables/data/inventory";
import { useSaleTypes } from "@/composables/data/saleTypes";
import FormSelect from "@/components/utils/FormSelect.vue";

const { saleTypes } = useSaleTypes();

const { updateSaleType } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const saleType = ref(null);

const errors = ref({});

async function updateSaleTypeOfBarcodeSequence() {
    errors.value = {};

    loadingFlags.add("updateSaleTypeOfBarcodeSequence");
    try {
        await updateSaleType(
            props.inventoryRecord.STYLE,
            props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL,
            props.inventoryRecord.SEQ_NO,
            saleType.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated sale type.");
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSaleTypeOfBarcodeSequence");
}

watch(
    () => props.isOpen,
    () => {
        saleType.value = null;
    }
);
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
