<template>
    <div v-if="loadingFlags.flags.size > 0" class="overlay-wrapper">
        <div class="overlay-content text-white fs-5">
            <span class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true"></span>
            <input type="text" value="Loading..."
                class="ms-2 bg-transparent border-0 text-white"
                @keydown.prevent
                ref="hiddenLoadingInput" />
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

const loadingFlags = useLoadingFlagsStore();

const hiddenLoadingInput = ref(null);

const previousFocusedElement = ref(null);

watch(() => loadingFlags.flags.size, () => {
    if(loadingFlags.flags.size > 0) {
        previousFocusedElement.value = document.activeElement;
        setTimeout(() => {
            hiddenLoadingInput.value?.focus();
        }, 0);
    } else {
        setTimeout(() => {
            previousFocusedElement.value?.focus();
        }, 0);
    }
});
</script>

<style scoped>
.overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.75);
    z-index: 999;
}

.overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}

input:focus {
    outline: none;
}
</style>
