<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-map-pin me-2"></i>Find Comment by Selling Location
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')" />
            </div>
            <hr />
            <div class="modal-container px-4">
                <FormSelect
                    label="Location"
                    v-model="location"
                    :options="locations"
                    :option-to-string="(location) => location.LOCATION"
                    id-prefix="findCommentLocation"
                    :errors="locationErrors"
                    @keydown-enter="focusnext('findCommentButton')"
                />
                <div class="modal-padding-container text-end">
                    <button type="button"
                        class="btn btn-outline-secondary me-2"
                        data-bs-dismiss="modal">
                        <i class="icon ph-bold ph-x-circle me-2"></i>Close
                    </button>
                    <button type="button"
                        class="btn btn-primary"
                        @click="findReturnComment"
                        id="findCommentButton">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Find
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useComments } from "@/composables/data/comments";
import { useLocations } from "@/composables/data/locations";
import FormSelect from "@/components/utils/FormSelect.vue";
import focusnext from "@/utils/focusnext";
import moment from "moment";

const props = defineProps({
    isOpen: Boolean,
    commentDate: String,
});

const emit = defineEmits(["found", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { locations, getLocations } = useLocations();
const location = ref(null);
const locationErrors = ref(null);

const { comments, getComments } = useComments();

onMounted(getLocations);

async function findReturnComment() {
    locationErrors.value = null;

    loadingFlags.add("findReturnComment");
    try {
        const commentMonth = moment.utc(props.commentDate, 'YYYY/MM');

        await getComments({
            filters: {
                COMMENT_RPT_DATE: {
                    value: [
                        commentMonth.startOf('month').format('YYYY-MM-DD'),
                        commentMonth.endOf('month').format('YYYY-MM-DD'),
                    ],
                    matchMode: 'between',
                },
                COMMENT_TEXT: {
                    value: "Ret",
                    matchMode: "startsWith"
                },
                LOCATION: {
                    value: location.value.LOCATION,
                    matchMode: "equals",
                },
            },
        });
        
        if (comments.value.length > 0) {
            const comment = comments.value[0];
            toasts.add(
                "SUCCESS",
                "Success",
                `Comment ID ${comment.COMMENT_ID} found ` +
                    `for returning items to location ${location.value.LOCATION}.`
            )
            emit("found", comment);
        } else {
            throw new Error(
                `No return comment found with this location ` +
                    `and comment date ${props.commentDate}.`
            );
        }
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("findReturnComment");
}
</script>
