<template>
    <DataTable
        :loading="loadingFlags.has('fetchVouchers')"
        lazy
        :value="vouchers"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchVouchers(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="e => { onSort(e); fetchVouchers(); }"
        selection-mode="single"
        v-model:selection="selectedVoucher"
        data-key="VOUCHER_ID"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column
            field="VOUCHER_ID"
            header="Voucher ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="VOUCHER_DATE"
            header="Voucher Date"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="VOUCHER_AMT"
            header="Voucher Amt."
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="TOTAL_DEBIT"
            header="Debit"
        />
        <Column
            field="TOTAL_CREDIT"
            header="Credit"
        />
        <Column
            field="DESCRIPTION"
            header="Description"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
            <template #body="{ data }">
                ({{ data.payee.NAME }}) {{ data.DESCRIPTION }}
            </template>
        </Column>
        <Column
            field="PAYEE_TYPE"
            header="Payee Type"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="PAYEE_ID"
            header="Payee ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="payee.NAME"
            header="Payee Name"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="PREPARED_BY"
            header="Prepared By"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="SCAN_FLAG"
            header="Scan Flag"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="PTR"
            header="PTR"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <template #footer>
            {{ totalRecords ?? 0 }} total records
        </template>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useVouchers } from '@/composables/data/vouchers';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    params: Object,
    filters: Object,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "VOUCHER_ID",
    "VOUCHER_DATE",
    "VOUCHER_AMT",
    "TOTAL_DEBIT",
    "TOTAL_CREDIT",
    "DESCRIPTION",
    "PAYEE_TYPE",
    "PAYEE_ID",
    "payee.NAME",
    "PREPARED_BY",
    "SCAN_FLAG",
    "PTR"
]);

const { vouchers, totalRecords, getVouchers } = useVouchers();

const selectedVoucher = defineModel();

const fetchTimeout = ref(null);

async function fetchVouchers() {
    vouchers.value = null;

    loadingFlags.add("fetchVouchers");
    try {
        await getVouchers({
            ...params.value,
            ...props.params,
            hardFilters: props.filters,
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchVouchers");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchVouchers, 800);
}

onMounted(() => {
    multiSortMeta.value = [
        {
            field: 'VOUCHER_DATE',
            order: -1,
        },
        {
            field: 'VOUCHER_ID',
            order: 1,
        },
    ];
    onSort();
    fetchVouchers();
});

watch(() => [props.params,  props.filters, props.refreshFlag], fetchVouchers);
</script>
