<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Import Errors
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <DataTable
                    :value="errors"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="row" header="Row #." />
                    <Column field="field" header="Field" />
                    <Column field="message" header="Message" />
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    isOpen: Boolean,
    errors: Array,
});

const emit = defineEmits(["close"]);
</script>
