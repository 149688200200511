<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-paint-brush me-2"></i>
                    Select Style
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <StyleTable
                        v-model="style"
                        :preset-filters="presetFilters"
                    />
                    <div class="text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!style"
                            @click=" (_) => { emit('select', style); }">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Style
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import StyleTable from "./StyleTable.vue";

const props = defineProps({
    presetFilters: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["select", "error"]);

const style = defineModel();
</script>
<style scoped>
.container {
    max-width: 600px
}
</style>
