import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useReconMainWarehouseActions() {
    const postRRToOriginalReplacement = async (originalBarcode, replacementBarcode) => {
        try {
            const postRRToOriginalReplacementResponse =
                await axios.post(route('api.recon-main-warehouse-actions.rr-to-original-replacements.store'), {
                    original_barcode: originalBarcode,
                    rr_barcode: replacementBarcode,
                });
            return postRRToOriginalReplacementResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while replacing with original barcode.');
        }
    };

    const postMainWarehouseReturn = async (barcode, commentId) => {
        try {
            const postMainWarehouseReturnResponse =
                await axios.post(route('api.recon-main-warehouse-actions.main-warehouse-returns.store'), {
                    barcode: barcode,
                    pullout_comment_id: commentId,
                });
            return postMainWarehouseReturnResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while returning barcode to main warehouse.');
        }
    };

    const postMainWarehouseLost = async (barcode, lostCommentId) => {
        try {
            const postMainWarehouseLostResponse =
                await axios.post(route('api.recon-main-warehouse-actions.losts.store'), {
                    barcode: barcode,
                    lost_comment_id: lostCommentId,
                });
            return postMainWarehouseLostResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while marking barcode as LOST.');
        }
    };

    const postMainWarehouseUnlost = async (barcode) => {
        try {
            const postMainWarehouseUnlostResponse =
                await axios.post(route('api.recon-main-warehouse-actions.unlosts.store'), {
                    barcode: barcode,
                });
            return postMainWarehouseUnlostResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while marking barcode as UNLOST.');
        }
    };
    
    return {
        postRRToOriginalReplacement,
        postMainWarehouseReturn,
        postMainWarehouseLost,
        postMainWarehouseUnlost,
    };
}
