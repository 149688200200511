<template>
    <fieldset class="h-100">
        <legend>
            <i class="icon ph-bold ph-clock me-2"></i>Employee Hours
        </legend>
        <DataTable
            :loading="loadingFlags.has('fetchEmployeeNidekaHoursOnDays')"
            lazy
            :value="employeeNidekaHoursOnDays"
            :total-records="totalRecords"
            paginator
            :rows="ROWS"
            @page="
                (e) => {
                    onPage(e);
                    fetchEmployeeNidekaHoursOnDays();
                }
            "
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="employee.FNAME" header="First Name" />
            <Column field="employee.LNAME" header="Last Name" />
            <Column field="DATE" header="Date" />
            <Column field="REGULAR_IN" header="Regular In" />
            <Column field="LATE_1" header="Late 1" />
            <Column field="LATE_2" header="Late 2" />
            <Column field="LATE_3" header="Late 3" />
            <Column field="LATE_4" header="Late 4" />
            <Column field="OUT_1" header="Out" />
            <Column field="OVERTIME" header="Overtime" />
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button type="button"
                class="btn btn-primary btn-sm"
                :disabled="!employeeNidekaHoursOnDays?.length"
                @click="exportPDF">
                <i class="icon ph-bold ph-file-pdf me-2"></i>Export as PDF
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { watch } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeNidekaHoursOnDays } from '@/composables/data/employeeNidekaHoursOnDays';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const ROWS = 15;

const props = defineProps({
    employeeId: Number,
    startDate: String,
    endDate: String,
});

const emit = defineEmits(['error']);

const { params, onPage } = useDataTableParams([
    'employee.FNAME', 'employee.LNAME', 'REGULAR_IN', 'LATE_1', 'LATE_2', 'LATE_3', 'LATE_4', 'OUT_1', 'OVERTIME'
], { rows: ROWS });

const { employeeNidekaHoursOnDays, totalRecords, getEmployeeNidekaHoursOnDays} = useEmployeeNidekaHoursOnDays();

const loadingFlags = useLoadingFlagsStore();

async function fetchEmployeeNidekaHoursOnDays() {
    employeeNidekaHoursOnDays.value = null;

    if (!props.employeeId || !props.startDate || !props.endDate) {
        return;
    }

    loadingFlags.add('fetchEmployeeNidekaHoursOnDays');
    try {
        getEmployeeNidekaHoursOnDays(props.employeeId, {
            ...params.value,
            startDate: props.startDate,
            endDate: props.endDate
        });
    } catch(e) {
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployeeNidekaHoursOnDays');
}

watch(() => [props.employeeId, props.startDate, props.endDate], fetchEmployeeNidekaHoursOnDays);

function exportPDF() {
    window.open(route('api.employees.employee-nideka-hours-on-days.spreadsheet.show', {
        ...params.value,
        employee: props.employeeId,
        startDate: props.startDate,
        endDate: props.endDate
    }));
}
</script>
