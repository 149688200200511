<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-island me-2"></i>Create Leave
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        @click="emit('close')"
                    >
                    </button>
                </div>
            </template>
            <div class="py-4">
                <FormSelect
                    v-model="form.TYPE"
                    :errors="errors.TYPE"
                    :options="LEAVE_TYPES"
                    label="Leave Type"
                    id-prefix="type"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="date"
                    v-model="form.START_DATE"
                    :errors="errors.START_DATE"
                    label="Start Date"
                    id-prefix="startDate"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="date"
                    v-model="form.END_DATE"
                    :errors="errors.END_DATE"
                    label="End Date"
                    id-prefix="endDate"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
            <template #footer>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="insertLeaveHistory"
                    >
                        <i class="ph ph-plus me-1"></i> Create
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useLeaveHistories } from "@/composables/data/leaveHistories";

import Card from "@/components/utils/Card.vue";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

const props = defineProps({
    isOpen: Boolean,
    employeeId: Number,
});

const emit = defineEmits(["done", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const LEAVE_TYPES = ["MATERNITY", "SICK", "VACATION"];

const { postEmployeeLeaveHistory } = useLeaveHistories();

const form = ref({
    TYPE: null,
    START_DATE: null,
    END_DATE: null,
});

const errors = ref({});

async function insertLeaveHistory() {
    loadingFlags.add("insertLeaveHistory");
    try {
        await postEmployeeLeaveHistory(props.employeeId, form.value);
        toasts.add("SUCCESS", "Success", "Successfully created leave history.");
        emit("done");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("insertLeaveHistory");
}
</script>

<style scoped>
.pop-up .card {
    max-width: 400px;
}
</style>
