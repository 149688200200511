<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-map-pin-area me-2"></i> Location Charges
        </legend>
        <DataTable
            lazy
            :loading="isLoading"
            :value="locationCharges"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchLocationCharges();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:selection="selectedLocationCharge"
            selection-mode="single"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchLocationCharges();
                }
            "
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="CHARGE_NAME"
                header="Name"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="START_DATE"
                header="Start Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="END_DATE"
                header="End Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="CHARGE_TYPE"
                header="Charge Type"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="CHARGE_VALUE"
                header="Charge Value"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i> No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLocationCharges } from "@/composables/data/locationCharges";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
    refreshFlag: null,
    class: String,
});

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "CHARGE_NAME",
    "START_DATE",
    "END_DATE",
    "CHARGE_TYPE",
    "CHARGE_VALUE",
]);

const { locationCharges, totalRecords, getLocationCharges } =
    useLocationCharges();

const selectedLocationCharge = defineModel();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchLocationCharges() {
    locationCharges.value = null;

    if (!props.location) {
        return;
    }

    isLoading.value = true;
    try {
        await getLocationCharges(props.location, params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLocationCharges, 300);
}

watch(
    () => props.location,
    () => {
        selectedLocationCharge.value = null;
        fetchLocationCharges();
    }
);
watch(() => props.refreshFlag, fetchLocationCharges);
</script>
