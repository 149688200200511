<template>
    <FormComponent
        :label="label"
        :hide-label="hideLabel"
        :id-prefix="idPrefix"
        :is-horizontal="isHorizontal"
        :class="class">
        <div v-if="isHorizontal" :class="inputContainerClasses">
            <input
                :type="type"
                v-model="model"
                :step="step"
                :min="min"
                :disabled="disabled"
                :required="required"
                :id="idPrefix + 'FormInput'"
                :class="inputClasses"
                @keyup="emit('keyup')"
                @keydown.enter.prevent="emit('keydown-enter')"
                @change="emit('change')"
            />
            <div v-if="formText" class="form-text">
                {{ formText }}
            </div>
            <div v-if="errors && errors.length > 0" class="invalid-feedback">
                <p v-for="error in errors">{{ error }}</p>
            </div>
        </div>
        <template v-else>
            <input
                :type="type"
                v-model="model"
                :step="step"
                :min="min"
                :disabled="disabled"
                :required="required"
                :id="idPrefix + 'FormInput'"
                :class="inputClasses"
                @keyup="emit('keyup')"
                @keydown.enter.prevent="emit('keydown-enter')"
                @change="emit('change')"
            />
            <div v-if="formText" class="form-text">
                {{ formText }}
            </div>
            <div v-if="errors && errors.length > 0" class="invalid-feedback">
                <p v-for="error in errors">{{ error }}</p>
            </div>
        </template>
    </FormComponent>
</template>

<script setup>
import { computed } from "vue";

import FormComponent from "./FormComponent.vue";

const props = defineProps({
    label: String,
    hideLabel: Boolean,
    type: String,
    step: String,
    min: null,
    required: Boolean,
    formText: String,
    idPrefix: String,
    disabled: {
        type: Boolean,
        default: false,
    },
    isHorizontal: {
        type: Boolean,
        default: false,
    },
    class: {
        type: String,
        default: "",
    },
    inputClass: {
        type: String,
        default: "",
    },
    inputContainerClass: {
        type: String,
        default: "",
    },
    errors: {
        type: Array,
        default: [],
    },
});

const emit = defineEmits(["keydown-enter", "keyup", "change"]);

const model = defineModel();

const hasErrors = computed(() => {
    if (!props.errors) return false;
    return props.errors.length > 0;
});

const inputClasses = computed(() => {
    const inputClasses = ["form-control"];
    if (hasErrors.value) inputClasses.push("is-invalid");
    if (props.inputClass || props.inputClass.length > 0)
        inputClasses.push(props.inputClass);
    return inputClasses.join(" ");
});

const inputContainerClasses = computed(() => {
    const classes = ["col"];
    if (props.inputContainerClass) {
        classes.push(props.inputContainerClass);
    }
    return classes.join(" ");
});
</script>
