<template>
    <div class="row mb-3">
        <div class="col-lg-5">
            <SelectUserCard
                @user-select="onUserSelect"
                class="mb-3 h-100"
                @save="saveChanges"
            />
        </div>
        <div class="col-lg-7">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-lock-laminated me-2"></i>Current User Roles & Permission
                </legend>
                <div class="row gx-5">
                    <div class="col-sm-6">
                        <h6>Selected User Roles</h6>
                        <DataTable
                            :value="takenRoles"
                            paginator
                            :rows="10"
                            filter-display="row"
                            v-model:filters="filters.takenRoles"
                            v-model:selection="selectedTakenRole"
                            selection-mode="single"
                            data-key="id"
                            :row-class="takenRoleRowClass"
                            :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        >
                            <Column
                                field="name"
                                header="Role Name"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: {  class: 'd-none' },
                                }"
                            >
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @keydown="filterCallback()"
                                        class="form-control"
                                        placeholder="Search"
                                    />
                                </template>
                            </Column>
                            <template #empty>
                                <template v-if="user == null">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>No roles.
                                    </div>
                                </template>
                            </template>
                        </DataTable>
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                :disabled="user == null || selectedTakenRole == null"
                                @click="removeTakenRole"
                            >
                                <i class="icon ph-bold ph-trash me-2"></i>Remove Selected Role
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h6>Selected User Permissions</h6>
                        <DataTable
                            :value="takenPermissions"
                            paginator
                            :rows="10"
                            filter-display="row"
                            v-model:filters="filters.takenPermissions"
                            v-model:selection="selectedTakenPermission"
                            selection-mode="single"
                            data-key="id"
                            :row-class="takenPermissionRowClass"
                            :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        >
                            <Column
                                field="name"
                                header="Permission Name"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }"
                            >
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @keydown="filterCallback()"
                                        class="form-control"
                                        placeholder="Search"
                                    />
                                </template>
                            </Column>
                            <template #empty>
                                <template v-if="user == null">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>No permissions.
                                    </div>
                                </template>
                            </template>
                        </DataTable>
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                :disabled="
                                    user == null ||
                                    selectedTakenPermission == null
                                "
                                @click="removeTakenPermission"
                            >
                                <i class="icon ph-bold ph-trash me-2"></i>Remove Selected Permission
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-user-circle-gear me-2"></i>Available Roles
                </legend>
                <DataTable
                    :value="availableRoles"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="filters.availableRoles"
                    v-model:selection="selectedAvailableRole"
                    selection-mode="single"
                    data-key="id"
                    :row-class="availableRoleRowClass"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                >
                    <Column
                        field="name"
                        header="Role Name"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @keydown="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <template v-if="user == null">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user-circle-gear me-2"></i>No roles.
                            </div>
                        </template>
                    </template>
                </DataTable>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="user == null || selectedAvailableRole == null"
                        @click="addAvailableRole"
                    >
                        <i class="icon ph-bold ph-plus me-2"></i>Add Selected Role
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-lock-key me-2"></i> Available Permissions
                </legend>
                <DataTable
                    :value="availablePermissions"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="filters.availablePermissions"
                    v-model:selection="selectedAvailablePermission"
                    selection-mode="single"
                    data-key="id"
                    :row-class="availablePermissionRowClass"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                >
                    <Column
                        field="name"
                        header="Permission Name"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @keydown="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <template v-if="user == null">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-lock-key me-2"></i>No permissions.
                            </div>
                        </template>
                    </template>
                </DataTable>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="user == null || selectedAvailablePermission == null"
                        @click="addAvailablePermission"
                    >
                        <i class="icon ph-bold ph-plus me-2"></i>Add Selected Permission
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import SelectUserCard from "./SelectUserCard.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import { FilterMatchMode } from "primevue/api";

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

import { useFlashMessages } from "../../../../composables/flashmessages";

import axios from "axios";

const { addFlashMessage } = useFlashMessages();

const filters = ref({
    availableRoles: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    takenRoles: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    availablePermissions: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    takenPermissions: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
});

const user = ref();

const availableRoles = ref();
const takenRoles = ref();
const availablePermissions = ref();
const takenPermissions = ref();

const selectedAvailableRole = ref();
const selectedTakenRole = ref();
const selectedAvailablePermission = ref();
const selectedTakenPermission = ref();

const availableRoleRowClass = (data) => {
    return [
        { "bg-primary text-white": data.id == selectedAvailableRole.value?.id },
    ];
};
const takenRoleRowClass = (data) => {
    return [
        { "bg-primary text-white": data.id == selectedTakenRole.value?.id },
    ];
};
const availablePermissionRowClass = (data) => {
    return [
        {
            "bg-primary text-white":
                data.id == selectedAvailablePermission.value?.id,
        },
    ];
};
const takenPermissionRowClass = (data) => {
    return [
        {
            "bg-primary text-white":
                data.id == selectedTakenPermission.value?.id,
        },
    ];
};

async function fetchRoles() {
    try {
        const rolesResponse = await axios.get(route("api.roles.index"));
        availableRoles.value = rolesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching roles.");
        scrollToTop();
    }
}

async function fetchPermissions() {
    try {
        const permissionsResponse = await axios.get(
            route("api.permissions.index")
        );
        availablePermissions.value = permissionsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching permissions.");
        scrollToTop();
    }
}

async function fetchUserRoles() {
    try {
        const userRolesResponse = await axios.get(
            route("api.users.roles.show", {
                user: user.value.id,
            })
        );
        takenRoles.value = userRolesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching user roles.");
        scrollToTop();
    }
}

async function fetchUserPermissions() {
    try {
        const userPermissionsResponse = await axios.get(
            route("api.users.permissions.show", {
                user: user.value.id,
            })
        );
        takenPermissions.value = userPermissionsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching user permissions.");
        scrollToTop();
    }
}

async function onUserSelect(selectedUser) {
    user.value = selectedUser;

    await Promise.all([
        fetchRoles(),
        fetchPermissions(),
        fetchUserRoles(),
        fetchUserPermissions(),
    ]);

    /**
     * Remove taken permissions from available permissions.
     * Remove taken roles from available roles.
     *
     * Note, this is an O(n^2) solution of removals,
     * but it won't matter that much since rarely do we edit permissions in the system.
     * Additionally, there are only less than 100 permissions in the system.
     *
     * The permissions are stored as arrays to seemlessly integrate with the DataTables
     * without difficulty. So I suppose, the added efficiency of storing another bunch of
     * Objects (or, dictionaries) then transforming it for the DataTables
     * does not trade well with code readability :)
     */
    for (let takenRole of takenRoles.value) {
        availableRoles.value = availableRoles.value.filter(
            (availableRole) => availableRole.id != takenRole.id
        );
    }
    for (let takenPermission of takenPermissions.value) {
        availablePermissions.value = availablePermissions.value.filter(
            (availablePermission) =>
                availablePermission.id != takenPermission.id
        );
    }
}

function addAvailableRole() {
    availableRoles.value = availableRoles.value.filter(
        (availableRole) => availableRole.id != selectedAvailableRole.value.id
    );
    takenRoles.value = [...takenRoles.value, selectedAvailableRole.value];
}

function removeTakenRole() {
    takenRoles.value = takenRoles.value.filter(
        (takenRole) => takenRole.id != selectedTakenRole.value.id
    );
    availableRoles.value = [...availableRoles.value, selectedTakenRole.value];
}

function addAvailablePermission() {
    availablePermissions.value = availablePermissions.value.filter(
        (availablePermission) =>
            availablePermission.id != selectedAvailablePermission.value.id
    );
    takenPermissions.value = [
        ...takenPermissions.value,
        selectedAvailablePermission.value,
    ];
}

function removeTakenPermission() {
    takenPermissions.value = takenPermissions.value.filter(
        (takenPermission) =>
            takenPermission.id != selectedTakenPermission.value.id
    );
    availablePermissions.value = [
        ...availablePermissions.value,
        selectedTakenPermission.value,
    ];
}

async function saveChanges() {
    try {
        await axios.put(
            route("api.users.roles-and-permissions.update", {
                user: user.value.id,
            }),
            {
                roles: takenRoles.value.map((takenRole) => takenRole.id),
                permissions: takenPermissions.value.map(
                    (takenPermission) => takenPermission.id
                ),
            }
        );
        addFlashMessage("SUCCESS", "Successfully saved roles and permissions.");
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while saving roles and permissions.");
    }
    scrollToTop();
}
</script>
