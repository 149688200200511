<template>
    <DataTable
        :loading="loadingFlags.has('fetchLoans')"
        lazy
        :value="employeeLoans"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchLoans(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedLoan"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="e => { onSort(e); fetchLoans(); }"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column field="LOANID" header="ID" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="LOANTYPE" header="Type" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.FNAME" header="First Name" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.LNAME" header="Last Name" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="employee.PAYTYPE" header="Paytype" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="DATE_GRANTED" header="Date Granted" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="DATE" header="Date" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="INIT_AMT" header="Initial Amt." sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="PAYMENTS_COUNT_PAYMENT" header="Payments Made"
            :filter-match-mode-options="[
                { label: 'Equal (=)', value: FilterMatchMode.EQUALS },
                { label: 'Less Than (<)', value: FilterMatchMode.LESS_THAN },
                { label: 'Less Than/Equal (<=)', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
                { label: 'Greater Than (>)', value: FilterMatchMode.GREATER_THAN },
                { label: 'Greater Than/Equal (>=)', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
            ]"
            :pt="{ columnFilter: { class: 'd-flex gap-1' },
                filterMenuButton: { class: 'btn btn-sm btn-primary' },
                headerFilterClearButton: { class: 'btn btn-sm btn-primary' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control form-control-sm" placeholder="Search" />
            </template>
        </Column>
        <Column field="PAYMENTS_SUM_PAYMENT" header="Total Payment" />
        <Column field="DESCRIPTION" header="Description" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="AMT_WEEK" header="Amt./Week" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="NUM_WEEKS" header="#. of Weeks" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="STATUS" header="Status" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="VOUCHER" header="Voucher" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="DOCNO" header="DOCNO" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <Column field="CLOSURE_REASON" header="Closure Reason" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                    class="form-control" placeholder="Search" />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
        <template #footer>
            {{ totalRecords ?? 0 }} total records
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { FilterMatchMode } from 'primevue/api';

import { useToastsStore } from '@/stores/toasts';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeLoans } from '@/composables/data/employeeLoans';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const emit = defineEmits(['filter']);

const toasts = useToastsStore();
const loadingFlags = useLoadingFlagsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'LOANID',
    'LOANTYPE',
    'employee.FNAME',
    'employee.LNAME',
    'employee.PAYTYPE',
    'DATE_GRANTED',
    'DATE',
    'INIT_AMT',
    'PAYMENTS_COUNT_PAYMENT',
    'PAYMENTS_SUM_PAYMENT',
    'DESCRIPTION',
    'AMT_WEEK',
    'NUM_WEEKS',
    'STATUS',
    'VOUCHER',
    'DOCNO',
    'CREATED_BY',
    'CLOSURE_REASON',
]);

watch(filters, () => {
    emit('filter', filters.value);
});

const { employeeLoans, totalRecords, getLoans } = useEmployeeLoans();

const selectedLoan = defineModel();

const fetchTimeout = ref(null);

async function fetchLoans() {
    employeeLoans.value = null;

    loadingFlags.add('fetchLoans');
    try {
        await getLoans(params.value);
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchLoans');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLoans, 300);
}

onMounted(fetchLoans);
</script>
