<template>
    <div class="d-flex justify-content-center align-items-center">
        <DataTable
            class="w-100"
            :loading="isLoading"
            lazy
            paginator
            :rows="10"
            :value="timePeriods"
            :total-records="totalRecords"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:selection="selectedTimePeriod"
            selection-mode="single"
            data-key="TIME_PERIOD"
            @page="(e) => { onPage(e); fetchTimePeriods(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="TIME_PERIOD"
                header="Time Period"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Time Period"
                    />
                </template>
            </Column>
            <Column
                field="START_DATE"
                header="Start Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search BY Start Date"
                    />
                </template>
            </Column>
            <Column
                field="END_DATE"
                header="End Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by End Date"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No time period.
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useTimePeriods } from "@/composables/data/timePeriods";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    presetFilters: Object,
    showOnlyType: String,
});

const emit = defineEmits(["error"]);

const selectedTimePeriod = defineModel();

const { params, filters, onPage, onFilter } = useDataTableParams(
    ["TIME_PERIOD", "START_DATE", "END_DATE"],
    {},
    { PAYTYPE: { value: props.showOnlyType, matchMode: null } }
);

const { timePeriods, totalRecords, getTimePeriodsLatest } = useTimePeriods();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchTimePeriods() {
    timePeriods.value = null;

    if (props.showOnlyType === null) {
        return;
    }

    isLoading.value = true;
    try {
        await getTimePeriodsLatest(params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchTimePeriods, 300);
}

onMounted(() => {
    if (props.presetFilters) {
        filters.value = { ...filters.value, ...props.presetFilters };
        onFilter();
    }

    fetchTimePeriods();
});

watch(
    () => props.showOnlyType,
    () => {
        params.value.filters = {
            ...params.value.filters,
            PAYTYPE: {
                value: props.showOnlyType,
                matchMode: "equals",
            },
        };
        fetchTimePeriods();
    }
);
</script>
