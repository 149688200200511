<template>
    <fieldset>
        <legend>
            Create Year Equivalence
        </legend>
        <form @submit.prevent="createTabulationYearEquivalence">
            <FormInput
                type="text"
                v-model="form.dept"
                :errors="errors.dept"
                label="Department"
                id-prefix="dept"
                class="mb-1"
            />
            <div class="text-end">
                <button type="button"
                    class="btn btn-sm btn-primary"
                    @click="(_) => { isSelectDepartmentPopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormInput
                type="number"
                v-model="form.days_in_year"
                :errors="errors.days_in_year"
                label="Days in Year"
                id-prefix="daysInYear"
                class="mb-3"
            />
            <FormInput
                type="date"
                v-model="form.start_date"
                :errors="errors.start_date"
                label="Start Date"
                id-prefix="startDate"
                class="mb-3"
            />
            <div class="text-end">
                <button type="submit"
                    class="btn btn-primary">
                    Create
                </button>
            </div>
        </form>
    </fieldset>

    <SelectDepartmentPopup
        :is-open="isSelectDepartmentPopupOpen"
        @close="(_) => { isSelectDepartmentPopupOpen = false; }"
        @select="(selectedDepartment) => {
            form.dept = selectedDepartment.DEPT;
            isSelectDepartmentPopupOpen = false;
        }"
    />
</template>

<script setup>
import { ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useTabulationYearEquivalences } from '@/composables/data/tabulationYearEquivalences';

import FormInput from '@/components/utils/FormInput.vue';
import SelectDepartmentPopup from '@/components/utils/popups/SelectDepartmentPopup.vue';

const emit = defineEmits(["success"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { postTabulationYearEquivalence } = useTabulationYearEquivalences();

const form = ref({
    dept: null,
    days_in_year: null,
    start_date: null,
});

const errors = ref({});

const isSelectDepartmentPopupOpen = ref(false);

async function createTabulationYearEquivalence() {
    loadingFlags.add("createTabulationYearEquivalence");
    try {
        await postTabulationYearEquivalence(form.value);
        toasts.add("SUCCESS", "Success", "Successfully added year equivalance.");
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createTabulationYearEquivalence");
}
</script>
