<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Create/Update Breakdown</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <FormInput type="text" v-model="orderIdDisplay" disabled
                label="Order ID" id-prefix="orderId"
                class="mb-3" />
            <FormInput type="text" v-model="form.SWATCH_NO"
                label="Swatch No." id-prefix="swatchNo"
                :errors="errors.SWATCH_NO"
                class="mb-3" />
            <FormInput type="text" v-model="form.COLOR"
                label="Color" id-prefix="color"
                :errors="errors.COLOR"
                class="mb-1" />
            <div class="text-end mb-3">
                <button type="button" class="btn btn-sm btn-primary"
                    @click="_ => { isSelectColorPopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormInput type="number" v-model="form.QUANTITY"
                label="Quantity" id-prefix="quantity"
                :errors="errors.QUANTITY"
                class="mb-3" />
            <FormSelect v-model="form.STATUS"
                label="Status" id-prefix="status"
                :options="STATUS_OPTIONS"
                :option-value="option => option.value"
                :option-to-string="option => option.label"
                :errors="errors.STATUS"
                class="mb-3" />
            <FormInput type="date" v-model="form.ARRIVAL_DATE"
                label="Arrival Date" id-prefix="arrivalDate"
                :errors="errors.ARRIVAL_DATE" />
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary me-2"
                        :disabled="loadingFlags.has('insertRawOrderBreakdown') || loadingFlags.has('updateRawOrderBreakdown')"
                        @click="_ => {
                            if(!rawOrderBreakdown) {
                                insertRawOrderBreakdown();
                            } else {
                                updateRawOrderBreakdown();
                            }
                        }">
                        <span v-if="loadingFlags.has('insertRawOrderBreakdown') || loadingFlags.has('updateRawOrderBreakdown')"
                            class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        Save
                    </button>
                    <button type="button" class="btn btn-outline-secondary"
                        @click="reset">
                        Clear
                    </button>
                </div>
            </template>
        </Card>
    </div>

    <SelectColor :is-open="isSelectColorPopupOpen"
        @close="_ => { isSelectColorPopupOpen = false; }"
        @select="selectedColor => {
            form.COLOR = selectedColor.COLOR;
            isSelectColorPopupOpen = false;
        }" />
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import { useRawOrderBreakdowns } from '@/composables/data/rawOrderBreakdowns';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectColor from '@/components/utils/SelectColor.vue';

const props = defineProps({
    isOpen: Boolean,
    orderId: Number,
});

const emit = defineEmits(['success', 'close']);

const toasts = useToastsStore();

const { postRawOrderBreakdown, putRawOrderBreakdown } = useRawOrderBreakdowns();

const STATUS_OPTIONS = [
    {
        value: 'O',
        label: 'To Order',
    },
    {
        value: 'R',
        label: 'Received',
    },
    {
        value: 'C',
        label: 'Cancelled',
    },
];

const rawOrderBreakdown = defineModel();

const orderIdDisplay = computed(() => props.orderId);

const form = ref({
    SWATCH_NO: null,
    COLOR: null,
    QUANTITY: null,
    STATUS: null,
});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();

const isSelectColorPopupOpen = ref(false);

watch(rawOrderBreakdown, () => {
    errors.value = {};
    form.value = Object.assign({
        SWATCH_NO: null,
        COLOR: null,
        QUANTITY: null,
        STATUS: null,
    }, rawOrderBreakdown.value);
});

function reset() {
    rawOrderBreakdown.value = null;
    if(!rawOrderBreakdown.value) {
        form.value = {
            SWATCH_NO: null,
            COLOR: null,
            QUANTITY: null,
            STATUS: null,
        };
    }
    errors.value = {};
}

async function insertRawOrderBreakdown() {
    errors.value = {};

    loadingFlags.add('insertRawOrderBreakdown');
    try {
        const newRawOrderBreakdown = await postRawOrderBreakdown(props.orderId, form.value);
        toasts.add('SUCCESS', 'success', 'Successfully created raw order breakdown.');
        rawOrderBreakdown.value = newRawOrderBreakdown;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'error', e.message);
    }
    loadingFlags.delete('insertRawOrderBreakdown');
}

async function updateRawOrderBreakdown() {
    errors.value = {};

    loadingFlags.add('updateRawOrderBreakdown');
    try {
        const newRawOrderBreakdown = await putRawOrderBreakdown(props.orderId, rawOrderBreakdown.value.BREAKDOWN_ID, form.value);
        toasts.add('SUCCESS', 'success', 'Successfully updated raw order breakdown.');
        rawOrderBreakdown.value = newRawOrderBreakdown;
        emit('success');
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'error', e.message);
    }
    loadingFlags.delete('updateRawOrderBreakdown');
}
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 50%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
