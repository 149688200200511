<template>
    <fieldset class="mb-md-1 mb-3">
        <legend><i class="icon ph-bold ph-money me-2"></i>Create Loan</legend>
        <form @submit.prevent="createLoan">
            <FormSelect
                label="Loan Type"
                v-model="loan.LOANTYPE"
                :options="loanTypes"
                :option-to-string="(loanType) => loanType.LOANTYPE + ' | ' + loanType.VOUCHER_FLAG"
                :option-value="(loanType) => loanType.LOANTYPE"
                id-prefix="loanType"
                is-horizontal
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                select-class="form-select-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.LOANTYPE"
                @change="loanTypeOnChange"
                @keydown-enter="focusNextInput('dateFormInput')"
                :disabled="employee == null"
            />
            <FormInput
                type="date"
                label="Effectivity Date"
                v-model="loan.DATE"
                id-prefix="date"
                is-horizontal
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.DATE"
                @keydown-enter="focusNextInput('dateGrantedFormInput')"
                :disabled="employee == null"
            />
            <FormInput
                type="date"
                label="Date Granted"
                v-model="loan.DATE_GRANTED"
                id-prefix="dateGranted"
                is-horizontal
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.DATE_GRANTED"
                @keydown-enter="focusNextInput('initialAmountFormInput')"
                :disabled="employee == null"
            />
            <FormInput
                type="number"
                step=".01"
                label="Initial Amount"
                v-model="loan.INIT_AMT"
                :is-horizontal="true"
                id-prefix="initialAmount"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.INIT_AMT"
                @keyup="initAmtOnChange"
                @change="initAmtOnChange"
                @keydown-enter="focusNextInput('principalFormInput')"
                :disabled="employee == null"
            />
            <div class="row gx-3">
                <div class="col-8">
                    <FormInput
                        type="number"
                        step=".01"
                        label="Principal"
                        v-model="loan.PRINCIPAL"
                        :is-horizontal="true"
                        id-prefix="principal"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-6 col-md-5 label-sm align-items-center"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                        :errors="errors.PRINCIPAL"
                        @keyup="calculateKimbelLoanInitialAmount"
                        @change="calculateKimbelLoanInitialAmount"
                        @keydown-enter="focusNextInput('noOfMonthsFormInput')"
                        :disabled="employee == null"
                    />
                </div>
                <div class="col-4">
                    <FormInput
                        type="number"
                        step=".01"
                        label="Months"
                        v-model="loanNumberOfMonths"
                        :is-horizontal="true"
                        id-prefix="noOfMonths"
                        class="mb-md-1 mb-2"
                        label-class="col-md-5 label-sm align-items-center"
                        input-class="form-control-sm"
                        input-container-class="col-md-7 col-12"
                        @keyup="numberOfMonthsInputOnChange"
                        @change="numberOfMonthsInputOnChange"
                        @keydown-enter="focusNextInput('descriptionFormInput')"
                        :disabled="employee == null"
                    />
                </div>
            </div>
            <FormInput
                type="text"
                label="Description"
                v-model="loan.DESCRIPTION"
                :is-horizontal="true"
                id-prefix="description"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.DESCRIPTION"
                @keydown-enter="focusNextInput('numberOfPaymentsFormInput')"
                :disabled="employee == null"
            />
            <FormInput
                type="number"
                label="No. of Payments"
                v-model="loan.NUM_WEEKS"
                :is-horizontal="true"
                id-prefix="numberOfPayments"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.NUM_WEEKS"
                @keyup="numPaymentsInputOnChange"
                @change="numPaymentsInputOnChange"
                @keydown-enter="focusNextInput('amountPerPaymentFormInput')"
                :disabled="employee == null"
            />
            <div class="col-xl-8 offset-xl-4">
                <p class="form-text mb-2 px-2">Employee is {{ employee?.PAYTYPE }}, {{ noOfWeeksOnPaytype }} week = 1 payment</p>
            </div>
            <FormInput
                type="number"
                step="any"
                label="Amt. per Payment"
                v-model="loan.AMT_WEEK"
                :is-horizontal="true"
                id-prefix="amountPerPayment"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.AMT_WEEK"
                @keyup="amtPerPaymentInputOnChange"
                @change="amtPerPaymentInputOnChange"
                @keydown-enter="focusNextInput('voucherFormInput')"
                :disabled="employee == null"
            />
            <div class="row g-3">
                <div class="col">
                    <FormInput
                        type="text"
                        label="Voucher"
                        v-model="loan.VOUCHER"
                        :is-horizontal="true"
                        id-prefix="voucher"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-5 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-7 col-md-7 col-12 pe-0 ps-3"
                        :errors="errors.VOUCHER"
                        @keydown-enter="focusNextInput('previousLoanFormInput')"
                        :disabled="employee == null"
                    />
                </div>
                <div class="col-auto mb-md-1 mb-2">
                    <button type="button"
                        class="btn btn-sm btn-primary w-100 px-2"
                        data-bs-toggle="modal"
                        data-bs-target="#viewVoucherModal"
                        @click="viewVoucherButtonOnClick">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>View
                    </button>
                </div>
            </div>
            <FormSelect
                label="Prev. Loan"
                v-model="loan.PREV_LOANID"
                :options="prevLoans"
                :option-to-string="(loan) => [ loan.LOANID, loan.LOANTYPE, 'Amt: ' + loan.INIT_AMT ].join(' - ')"
                :option-value="(loanType) => loanType.LOANID"
                id-prefix="previousLoan"
                :is-horizontal="true"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                select-class="form-select-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.PREV_LOANID"
                @keydown-enter="focusNextInput('docnoFormInput')"
                :disabled="employee == null"
            />
            <FormInput
                type="text"
                label="DOCNO"
                v-model="loan.DOCNO"
                :is-horizontal="true"
                id-prefix="docno"
                class="mb-md-1 mb-2"
                label-class="col-xl-4 col-md-5 label-sm align-items-center"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
                :errors="errors.DOCNO"
                @keydown-enter="focusNextInput('createLoanButton')"
                :disabled="employee == null"
            />
            <div class="col-xl-8 offset-xl-4">
                <p class="form-text mb-3 px-2">
                    {{ loan.AMT_WEEK }} will be paid for {{ loan.NUM_WEEKS }} payments, or {{ loan.NUM_WEEKS ? (loan.NUM_WEEKS * noOfWeeksOnPaytype) / 4 : ""}} months
                </p>
            </div>
            <div class="col-xl-8 offset-xl-4 mt-4 text-end">
                <button type="reset"
                    class="btn btn-outline-secondary btn-sm me-1"
                    @click="clearLoanForm">
                    <i class="icon ph-bold ph-broom me-2"></i>Clear
                </button>
                <button type="submit"
                    class="btn btn-primary btn-sm"
                    :disabled="employee == null">
                    <i class="icon ph-bold ph-plus-circle me-2"></i>Create Loan
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { inject, ref, computed, onMounted, watch } from "vue";
import { useToastsStore } from "@/stores/toasts";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import axios from "axios";

import focusNextInput from "@/utils/focusnext";

const props = defineProps({
    employee: Object,
    noOfWeeksOnPaytype: Number,
});

const emit = defineEmits(['voucher-image-view']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const paymentsPerMonthDisplay = computed(() => {
    if (!props.employee) {
        return;
    }

    if (props.employee.PAYTYPE === "BIMONTHLY") {
        return 2;
    } else if (props.employee.PAYTYPE === "WEEKLY") {
        return 4;
    }
});

const errors = ref({});

const loanTypes = ref(null);
const prevLoans = ref(null);

const loanNumberOfMonths = ref(null);

const loan = ref({
    LOANTYPE: null,
    DATE: null,
    DATE_GRANTED: null,
    INIT_AMT: null,
    PRINCIPAL: null,
    DESCRIPTION: null,
    NUM_WEEKS: null, // NUM_WEEKS is actually number of payments. This variable is used for both WEEKLY/BIMONTHLY which auto adjusts.
    AMT_WEEK: null, // AMT_WEEK is actually amt per payment. This variable is used for both WEEKLY/BIMONTHLY which auto adjusts.
    VOUCHER: null,
    PREV_LOANID: null,
    DOCNO: null
});

watch(() => props.employee, clearLoanForm);

onMounted(async() => {
    try {
        const loanTypesResponse = await axios.get(route('api.loan-types.index'));
        loanTypes.value = loanTypesResponse.data.data;
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching loan types: ' +
            e.response?.data.message ?? e.message);
    }
});

async function loanTypeOnChange() {
    try {
        const prevLoansResponse = await axios.get(
            route('api.employees.loans.index', {
                employee: props.employee.EMPID,
                STATUS: 'O',
                ORDER_DESC: 'LOANID',
            }));
        prevLoans.value = prevLoansResponse.data.data;
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching previous loans: ' +
            e.response?.data.message ?? e.message);
    }
}

function updateAmtPerPayment() {
    if (!loan.value.INIT_AMT || !loan.value.NUM_WEEKS) {
        loan.value.AMT_WEEK = null;
        return;
    }

    loan.value.AMT_WEEK = loan.value.INIT_AMT / loan.value.NUM_WEEKS;
}

function updateLoanNumberOfMonths() {
    if (!loan.value.NUM_WEEKS) {
        return;
    }
    loanNumberOfMonths.value = loan.value.NUM_WEEKS * props.noOfWeeksOnPaytype / 4;
}

function initAmtOnChange() {
    if (!loan.value.LOANTYPE || !loan.value.INIT_AMT) {
        loan.value.NUM_WEEKS = null;
        return;
    }

    if (
        props.employee.DEPT.includes("EXTRA") &&
        props.employee.PAYTYPE.includes("WEEKLY") &&
        loan.value.LOANTYPE === "ITEM"
    ) {
        if (loan.value.INIT_AMT >= 1 && loan.value.INIT_AMT <= 100) {
            loan.value.NUM_WEEKS = 1;
        } else {
            loan.value.NUM_WEEKS = 2;
        }
    } else if (
        !props.employee.DEPT.includes("EXTRA") &&
        props.employee.PAYTYPE.includes("WEEKLY") &&
        loan.value.LOANTYPE === "ITEM"
    ) {
        if (loan.value.INIT_AMT >= 1 && loan.value.INIT_AMT <= 280) {
            loan.value.NUM_WEEKS = 2;
        } else if (loan.value.INIT_AMT <= 999) {
            loan.value.NUM_WEEKS = 4;
        } else {
            loan.value.NUM_WEEKS = 8;
        }
    } else if (
        props.employee.DEPT.includes("SC") &&
        props.employee.PAYTYPE.includes("BIMONTHLY") &&
        loan.value.LOANTYPE === "ITEM"
    ) {
        if (loan.value.INIT_AMT >= 1 && loan.value.INIT_AMT <= 280) {
            loan.value.NUM_WEEKS = 1;
        } else {
            loan.value.NUM_WEEKS = 2;
        }
    } else if (
       (
            props.employee.DEPT.includes("ADMIN" ||
            props.employee.DEPT.includes("SALES"))
        ) &&
        props.employee.PAYTYPE.includes("BIMONTHLY") &&
        loan.value.LOANTYPE === "ITEM"
    ) {
        if (loan.value.INIT_AMT >= 1 && loan.value.INIT_AMT <= 280) {
            loan.value.NUM_WEEKS = 1;
        } else if (loan.value.INIT_AMT >= 281 && loan.value.INIT_AMT <= 999) {
            loan.value.NUM_WEEKS = 2;
        } else {
            loan.value.NUM_WEEKS = 4;
        }
    } else if (
        props.employee.PAYTYPE.includes("BIMONTHLY") &&
        loan.value.LOANTYPE === "ITEM"
    ) {
        if (loan.value.INIT_AMT >= 1 && loan.value.INIT_AMT <= 280) {
            loan.value.NUM_WEEKS = 1;
        } else if (loan.value.INIT_AMT >= 281) {
            loan.value.NUM_WEEKS = 2;
        }
    }

    if (loan.value.NUM_WEEKS == "") {
        return;
    }

    updateLoanNumberOfMonths();
    updateAmtPerPayment();
}

function numPaymentsInputOnChange() {
    updateLoanNumberOfMonths();
    updateAmtPerPayment();
}

function calculateKimbelLoanInitialAmount() {
    if (
        loan.value.LOANTYPE === "KIMBEL" &&
        loan.value.PRINCIPAL &&
        loanNumberOfMonths.value
    ) {
        let perMonthInterest;

        if (loan.value.PRINCIPAL < 50000) {
            if (loanNumberOfMonths.value <= 12) {
                perMonthInterest = 0.01;
            } else if (loanNumberOfMonths.value < 24) {
                perMonthInterest = 0.015;
            } else {
                perMonthInterest = 0.02;
            }
        } else {
            perMonthInterest = 0.02;
        }

        loan.value.INIT_AMT = loan.value.PRINCIPAL * perMonthInterest * loanNumberOfMonths.value + loan.value.PRINCIPAL;
        initAmtOnChange();
    }
}

function numberOfMonthsInputOnChange() {
    loan.value.NUM_WEEKS = (loanNumberOfMonths.value * 4) / props.noOfWeeksOnPaytype;
    calculateKimbelLoanInitialAmount();
    updateAmtPerPayment();
}

function amtPerPaymentInputOnChange() {
    if (loan.value.INIT_AMT == null || loan.value.AMT_WEEK == null) {
        loan.value.NUM_WEEKS = "";
        return;
    }

    loan.value.NUM_WEEKS = loan.value.INIT_AMT / loan.value.AMT_WEEK;
    updateLoanNumberOfMonths();
}

async function viewVoucherButtonOnClick() {
    errors.value.VOUCHER = null;

    if (!loan.value.VOUCHER) {
        return;
    }

    if (!["SSS", "SSS CALAMITY", "PAGIBIG", "PAGIBIG CALAMITY"].includes(loan.value.LOANTYPE)) {
        try {
            const voucherResponse = await axios.get(route('api.vouchers.show', { voucher: loan.value.VOUCHER }));
            loan.value.DESCRIPTION = voucherResponse.data.DESCRIPTION;
        } catch (e) {
            console.log(e);
            if (e.response && e.response.status == 404) {
                errors.value.VOUCHER = [ "No Voucher found. Please try another." ];
            } else {
                toasts.add('ERROR', 'Error', 'Error while fetching voucher: ' +  e.response?.data.message ?? e.message);
            }
            return;
        }
    }

    emit('voucher-image-view', loan.value);
}

async function createLoan() {
    errors.value = {};

    if (props.employee.DEPT == "RESIGNED") {
        toasts.add('ERROR', 'Error', 'Cannot create loan for a RESIGNED employee.');
        return;
    }

    if (
        loan.value.PREV_LOANID &&
        !window.confirm(
            "You have selected to extend a loan. Clicking YES will close the old loan and create a new related loan. Clicking NO will do nothing. Proceed?"
        )
    ) {
        return;
    }

    loadingFlags.add("createLoan");
    try {
        await axios.post(
            route("api.employees.loans.store", { employee: props.employee.EMPID }),
            {
                ...loan.value,
                AMT_WEEK: loan.value.AMT_WEEK / props.noOfWeeksOnPaytype,
                NUM_WEEKS: loan.value.NUM_WEEKS * props.noOfWeeksOnPaytype,
            }
        );
        toasts.add("SUCCESS", "Success", "Successfully created loan.");
        emit("success");
        clearLoanForm();
    } catch (e) {
        console.log(e);
        errors.value = e.response.data.errors ?? {};
        toasts.add("ERROR", "Error", e.response.data.message ?? e.message);
    }
    loadingFlags.delete("createLoan");
}

function clearLoanForm() {
    loan.value = {
        LOANTYPE: null,
        DATE: null,
        DATE_GRANTED: null,
        INIT_AMT: null,
        PRINCIPAL: null,
        DESCRIPTION: null,
        NUM_WEEKS: null,
        AMT_WEEK: null,
        VOUCHER: null,
        PREV_LOANID: null,
        DOCNO: null
    };
}
</script>
