<template>
    <div>
        <fieldset class="mb-3">
            <legend><i class="icon ph-bold ph-info me-2"></i>Details</legend>
            <div class="row g-3 mb-3">
                <div class="col-md-6 pe-md-5">
                    <FormInput
                        type="text"
                        disabled
                        v-model="joborderNewJono"
                        label="JONO"
                        id-prefix="jono"
                        is-horizontal
                        class="mb-md-1 mb-0"
                        label-class="col-xl-2 col-md-5"
                        input-container-class="col-xl-10 col-md-7 col-12"
                    />
                </div>
                <div class="col-md-6">
                    <FormInput
                        type="text"
                        disabled
                        v-model="joborderNewAccountId"
                        label="Account"
                        id-prefix="accountId"
                        is-horizontal
                        class="mb-md-1 mb-2"
                        label-class="col-xl-2 col-md-5"
                        input-container-class="col-xl-10 col-md-7 col-12"
                    />
                </div>
            </div>
            <OrdersByStyleCard
                :joborder-new="joborderNew"
                class="mb-3"
                :refresh-flag="orderDetailsTableRefreshFlag"
                @select="(selectedDetail) => { selectedDetailFromTable = selectedDetail; }">
                <template #footer>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                                (_) => {
                                    isViewingHistory = true;
                                }
                            ">
                            <i class="icon ph-bold ph-clock-counter-clockwise me-2"></i>View History
                        </button>
                    </div>
                </template>
            </OrdersByStyleCard>
        </fieldset>
    </div>
    <OrderDetailsFormCard
        :joborder-new="joborderNew"
        :detail-to-update="selectedDetailFromTable"
        @action-done="
            (_) => {
                orderDetailsTableRefreshFlag = !orderDetailsTableRefreshFlag;
            }
        "
    />
    <JoborderDetailHistoryPopup
        :joborder-new="joborderNew"
        :refresh-flag="orderDetailsTableRefreshFlag"
        :is-open="isViewingHistory"
        @close="
            (_) => {
                isViewingHistory = false;
            }
        "
    />
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useJobordersNew } from "@/composables/data/jobordersNew";
import JoborderDetailHistoryPopup from "./JoborderDetailHistoryPopup.vue";
import FormInput from "@/components/utils/FormInput.vue";
import OrderDetailsFormCard from "./OrderDetailsFormCard.vue";
import OrdersByStyleCard from "../utils/OrdersByStyleCard.vue";

const { addFlashMessage } = useFlashMessages();

const { joborderNew, getJoborderNew } = useJobordersNew();

// Just serves as a value that the table component watches via prop for refreshing
// Flip the value to trigger refresh
const orderDetailsTableRefreshFlag = ref(false);

const joborderNewJono = computed(() => joborderNew.value?.JONO);
const joborderNewAccountId = computed(() => joborderNew.value?.ACCOUNT_ID);

const selectedDetailFromTable = ref(null);

const isViewingHistory = ref(false);

onMounted(async () => {
    try {
        await getJoborderNew(
            new URLSearchParams(window.location.search).get("JONO")
        );
    } catch (e) {
        if (e.status == 404)
            addFlashMessage("ERROR", "Unknown Joborder. Please check.");
    }
});
</script>
