<template>
    <Card>
        <template #header>
            Loans
        </template>
        <LoansTable v-model="selectedLoan"
            @filter="filters => {
                tableFilters = filters;
            }" />
        <template #footer>
            <div class="text-end">
                <Button type="button" class="btn btn-primary me-2"
                    @click="_ => { isBulkCreateLoanFormPopupOpen = true; }">
                    <i class="fas fa-plus"></i>
                    Bulk Create
                </Button>
                <Button type="button" class="btn btn-primary me-2"
                    :disabled="!selectedLoan"
                    @click="_ => { isViewLoanPaymentsTablePopupOpen = true; }">
                    <i class="fas fa-eye"></i>
                    View Payments
                </Button>
                <Button type="button" class="btn btn-primary"
                    @click="exportSpreadsheet">
                    <i class="fas fa-table"></i>
                    Export Spreadsheet
                </Button>
            </div>
        </template>
    </Card>

    <BulkCreateLoanFormPopup :is-open="isBulkCreateLoanFormPopupOpen"
        @close="_ => { isBulkCreateLoanFormPopupOpen = false; }" />

    <ViewLoanPaymentsTablePopup :loan="selectedLoan"
        :is-open="isViewLoanPaymentsTablePopupOpen"
        @close="_ => { isViewLoanPaymentsTablePopupOpen = false; }" />
</template>

<script setup>
import { ref } from 'vue';

import Button from 'primevue/button';

import LoansTable from '@/components/utils/tables/LoansTable.vue';

import Card from '@/components/utils/Card.vue';
import ViewLoanPaymentsTablePopup from './ViewLoanPaymentsTablePopup.vue';
import BulkCreateLoanFormPopup from './BulkCreateLoanFormPopup.vue';

const selectedLoan = ref(null);

const isBulkCreateLoanFormPopupOpen = ref(false);
const isViewLoanPaymentsTablePopupOpen = ref(false);

const tableFilters = ref(null);

function exportSpreadsheet() {
    window.open(route('api.loans.spreadsheet.show', {
        filters: tableFilters.value
    }));
}
</script>
