<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-user me-2"></i>Selected Employee
        </legend>
        <div class="row gx-3 gy-2">
            <div class="col-md-6">
                <input
                    type="text"
                    :value="employeeFullName"
                    class="form-control form-control-sm"
                    disabled
                />
            </div>
            <div class="col-md-6">
                <input
                    type="text"
                    :value="employee?.PAYTYPE ?? ''"
                    class="form-control form-control-sm"
                    disabled
                />
            </div>
        </div>
    </fieldset>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    employee: Object,
    class: String
});

const emit = defineEmits(['click-update-button']);

const employeeFullName = computed(() =>
    props.employee !== null ?
        `${props.employee.FNAME} ${props.employee.LNAME}` :
        ''
);
</script>
