<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select Department
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DepartmentsTable v-model="selectedDepartment" />
                </div>
                <div class="modal-padding-container text-end">
                    <button type="button" class="btn btn-primary mt-3 btn-sm"
                        :disabled="!selectedDepartment"
                        @click="_ => { emit('select', selectedDepartment); }">
                        <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Department
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import DepartmentsTable from '../tables/DepartmentsTable.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['select', 'close']);

const selectedDepartment = ref(null);
</script>
<style scoped>
.container {
    max-width: 800px;
}
</style>
