<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <form @submit.prevent="addNoBarcodeToInventoryCheck">
                <div class="d-flex justify-content-between align-items-center px-4">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-barcode me-2"></i>Insert NOBC to Inventory Check
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        @click="emit('close')"
                    ></button>
                </div>
                <hr />
                <div class="modal-container px-4">
                    <div class="modal-padding-container">
                        <FormInput
                            type="text"
                            v-model="locationDisplay"
                            disabled
                            label="Location"
                            id-prefix="locationDisplay"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-2 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-10 col-md-7 col-12"
                        />
                        <FormInput
                            type="text"
                            v-model="inventoryDoneDisplay"
                            disabled
                            label="Inventory Done"
                            id-prefix="inventoryDoneDisplay"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-2 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-10 col-md-7 col-12"
                        />
                        <FormInput
                            type="text"
                            v-model="styleDisplay"
                            disabled
                            label="Style"
                            id-prefix="styleDisplay"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-2 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-10 col-md-7 col-12"
                        />
                        <FormSelect
                            label="Color"
                            v-model="color"
                            :options="colors"
                            required
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-2 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-10 col-md-7 col-12"
                        />
                    </div>
                    <div class="modal-padding-container py-4 text-end">
                        <button type="submit"
                            class="btn btn-primary"
                            :disabled="!color">
                            <i class="icon ph-bold ph-file-plus me-2"></i>Add to Inventory Check
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useColors } from "@/composables/data/colors";
import { useInventoryChecks } from "@/composables/data/inventoryChecks";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

const props = defineProps({
    location: String,
    inventoryDone: String,
    style: String,
    isOpen: Boolean,
});

const emit = defineEmits(["close", "done", "error"]);

const locationDisplay = computed(() => props.location);
const inventoryDoneDisplay = computed(() => props.inventoryDone);
const styleDisplay = computed(() => props.style);

const { colors, getActiveColors } = useColors();

const color = ref(null);

const { postInventoryCheck } = useInventoryChecks();

async function fetchColors() {
    colors.value = null;
    try {
        await getActiveColors();
        colors.value = colors.value.map((color) => color.COLOR);
    } catch (e) {
        emit("error", e.message);
    }
}

onMounted(fetchColors);

async function addNoBarcodeToInventoryCheck() {
    try {
        await postInventoryCheck({
            LOCATION: props.location,
            INVENTORY_DONE: props.inventoryDone,
            STYLE: props.style,
            COLOR: color.value,
        });
        emit("done", "Successfully added NOBC to inventory check.");
    } catch (e) {
        emit("error", e.message);
    }
}
</script>
