import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleLandmarkSkus() {
    const styleLandmarkSku = ref(null);

    const getStyleLandmarkSku = async (style) => {
        try {
            const getStyleLandmarkSkuResponse = await axios.get(route("api.styles.landmark-sku.show", {
                style
            }));
            styleLandmarkSku.value = getStyleLandmarkSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Landmark SKU.');
        }
    };

    const putStyleLandmarkSku  = async (style, styleLandmarkSku) => {
        try {
            const putStyleLandmarkSkuResponse = await axios.put(route("api.styles.landmark-sku.update", {
                style
            }), styleLandmarkSku);
            return putStyleLandmarkSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating Landmark SKU.');
        }
    };
    
    return {
        styleLandmarkSku,
        getStyleLandmarkSku,
        putStyleLandmarkSku,
    };
}
