<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i> {{ title }}
        </legend>
        <p v-if="title === 'Duplicate Barcodes'" class="text-primary">
            **exists already or inputted twice
        </p>
        <DataTable
            :value="barcodes"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="filters"
            sort-mode="multiple"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="RAW"
                header="Raw"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                    bodyCell: { class: 'barcode' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search by Raw"
                    />
                </template>
            </Column>
            <template #footer>
                There are {{ barcodes?.length ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { useDataTableParams } from "@/composables/data/dataTableParams";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    barcodes: Array,
    title: String,
    class: String,
});

const { filters } = useDataTableParams(["RAW"]);
</script>
