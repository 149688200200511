<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-gear me-2"></i>RECON_RAW_FINAL_FULL
        </legend>
        <DataTable lazy
            :value="reconRawFinalFulls"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchReconRawFinalFulls(); }"
            v-model:selection="selectedStyleSummary"
            selection-mode="single"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="(e) => { onSort(e); fetchReconRawFinalFulls(); }"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <template #header>
                <div class="row justify-content-end mb-2">
                    <div class="col-md-6 col-lg-3">
                        <InputText
                            v-model="filters.STYLE.value"
                            @input="tableOnFilter"
                            placeholder="Search STYLE"
                            class="form-control form-control-sm"
                        />
                    </div>
                </div>
            </template>
            <Column
                field="STYLE"
                header="STYLE"
                sortable>
            </Column>
            <Column
                field="INIT_CNT"
                header="INIT"
                sortable>
            </Column>
            <Column
                field="DELV_CNT"
                header="DELV"
                sortable>
            </Column>
            <Column
                field="PO_CNT"
                header="PO"
                sortable>
            </Column>
            <Column
                field="SOLD_CNT"
                header="SOLD"
                sortable>
            </Column>
            <Column
                field="RSUB_CNT"
                header="RSUB"
                sortable>
            </Column>
            <Column
                field="RADD_CNT"
                header="RADD"
                sortable>
            </Column>
            <Column
                field="RETURN_CNT"
                header="RET"
                sortable>
            </Column>
            <Column
                field="UNKNOWN_CNT"
                header="UNKN"
                sortable>
            </Column>
            <Column
                field="PHYSICAL_COUNT"
                header="PHY"
                sortable>
            </Column>
            <Column
                field="ADJ_COUNT"
                header="ADJ"
                sortable>
            </Column>
            <Column
                field="TOTAL_COUNT"
                header="TOT"
                sortable>
            </Column>
            <Column
                field="DISC_COUNT"
                header="DISC"
                sortable>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer">
            <slot name="footer"></slot>
        </template>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useReconRawFinalFulls } from "@/composables/data/reconRawFinalFulls";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    location: String,
    timePeriod: Number,
    refreshFlag: Boolean,
    selectedReplacementBarcodeStyle: String,
    selectedMissingBarcodeStyle: String,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "INIT_CNT",
    "DELV_CNT",
    "PO_CNT",
    "SOLD_CNT",
    "RSUB_CNT",
    "RADD_CNT",
    "RETURN_CNT",
    "UNKNOWN_CNT",
    "PHYSICAL_COUNT",
    "ADJ_COUNT",
    "TOTAL_COUNT",
    "DISC_COUNT",
]);

const { reconRawFinalFulls, totalRecords, getReconRawFinalFulls } =
    useReconRawFinalFulls();

const selectedStyleSummary = defineModel();

const fetchTimeout = ref(null);

async function fetchReconRawFinalFulls() {
    reconRawFinalFulls.value = null;

    if (!props.location || !props.timePeriod) {
        return;
    }

    try {
        await getReconRawFinalFulls({
            ...params.value,
            filters: {
                ...params.value.filters,
                LOCATION: {
                    value: props.location,
                    matchMode: "equals",
                },
                TIME_PERIOD: {
                    value: props.timePeriod,
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReconRawFinalFulls, 300);
}

watch(() => [props.location, props.timePeriod], fetchReconRawFinalFulls);
watch(() => props.refreshFlag, fetchReconRawFinalFulls);

watch(
    () => props.selectedReplacementBarcodeStyle,
    async () => {
        filters.value.STYLE.value = props.selectedReplacementBarcodeStyle;
        onFilter();
        selectedStyleSummary.value = null;
        await fetchReconRawFinalFulls();
        if (props.selectedReplacementBarcodeStyle && totalRecords.value > 0) {
            selectedStyleSummary.value = reconRawFinalFulls.value[0];
        }
    }
);

watch(
    () => props.selectedMissingBarcodeStyle,
    async () => {
        filters.value.STYLE.value = props.selectedMissingBarcodeStyle;
        onFilter();
        selectedStyleSummary.value = null;
        await fetchReconRawFinalFulls();
        if (props.selectedMissingBarcodeStyle && totalRecords.value > 0) {
            selectedStyleSummary.value = reconRawFinalFulls.value[0];
        }
    }
);
</script>
