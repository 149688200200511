import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSampleDetails() {
    const sampleDetails = ref(null);
    const totalRecords = ref(null);

    const getSampleDetails = async (sampleId, params) => {
        try {
            const sampleDetailsResponse = await axios.get(route('api.samples.sample-details.index', {
                sample: sampleId,
                ...params
            }));
            sampleDetails.value = sampleDetailsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sample details.');
        }
    };
    
    const postSampleDetail = async (sampleId, sampleDetail) => {
        try {
            const sampleDetailResponse = await axios.post(route('api.samples.sample-details.store', {
                sample: sampleId
            }), sampleDetail);
            return sampleDetailResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving sample detail.');
        }
    };
    
    const putSampleDetail = async (sampleId, sampleDetailId, sampleDetail) => {
        try {
            const sampleDetailResponse = await axios.put(route('api.samples.sample-details.update', {
                sample: sampleId,
                sample_detail: sampleDetailId
            }), sampleDetail);
            return sampleDetailResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sample detail.');
        }
    };
    
    return {
        sampleDetails,
        totalRecords,
        getSampleDetails,
        postSampleDetail,
        putSampleDetail,
    };
}
