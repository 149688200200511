<template>
    <Card>
        <template #header>
            Inventory Checks
        </template>
        <DataTable
            lazy
            :value="inventoryChecks"
            :total-records="inventoryChecksTotalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchInventoryChecks(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="e => { onSort(e); fetchInventoryChecks(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="RAW" header="Raw" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="INVENTORY_DONE" header="Inventory Done" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LOCATION" header="Location" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #footer>
                {{ inventoryChecksTotalRecords ?? 0 }} total records.
            </template>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryChecks } from '@/composables/data/inventoryChecks';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import Card from '@/components/utils/Card.vue';

const props = defineProps({
    barcode: String,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'RAW',
    'LOCATION',
    'INVENTORY_DONE',
]);

const {
    inventoryChecks,
    inventoryChecksTotalRecords,
    getInventoryChecks
} = useInventoryChecks();

const fetchTimeout = ref(null);

async function fetchInventoryChecks() {
    inventoryChecks.value = null;

    if (!props.barcode) {
        inventoryChecksTotalRecords.value = null;
        return;
    }

    loadingFlags.add("fetchInventoryChecks");
    try {
        await getInventoryChecks({
            ...params.value,
            hardFilters: {
                RAW: {
                    value: props.barcode,
                    matchMode: 'equals'
                }
            }
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchInventoryChecks");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryChecks, 300);
}

onMounted(fetchInventoryChecks);

watch(() => [props.barcode, props.refreshFlag], fetchInventoryChecks);
</script>
