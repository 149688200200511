<template>
    <div class="row">
        <div class="col-12 col-lg-6 col-xl-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-users me-2"></i>Employee SM Mapping
                </legend>
                <form @submit.prevent="mappingOnSubmit">
                    <div class="row mb-xl-1 mb-2">
                        <label for="kimbelIDInput" class="col-xl-4 label-sm form-label">Kimbel Employee ID</label>
                        <div class="col-xl-8">
                            <input
                                type="text"
                                :value="employee?.EMPID"
                                disabled
                                id="kimbelIDInput"
                                class="form-control form-control-sm mb-1"
                                :class="{ 'is-invalid': errors.KIMDB_EMPID }"
                            />
                        </div>
                        <div class="col-xl-8 offset-xl-4">
                            <div class="mb-1 row gx-2">
                                <div class="col">
                                    <p v-if="employee" class="form-text">{{ employee.FNAME }} {{ employee.LNAME }}</p>
                                </div>
                                <div class="col-auto">
                                    <button type="button"
                                        class="btn btn-sm btn-primary"
                                        @click="(_) => { isSelectEmployeePopupOpen = true; }">
                                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="errors.KIMDB_EMPID" class="invalid-feedback">
                            <p v-for="error in errors.KIMDB_EMPID">{{ error }}</p>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <label for="smIDInput" class="col-xl-4 label-sm form-label">SM ID</label>
                        <div class="col-xl-8">
                            <div v-if="isLoading">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Fetching existing mapping...
                            </div>
                            <input
                                type="text"
                                v-model="nidekaEmpid"
                                id="smIDInput"
                                class="form-control form-control-sm"
                                :class="{ 'is-invalid': errors.NIDEKA_EMPID }"
                                :disabled="isLoading"
                            />
                            <div v-if="errors.NIDEKA_EMPID" class="invalid-feedback">
                                <p v-for="error in errors.NIDEKA_EMPID">{{ error }}</p>
                            </div>
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-outline-secondary btn-sm me-2"
                            @click="clearOnClick">
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                        <button type="submit"
                            class="btn btn-primary btn-sm">
                            <i class="icon ph-bold ph-floppy-disk-back me-2"></i>Save
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="_ => { isSelectEmployeePopupOpen = false; }"
        @select="(selectedEmployee) => {
            employee = selectedEmployee;
            kimbelIDInput = selectedEmployee.EMPID;
            isSelectEmployeePopupOpen = false;
        }"
    />
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useSMMappings } from "@/composables/data/employeeMapSM";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { mapping, getSMMapping, postSMMapping } = useSMMappings();

const errors = ref({});

const employee = ref(null);
const nidekaEmpid = ref(null);

const isSelectEmployeePopupOpen = ref(false);

const isLoading = ref(false);

const fetchTimeout = ref(null);

function clearOnClick() {
    employee.value = null;
    nidekaEmpid.value = null;
    errors.value = {};
}

async function fetchCurrentMapping() {
    isLoading.value = true;
    try {
        await getSMMapping(employee.value.EMPID);
        if (mapping.value) {
            nidekaEmpid.value = mapping.value.NIDEKA_EMPID;
        }
    } catch (e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

watch(employee, () => {
    nidekaEmpid.value = null;
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }

    if (employee.value) {
        fetchTimeout.value = setTimeout(fetchCurrentMapping, 300);
    }
});

async function mappingOnSubmit() {
    errors.value = {};
    try {
        await postSMMapping(employee.value.EMPID, nidekaEmpid.value);
        addFlashMessage('SUCCESS', `Successfully mapped employee ${employee.value.EMPID} -
        ${employee.value.FNAME} ${employee.value.LNAME}.`);
    } catch (e) {
        if (e.status == 422) {
            errors.value = e.errors ?? {};
        }

        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    scrollToTop();
}
</script>
