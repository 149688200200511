<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Warnings</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable
                :value="details?.warnings"
                paginator
                :rows="10"
                :pt="{ table: { class: 'table table-bordered table-hover' }}"
            >
                <Column
                    header="Warning"
                >
                    <template #body="{ data }">
                        <span class="badge bg-warning">
                            Warning
                        </span>
                    </template>
                </Column>
                <Column
                    field="line"
                    header="Line"
                />
                <Column
                    field="message"
                    header="Message"
                />
                <template #empty>
                    <div class="text-center">
                        No messages.
                    </div>
                </template>
            </DataTable>
        </Card>
    </div>    
</template>

<script setup>
import { useDataTableParams } from '@/composables/data/dataTableParams';

import Card from '@/components/utils/Card';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    details: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 40%;
}

@media (max-width: 1199.98px) {
    .pop-up .card {
        width: 60%;
    }
}

@media (max-width: 991.98px) {
    .pop-up .card {
        width: 75%;
    }
}

@media (max-width: 767.98px) {
    .pop-up .card {
        width: 100%;
    }
}
</style>
