<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-map-pin me-2"></i>Locations</legend>
        <div v-if="isTableLoading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <DataTable
            v-else
            :value="reconParms"
            paginator
            :rows="10"
            v-model:filters="filters"
            filter-display="row"
            v-model:selection="selectedLocationReconParm"
            selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="LOCATION"
                header="Location"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="RECON_RAW_ADJ_START_DATE"
                header="Start Adj"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="RECON_RAW_ADJ_END_DATE"
                header="End Adj"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="INVENTORY_DATE"
                header="Inventory Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="RECON_FLAG"
                header="Recon Flag"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-map-pin me-2"></i>No recon
                    locations.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useReconParms } from "@/composables/data/reconParms";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    timePeriod: Object,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { filters } = useDataTableParams([
    "LOCATION",
    "RECON_RAW_ADJ_START_DATE",
    "RECON_RAW_ADJ_END_DATE",
    "INVENTORY_DATE",
    "RECON_FLAG",
]);

const { reconParms, getReconParmsWithinTimePeriod } = useReconParms();

const selectedLocationReconParm = defineModel();

const isTableLoading = ref(false);

async function fetchReconParms() {
    reconParms.value = null;

    if (!props.timePeriod) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getReconParmsWithinTimePeriod(props.timePeriod.TIME_PERIOD);
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    isTableLoading.value = false;
}

watch(() => props.timePeriod, fetchReconParms);
</script>
