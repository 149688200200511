<template>
    <div v-if="hasEventsToday" :class="class">
        <div v-if="birthdayEvents?.length > 0" class="card bg-white py-4 px-3 mb-3">
            <div class="card-header">
                <h3 class="text-center mb-0 fw-bold">
                    <i class="icon ph-bold ph-cake me-4"></i>HAPPY BIRTHDAY<i class="icon ph-bold ph-cake ms-4"></i>
                </h3>
            </div>
            <div class="card-body px-4 pt-4">
                <div class="d-flex flex-wrap justify-content-center mb-3">
                    <div v-for="birthdayEvent of birthdayEvents"
                        class="p-2">
                        <img src="/img/bagnoimage.jpg"
                            height="150"
                            class="me-2" />
                    </div>
                </div>
                <div class="text-center">
                    <div v-for="birthdayEvent of birthdayEvents" class="mb-3">
                        <b>{{ birthdayEvent.associated_employee.FNAME }} {{ birthdayEvent.associated_employee.LNAME }}</b><br/>
                        {{ birthdayEvent.associated_employee.DEPT }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="anniversaryEvents?.length > 0" class="card bg-white py-4 px-3 mb-3">
            <div class="card-header">
                <h3 class="text-center mb-0 fw-bold">
                    <i class="icon ph-bold ph-gift me-4"></i>HAPPY ANNIVERSARY<i class="icon ph-bold ph-gift ms-4"></i>
                </h3>
            </div>
            <div class="card-body px-4 pt-4">
                <div class="d-flex flex-wrap justify-content-center mb-3">
                    <div v-for="anniversaryEvent of anniversaryEvents"
                        class="p-2">
                        <img src="/img/bagnoimage.jpg"
                            height="150"
                            class="me-2" />
                    </div>
                </div>
                <div class="text-center">
                    <div v-for="anniversaryEvent of anniversaryEvents" class="mb-3">
                        <b>{{ anniversaryEvent.associated_employee.FNAME }} {{ anniversaryEvent.associated_employee.LNAME }}</b><br/>
                        {{ anniversaryEvent.associated_employee.DEPT }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="regularizationEvents?.length > 0" class="card bg-white py-4 px-3 mb-3">
            <div class="card-header">
                <h3 class="text-center mb-0 fw-bold">
                    <i class="icon ph-bold ph-gift me-4"></i>REGULARIZATION<i class="icon ph-bold ph-gift ms-4"></i>
                </h3>
            </div>
            <div class="card-body px-4 pt-4">
                <div class="d-flex flex-wrap justify-content-center mb-3">
                    <div v-for="regularizationEvent of regularizationEvents"
                        class="p-2">
                        <img src="/img/bagnoimage.jpg"
                            height="150"
                            class="me-2" />
                    </div>
                </div>
                <div class="text-center">
                    <div v-for="regularizationEvent of regularizationEvents" class="mb-3">
                        <b>{{ regularizationEvent.associated_employee.FNAME }} {{ regularizationEvent.associated_employee.LNAME }}</b><br/>
                        {{ regularizationEvent.associated_employee.DEPT }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanyEvents } from "@/composables/data/companyEvents";

import moment from "moment";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { companyEvents, getCompanyEvents } = useCompanyEvents();

const birthdayEvents = ref(null);
const anniversaryEvents = ref(null);
const regularizationEvents = ref(null);

const hasEventsToday = computed(() =>
    birthdayEvents.value?.length > 0 || anniversaryEvents.value?.length > 0 || regularizationEvents.value?.length > 0
);

async function fetchEventsToday() {
    const dateToday = moment.utc().format("YYYY-MM-DD");

    loadingFlags.add("fetchEventsToday");
    try {
        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: 'BIRTHDAY',
                    matchMode: 'equals',
                },
            },
            with: 'associated_employee',
        });
        birthdayEvents.value = [...companyEvents.value];

        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: 'ANNIV',
                    matchMode: 'equals',
                },
            },
            with: 'associated_employee',
        });
        anniversaryEvents.value = [...companyEvents.value];

        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: 'REGULAR',
                    matchMode: 'equals',
                },
            },
            with: 'associated_employee',
        });
        regularizationEvents.value = [...companyEvents.value];
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEventsToday");
}

onMounted(fetchEventsToday);
</script>

<style scoped>
b {
    font-weight: bold;
}
</style>
