<template>
    <DataTable
        :loading="loadingFlags.has('fetchCompanyEvents')"
        lazy
        :value="companyEvents"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchCompanyEvents(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedCompanyEvent"
        data-key="id"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column field="id"
            header="ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="name"
            header="Event Name"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="type"
            header="Event Type"
            :pt="{
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <FormSelect v-model="filterModel.value"
                    label="Select Type"
                    id-prefix="eventTypeSearch"
                    hide-label
                    :options="companyEventTypes"
                    :option-to-string="option => option.label"
                    :option-value="option => option.value"
                    select-class="form-select-sm"
                    @change="filterCallback" />
            </template>
            <template #body="{ data }">
                {{ companyEventTypesNameMapping[data.type] }}
            </template>
        </Column>
        <Column field="event_date"
            header="Event Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="is_recurring"
            header="Is Recurring (YEARLY)"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="user.name"
            header="Created By"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useCompanyEventTypes } from '@/composables/data/companyEventTypes';
import { useCompanyEvents } from '@/composables/data/companyEvents';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import FormSelect from '../FormSelect.vue';

const props = defineProps({
    refreshFlag: null,
    class: String,
});

const { params, filters, onPage, onFilter } = useDataTableParams([
    'id',
    'name',
    'type',
    'event_date',
    'is_recurring',
    'user.name',
]);

const { companyEventTypes } =  useCompanyEventTypes();

const companyEventTypesNameMapping = computed(() =>
    companyEventTypes?.reduce((mapping, type) => ({
            ...mapping,
            [type.value]: type.label
        }), {}) ?? {}
);

const { companyEvents, totalRecords, getCompanyEvents } = useCompanyEvents();

const selectedCompanyEvent = defineModel();

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const fetchTimeout = ref(null);

async function fetchCompanyEvents() {
    companyEvents.value = null;

    loadingFlags.add("fetchCompanyEvents");
    try {
        await getCompanyEvents(params.value);
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("fetchCompanyEvents");
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchCompanyEvents, 300);
}

onMounted(fetchCompanyEvents);

watch(() => props.refreshFlag, fetchCompanyEvents);
</script>
