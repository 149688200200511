<template>
    <div class="row g-3">
        <div class="col-xl-4">
            <fieldset :class="class">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select a Location
                </legend>
                <LocationSelectTableCard v-model="selectedLocation" />
            </fieldset>
        </div>
        <div class="col-xl-8">
            <InventoryCheckDetailsCard :location="selectedLocation?.LOCATION" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import LocationSelectTableCard from '@/components/utils/LocationSelectTableCard.vue';
import InventoryCheckDetailsCard from './InventoryCheckDetailsCard.vue';

const selectedLocation = ref(null);
</script>
