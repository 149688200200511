<template>
    <div class="pop-up justify-content-center align-items-center"
        :class="{ 'd-none': !isOpen, 'd-flex': isOpen }">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between">
                    <span>Select Return to Main Comment</span>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <CommentsTable v-model="selectedComment"
                :filters="{
                    COMMENT_TEXT: {
                        value: 'Pull-out for Kimbel factory (for MAIN_1 recon)',
                        matchMode: 'equals',
                    },
                }" />
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedComment"
                        @click="emit('select', selectedComment)">
                        Select
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card';
import CommentsTable from '@/components/utils/tables/CommentsTable.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['select']);

const selectedComment = ref(null);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 100%;
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}
</style>
