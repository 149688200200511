<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-pencil-ruler me-2"></i>Select Margins
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <FormInput
                        type="number"
                        required
                        label="Top:"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-9 col-12"
                        is-horizontal
                        label-class="col-xl-4 col-md-3 label-sm align-items-center"
                        v-model="margin.top"
                    />
                    <FormInput
                        type="number"
                        required
                        label="Left:"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-9 col-12"
                        is-horizontal
                        label-class="col-xl-4 col-md-3 label-sm align-items-center"
                        v-model="margin.left"
                    />
                    <FormInput
                        type="number"
                        required
                        label="Right:"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-9 col-12"
                        is-horizontal
                        label-class="col-xl-4 col-md-3 label-sm align-items-center"
                        v-model="margin.right"
                    />
                    <FormInput
                        type="number"
                        required
                        label="Bottom:"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-9 col-12"
                        is-horizontal
                        label-class="col-xl-4 col-md-3 label-sm align-items-center"
                        v-model="margin.bottom"
                    />

                    <div class="text-end mt-3">
                        <button type="button"
                            class="btn btn-primary"
                            @click="generateReport">
                            <i class="icon ph-bold ph-gear me-2"></i>Generate Comment Summary
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    commentId: Number,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const margin = ref({
    top: 10,
    left: 10,
    right: 10,
    bottom: 20,
});

function generateReport() {
    window.open(
        route(`api.reports.inventory.comment-report-generator`, {
            REPORT_TYPE: "comment-summary",
            COMMENT_ID: props.commentId,
            MARGIN_TOP: margin.value.top,
            MARGIN_LEFT: margin.value.left,
            MARGIN_RIGHT: margin.value.right,
            MARGIN_BOTTOM: margin.value.bottom,
        }),
        "_blank"
    );
}
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
