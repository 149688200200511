<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-paint-brush me-2"></i>Select Style
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        paginator
                        :rows="10"
                        :value="usedStyles"
                        filter-display="row"
                        v-model:filters="filters"
                        v-model:selection="selectedStyle"
                        selection-mode="single"
                        data-key="STYLE"
                        :row-class="(data) => [{ 'bg-primary text-white' : data.STYLE == selectedStyle?.STYLE }]"
                        :pt="{ table: { class: 'table table-bordered table-hover' }}">
                        <Column
                            field="STYLE"
                            header="Style"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input.enter="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Style"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-3">
                                <i class="icon ph-bold ph-database me-2"></i>No
                                styles.
                            </div>
                        </template>
                    </DataTable>
                    <div class="text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary mx-lg-4"
                            :disabled="!selectedStyle"
                            @click=" (_) => { emit('select', selectedStyle); }">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Style
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useJoborderDetails } from "@/composables/data/joborderDetails";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    accountId: Number,
    isOpen: Boolean,
});

const emit = defineEmits("select");

const { usedStyles, getUsedStyles } = useJoborderDetails();

const filters = ref({
    STYLE: { value: null, matchMode: "contains" },
});

const selectedStyle = ref(null);

watch(
    () => props.accountId,
    async () => {
        usedStyles.value = null;
        if (!props.accountId) return;
        await getUsedStyles(props.accountId);
    }
);
</script>
