import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconMainWarehouseInfoGenerations() {
    const reconMainWarehouseInfoGenerations = ref(null);
    const totalRecords = ref(null);

    const getReconMainWarehouseInfoGenerations = async (params) => {
        try {
            const getReconMainWarehouseInfoGenerations = await axios.get(route('api.recon-main-warehouse-info-generations.index', params));
            reconMainWarehouseInfoGenerations.value = getReconMainWarehouseInfoGenerations.data.data;
            totalRecords.value = getReconMainWarehouseInfoGenerations.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching recon main warehouse generations.');
        }
    };

    const postReconMainWarehouseInitialization = async (inventoryDate) => {
        try {
            const postReconMainWarehouseInitializationResponse = await axios.post(route('api.recon-main-warehouse.initializations.store'), {
                inventory_date: inventoryDate
            });
            return postReconMainWarehouseInitializationResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while initializating recon main warehouse information.');
        }
    };

    return {
        reconMainWarehouseInfoGenerations,
        totalRecords,
        getReconMainWarehouseInfoGenerations,
        postReconMainWarehouseInitialization,
    };
}
