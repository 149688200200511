<style scoped>
#employeesTableContainer {
    max-height: 300px;
    overflow-y: scroll;
}
</style>

<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-user me-2"></i>Select an Employee
        </legend>
        <form @submit.prevent="fetchEmployees">
            <!-- Filters -->
            <div class="row gx-5 mb-3">
                <!-- Time Period-->
                <div class="col-md-6 mb-md-1 mb-2">
                    <div class="row">
                        <label
                            for="timePeriodFilter"
                            class="col-xl-3 col-md-5 label-sm align-items-center"
                        >
                            Time Period
                        </label>
                        <div class="col-xl-8 col-md-7 col-12">
                            <select
                                v-model="selectedTimePeriod"
                                class="form-select form-select-sm"
                                id="timePeriodFilter"
                                @change="timePeriodFilterOnChange"
                            >
                                <option disabled selected :value="null">SELECT OPEN TIME PERIOD</option>
                                <option v-for="timePeriod in openTimePeriodsList" :value="timePeriod">
                                    {{ timePeriod.TIME_PERIOD }} -
                                    {{ timePeriod.PAYTYPE }} -
                                    {{ timePeriod.START_DATE }} to
                                    {{ timePeriod.END_DATE }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Department -->
                <div class="col-md-6 mb-md-1 mb-2">
                    <div class="row gx-1">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="selectedDepartmentDisplay"
                                disabled
                                label="Department (optional)"
                                id-prefix="departmentFilter"
                                label-class="col-md-5 label-sm align-items-center"
                                input-class="form-control-sm"
                                input-container-class="col-md-7 col-12"
                                is-horizontal
                            />
                        </div>
                        <div class="col-auto">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary px-2"
                                @click="(_) => { isSelectDepartmentPopupOpen = true; }"
                            >
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Employee First Name -->
                <div class="col-md-6 mb-md-1 mb-2">
                    <div class="row">
                        <label
                            for="firstNameFilter"
                            class="col-xl-3 col-md-5 label-sm align-items-center"
                        >
                            First Name
                        </label>
                        <div class="col-xl-8 col-md-7 col-12">
                            <input
                                type="text"
                                v-model="firstNameFilter"
                                id="firstNameFilter"
                                class="form-control form-control-sm"
                                placeholder="Specify the first name..."
                            />
                        </div>
                    </div>
                </div>
                <!-- Employee Last Name -->
                <div class="col-md-6 mb-md-1 mb-2">
                    <div class="row">
                        <label
                            for="lastNameFilter"
                            class="col-xl-4 col-md-5 label-sm align-items-center"
                        >
                            Last Name
                        </label>
                        <div class="col-xl-8 col-md-7 col-12">
                            <input
                                type="text"
                                v-model="lastNameFilter"
                                id="lastNameFilter"
                                class="form-control form-control-sm"
                                placeholder="Specify the last name..."
                            />
                        </div>
                    </div>
                </div>
                <!-- Load Button -->
                <div class="col-md-6 mt-3">
                    <button
                        type="submit"
                        class="btn btn-primary btn-sm me-2 mb-2"
                        :disabled="!selectedTimePeriod"
                    >
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Load
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm me-2 mb-2"
                        @click="clearButtonOnClick"
                    >
                        <i class="icon ph-bold ph-broom me-2"></i>Clear
                    </button>
                </div>
            </div>
        </form>
        <hr class="my-3" />
        <!-- Employees List Based on Filter -->
        <DataTable
            :loading="loadingFlags.has('fetchEmployees')"
            lazy
            :value="employees"
            :total-records="totalRecords"
            paginator
            :rows="ROWS"
            @page="
                (e) => {
                    onPage(e);
                    fetchEmployees();
                }
            "
            selection-mode="single"
            v-model:selection="selectedEmployee"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column field="EMPID" header="Employee ID" />
            <Column field="PAYTYPE" header="Paytype" />
            <Column field="DEPT" header="Department" />
            <Column field="FNAME" header="First Name" />
            <Column field="LNAME" header="Last Name" />
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
         </DataTable>
    </fieldset>
    <SelectDepartmentPopup
        :is-open="isSelectDepartmentPopupOpen"
        @close="(_) => { isSelectDepartmentPopupOpen = false; }"
        @select="(department) => {
            selectedDepartment = department;
            isSelectDepartmentPopupOpen = false;
        }"
    />
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import SelectDepartmentPopup from '@/components/utils/popups/SelectDepartmentPopup.vue';

const ROWS = 9;

const props = defineProps({
    class: String
});

const emit = defineEmits(['time-period-select', 'employee-select', 'error']);

const { employees, totalRecords, getEmployees } = useEmployees();

const { params, onPage } = useDataTableParams([
    'EMPID', 'PAYTYPE', 'DEPT', 'FNAME', 'LNAME'
], { rows: ROWS });

const loadingFlags = useLoadingFlagsStore();

const openTimePeriodsList = ref(null);
const departmentsList = ref(null);

const selectedTimePeriod = ref(null);
const selectedDepartment = ref(null);
const selectedDepartmentDisplay = computed(() => selectedDepartment.value?.DEPT);

const firstNameFilter = ref(null);
const lastNameFilter = ref(null);

const selectedEmployee = ref(null);

const isSelectDepartmentPopupOpen = ref(false);

onMounted(async function () {
    loadingFlags.add('fetchTimePeriodsAndDepartments');

    try {
        const timePeriodsResponse = await axios.get(route('api.time-periods.index', {
            filters: {
                PAYTYPE: {
                    value: ['WEEKLY', 'BIMONTHLY'],
                    matchMode: 'in',
                },
                STATUS: {
                    value: 'O',
                    matchMode: 'equals',
                }
            },
        }));
        openTimePeriodsList.value = timePeriodsResponse.data.data;

        const departmentsResponse = await axios.get(route('api.departments.index', {
            rows: 0
        }));
        departmentsList.value = departmentsResponse.data.data.filter(department => department.DEPT != 'RESIGNED');
    } catch (e) {
        console.log(e);
        emit('error', 'An error occurred while initializing time periods/departments. Contact your administrator.');
    }

    loadingFlags.delete('fetchTimePeriodsAndDepartments');
});

function timePeriodFilterOnChange() {
    employees.value = null;
    emit('time-period-select', selectedTimePeriod.value);
}

function clearButtonOnClick() {
    selectedTimePeriod.value = null;
    selectedDepartment.value = null;
    employeeFirstNameFilter.value = "";
    employeeLastNameFilter.value = "";
    selectedEmployee.value = null;
    employees.value = null;
    emit('clear');
}

async function fetchEmployees() {
    loadingFlags.add('fetchEmployees');
    try {
        await getEmployees({
            ...params.value,
            filters: {
                PAYTYPE: {
                    value: selectedTimePeriod.value.PAYTYPE,
                    matchMode: 'equals',
                },
                DEPT: {
                    value: selectedDepartment.value?.DEPT,
                    matchMode: 'equals',
                },
                FNAME: {
                    value: firstNameFilter?.value,
                    matchMode: 'contains',
                },
                LNAME: {
                    value: lastNameFilter?.value,
                    matchMode: 'contains',
                },
            },
            hardFilters: {
                DEPT: {
                    value: 'RESIGNED',
                    matchMode: 'notEquals',
                },
            }
        });
    } catch (e) {
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployees');
}

watch(selectedEmployee, () => {
    if (selectedEmployee.value) {
        emit('employee-select', selectedEmployee.value);
    }
})
</script>
