<template>
    <div class="row">
        <div class="col-xl-8 col-lg-12">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Materials
                </legend>
                <DataTable
                    lazy
                    :value="materials"
                    :total-records="totalRecords"
                    paginator
                    :rows="15"
                    filter-display="row"
                    v-model:filters="filters"
                    v-model:selection="selectedMaterial"
                    selection-mode="single"
                    data-key="id"
                    @page="(e) => onPage(e)"
                    @filter="(e) => onFilter(e)"
                    :row-class="(data) => [{ 'bg-primary text-white': data.id == selectedMaterial?.id}]"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}"
                    class="py-4">
                    <Column
                        field="MATERIAL"
                        header="Material"
                        :pt="{
                            filterInput: {class: 'input-group input-group-sm'},
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Material"
                            />
                        </template>
                    </Column>
                    <Column
                        field="COLOR"
                        header="Color"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Color"
                            />
                        </template>
                    </Column>
                    <Column
                        field="UNIT"
                        header="Unit"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Unit"
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRICE"
                        header="Price"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Price"
                            />
                        </template>
                    </Column>
                    <Column
                        field="supplier_id"
                        header="Supplier"
                        filter-field="relation_supplier_ACCOUNT_NAME"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Supplier"
                            />
                        </template>
                        <template #body="slotProps">
                            {{ slotProps.data.supplier?.ACCOUNT_NAME }}
                        </template>
                    </Column>
                    <Column
                        field="CREATED_BY"
                        header="Created By"
                        :pt="{ filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @keydown="filterCallback()"
                                class="form-control"
                                placeholder="Search by Created by"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No materials.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-xl-4 col-lg-12">
            <form @submit.prevent="insertMaterial">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-file-text me-2"></i>
                        Material Form
                    </legend>
                    <FormInput
                        type="text"
                        label="Material"
                        v-model="material.MATERIAL"
                        :errors="errors.MATERIAL"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                    />
                    <FormInput
                        type="text"
                        label="Color"
                        v-model="material.COLOR"
                        :errors="errors.COLOR"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                    />
                    <FormSelect
                        label="Unit"
                        :options="units"
                        :option-value="(unit) => unit.value"
                        :option-to-string="(unit) => [unit.value, unit.verbose].join(' | ')"
                        v-model="material.UNIT"
                        :errors="errors.UNIT"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                    />
                    <FormInput
                        type="number"
                        step=".01"
                        label="Price"
                        v-model="material.PRICE"
                        :errors="errors.PRICE"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                    />
                    <FormInput
                        type="number"
                        label="Supplier"
                        v-model="material.supplier_id"
                        disabled
                        :errors="errors.supplier_id"
                        :form-text="selectedRelationships.supplier?.ACCOUNT_NAME"
                        class="mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                    />
                    <div class="text-end mb-5">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click=" (_) => { isSelectingAccount = true; }">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>
                            Select Account
                        </button>
                    </div>
                    <hr class="my-4" />
                    <div class="row pe-xl-2">
                        <div class="col-xl-6 col-lg-3 col-md-6 col-12 mb-2 pe-xl-0">
                            <button type="reset"
                                class="btn btn-outline-secondary btn-sm w-100"
                                @click="reset">
                                <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>
                                Reset
                            </button>
                        </div>
                        <div class="col-xl-6 col-lg-3 col-md-6 col-12 mb-2 pe-xl-0">
                            <button type="button"
                                class="btn btn-outline-danger btn-sm w-100"
                                :disabled="!material.id"
                                @click="destroyMaterial">
                                <i class="icon ph-bold ph-trash me-2"></i>
                                Delete
                            </button>
                        </div>
                        <div class="col-xl-6 col-lg-3 col-md-6 col-12 mb-2 pe-xl-0">
                            <button type="submit"
                                class="btn btn-success btn-sm w-100"
                                :disabled="material.id">
                                <i class="icon ph-bold ph-file-plus me-2"></i>
                                Insert
                            </button>
                        </div>
                        <div class="col-xl-6 col-lg-3 col-md-6 col-12 mb-2 pe-xl-0">
                            <button type="button"
                                class="btn btn-primary btn-sm w-100"
                                :disabled="!material.id"
                                @click="updateMaterial">
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>
                                Update
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    <SelectAccount
        :is-open="isSelectingAccount"
        :on-select-account="
            (selectedAccount) => {
                material.supplier_id = selectedAccount.ACCOUNT_ID;
                selectedRelationships.supplier = selectedAccount;
            }
        "
        :on-close="(_) => { isSelectingAccount = false; }"
    />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useMaterials } from "@/composables/data/materials";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectAccount from "@/components/utils/SelectAccount.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const units = [
    { value: "P", verbose: "Pieces" },
    { value: "M", verbose: "Meters" },
];

const {
    materials,
    totalRecords,
    getMaterials,
    postMaterial,
    putMaterial,
    deleteMaterial,
} = useMaterials();

const { params, filters, onPage, onFilter } = useDataTableParams(
    [
        "MATERIAL",
        "COLOR",
        "UNIT",
        "PRICE",
        "CREATED_BY",
        "relation_supplier_ACCOUNT_NAME",
    ],
    { include_relations: "supplier" }
);

const selectedMaterial = ref();

const errors = ref({});

const material = ref({
    MATERIAL: null,
    COLOR: null,
    UNIT: null,
    PRICE: null,
    supplier_id: null,
});

const selectedRelationships = ref({
    supplier: null,
});

const isSelectingAccount = ref(false);

async function fetchMaterials() {
    try {
        await getMaterials(params.value);
    } catch (e) {
        handleFetchErrors(e, "Error while fetching materials.");
        scrollToTop();
    }
}

onMounted(fetchMaterials);

watch(params, fetchMaterials, { deep: true });

watch(selectedMaterial, () => {
    errors.value = {};
    material.value = Object.assign({}, selectedMaterial.value);
    selectedRelationships.value.supplier = selectedMaterial.value?.supplier;
});

function reset() {
    selectedMaterial.value = null;
    material.value = {
        MATERIAL: null,
        COLOR: null,
        UNIT: null,
        PRICE: null,
    };
}

async function insertMaterial() {
    errors.value = {};
    try {
        const savedMaterial = await postMaterial(material.value);
        selectedMaterial.value = savedMaterial;
        addFlashMessage("SUCCESS", "Successfully saved material.");
        fetchMaterials();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
}

async function updateMaterial() {
    errors.value = {};
    try {
        const updatedMaterial = await putMaterial(
            material.value.id,
            material.value
        );
        selectedMaterial.value = updatedMaterial;
        addFlashMessage("SUCCESS", "Successfully updated material.");
        fetchMaterials();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
}

async function destroyMaterial() {
    errors.value = {};

    if (window.prompt('Type "DELETE" to proceed.') != "DELETE") {
        addFlashMessage("WARNING", "Invalid keyword. Did not proceed.");
        return;
    }

    try {
        await deleteMaterial(material.value.id);
        selectedMaterial.value = null;
        addFlashMessage("SUCCESS", "Successfully deleted material.");
        fetchMaterials();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}
</script>
