<template>
    <DataTable :loading="isLoading"
        lazy
        :value="styles"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => { onPage(e); fetchStyles();  }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        v-model:selection="style"
        selection-mode="single"
        :pt="{ table: { class: tableClass } }">
        <Column
            field="STYLE"
            header="Style"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Style"
                />
            </template>
        </Column>
        <Column
            field="STYLE_TYPE"
            header="Style Type"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Style Type"
                />
            </template>
        </Column>
        <Column
            field="CREATED_BY"
            header="Created By"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Created by"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No styles.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useStyles } from "@/composables/data/styles";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    presetFilters: Object,
    tableClass: String,
});

const emit = defineEmits(["error"]);

const tableClass = computed(() => {
    let tableClass = "table table-bordered table-hover";
    if (props.tableClass) tableClass += " " + props.tableClass;
    return tableClass;
});

const style = defineModel();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "STYLE",
    "STYLE_TYPE",
    "CREATED_BY",
]);

const { styles, totalRecords, getStyles } = useStyles();

const fetchTimeout = ref(null);

const isLoading = ref(false);

async function fetchStyles() {
    styles.value = null;
    isLoading.value = true;
    try {
        await getStyles(params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

onMounted(fetchStyles);

watch(
    () => props.presetFilters,
    () => {
        if (props.presetFilters) {
            filters.value = { ...filters.value, ...props.presetFilters };
            onFilter();
        }

        fetchStyles();
    }
);

function tableOnFilter(e) {
    onFilter(e);
    style.value = null;
    if (fetchTimeout.value) clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchStyles, 300);
}
</script>
