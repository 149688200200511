import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useRemittanceAdvice() {
    const postRemittanceAdvice = async (timePeriod, remittanceAdviceFiles) => {
        try {
            const postRemittanceAdviceResponse =
                await axios.post(route('api.remittance-advice.store', {
                    time_period: timePeriod
                }), remittanceAdviceFiles);
            return postRemittanceAdviceResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while uploading remittance advice.');
        }
    };

    return {
        postRemittanceAdvice
    };
}
