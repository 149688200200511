import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function usePayslips() {
    const payslips = ref(null);

    const getPayslips = async (params) => {
        try {
            const getPayslipsResponse = await axios.get(route('api.payslips.index', params));
            payslips.value = getPayslipsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching payslips.');
        }
    };
    
    return { payslips, getPayslips };
}
