import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useAccounts() {
    const accounts = ref(null);

    const getAccounts = async (params) => {
        try {
            const getAccountsResponse = await axios.get(route('api.accounts.index', params));
            accounts.value = getAccountsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching accounts.');
        }
    };

    return {
        accounts,
        getAccounts,
    };
}
