import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useInventoryChecks() {
    const inventoryChecks = ref(null);
    const inventoryChecksTotalRecords = ref(null);

    const inventoryChecksInReconSameMonth = ref(null);
    const inventoryChecksInReconSameMonthTotalRecords = ref(null);

    const inventoryChecksNotInReconSameMonth = ref(null);
    const inventoryChecksNotInReconSameMonthTotalRecords = ref(null);

    const inventoryChecksSameStyleDiffMonthNoBarcode = ref(null);
    const inventoryChecksSameStyleDiffMonthNoBarcodeTotalRecords = ref(null);

    const getInventoryChecks = async (params) => {
        try {
            const getInventoryChecksResponse = await axios.get(route('api.inventory-checks.index', params));
            inventoryChecks.value = getInventoryChecksResponse.data.data;
            inventoryChecksTotalRecords.value = getInventoryChecksResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check data.');
        }
    };

    const getInventoryChecksOfBarcode = async (raw, params) => {
        try {
            const getInventoryChecksOfBarcodeResponse = await axios.get(route('api.inventory-checks.by-barcode.index', {
                RAW: raw,
                ...params
            }));
            inventoryChecks.value = getInventoryChecksOfBarcodeResponse.data.data;
            inventoryChecksTotalRecords.value = getInventoryChecksOfBarcodeResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory checks.');
        }
    };

    const postMultipleInventoryChecks = async (location, inventoryDone, barcodes, noBarcodes) => {
        try {
            const postMultipleInventoryCheckResponse = await axios.post(route('api.inventory-checks.flock.store'), {
                LOCATION: location,
                INVENTORY_DONE: inventoryDone,
                barcodes: barcodes,
                no_barcodes: noBarcodes,
            });
            return postMultipleInventoryCheckResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving inventory checks.');
        }
    };

    const getInventoryChecksInReconSameMonth = async (timePeriod, location, inventoryDone, style, params) => {
        try {
            const getInventoryChecksInReconSameMonthResponse = await axios.get(
                route('api.inventory-checks.in-recon-same-month.index', {
                    TIME_PERIOD: timePeriod,
                    LOCATION: location,
                    INVENTORY_DONE: inventoryDone,
                    STYLE: style,
                    ...params
                })
            );
            inventoryChecksInReconSameMonth.value = getInventoryChecksInReconSameMonthResponse.data.data;
            inventoryChecksInReconSameMonthTotalRecords.value = getInventoryChecksInReconSameMonthResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check data.');
        }
    };

    const getInventoryChecksNotInReconSameMonth = async (timePeriod, location, inventoryDone, style, params) => {
        try {
            const getInventoryChecksNotInReconSameMonthResponse = await axios.get(
                route('api.inventory-checks.not-in-recon-same-month.index', {
                    TIME_PERIOD: timePeriod,
                    LOCATION: location,
                    INVENTORY_DONE: inventoryDone,
                    STYLE: style,
                    ...params
                })
            );
            inventoryChecksNotInReconSameMonth.value = getInventoryChecksNotInReconSameMonthResponse.data.data;
            inventoryChecksNotInReconSameMonthTotalRecords.value = getInventoryChecksNotInReconSameMonthResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check data.');
        }
    };

    const getInventoryChecksSameStyleDiffMonthNoBarcode = async (location, style, params) => {
        try {
            const getInventoryChecksSameStyleDiffMonthNoBarcodeResponse = await axios.get(
                route('api.inventory-checks.same-style-different-months-no-barcode.index', {
                    LOCATION: location,
                    STYLE: style,
                    ...params
                })
            );
            inventoryChecksSameStyleDiffMonthNoBarcode.value =
                getInventoryChecksSameStyleDiffMonthNoBarcodeResponse.data.data;
            inventoryChecksSameStyleDiffMonthNoBarcodeTotalRecords.value =
                getInventoryChecksSameStyleDiffMonthNoBarcodeResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching inventory check data.');
        }
    };

    const postInventoryCheck = async(inventoryCheck) => {
        try {
            const postInventoryCheckResponse = await axios.post(route('api.inventory-checks.store'), inventoryCheck);
            return postInventoryCheckResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while storing inventory check.');
        }
    };

    const postMalformedToStyleColorValidation = async (barcodes) => {
        try {
            const postMalformedToStyleColorValidationResponse = await axios.post(route('api.inventory-checks.malformed-to-style-color-validations.store'), {
                barcodes,
            });
            return postMalformedToStyleColorValidationResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while validating malformed barcodes.');
        }
    };

    const updateInventoryCheck = async (inventoryCheckId, inventoryCheck) => {
        try {
            const updateInventoryCheckResponse = await axios.put(route('api.inventory-checks.update', {
                inventory_check: inventoryCheckId,
            }), inventoryCheck);
            return updateInventoryCheckResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating inventory check.');
        }
    };

    const deleteInventoryCheck = async (inventoryCheckId) => {
        try {
            const deleteInventoryCheckResponse = await axios.delete(route('api.inventory-checks.destroy', {
                inventory_check: inventoryCheckId,
            }));
            return deleteInventoryCheckResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting inventory check.');
        }
    };

    const deleteDuplicateInventoryChecks = async () => {
        try {
            const deleteDuplicateInventoryChecksResponse = await axios.delete(route('api.inventory-checks.duplicates.delete'));
            return deleteDuplicateInventoryChecksResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting duplicate inventory checks.');
        }
    };
    
    return {
        inventoryChecks,
        inventoryChecksTotalRecords,
        getInventoryChecks,
        getInventoryChecksOfBarcode,
        inventoryChecksInReconSameMonth,
        inventoryChecksInReconSameMonthTotalRecords,
        getInventoryChecksInReconSameMonth,
        inventoryChecksNotInReconSameMonth,
        inventoryChecksNotInReconSameMonthTotalRecords,
        getInventoryChecksNotInReconSameMonth,
        inventoryChecksSameStyleDiffMonthNoBarcode,
        inventoryChecksSameStyleDiffMonthNoBarcodeTotalRecords,
        getInventoryChecksSameStyleDiffMonthNoBarcode,
        postInventoryCheck,
        postMultipleInventoryChecks,
        postMalformedToStyleColorValidation,
        updateInventoryCheck,
        deleteInventoryCheck,
        deleteDuplicateInventoryChecks
    };
}
