import { defineStore } from "pinia";

import { useLoadingFlagsStore } from "./loadingFlags";
import { useToastsStore } from "./toasts";
import { useLoggedInUser } from "@/composables/data/loggedInUser";

import { ref } from "vue";

export const useLoggedInUserStore = defineStore('loggedInUser', () => {
    const loadingFlags = useLoadingFlagsStore();
    const toasts = useToastsStore();

    const user = ref(null);

    const initialize = async () => {
        const { loggedInUser, getLoggedInUser } = useLoggedInUser();
        loadingFlags.add('fetchLoggedInUser');
        try {
            await getLoggedInUser();
            user.value = loggedInUser.value;
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete('fetchLoggedInUser');
    };

    initialize();

    return {
        user,
        initialize,
    };
});
