<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-user me-2"></i>Select Employee
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :loading="isLoading"
                        :value="employees"
                        paginator
                        :rows="10"
                        v-model:filters="filters"
                        filter-display="row"
                        v-model:selection="selectedEmployee"
                        selection-mode="single"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }">
                        <Column
                            field="EMPID"
                            header="ID"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="FNAME"
                            header="First Name"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="LNAME"
                            header="Last Name"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DEPT"
                            header="Dept"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <template #loading>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Loading employees...
                        </template>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No employees.
                            </div>
                        </template>
                    </DataTable>
                </div>
                <div class="modal-padding-container text-end">
                    <button type="button"
                        class="btn btn-primary mx-lg-4 mt-3"
                        :disabled="!selectedEmployee"
                        @click="
                            (_) => {
                                emit('select', selectedEmployee);
                            }
                        ">
                        <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Employee
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    isOpen: Boolean,
    startTimePeriod: Number,
    endTimePeriod: Number,
    location: String,
});

const emit = defineEmits(['select', 'error', 'close']);

const { filters } = useDataTableParams(['EMPID', 'FNAME', 'LNAME', 'DEPT']);

const { employees, getEmployeesEligibleForCommission } = useEmployees();

const selectedEmployee = ref(null);

const isLoading = ref(false);

watch(() => [props.startTimePeriod, props.endTimePeriod, props.location], async () => {
    employees.value = null;

    if (!props.startTimePeriod || !props.endTimePeriod || !props.location) {
        return;
    }

    isLoading.value = true;
        try {
            await getEmployeesEligibleForCommission(props.startTimePeriod, props.endTimePeriod, props.location);
        } catch(e) {
            emit('error', e.message);
        }
        isLoading.value = false;
    });
</script>
