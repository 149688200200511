<template>
    <fieldset class="mb-3">
        <legend>
            Filters
        </legend>
        <div class="row g-3 mb-3">
            <div class="col-lg-4">
                <FormInput
                    type="text"
                    v-model="filterForm.EMPID.value"
                    disabled
                    label="Employee ID"
                    id-prefix="employeeId"
                    :form-text="selectedObjects.employee ? `${selectedObjects.employee.FNAME} ${selectedObjects.employee.LNAME}` : null"
                    class="mb-1"
                />
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="(_) => { isSelectEmployeePopupOpen = true; }"
                    >
                        Select
                    </button>
                </div>
            </div>
            <div class="col-lg-4">
                <FormInput
                    type="date"
                    v-model="filterForm.DATE.value[0]"
                    label="Start Date"
                    id-prefix="startDate"
                />
            </div>
            <div class="col-lg-4">
                <FormInput
                    type="date"
                    v-model="filterForm.DATE.value[1]"
                    label="End Date"
                    id-prefix="endDate"
                    class="mb-1"
                />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="(_) => { isSelectTimePeriodPopupOpen = true; }">
                        Select Time Period
                    </button>
                </div>
            </div>
            <div class="col-lg-4">
                <FormInput
                    type="text"
                    v-model="filterForm.JONO.value"
                    label="JONO"
                    id-prefix="jono"
                />
            </div>
            <div class="col-lg-4">
                <FormInput
                    type="text"
                    v-model="filterForm.STYLE.value"
                    label="STYLE"
                    id-prefix="style"
                    class="mb-1"
                />
                <div class="text-end">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="(_) => { isSelectJoborderPopupOpen = true; }">
                        Select Joborder
                    </button>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-secondary me-2"
                @click="clearFilters"
            >
                Clear Form
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="applyFilters"
            >
                Apply Filters
            </button>
        </div>
    </fieldset>
    <fieldset>
        <legend>
            Employee Hours
        </legend>
        <EmployeeHoursTable
            :filters="filters"
        />
    </fieldset>

    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="(_) => { isSelectEmployeePopupOpen = false; }"
        @select="(employee) => {
            filterForm.EMPID.value = employee.EMPID;
            selectedObjects.employee = employee;
            isSelectEmployeePopupOpen = false;
        }"
    />

    <SelectTimePeriodPopup
        :is-open="isSelectTimePeriodPopupOpen"
        can-select-paytype
        @close="(_) => { isSelectTimePeriodPopupOpen = false; }"
        @select="(timePeriod) => {
            filterForm.DATE.value = [timePeriod.START_DATE, timePeriod.END_DATE];
            isSelectTimePeriodPopupOpen = false;
        }"
    />

    <SelectJobOrder
        :is-open="isSelectJoborderPopupOpen"
        @close="(_) => { isSelectJoborderPopupOpen = false; }"
        @select="(joborder) => {
            filterForm.JONO.value = joborder.JONO;
            filterForm.STYLE.value = joborder.STYLE;
            isSelectJoborderPopupOpen = false;
        }"
    />
</template>

<script setup>
import { ref } from 'vue';

import FormInput from '@/components/utils/FormInput.vue';

import EmployeeHoursTable from '@/components/utils/tables/EmployeeHoursTable.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';
import SelectTimePeriodPopup from '@/components/utils/popups/SelectTimePeriodPopup.vue';
import SelectJobOrder from '@/components/utils/SelectJobOrder.vue';

const filterForm = ref({
    EMPID: {
        value: null,
        matchMode: 'equals',
    },
    DATE: {
        value: [null, null],
        matchMode: 'between'
    },
    JONO: {
        value: null,
        matchMode: 'equals',
    },
    STYLE: {
        value: null,
        matchMode: 'equals',
    },
});

const selectedObjects = ref({
    employee: null
});

const filters = ref(null);

const isSelectEmployeePopupOpen = ref(false);
const isSelectTimePeriodPopupOpen = ref(false);
const isSelectJoborderPopupOpen = ref(false);

function clearFilters() {
    filterForm.value = {
        EMPID: {
            value: null,
            matchMode: 'equals',
        },
        DATE: {
            value: [null, null],
            matchMode: 'between'
        },
        JONO: {
            value: null,
            matchMode: 'equals',
        },
        STYLE: {
            value: null,
            matchMode: 'equals',
        },
    };
}

function applyFilters() {
    filters.value = JSON.parse(JSON.stringify(filterForm.value));
}
</script>
