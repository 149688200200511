import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleRobinsonsGenericSkus() {
    const styleRobinsonsGenericSku = ref(null);

    const getStyleRobinsonsGenericSku = async (style) => {
        try {
            const getStyleRobinsonsGenericSkuResponse = await axios.get(route("api.styles.robinsons-generic-sku.index", {
                style
            }));
            styleRobinsonsGenericSku.value = getStyleRobinsonsGenericSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Robinsons Generic SKU.');
        }
    };

    const postStyleRobinsonsGenericSku  = async (style, styleRobinsonsGenericSku) => {
        try {
            const postStyleRobinsonsGenericSkuResponse = await axios.post(route("api.styles.robinsons-generic-sku.store", {
                style
            }), styleRobinsonsGenericSku);
            return postStyleRobinsonsGenericSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating Robinsons Generic SKU.');
        }
    };
    
    return {
        styleRobinsonsGenericSku,
        getStyleRobinsonsGenericSku,
        postStyleRobinsonsGenericSku,
    };
}
