import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleKCCSkus() {
    const styleKCCSku = ref(null);

    const getStyleKCCSku = async (style) => {
        try {
            const getStyleKCCSkuResponse = await axios.get(route("api.styles.kcc-sku.show", {
                style
            }));
            styleKCCSku.value = getStyleKCCSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching KCC SKU.');
        }
    };

    const putStyleKCCSku  = async (style, styleKCCSku) => {
        try {
            const putStyleKCCSkuResponse = await axios.put(route("api.styles.kcc-sku.update", {
                style
            }), styleKCCSku);
            return putStyleKCCSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating KCC SKU.');
        }
    };
    
    return {
        styleKCCSku,
        getStyleKCCSku,
        putStyleKCCSku,
    };
}
