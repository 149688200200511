<template>
    <div class="row">
        <div class="col-xl-8">
            <fieldset class="mb-md-1 mb-3">
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i>Create Payroll
                </legend>
                <div class="row gx-4">
                    <div class="col-lg-5 mb-lg-0 mb-3">
                        <div class="row mb-md-1 mb-2 align-items-center">
                            <label for="companyFilter" class="col-form-label col-sm-3 label-sm">Company</label>
                            <div class="col-sm-9">
                                <select v-model="companyFilter"
                                    class="form-select form-select-sm">
                                    <option :value="null">-- SELECT A COMPANY --</option>
                                    <option v-for="company in companyList"
                                        :value="company">
                                        {{ company.COMPANY_DESCRIPTION }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <FormSelect
                            v-model="timePeriodFilter"
                            :options="[
                                'WEEKLY',
                                'BIMONTHLY - OFFICE',
                                'BIMONTHLY - SALES(1,2)',
                                'BIMONTHLY - SALES(3)'
                            ]"
                            @change="timePeriodFilterOnChange"
                            label="Time Period"
                            id-prefix="timePeriod"
                            is-horizontal
                            class="mb-md-1 mb-2 align-items-center"
                            select-class="form-select-sm"
                            label-class="col-form-label col-sm-3 label-sm"
                            select-container-class="col-sm-9"
                        />
                        <div class="row align-items-center">
                            <label for="ptrDisplay" class="col-form-label col-sm-3 label-sm">PTR</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    id="ptrDisplay"
                                    :value="ptrDisplay"
                                    class="form-control form-control-sm"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="row g-1">
                            <div class="col-lg-6">
                                <button type="button"
                                    class="btn btn-primary px-1 btn-sm w-100"
                                    :disabled="companyFilter == null || openTimePeriod == null"
                                    @click="viewPayrollButtonOnClick">
                                    <i class="icon ph-bold ph-eye me-2"></i>View Payroll
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button type="button"
                                    class="btn btn-primary px-1 btn-sm w-100"
                                    :disabled="viewJoborderReportLink == null"
                                    @click="viewJoborderReportButtonOnClick">
                                    <i class="icon ph-bold ph-eye me-2"></i>View Joborder Report
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button type="button"
                                    class="btn btn-primary px-1 btn-sm w-100"
                                    :disabled="exportPayrollToExcelLink == null"
                                    @click="exportPayrollToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export Payroll to Excel
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button type="button"
                                    class="btn btn-primary px-1 btn-sm w-100"
                                    :disabled="exportBDOToExcelLink == null"
                                    @click="exportBDOToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export BDO to Excel
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button type="button"
                                    class="btn btn-primary px-1 btn-sm w-100"
                                    :disabled="exportBPIToExcelLink == null"
                                    @click="exportBPIToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export BPI to Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-4" />
                <div class="row gx-4">
                    <div class="col-lg-7">
                        <p v-if="openTimePeriod">
                            <b>Open Time Period:</b> ({{ openTimePeriod.TIME_PERIOD }}) {{ openTimePeriod.START_DATE }} to {{ openTimePeriod.END_DATE }}
                        </p>
                        <div v-if="!isPayrollEntriesLoading" class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>AFLAG</th>
                                        <th>GL ID</th>
                                        <th>Class</th>
                                        <th>Acct Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="payrollEntries && payrollEntries.length > 0" v-for="payrollEntry in payrollEntries">
                                        <td>{{ payrollEntry.AFLAG }}</td>
                                        <td>{{ payrollEntry.GL_ID }}</td>
                                        <td>{{ payrollEntry.CLASS }}</td>
                                        <td>{{ payrollEntry.ACCTNAME }}</td>
                                        <td>{{ payrollEntry.AMOUNT1?.toFixed(2) }}</td>
                                        <td>{{ payrollEntry.AMOUNT2?.toFixed(2) }}</td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="6" class="text-center">
                                            <div class="text-center py-2">
                                                <i class="icon ph-bold ph-database me-2"></i>No entries yet.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="text-center mb-3">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row gx-5">
                            <div class="col-6">
                                <div class="row mb-md-1 mb-2">
                                    <label for="totalDebitDisplay" class="col-form-label col-lg-5 label-sm">
                                        Total Debit
                                    </label>
                                    <div class="col-lg-7">
                                        <input
                                            type="number"
                                            step=".01"
                                            id="totalDebitDisplay"
                                            :value="totalDebit"
                                            class="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-md-1 mb-2">
                                    <label for="totalCreditDisplay" class="col-form-label col-lg-5 label-sm">
                                        Total Credit
                                    </label>
                                    <div class="col-lg-7">
                                        <input
                                            type="number"
                                            step=".01"
                                            id="totalCreditDisplay"
                                            :value="totalCredit"
                                            class="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="row mb-3 align-items-center gx-2">
                            <div class="col">
                                <div class="row align-items-center">
                                    <label for="voucherIdSearch" class="col-form-label col-lg-5 label-sm">
                                        Voucher ID
                                    </label>
                                    <div class="col-lg-7">
                                        <input
                                            type="text"
                                            id="voucherIdSearch"
                                            class="form-control form-control-sm"
                                            :value="selectedVoucher?.VOUCHER_ID"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mt-auto">
                                <button type="button"
                                    class="btn btn-primary btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#searchVoucherFormModal"
                                    :disabled="openTimePeriod === null">
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                                </button>
                            </div>
                        </div>
                        <button type="button"
                            class="btn btn-primary"
                            :disabled="selectedVoucher === null"
                            @click="updatePayrollVoucherButtonOnClick">
                            <i class="icon ph-bold ph-note-pencil me-2"></i>Update Payroll Voucher
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SearchVoucherFormModal
        id="searchVoucherFormModal"
        @select="(voucher) => (selectedVoucher = voucher)"
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import SearchVoucherFormModal from "./SearchVoucherFormModal.vue";
import axios from "axios";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useFlashMessages } from "@/composables/flashmessages";
import FormSelect from "@/components/utils/FormSelect.vue";

const { addFlashMessage } = useFlashMessages();

const companyList = ref([]);

const companyFilter = ref(null);
const timePeriodFilter = ref(null);
const openTimePeriod = ref(null);

const isPayrollEntriesLoading = ref(false);
const payrollEntries = ref(null);
const totalDebit = ref(null);
const totalCredit = ref(null);

const selectedVoucher = ref(null);

const viewJoborderReportLink = computed(() => {
    if (companyFilter.value == null || openTimePeriod.value == null) {
        return null;
    }

    return route('api.payroll.joborder-report', {
        TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        PAYTYPE: timePeriodFilterMapping[timePeriodFilter.value].paytype,
        IS_SALES: timePeriodFilterMapping[timePeriodFilter.value].sflag === 'S' ? 1 : 0
    });
})

const exportPayrollToExcelLink = computed(() => {
    if (companyFilter.value == null || openTimePeriod.value == null) {
        return null;
    }

    return route('api.payroll.payroll-spreadsheet', {
        FROM_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        TO_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodFilter.value].sflag,
        COMPANY_CODE: companyFilter.value.COMPANY_CODE
    });
});

const exportBDOToExcelLink = computed(() => {
    if (companyFilter.value == null || openTimePeriod.value == null) {
        return null;
    }

    return route('api.payroll.bdo-spreadsheet', {
        FROM_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        TO_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodFilter.value].sflag
    });
});

const exportBPIToExcelLink = computed(() => {
    if (companyFilter.value == null || openTimePeriod.value == null) {
        return null;
    }

    return route('api.payroll.bpi-spreadsheet', {
        FROM_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        TO_TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodFilter.value].sflag
    });
});

const timePeriodFilterMapping = {
    "WEEKLY": {
        paytype: "WEEKLY",
        ptrDisplayStart: "PY/W/",
        sflag: "W",
        bdoflag: ""
    },
    "BIMONTHLY - OFFICE": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/A/",
        sflag: "O",
        bdoflag: ""
    },
    "BIMONTHLY - SALES(1,2)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "2"
    },
    "BIMONTHLY - SALES(3)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "3"
    }
};

const ptrDisplay = computed(() => {
    if (openTimePeriod.value == null) {
        return;
    }

    return timePeriodFilterMapping[timePeriodFilter.value].ptrDisplayStart +
        String(openTimePeriod.value.TIME_PERIOD).padStart(6, '0');
});

onMounted(async () => {
    try {
        const companyListResponse = await axios.get(route('api.companies.index'));
        companyList.value = companyListResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching companies.');
        scrollToTop();
    }
});

async function timePeriodFilterOnChange() {
    openTimePeriod.value = null;

    if (!timePeriodFilter.value) {
        return;
    }

    try {
        const openTimePeriodResponse = await axios.get(
            route('api.time-periods.index'),
            {
                params: {
                    STATUS: 'O',
                    PAYTYPE: timePeriodFilterMapping[timePeriodFilter.value].paytype
                }
            }
        );

        const openTimePeriods = openTimePeriodResponse.data.data;
        if (openTimePeriods.length == 0) {
            addFlashMessage('WARNING', `There are no open time periods with this filter.`);
            return;
        } else if (openTimePeriods.length > 1) {
            addFlashMessage('WARNING', `Multiple time periods are open.
                Please check on time period maintenance.`);
            return;
        }

        openTimePeriod.value = openTimePeriodResponse.data.data[0];
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching open time period.');
        scrollToTop();
    }
}

async function viewPayrollButtonOnClick() {
    isPayrollEntriesLoading.value = true;
    try {
        const payrollEntriesResponse = await axios.get(
            route('api.payroll.payroll-entries'),
            {
                params: {
                    TIME_PERIOD: openTimePeriod.value.TIME_PERIOD,
                    COMPANY_CODE: companyFilter.value.COMPANY_CODE,
                    SFLAG: timePeriodFilterMapping[timePeriodFilter.value].sflag,
                    BDO_FLAG: timePeriodFilterMapping[timePeriodFilter.value].bdoflag
                }
            });
        const payrollEntriesData = payrollEntriesResponse.data.data;
        payrollEntries.value = payrollEntriesData.entries;
        totalDebit.value = payrollEntriesData.totalDebit;
        totalCredit.value = payrollEntriesData.totalCredit;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching payroll entries.');
        scrollToTop();
    }
    isPayrollEntriesLoading.value = false;
}

function viewJoborderReportButtonOnClick() {
    window.open(viewJoborderReportLink.value, '_blank');
}

function exportPayrollToExcelButtonOnClick() {
    window.open(exportPayrollToExcelLink.value, '_blank');
}

function exportBDOToExcelButtonOnClick() {
    window.open(exportBDOToExcelLink.value, '_blank');
}

function exportBPIToExcelButtonOnClick() {
    window.open(exportBPIToExcelLink.value, '_blank');
}

async function updatePayrollVoucherButtonOnClick() {
    if (!payrollEntries.value?.length) {
        window.alert("There are no entries. Please View Payroll first.");
        return;
    }
    if (Math.abs(totalDebit.value - totalCredit.value) > 5) {
        window.alert("Amounts do not balance. Contact your administrator to investigate.");
        return;
    }

    let existingApproval = null;
    try {
        const existingApprovalResponse = await axios.get(
            route('api.vouchers.accounting-approval.show', [selectedVoucher.value.VOUCHER_ID]));
        existingApproval = existingApprovalResponse.data.data;

        if (existingApproval) {
            window.alert('Cannot use voucher since it is already approved.');
            return;
        }
    } catch (e) {
        if (e.response.status != 404) {
            console.log(e);
            handleFetchErrors(e, 'Error while fetching existing approval.');
            scrollToTop();
        }
    }

    if (!window.confirm(`This will reset the GL accounts for voucher ${selectedVoucher.value.VOUCHER_ID}. Are you sure?`)) {
        return;
    }

    try {
        const response = await axios.post(route('api.vouchers.update-payroll-voucher-requests.store'), {
            VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
            PTR: ptrDisplay.value,
            ENTRIES: payrollEntries.value.filter(entry => entry.AFLAG === "A")
                .map(entry => {
                    let glEntry = {
                        GL_ID: entry.GL_ID,
                        GL_AMT: 0
                    };
                    if (entry.AMOUNT1 != null) {
                        glEntry.GL_AMT += entry.AMOUNT1;
                    }
                    if (entry.AMOUNT2 != null) {
                        glEntry.GL_AMT -= entry.AMOUNT2;
                    }
                    return glEntry;
                })
        });
        addFlashMessage('SUCCESS', response.data.message);
        viewPayrollButtonOnClick();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while updating voucher PTR.');
    }
    scrollToTop();
}
</script>
<style scoped>
thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}
</style>
