<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-handbag-simple me-2"></i>Style
        </legend>
        <form>
            <FormInput
                label="Style"
                type="text"
                v-model="form.STYLE"
                is-horizontal
                id-prefix="selectedStyle"
                class="mb-md-1 mb-2"
                :disabled="props.style?.STYLE != null"
                :errors="errors.STYLE"
                @keydown-enter="focusNextInput('styleTypeFormInput')"
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                input-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                input-class="form-control-sm"
            />
            <FormSelect
                label="Style Type"
                v-model="form.STYLE_TYPE"
                :options="styleTypes"
                :option-value="(styleType) => styleType.STYLE_TYPE"
                :option-to-string="(styleType) => styleType.STYLE_TYPE"
                is-horizontal
                id-prefix="styleType"
                class="mb-md-1 mb-2"
                :errors="errors.STYLE_TYPE"
                @keydown-enter="focusNextInput('categoryFormInput')"
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                select-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                select-class="form-select-sm"
            />
            <FormSelect
                label="Category"
                v-model="form.CATEGORY"
                :options="styleCategories"
                is-horizontal
                id-prefix="category"
                class="mb-md-1 mb-2"
                :errors="errors.CATEGORY"
                @keydown-enter="focusNextInput('supplierFormInput')"
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                select-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                select-class="form-select-sm"
            />
            <FormSelect
                label="Supplier"
                v-model="form.SUPPLIER_ID"
                :options="suppliers"
                :option-to-string="(supplier) => supplier.ACCOUNT_NAME"
                :option-value="(supplier) => supplier.ACCOUNT_ID"
                is-horizontal
                id-prefix="supplier"
                class="mb-md-1 mb-2"
                :errors="errors.SUPPLIER_ID"
                @keydown-enter="focusNextInput('insertStyleButton')"
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                select-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                select-class="form-select-sm"
            />
            <FormInput
                label="Created By"
                type="text"
                v-model="form.CREATED_BY"
                is-horizontal
                id-prefix="createdBy"
                class="mb-md-1 mb-2"
                disabled
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                input-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                input-class="form-control-sm"
            />
            <FormInput
                label="Created On"
                type="text"
                v-model="form.CREATED_WHEN"
                is-horizontal
                id-prefix="createdOn"
                class="mb-md-1 mb-2"
                disabled
                label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                input-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                input-class="form-control-sm"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-primary btn-sm me-2 mb-1"
                    id="insertStyleButton"
                    :disabled="style"
                    @click="insertStyle">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Insert
                </button>
                <button type="button"
                    class="btn btn-primary btn-sm me-2 mb-1"
                    id="updateStyleButton"
                    :disabled="!style"
                    @click="updateStyle">
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                </button>
                <button type="button"
                    class="btn btn-outline-danger btn-sm me-2 mb-1"
                    @click="clear">
                    <i class="icon ph-bold ph-x me-2"></i>Clear
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleCategories } from "@/composables/data/styleCategories";
import { useAccounts } from "@/composables/data/accounts";
import { useStyles } from "@/composables/data/styles";

import FormSelect from "@/components/utils/FormSelect.vue";
import FormInput from "@/components/utils/FormInput.vue";

import focusNextInput from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    style: Object,
    styleTypes: Array,
    class: String,
});

const emit = defineEmits(['success']);

const errors = ref({});

const { styleCategories, getStyleCategories } = useStyleCategories();
const { accounts: suppliers, getAccounts } = useAccounts();

const { postStyle, putStyle } = useStyles();

const style = defineModel();

const form = ref({
    STYLE: null,
    STYLE_TYPE: null,
    CATEGORY: null,
    SUPPLIER_ID: null,
    CREATED_BY: null,
    CREATED_WHEN: null,
});

watch(
    style,
    () => {
        errors.value = {};
        form.value = Object.assign({
            STYLE: null,
            STYLE_TYPE: null,
            CATEGORY: null,
            SUPPLIER_ID: null,
            CREATED_BY: null,
            CREATED_WHEN: null,
        }, style.value);
    }
);

async function fetchStyleCategories() {
    loadingFlags.add('fetchStyleCategories');
    try {
        await getStyleCategories();
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchStyleCategories');
}

async function fetchSuppliers() {
    loadingFlags.add('fetchSuppliers');
    try {
        await getAccounts({
            accountType: "STYLE-SUPP",
        });
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchSuppliers');
}

onMounted(async () => {
    fetchStyleCategories();
    fetchSuppliers();
});

async function insertStyle() {
    errors.value = {};

    loadingFlags.add('insertStyle');
    try {
        const newStyle = await postStyle(form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully inserted style.');
        emit('success');
        style.value = newStyle;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('insertStyle');
}

async function updateStyle() {
    errors.value = {};

    loadingFlags.add('updateStyle');
    try {
        const newStyle = await putStyle(style.value.STYLE, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated style.');
        emit('success');
        style.value = newStyle;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateStyle');
}

function clear() {
    style.value = null;
}
</script>
