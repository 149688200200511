<template>
    <DataTable
        :loading="loadingFlags.has('fetchDepartments')"
        lazy
        :value="departments"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchDepartments(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedDepartment"
        data-key="DEPT"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column
            field="DEPT"
            header="Department"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="REPT_GROUP"
            header="Rept. Group"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="GL_ID"
            header="GL ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useDepartments } from '@/composables/data/departments';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    refreshFlag: null,
});

const emit = defineEmits(['success']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "DEPT",
    "REPT_GROUP",
    "GL_ID",
]);

const {
    departments,
    totalRecords,
    getDepartments,
} = useDepartments();

const selectedDepartment = defineModel();

const fetchTimeout = ref(null);

async function fetchDepartments() {
    departments.value = null;

    loadingFlags.add('fetchDepartments');
    try {
        await getDepartments(params.value);
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchDepartments');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchDepartments, 800);
}

onMounted(fetchDepartments);

watch(() => props.refreshFlag, fetchDepartments);
</script>
