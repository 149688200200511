export function useCompanyEventTypes() {
    const companyEventTypes = [
        {
            value: 'R-HOLIDAY',
            label: 'Regular Holiday',
        },
        {
            value: 'S-HOLIDAY',
            label: 'Special Holiday',
        },
        {
            value: 'COMPANY',
            label: 'Company Event',
        },
        {
            value: 'BIRTHDAY',
            label: 'Birthday',
        },
        {
            value: 'ANNIV',
            label: 'Anniversary',
        },
        {
            value: 'REGULAR',
            label: 'Regularization',
        },
    ];
    
    return { companyEventTypes };
}
