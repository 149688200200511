<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <form @submit.prevent="
                (_) => {
                    if (!inventoryCheckDescription) {
                        createInventoryCheckDescription();
                    } else {
                        updateInventoryCheckDescription();
                    }
                }
            ">
                <div class="d-flex justify-content-between align-items-center px-4">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-archive-box me-2"></i>
                        {{ createOrUpdateText }} Inventory Check Description
                    </h4>
                    <button type="button"
                        class="btn-close"
                        @click="emit('close')">
                    </button>
                </div>
                <hr />
                <div class="modal-container px-4">
                    <div class="modal-padding-container">
                        <FormInput
                            type="text"
                            v-model="form.LOCATION"
                            disabled
                            label="Location"
                            id-prefix="location"
                            :errors="errors.LOCATION"
                            class="mb-3"
                        />
                        <FormInput
                            type="date"
                            v-model="form.INVENTORY_DONE"
                            label="Inventory Done"
                            id-prefix="inventoryDone"
                            :errors="errors.INVENTORY_DONE"
                            class="mb-3"
                        />
                        <FormInput
                            type="text"
                            v-model="form.DESCRIPTION"
                            label="Description"
                            id-prefix="description"
                            :errors="errors.DESCRIPTION"
                        />
                    </div>
                    <div class="modal-padding-container">
                        <hr class="my-3" />
                        <div class="text-end">
                            <button type="button" class="btn btn-outline-secondary me-2"
                                @click="clear">
                                <i class="icon ph-bold ph-trash me-2"></i>Clear
                            </button>
                            <button type="submit" class="btn btn-primary">
                                <i class="icon ph-bold ph-file-plus me-2"></i>{{ createOrUpdateText }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useInventoryCheckDescriptions } from "@/composables/data/inventoryCheckDescriptions";
import FormInput from "@/components/utils/FormInput";

const {
    postInventoryCheckDescription,
    putInventoryCheckDescription,
} = useInventoryCheckDescriptions();

const props = defineProps({
    location: String,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const inventoryCheckDescription = defineModel();

const form = ref({
    LOCATION: null,
    INVENTORY_DONE: null,
    DESCRIPTION: null,
});

const errors = ref({});

const createOrUpdateText = computed(() => inventoryCheckDescription.value ? "Update" : "Create");

watch(inventoryCheckDescription, () => {
    form.value = Object.assign({
        LOCATION: props.location,
        INVENTORY_DONE: null,
        DESCRIPTION: null,
    }, inventoryCheckDescription.value);
    errors.value = {};
});

watch(() => props.location, () => {
    if (!inventoryCheckDescription.value) {
        form.value.LOCATION = props.location;
    }
});

async function createInventoryCheckDescription() {
    errors.value = {};

    loadingFlags.add("createInventoryCheckDescription");
    try {
        const newInventoryCheckDescription = await postInventoryCheckDescription(
            form.value
        );
        toasts.add("SUCCESS", "Success", "Successfully created inventory check description.");
        inventoryCheckDescription.value = newInventoryCheckDescription;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createInventoryCheckDescription");
}

async function updateInventoryCheckDescription() {
    errors.value = {};

    loadingFlags.add("updateInventoryCheckDescription");
    try {
        const newInventoryCheckDescription = await putInventoryCheckDescription(
            inventoryCheckDescription.value.id, form.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated inventory check description.");
        inventoryCheckDescription.value = newInventoryCheckDescription;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateInventoryCheckDescription");
}

function clear() {
    form.value = {
        LOCATION: props.location,
        INVENTORY_DONE: null,
        DESCRIPTION: null,
    };
    inventoryCheckDescription.value = null;
    errors.value = {};
}
</script>
