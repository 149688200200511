<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-swatches me-2"></i>
                    Select Material
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 pb-3 pt-4">
                <DataTable
                    lazy
                    paginator
                    :rows="10"
                    :value="materials"
                    :total-records="totalRecords"
                    filter-display="row"
                    v-model:filters="filters"
                    v-model:selection="selectedMaterial"
                    selection-mode="single"
                    data-key="id"
                    @page="
                        (e) => {
                            onPage(e);
                            fetchMaterials();
                        }
                    "
                    @filter="
                        (e) => {
                            onFilter(e);
                            fetchMaterials();
                        }
                    "
                    :row-class="
                        (data) => [{ 'bg-primary text-white': data.id == selectedMaterial?.id }]
                    "
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column
                        field="id"
                        header="ID"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by ID"
                            />
                        </template>
                    </Column>
                    <Column
                        field="MATERIAL"
                        header="Material"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Material"
                            />
                        </template>
                    </Column>
                    <Column
                        field="COLOR"
                        header="Color"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Color"
                            />
                        </template>
                    </Column>
                    <Column
                        field="UNIT"
                        header="Unit"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search bu Unit"
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRICE"
                        header="Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Price"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CREATED_BY"
                        header="Created By"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Created by"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i> No materials.
                        </div>
                    </template>
                </DataTable>
                <div class="modal-padding-container text-end">
                    <hr class="my-4" />
                    <button
                        type="button"
                        class="btn btn-primary mx-lg-4"
                        :disabled="!selectedMaterial"
                        @click="
                            (_) => {
                                emit('select', selectedMaterial);
                            }
                        ">
                        <i class="icon ph-bold ph-hand-pointing me-2"></i>
                        Select  Material
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useMaterials } from "@/composables/data/materials";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["select"]);

const selectedMaterial = ref(null);

const { params, filters, onPage, onFilter } = useDataTableParams([
    "id",
    "MATERIAL",
    "COLOR",
    "UNIT",
    "PRICE",
    "CREATED_BY",
]);

const { materials, totalRecords, getMaterials } = useMaterials();

async function fetchMaterials() {
    await getMaterials(params.value);
}

onMounted(fetchMaterials);
</script>
