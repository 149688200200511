<template>
    <div class="modal fade" :id="id" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalSearchLabel">
                        <i class="icon ph-bold ph-clock me-2"></i>View Hours (Weekly)
                    </h5>
                    <button type="button"
                        class="btn-close"
                        ref="closeButton"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-xl-3 col-lg-5">
                            <div class="row mb-md-1 mb-2">
                                <label for="timePeriodDisplay" class="form-label col-md-5 label-sm">Time Period</label>
                                <div class="col-md-7 col-12">
                                    <input
                                        type="text"
                                        id="timePeriodDisplay"
                                        class="form-control form-control-sm"
                                        :value="timePeriod?.TIME_PERIOD"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label for="employeeIDDisplay" class="form-label col-md-5 label-sm">Employee ID</label>
                                <div class="col-md-7 col-12">
                                    <input
                                        type="text"
                                        id="employeeIDDisplay"
                                        class="form-control form-control-sm"
                                        :value="employee?.EMPID"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label for="totalRegularDisplay" class="form-label col-md-5 label-sm">Total Regular</label>
                                <div class="col-md-7 col-12">
                                    <input
                                        type="text"
                                        id="totalRegularDisplay"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label for="totalOvertimeDisplay" class="form-label col-md-5 label-sm">Total Overtime</label>
                                <div class="col-md-7 col-12">
                                    <input
                                        type="text"
                                        id="totalOvertimeDisplay"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label for="flexFlagDisplay" class="form-label col-md-5 label-sm">Flex Flag</label>
                                <div class="col-md-7 col-12">
                                    <input
                                        type="text"
                                        id="flexFlagDisplay"
                                        class="form-control form-control-sm"
                                        :value="employee?.FLEX_FLAG"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-7">
                            <div class="row">
                                <div class="col-xl-6 mb-md-1 mb-2">
                                    <div class="row">
                                        <label for="startDateDisplay" class="form-label col-xl-4 col-md-5 label-sm">Start Date</label>
                                        <div class="col-xl-8 col-md-7 col-12">
                                            <input
                                                type="text"
                                                id="startDateDisplay"
                                                class="form-control form-control-sm"
                                                :value="timePeriod?.START_DATE"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 mb-md-1 mb-2">
                                    <div class="row">
                                        <label for="endDateDisplay" class="form-label col-xl-4 col-md-5 label-sm">End Date</label>
                                        <div class="col-xl-8 col-md-7 col-12">
                                            <input
                                                type="text"
                                                id="endDateDisplay"
                                                class="form-control form-control-sm"
                                                :value="timePeriod?.END_DATE"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 mb-md-1 mb-2">
                                    <div class="row">
                                        <label for="employeeNameDisplay" class="form-label col-xl-4 col-md-5 label-sm">Employee Name</label>
                                        <div class="col-xl-8 col-md-7 col-12">
                                            <input
                                                type="text"
                                                id="employeeNameDisplay"
                                                class="form-control form-control-sm"
                                                :value="employeeName"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 mb-md-1 mb-2">
                                    <div class="row">
                                        <label for="departmentPassDisplay" class="form-label col-xl-4 col-md-5 label-sm">Department</label>
                                        <div class="col-xl-8 col-md-7 col-12">
                                            <input
                                                type="text"
                                                id="departmentPassDisplay"
                                                class="form-control form-control-sm"
                                                :value="employee?.DEPT"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button"
                                class="btn btn-primary btn-sm mt-3"
                                @click="completeHoursButtonOnClick">
                                <i class="icon ph-bold ph-clock me-2"></i>Complete 48 Hours
                            </button>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reg In</th>
                                    <th>Late 1</th>
                                    <th>Late 2</th>
                                    <th>Late 3</th>
                                    <th>Out 1</th>
                                    <th>Out 2</th>
                                    <th>Overtime</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="date in datesInTimePeriod">
                                    <td>{{ moment(date).format("ddd | MMM D, YYYY") }}</td>
                                    <td>{{ logs?.[date]?.REG_IN }}</td>
                                    <td>{{ logs?.[date]?.LATE_1 }}</td>
                                    <td>{{ logs?.[date]?.LATE_2 }}</td>
                                    <td>{{ logs?.[date]?.LATE_3 }}</td>
                                    <td>{{ logs?.[date]?.OUT_1 }}</td>
                                    <td>{{ logs?.[date]?.OUT_2 }}</td>
                                    <td>{{ logs?.[date]?.OVERTIME }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        disabled>
                        <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                    </button>
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        disabled>
                        <i class="icon ph-bold ph-calculator me-2"></i>Estimate
                    </button>
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        disabled>
                        <i class="icon ph-bold ph-eye me-2"></i>Show Hours
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import axios from "axios";
import moment from "moment";

const props = defineProps({
    timePeriod: Object,
    employee: Object,
    datesInTimePeriod: Array,
    id: String
});

const emit = defineEmits(['complete-hours']);

const closeButton = ref(null);

const employeeSchedule = ref(null);

const employeeName = computed(() => {
    if (!props.employee) {
        return;
    }
    return props.employee.FNAME + " " + props.employee.LNAME;
});

const logs = ref(null);

const loadingFlags = useLoadingFlagsStore();

watch(() => props.employee, async() => {
    if (
        !props.employee ||
        (
            props.employee.PAYTYPE != "WEEKLY" &&
            !(
                props.employee.PAYTYPE == 'BIMONTHLY' &&
                ['ADMIN', 'ADMIN - S', 'MAINTENANCE'].includes(props.employee.DEPT)
            )
        )
    ) {
        return;
    }

    loadingFlags.add('showHoursFetchData');

    try {
        const scheduleResponse = await axios.get(
            route('api.employees.schedule.index', {
                EMPID: props.employee.EMPID
            })
        );
        employeeSchedule.value = scheduleResponse.data.data;
    } catch (e) {
        console.log(e);
    }

    try {
        const nidekaLogsResponse = await axios.get(
            route('api.employees.nideka-logs.index', {
                employee: props.employee.EMPID
            })
        );

        function createEmptyLog() {
            return {
                REG_IN: null,
                LATE_1: null,
                LATE_2: null,
                LATE_3: null,
                OUT_1: null,
                OUT_2: null,
                OVERTIME: null
            };
        }

        const nidekaLogs = nidekaLogsResponse.data.data;
        logs.value = props.datesInTimePeriod.reduce((allLogs, date) => {
            let log = allLogs[date];
            if (!log) {
                log = createEmptyLog();
                allLogs[date] = log;
            }

            nidekaLogs.filter(nidekaLog =>
                new Date(nidekaLog.DATE_TIME.split(" ")[0])
                    .getTime() == date.getTime()
                ).forEach(nidekaLog => {
                const TIME_FORMAT = "hh:mm:ss";
                const time = moment(nidekaLog.DATE_TIME.split(" ")[1], 'hh:mm:ss');
                if (time.isBetween(moment("00:00:00", TIME_FORMAT), moment("08:00:59", TIME_FORMAT))) {
                    log.REG_IN = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("08:01:00", TIME_FORMAT), moment("10:15:59", TIME_FORMAT))) {
                    log.LATE_1 = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("10:16:00", TIME_FORMAT), moment("13:00:59", TIME_FORMAT))) {
                    log.LATE_2 = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("13:01:00", TIME_FORMAT), moment("15:00:59", TIME_FORMAT))) {
                    log.LATE_3 = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("15:01:00", TIME_FORMAT), moment("17:59:59", TIME_FORMAT))) {
                    log.OUT_1 = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("18:00:00", TIME_FORMAT), moment("18:18:59", TIME_FORMAT))) {
                    log.OUT_2 = time.format(TIME_FORMAT);
                } else if (time.isBetween(moment("18:19:00", TIME_FORMAT), moment("23:59:00", TIME_FORMAT))) {
                    log.OVERTIME = time.format(TIME_FORMAT);
                }
            });

            return allLogs;
        }, {});
    } catch (e) {
        console.log(e);
    }

    loadingFlags.delete('showHoursFetchData');
});

function completeHoursButtonOnClick() {
    let jobHoursMappingByDept = {
        "ACCOUNTING": {
            JONO: "ACCTNG",
            STYLE: "ACCTNG",
            RATE: "REGULAR"
        },
        "EXTRA-ACCT": {
            JONO: "ACCTNG",
            STYLE: "ACCTNG",
            RATE: "REGULAR"
        },
        "MARKETING": {
            JONO: "MKTNG",
            STYLE: "MKTNG",
            RATE: "REGULAR"
        },
        "EXTRA-MKTG": {
            JONO: "MKTNG",
            STYLE: "MKTNG",
            RATE: "REGULAR"
        },
        "MAINTENANCE": {
            JONO: "MAINT",
            STYLE: "ERRAND",
            RATE: "REGULAR"
        },
        "PRODQC": {
            JONO: "SPQC",
            STYLE: "SPQC",
            RATE: "REGULAR"
        },
        "EXTRA-PRODQC": {
            JONO: "SPQC",
            STYLE: "SPQC",
            RATE: "REGULAR"
        }
    };
    emit('complete-hours', jobHoursMappingByDept[props.employee.DEPT]);
    closeButton.value.click();
}
</script>
<style scoped>
thead {
    position: sticky;
    top: 0;
    margin: 0;
    background-color: lightslategrey;
    color: #fff;
}
</style>
