<style scoped>
#violationsTableContainer {
    max-height: 350px;
    overflow-y: auto;
}

#violationsTableContainer table thead {
    position: sticky;
    top: 0;
    background-color: lightslategrey;
    color: #fff;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-3 col-lg-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-user me-2"></i> Selected Employee
                </legend>
                <div class="text-end mb-3">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="
                            (_) => {
                                isSelectEmployeePopupOpen = true;
                            }
                        "
                    >
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search Employee
                    </button>
                </div>
                <FormInput
                    type="text"
                    label="ID"
                    v-model="employee.EMPID"
                    id-prefix="empid"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                    disabled
                />
                <FormInput
                    type="text"
                    label="First Name"
                    v-model="employee.FNAME"
                    id-prefix="fname"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                    disabled
                />
                <FormInput
                    type="text"
                    label="Middle Name"
                    v-model="employee.MNAME"
                    id-prefix="fname"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                    disabled
                />
                <FormInput
                    type="text"
                    label="Last Name"
                    v-model="employee.LNAME"
                    id-prefix="fname"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                    disabled
                />
            </fieldset>
        </div>
        <div class="col-xl-9 col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-notepad me-2"></i> Violation Form
                </legend>
                <form @submit.prevent="saveViolation">
                    <div class="row g-3">
                        <div class="col-xl-8 col-lg-7">
                            <FormTextarea
                                label="Memo"
                                v-model="violation.VIOLATION_MEMO"
                                id-prefix="memo"
                                :rows="5"
                                :errors="errors.VIOLATION_MEMO"
                                @keydown-enter="focusNextInput('dateFormInput')"
                            />
                        </div>
                        <div class="col-xl-4 col-lg-5">
                            <FormInput
                                type="date"
                                label="Date"
                                v-model="violation.VIOLATION_DATE"
                                id-prefix="date"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.VIOLATION_DATE"
                                @keydown-enter="focusNextInput('daysFormInput')"
                            />
                            <FormInput
                                type="number"
                                label="Days"
                                v-model="violation.VIOLATION_DAYS"
                                id-prefix="days"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.VIOLATION_DAYS"
                                @keydown-enter="focusNextInput('typeFormInput')"
                            />
                            <FormSelect
                                label="Type"
                                v-model="violation.VIOLATION_TYPE"
                                :options="violationTypes"
                                :option-to-string="(type) => type.verbose"
                                :option-value="(type) => type.value"
                                id-prefix="type"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                select-class="form-select-sm"
                                select-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.VIOLATION_TYPE"
                                @keydown-enter="focusNextInput('insertButton')"
                            />
                            <div class="d-grid gap-1 mt-3">
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                    id="insertButton"
                                    :disabled="employee.EMPID == null"
                                >
                                    <i class="icon ph-bold ph-plus me-2"></i>Insert Violation
                                </button>
                                <button
                                    type="reset"
                                    class="btn btn-outline-secondary btn-sm"
                                    @click="resetButtonOnClick"
                                >
                                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Reset Fields
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <fieldset class="h-100">
        <legend>
            <i class="icon ph-bold ph-warning-circle me-2"></i> Employee Violations
        </legend>
        <div class="table-responsive" id="violationsTableContainer">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Days</th>
                        <th>Memo</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="employee.EMPID == null">
                        <td colspan="6" class="text-center">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user me-2"></i>Please select an employee.
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="violations.length == 0">
                        <td colspan="6" class="text-center">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-warning-circle me-2"></i>No violations.
                            </div>
                        </td>
                    </tr>
                    <tr v-else v-for="violation in violations">
                        <td valign="middle">{{ violation.id }}</td>
                        <td valign="middle">{{ violation.VIOLATION_DATE }}</td>
                        <td valign="middle">{{ violation.VIOLATION_TYPE }}</td>
                        <td valign="middle">{{ violation.VIOLATION_DAYS }}</td>
                        <td valign="middle">{{ violation.VIOLATION_MEMO }}</td>
                        <td valign="middle">
                            <button
                                type="button"
                                class="btn btn-sm btn-danger py-0"
                                @click="deleteViolation(violation.id)"
                            >
                                <i class="icon ph-bold ph-trash me-2"></i>Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (employee) => {
                isSelectEmployeePopupOpen = false;
                onEmployeeSelect(employee);
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormTextarea from "@/components/utils/FormTextarea.vue";

import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

import focusNextInput from "@/utils/focusnext.js";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const errors = ref({});

const violationTypes = [
    { value: "VW", verbose: "Verbal Warning" },
    { value: "WW", verbose: "Written Warning" },
    { value: "S", verbose: "Suspension" },
    { value: "T", verbose: "Terminated" },
    { value: "R", verbose: "Resign" },
];

const violations = ref();

const isSelectEmployeePopupOpen = ref(false);

const employee = ref({});
const violation = ref({});

const isSubmitting = ref(false);

async function fetchViolations() {
    try {
        const violationsResponse = await axios.get(
            route("api.employees.violations.index", {
                employee: employee.value.EMPID,
            })
        );
        violations.value = violationsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching violations.");
        scrollToTop();
    }
}

const onEmployeeSelect = (selectedEmployee) => {
    employee.value = selectedEmployee;
    violation.value = {};
    errors.value = {};
    fetchViolations();
};

const saveViolation = async () => {
    isSubmitting.value = true;
    errors.value = {};
    try {
        await axios.post(
            route("api.employees.violations.store", {
                employee: employee.value.EMPID,
            }),
            violation.value
        );
        addFlashMessage("SUCCESS", "Successfully added violation.");
        fetchViolations();
        violation.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, "Error while saving violation.");
    }
    scrollToTop();
    isSubmitting.value = false;
};

const deleteViolation = async (id) => {
    try {
        await axios.delete(
            route("api.employees.violations.destroy", {
                employee: employee.value.EMPID,
                violation: id,
            })
        );
        addFlashMessage("SUCCESS", "Successfully deleted violation.");
        fetchViolations();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while deleting violation.");
    }
    scrollToTop();
};

function resetButtonOnClick() {
    violation.value = {};
}
</script>
