<template>
    <div class="row g-3 mb-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <TimePeriodTable
                    v-model="selectedTimePeriod"
                    show-from-current-date
                    paytype="MONTHLY"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                />
            </Card>
        </div>
        <div class="col-md-8">
            <form @submit.prevent="uploadRemittanceAdvice" class="mb-3">
                <Card>
                    <template #header>
                        Upload Remittance Advice
                    </template>
                    <div class="mb-3">
                        <label for="mainFileInput">Main File</label>
                        <input
                            type="file"
                            name="mainFileInput"
                            id="mainFileInput"
                            ref="mainFileInput"
                            class="form-control"
                            @change="(e) => {
                                mainFile = e.target.files[0];
                            }"
                        />
                    </div>
                    <template #footer>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary">
                                Upload
                            </button>
                        </div>
                    </template>
                </Card>
            </form>
            <Card>
                <template #header>
                    Upload Messages
                </template>
                <DataTable
                    :value="uploadMessages"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}"
                >
                    <Column
                        header="Warning"
                    >
                        <template #body="{ data }">
                            <span class="badge bg-warning">
                                Warning
                            </span>
                        </template>
                    </Column>
                    <Column
                        field="line"
                        header="Line"
                    />
                    <Column
                        field="message"
                        header="Message"
                    />
                    <template #empty>
                        <div class="text-center">
                            No messages.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
    </div>
    <VouchersTableCard
        :time-period="selectedTimePeriod?.TIME_PERIOD"
        class="mb-3"
    />
    <RemittanceAdviceUploadedFileStatusesTableCard
        :refresh-flag="uploadedFilesTableRefreshFlag"
    />
</template>

<script setup>
import { ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useRemittanceAdvice } from '@/composables/data/remittanceAdvice';

import Card from '@/components/utils/Card.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';

import VouchersTableCard from './VouchersTableCard.vue';
import RemittanceAdviceUploadedFileStatusesTableCard from './RemittanceAdviceUploadedFileStatusesTableCard.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const selectedTimePeriod = ref(null);

const mainFileInput = ref();

const mainFile = ref(null);

const { postRemittanceAdvice } = useRemittanceAdvice();

const uploadMessages = ref(null);

const uploadedFilesTableRefreshFlag = ref(false);

async function uploadRemittanceAdvice() {
    uploadMessages.value = null;

    if (!selectedTimePeriod.value) {
        toasts.add("ERROR", "Error", "Please select a time period.");
        return;
    }
    
    loadingFlags.add("uploadRemittanceAdvice");
    try {
        const formData = new FormData();
        formData.append("file_main", mainFile.value);
        const response = await postRemittanceAdvice(selectedTimePeriod.value.TIME_PERIOD, formData);
        const warnings = response.data.warnings;

        toasts.add("SUCCESS", "Success", "Successfully uploaded remittance advice files.");
        if (warnings.length > 0) {
            toasts.add("WARNING", "Warning", "There are upload warnings. Please check.");
            uploadMessages.value = response.data.warnings;
        }
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("uploadRemittanceAdvice");
    uploadedFilesTableRefreshFlag.value = !uploadedFilesTableRefreshFlag.value;
}
</script>
