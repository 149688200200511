<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-watch me-2"></i>Total Hours</legend>
        <FormInput
            type="number"
            v-model="totalHours"
            disabled
            label="Total Hours"
            id-prefix="totalHours"
            is-horizontal
            label-class="col-xl-4 col-md-6 label-sm align-items-center"
            input-class="form-control-sm"
            input-container-class="col-xl-8 col-md-6 col-12"
        />
        <DataTable
            :loading="loadingFlags.has('fetchEmployeeHoursOnTimePeriod')"
            :value="hoursSummary"
            paginator
            :rows="10"
            :pt="{ table: { class: 'table table-bordered table-hover table-sm' } }"
            class="mt-3">
            <Column
                field="rate"
                header="Rate"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="hours"
                header="Hours"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-clock me-2"></i>No saved hours.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useEmployeeHours } from '@/composables/data/employeeHours';
import FormInput from '@/components/utils/FormInput.vue';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Object,
    employee: Object,
    refreshFlag: null,
    class: String
});

const emit = defineEmits(['error']);

const { employeeHours, getEmployeeHoursOnTimePeriod } = useEmployeeHours();

const hoursSummary = ref(null);

const loadingFlags = useLoadingFlagsStore();

const totalHours = computed(() => hoursSummary.value?.reduce((total, hoursOnRate) => total + hoursOnRate.hours, 0) ?? 0);

async function fetchEmployeeHoursOnTimePeriod() {
    employeeHours.value = null;
    hoursSummary.value = null;

    if (!props.employee || !props.timePeriod) {
        return;
    }

    loadingFlags.add('fetchEmployeeHoursOnTimePeriod');
    try {
        await getEmployeeHoursOnTimePeriod(props.employee.EMPID, props.timePeriod.TIME_PERIOD);
        hoursSummary.value = Object.values(employeeHours.value.reduce((hoursSummary, employeeHoursOnDate) => {
            const newHoursSummary = Object.assign({}, hoursSummary);
            if (employeeHoursOnDate.RATE in newHoursSummary) {
                newHoursSummary[employeeHoursOnDate.RATE].hours += employeeHoursOnDate.HOURS;
            } else
                newHoursSummary[employeeHoursOnDate.RATE] = {
                    rate: employeeHoursOnDate.RATE,
                    hours: employeeHoursOnDate.HOURS,
                };
            return newHoursSummary;
        }, {}));
    } catch (e) {
        console.log(e);
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployeeHoursOnTimePeriod');
}

watch(() => [props.employee, props.timePeriod], fetchEmployeeHoursOnTimePeriod);
watch(() => props.refreshFlag, fetchEmployeeHoursOnTimePeriod);
</script>
