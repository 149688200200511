<template>
    <DataTable :loading="isLoading"
        :value="locations"
        paginator
        :rows="10"
        filter-display="row"
        v-model:filters="filters"
        v-model:selection="selectedLocation"
        selection-mode="single"
        sort-mode="multiple"
        :pt="{ table: { class: ' table table-bordered table-hover' } }">
        <Column
            v-if="!hideProperties?.has('LOCATION')"
            field="LOCATION"
            header="Location"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            v-if="!hideProperties?.has('DESCRIPTION')"
            field="DESCRIPTION"
            header="Description"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            v-if="!hideProperties?.has('TYPE')"
            field="TYPE"
            header="Type"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            v-if="!hideProperties?.has('PRICE_TYPE')"
            field="PRICE_TYPE"
            header="Price Type"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #loading>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            Loading locations...
        </template>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLocations } from '@/composables/data/locations';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    filters: Object,
    hideProperties: Set,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    "LOCATION",
    "DESCRIPTION",
    "TYPE",
    "PRICE_TYPE",
]);

const { locations, getLocations } = useLocations();

const selectedLocation = defineModel();

const isLoading = ref(false);

async function fetchLocations() {
    locations.value = null;

    loadingFlags.add('fetchLocations');
    try {
        await getLocations({ hardFilters: props.filters });
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchLocations');
}

onMounted(fetchLocations);

watch(() => props.refreshFlag, fetchLocations);
</script>
