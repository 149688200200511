import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconMainNotFoundItems() {
    const reconMainNotFoundItems = ref(null);
    const totalRecords = ref(null);

    const getReconMainNotFoundItems = async (params) => {
        try {
            const getReconMainNotFoundItemsResponse = await axios.get(route('api.recon-main-not-found-items.index', params));
            reconMainNotFoundItems.value = getReconMainNotFoundItemsResponse.data.data;
            totalRecords.value = getReconMainNotFoundItemsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while getting recon main not found items.');
        }
    };
    
    return {
        reconMainNotFoundItems,
        totalRecords,
        getReconMainNotFoundItems,
    };
}
