<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-file-xsl me-2"></i>Export Sold Discrepancies
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <TimePerioSelectTableCard
                        v-model="selectedTimePeriod"
                        show-only-type="MONTHLY"
                        class="w-100"
                    />
                    <div class="text-end py-3">
                        <button type="button"
                            class="btn btn-primary"
                            :disabled="!selectedTimePeriod"
                            @click="exportDiscrepancies">
                            <i class="icon ph-bold ph-upload me-2"></i>Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import TimePerioSelectTableCard from "@/components/utils/TimePeriodSelectTableCard";

const props = defineProps({
    location: String,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const selectedTimePeriod = ref(null);

function exportDiscrepancies() {
    window.open(
        route("api.locations.sold-discrepancies-spreadsheet.show", {
            location: props.location,
            start_date: selectedTimePeriod.value.START_DATE,
            end_date: selectedTimePeriod.value.END_DATE,
        })
    );
}
</script>
