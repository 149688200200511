<style scoped>
img {
    height: 200px;
}
</style>

<template>
    <div class="row g-3">
        <div class="col-xl-9">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-tea-bag me-2"></i>Styles Table
                </legend>
                <p class="text-primary">
                    (please select a Style before clicking a report below)
                </p>
                <div class="row mb-3">
                    <div class="col-lg-5 mb-lg-0 mb-2">
                        <FormSelect
                            label="Style Type Filter"
                            v-model="filters.STYLE_TYPE.value"
                            id-prefix="styleTypeFilter"
                            :options="styleTypes"
                            :option-to-string="(styleType) => styleType.STYLE_TYPE"
                            :option-value="(styleType) => styleType.STYLE_TYPE"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-md-9 col-12"
                            is-horizontal
                            label-class="col-xl-4 col-md-3 label-sm align-items-center"
                            @change="onFilter"
                        />
                    </div>
                    <div class="col-lg-5">
                        <FormSelect
                            label="Style Filter"
                            v-model="styleFilter"
                            id-prefix="styleFilter"
                            :options="styleFilters"
                            :option-to-string="(styleFilter) => styleFilter.verbose"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-md-9 col-12"
                            is-horizontal
                            label-class="col-xl-4 col-md-3 label-sm align-items-center"
                            @change="onFilter"
                        />
                    </div>
                </div>
                <DataTable :loading="isLoading"
                    lazy
                    paginator
                    :rows="10"
                    :total-records="totalRecords"
                    :value="styles"
                    v-model:filters="filters"
                    filter-display="row"
                    :globalFilterFields="['STYLE', 'STYLE_TYPE', 'CATEGORY']"
                    v-model:selection="selectedStyle"
                    selection-mode="single"
                    data-key="STYLE"
                    @page="onPage"
                    @filter="onFilter"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column
                        field="STYLE"
                        header="Style"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="STYLE_TYPE"
                        header="Style Type"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="SM_SKU"
                        header="SM SKU"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="stylePrices.START_DATE"
                        header="Start Date"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                        <template #body="slotProps">
                            {{
                                slotProps.data.style_prices.filter(
                                    (stylePrice) =>
                                        stylePrice.END_DATE == "9999-12-31"
                                )[0]?.START_DATE
                            }}
                        </template>
                    </Column>
                    <Column
                        field="stylePrices.SM_PRICE"
                        header="SM Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                        <template #body="slotProps">
                            {{
                                slotProps.data.style_prices.filter(
                                    (stylePrice) =>
                                        stylePrice.END_DATE == "9999-12-31"
                                )[0]?.SM_PRICE
                            }}
                        </template>
                    </Column>
                    <Column
                        field="stylePrices.LM_REG_PRICE"
                        header="LM Reg Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                        <template #body="slotProps">
                            {{
                                slotProps.data.style_prices.filter(
                                    (stylePrice) =>
                                        stylePrice.END_DATE == "9999-12-31"
                                )[0]?.LM_REG_PRICE
                            }}
                        </template>
                    </Column>
                    <Column
                        field="stylePrices.LM_SALE_PRICE"
                        header="LM Sale Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                        <template #body="slotProps">
                            {{
                                slotProps.data.style_prices.filter(
                                    (stylePrice) =>
                                        stylePrice.END_DATE == "9999-12-31"
                                )[0]?.LM_SALE_PRICE
                            }}
                        </template>
                    </Column>
                    <Column
                        field="styleSkuPrices.SKU_PRICE"
                        header="SKU Price"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                        <template #body="slotProps">
                            {{
                                slotProps.data.style_sku_prices.filter(
                                    (styleSkuPrice) =>
                                        styleSkuPrice.END_DATE == "9999-12-31"
                                )[0]?.SKU_PRICE
                            }}
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No styles.
                        </div>
                    </template>
                    <template #footer>
                        <div class="mb-3">
                            Total Records: <b>{{ totalRecords }}</b> styles.
                        </div>
                    </template>
                </DataTable>
                <hr class="my-3" />
                <div class="row g-1">
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                                openInventoryReportLink(
                                    'styles-without-price-report'
                                )
                            ">
                            <i class="icon ph-bold ph-tag me-2"></i>Styles w/o Price
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            disabled>
                            <i class="icon ph-bold ph-placeholder me-2"></i>Not Distrib
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            disabled>
                            <i class="icon ph-bold ph-chart-bar me-2"></i>Diff Count
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#bestsellersStyleModal">
                            <i class="icon ph-bold ph-star me-2"></i>Bestsellers Style
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedStyle"
                            @click="openStyleReportLink('locations-report')">
                            <i class="icon ph-bold ph-map-pin me-2"></i>Locations
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedStyle"
                            @click="openStyleReportLink('locations-color-report')">
                            <i class="icon ph-bold ph-palette me-2"></i>Locations of Colors
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedStyle"
                            @click="openStyleReportLink('location-pullout-report')">
                            <i class="icon ph-bold ph-arrow-up-right me-2"></i>Location Pullout
                        </button>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedStyle"
                            @click="openStyleReportLink('location-color-pullout-report')">
                            <i class="icon ph-bold ph-palette me-2"></i>Location PO Color
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-3">
            <StyleImageCard :style="selectedStyle?.STYLE" class="mb-3" />
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Inventory Counts
                </legend>
                <DataTable
                    :value="inventoryCounts"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="DATE" header="Date" />
                    <Column field="COUNT" header="Count" />
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>

    <BestsellersStyleModal />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import StyleImageCard from "@/components/utils/StyleImageCard.vue";
import BestsellersStyleModal from "./BestsellersStyleModal.vue";

const filters = ref({
    STYLE: { value: null, matchMode: "contains" },
    STYLE_TYPE: { value: null, matchMode: "contains" },
    SM_SKU: { value: null, matchMode: "contains" },
    'stylePrices.START_DATE': { value: null, matchMode: "contains" },
    'stylePrices.END_DATE': { value: null, matchMode: "contains" },
    'stylePrices.SM_PRICE': { value: null, matchMode: "contains" },
    'stylePrices.LM_REG_PRICE': { value: null, matchMode: "contains" },
    'stylePrices.LM_SALE_PRICE': { value: null, matchMode: "contains" },
    'styleSkuPrices.END_DATE': { value: null, matchMode: "contains" },
    'styleSkuPrices.SKU_PRICE': { value: null, matchMode: "contains" },
});

const params = ref({});

const styleTypes = ref(null);

const styleFilters = [
    {
        verbose: "Styles w/ Price",
        filters: {
            relation_stylePrices_END_DATE: {
                value: "9999-12-31",
                matchMode: "contains",
            },
        },
    },
    { verbose: "Styles w/o Price", params: { without: "stylePrices" } },
    { verbose: "Styles w/o SKU", params: { without: "styleSkuPrices" } },
];
const styleFilter = ref(null);

const totalRecords = ref(0);
const styles = ref(null);

const selectedStyle = ref();

const inventoryCounts = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchStyleTypes() {
    try {
        const styleTypesResponse = await axios.get(
            route("api.styles.types.index")
        );
        styleTypes.value = styleTypesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching style types.");
    }
}

onMounted(fetchStyleTypes);

async function fetchStyles() {
    isLoading.value = true;
    try {
        const stylesResponse = await axios.get(
            route("api.styles.index", {
                ...params.value,
                ...styleFilter.value?.params,
                filters: {
                    ...filters.value,
                    ...styleFilter.value?.filters,
                },
                include_relations: "stylePrices,styleSkuPrices",
            })
        );
        totalRecords.value = stylesResponse.data.meta.total;
        styles.value = stylesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching styles.");
    }
    isLoading.value = false;
}

onMounted(() => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: "STYLE",
        sortOrder: 1,
        filters: filters.value,
    };
    fetchStyles();
});

const onPage = (event) => {
    params.value = event;
    fetchStyles();
};

const onFilter = (_) => {
    // Special case: when specifying values for the relation stylePrices,
    // filter that relation with the END_DATE as '9999-12-31'. This makes sure
    // that we only search the latest style price record.
    if (
        filters.value['stylePrices.START_DATE'].value?.length > 0 ||
        filters.value['stylePrices.SM_PRICE'].value?.length > 0 ||
        filters.value['stylePrices.LM_REG_PRICE'].value?.length > 0 ||
        filters.value['stylePrices.LM_SALE_PRICE'].value?.length > 0
    ) {
        filters.value['stylePrices.END_DATE'].value = "9999-12-31";
    } else {
        filters.value['stylePrices.END_DATE'].value = null;
    }

    // Special case: when specifying values for the relation styleSkuPrices, do the same as above
    if (filters.value['styleSkuPrices.SKU_PRICE'].value?.length > 0) {
        filters.value['styleSkuPrices.END_DATE'].value = "9999-12-31";
    } else {
        filters.value['styleSkuPrices.END_DATE'].value = null;
    }

    params.value.page = 0;
    params.value.first = 0;

    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }

    fetchTimeout.value = setTimeout(fetchStyles, 300);
};

watch(selectedStyle, async () => {
    inventoryCounts.value = null;
    if (!selectedStyle.value) {
        return;
    }

    try {
        const inventoryCountsResponse = await axios.get(
            route("api.styles.inventory.date-counts.index", {
                style: selectedStyle.value.STYLE,
            })
        );
        inventoryCounts.value = inventoryCountsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching inventory logs.");
    }
});

function openInventoryReportLink(subroute) {
    window.open(route("api.reports.inventory." + subroute), "_blank");
}

function openStyleReportLink(subroute) {
    window.open(
        route("api.reports.inventory." + subroute, {
            style: selectedStyle.value.STYLE,
        }),
        "_blank"
    );
}
</script>
