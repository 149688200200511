<template>
    <fieldset>
        <legend>RR Barcodes</legend>
        <DataTable
            :loading="isTableLoading"
            lazy
            :value="inventoryRecords"
            :total-records="inventoryTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchReplacementBarcodes(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="(e) => { onSort(e); fetchReplacementBarcodes(); }"
            v-model:selection="selectedRecord"
            selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    bodyCell: { class: 'barcode' },
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SERIAL"
                header="Serial"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SEQ_NO"
                header="Seq"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="EVENT_DT"
                header="Event Dt."
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer">
            <slot name="footer" />
        </template>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "SEQ_NO",
    "PRICE",
    "EVENT_DT",
    "COLOR",
]);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } =
    useInventory();

const selectedRecord = defineModel();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchReplacementBarcodes() {
    inventoryRecords.value = null;

    if (!props.location) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getInventoryRecords({
            ...params.value,
            filters: {
                ...filters.value,
                LOCATION: {
                    value: props.location,
                    matchMode: "equals",
                },
                PREV_LOC: {
                    value: "__placeholder__",
                    matchMode: "isEmpty",
                },
            },
            isRR: true,
        });
    } catch (e) {
        emit("error", e.message);
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReplacementBarcodes, 300);
}

watch(() => props.location, fetchReplacementBarcodes);
</script>
