<template>
    <div class="employees-available-table-container" :class="class"
        @keydown.enter="_ => { focusNextInput('otherSelectUnifiedDoneButton'); }"
        tabindex="-1">
        <table class="table table-sm table-bordered table-hover">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>FNAME</th>
                    <th>LNAME</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="employees?.length"
                    v-for="employee in employees"
                    :class="{ 'bg-primary text-white': selectedEmployee?.EMPID == employee.EMPID }"
                    @click="_ => { selectedEmployee = employee; }">
                    <td>{{ employee.EMPID }}</td>
                    <td>{{ employee.FNAME }}</td>
                    <td>{{ employee.LNAME }}</td>
                </tr>
                <tr v-else>
                    <td colspan="3">
                        <div class="text-center">
                            <template v-if="!date">
                                Please specify sold date.
                            </template>
                            <template v-else>
                                No employees.
                            </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useEmployeeLocations } from "@/composables/data/employeeLocations";

const props = defineProps({
    location: String,
    date: String,
    class: String,
});

const emit = defineEmits(['error']);

const selectedEmployee = defineModel();

const { employeeLocations, getEmployeeLocations } = useEmployeeLocations();

const employees = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

function fetchAvailableEmployees() {
    employees.value = null;

    if (!props.location || !props.date) {
        return;
    }

    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }

    fetchTimeout.value = setTimeout(async () => {
        isLoading.value = true;
        try {
            await getEmployeeLocations({
                rows: 0,
                filters: {
                    LOCATION: {
                        value: props.location,
                        matchMode: 'equals',
                    },
                    START_DATE: {
                        value: props.date,
                        matchMode: 'lte',
                    },
                    END_DATE: {
                        value: props.date,
                        matchMode: 'gte',
                    },
                },
            });

            employees.value = employeeLocations.value.map(employeeLocation => employeeLocation.employee);
        } catch(e) {
            emit('error', e.message);
        }
        isLoading.value = false;
    }, 300);
}

watch(() => [props.location, props.date], fetchAvailableEmployees);

onMounted(fetchAvailableEmployees);
</script>

<style scoped>
.employees-available-table-container {
    height: 150px;
    overflow-y: auto;
}
</style>