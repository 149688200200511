<template>
    <div :class="classes">
        <label :for="idPrefix + 'FormInput'"
            :class="labelClasses">{{ label }}</label>
        <slot></slot>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    label: String,
    hideLabel: Boolean,
    idPrefix: String,
    isHorizontal: {
        type: Boolean,
        default: false
    },
    class: {
        type: String,
        default: ""
    },
    labelClass: { // New prop for custom label class
        type: String,
        default: ""
    }
});

const classes = computed(() => {
    const componentClasses = [props.class];
    if(props.isHorizontal)
        componentClasses.push("row");
    return componentClasses.join(" ");
});

const labelClasses = computed(() => {
    const labelClasses = [props.labelClass]; // Add the custom label class
    if(props.hideLabel)
        labelClasses.push("d-none");
    if(props.isHorizontal)
        labelClasses.push("col-form-label");
    else
        labelClasses.push("form-label");
    return labelClasses.join(" ");
});
</script>
