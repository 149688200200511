<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Voucher Images
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 mb-3">
                <div class="modal-padding-container">
                    <div v-if="noImageFound" class="text-center text-danger">
                        No Gov't. Voucher uploaded with this name. Please upload
                        first using the Update Voucher page.
                    </div>
                    <div v-else-if="isImageLoading"
                        class="spinner-border"
                        role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <img v-if="!noImageFound"
                        :src="imageURL"
                        :alt="govtVoucherId + ' voucher image'"
                        @load="(_) => { isImageLoading = false; }"
                        @error="noImageFound"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    govtVoucherId: String,
    isOpen: Boolean,
});

const emit = defineEmits(['error', 'close']);

const imageURL = ref(null);
const isImageLoading = ref(false);

const noImageFound = ref(false);

async function loadImage() {
    imageURL.value = null;

    if (!props.govtVoucherId) {
        return;
    }

    noImageFound.value = false;
    try {
        isImageLoading.value = true;
        imageURL.value = (await axios.get(route('api.vouchers.govt.image.show', {
            govt_voucher_id: props.govtVoucherId
        }))).data.data.url;
    } catch (e) {
        isImageLoading.value = false;
        if (e.response.status != 404) {
            emit('error', e.message);
        } else {
            noImageFound.value = true;
        }
    }
}

onMounted(loadImage);

watch(() => [props.isOpen, props.govtVoucherId], () => {
    if (props.isOpen) {
        loadImage();
    }
});
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
