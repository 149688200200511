import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useDepartmentGroups() {
    const departmentGroups = ref(null);
    const totalRecords = ref(null);

    const getDepartmentGroups = async (params) => {
        try {
            const getDepartmentGroupsResponse = await axios.get(route('api.department-groups.index', params));
            departmentGroups.value = getDepartmentGroupsResponse.data.data;
            totalRecords.value = getDepartmentGroupsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching department groups.');
        }
    };
    
    return {
        departmentGroups,
        totalRecords,
        getDepartmentGroups,
    };
}
