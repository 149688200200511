<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-2">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-info me-2"></i>Details
                </legend>
                <FormInput
                    type="text"
                    v-model="timePeriodDisplay"
                    disabled
                    label="Time Period"
                    id-prefix="timePeriodDisplay"
                    class="mb-3"
                />
                <FormInput
                    type="text"
                    v-model="locationDisplay"
                    disabled
                    label="Location"
                    id-prefix="locationDisplay"
                />
            </fieldset>
        </div>
        <div class="col-xl-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-tea-bag me-2"></i>Select Style
                </legend>
                <StyleTable
                    v-model="style"
                    table-class="recon-table"
                    :preset-filters="initialStyleTableFilters"
                />
            </fieldset>
        </div>
        <div class="col-xl-6">
            <NoBarcodesTableCard
                :location="reconParm?.LOCATION"
                :style="style?.STYLE"
                table-class="recon-table"
                @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
            />
        </div>
        <div class="col-xl-6">
            <PulloutCommentsOnMonthTableCard
                :time-period="reconParm?.TIME_PERIOD"
                :location="reconParm?.LOCATION"
                table-class="recon-table"
                class="mb-3"
            />
        </div>
        <div class="col-xl-6">
            <ReplacementBarcodesTableCard
                :location="reconParm?.LOCATION"
                :style="style?.STYLE"
                table-class="recon-table"
                class="mb-3"
                @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useReconParms } from "@/composables/data/reconParms";
import { useStyles } from "@/composables/data/styles";
import FormInput from "@/components/utils/FormInput.vue";
import StyleTable from "@/components/utils/style/StyleTable.vue";
import PulloutCommentsOnMonthTableCard from "./PulloutCommentsOnMonthTableCard.vue";
import ReplacementBarcodesTableCard from "./ReplacementBarcodesTableCard.vue";
import NoBarcodesTableCard from "./NoBarcodesTableCard.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { reconParm, getReconParm } = useReconParms();

const timePeriodDisplay = computed(() => reconParm.value?.TIME_PERIOD);
const locationDisplay = computed(() => reconParm.value?.LOCATION);

const { style, getStyle } = useStyles();

const initialStyleTableFilters = ref(null);

onMounted(async () => {
    // Get recon_parm id from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const reconParmId = urlParams.get("recon_parm");
    await getReconParm(reconParmId, {
        include_relations: "location,location.reconComment",
    });

    const preselectedStyle = urlParams.get("preselected_style");
    if (!preselectedStyle) {
        return;
    }
    try {
        await getStyle(preselectedStyle);
        initialStyleTableFilters.value = {
            STYLE: {
                value: style.value.STYLE,
                matchMode: "equals",
            },
        };
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
});
</script>
