<template>
    <DataTable
        :loading="loadingFlags.has('fetchNewBarcodeInputs')"
        lazy
        :value="newBarcodeInputs"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => {
            onPage(e);
            fetchNewBarcodeInputs();
        }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
        editMode="cell"
        @cell-edit-complete="updateNewBarcodeInput">
        <Column
            field="style"
            header="Style"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Style"
                />
            </template>
        </Column>
        <Column
            field="color"
            header="Color"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Color"
                />
            </template>
        </Column>
        <Column
            field="date"
            header="Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Date"
                />
            </template>
        </Column>
        <Column
            field="count"
            header="Count"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Count"
                />
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    v-model="data[field]"
                    class="form-control-sm"
                    autofocus
                />
            </template>
        </Column>
        <Column
            field="price"
            header="Price"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Price"
                />
            </template>
        </Column>
        <Column header="Delete"
            body-class="d-flex justify-content-center">
            <template #body="{ data }">
                <a
                    class="btn btn-outline-danger px-1 py-0"
                    href="#"
                    role="button"
                    @click="destroyNewBarcodeInput(data.id)"
                    >
                    <i class="icon ph-bold ph-trash"></i>
                </a>
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No inputs.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useNewBarcodeInputs } from '@/composables/data/newBarcodeInputs';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';

const props = defineProps({
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    'style',
    'color',
    'date',
    'count',
    'price',
]);

const {
    newBarcodeInputs,
    totalRecords,
    getNewBarcodeInputs,
    putNewBarcodeInput,
    deleteNewBarcodeInput,
} = useNewBarcodeInputs();

const fetchTimeout = ref(null);

async function fetchNewBarcodeInputs() {
    newBarcodeInputs.value = null;

    loadingFlags.add("fetchNewBarcodeInputs");
    try {
        await getNewBarcodeInputs(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchNewBarcodeInputs");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchNewBarcodeInputs, 700);
}

async function updateNewBarcodeInput(event) {
    const { data, newValue, field } = event;
    data[field] = newValue;

    loadingFlags.add("updateNewBarcodeInput");
    try {
        await putNewBarcodeInput(data['id'], data);
        toasts.add("SUCCESS", "Success", "Successfully updated style for barcode generation.");
        fetchNewBarcodeInputs();
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateNewBarcodeInput");
}

async function destroyNewBarcodeInput(id) {
    loadingFlags.add("destroyNewBarcodeInput");
    try {
        await deleteNewBarcodeInput(id);
        toasts.add("SUCCESS", "Success", "Successfully deleted barcode to generate.");
        fetchNewBarcodeInputs();
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("destroyNewBarcodeInput");
}

onMounted(fetchNewBarcodeInputs);

watch(() => props.refreshFlag, fetchNewBarcodeInputs);
</script>
