<template>
    <DataTable
        :loading="loadingFlags.has('fetchEmployeeHours')"
        lazy
        :value="employeeHours"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchEmployeeHours(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="e => { onSort(e); fetchEmployeeHours(); }"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column
            field="EMPID"
            header="Employee ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="employee.DEPT"
            header="DEPT"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="employee.FNAME"
            header="First Name"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="employee.LNAME"
            header="Last Name"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="DATE"
            header="Date"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="SEQ_NO"
            header="Seq. No."
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="JONO"
            header="JONO"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="STYLE"
            header="Style"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="RATE"
            header="Rate"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column
            field="HOURS"
            header="Hours"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <template #footer>
            {{ totalRecords ?? 0 }} total records
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployeeHours } from '@/composables/data/employeeHours';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    filters: Object,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'EMPID',
    'employee.DEPT',
    'employee.FNAME',
    'employee.LNAME',
    'DATE',
    'SEQ_NO',
    'JONO',
    'STYLE',
    'RATE',
    'HOURS',
]);

const { employeeHours, totalRecords, getEmployeeHours } = useEmployeeHours();

const fetchTimeout = ref(null);

async function fetchEmployeeHours() {
    employeeHours.value = null;

    loadingFlags.add("fetchEmployeeHours");
    try { 
        await getEmployeeHours({
            ...params.value,
            hardFilters: props.filters,
            with: 'employee'
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEmployeeHours");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployeeHours, 800);
}

onMounted(fetchEmployeeHours);

watch(() => props.filters, fetchEmployeeHours);
</script>
