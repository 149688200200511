import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useNewBarcodeInputs() {
    const newBarcodeInputs = ref(null);
    const totalRecords = ref(null);

    const getNewBarcodeInputs = async (params) => {
        try {
            const getNewBarcodeInputsResponse = await axios.get(route('api.new-barcode-inputs.index', params));
            newBarcodeInputs.value = getNewBarcodeInputsResponse.data.data;
            totalRecords.value = getNewBarcodeInputsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching new barcode inputs.');
        }
    };

    const postNewBarcodeInput = async (newBarcodeInput) => {
        try {
            const postNewBarcodeInputResponse = await axios.post(route('api.new-barcode-inputs.store'), newBarcodeInput);
            return postNewBarcodeInputResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving new barcode input.');
        }
    };

    const postNewBarcodeInputMovements = async () => {
        try {
            const postNewBarcodeInputMovementsResponse = await axios.post(route("api.new-barcode-inputs.movements.store"));
            return postNewBarcodeInputMovementsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving new barcode input.');
        }
    };

    const postNewBarcodeInputSpreadsheetImportation = async (formData) => {
        try {
            const postNewBarcodeInputSpreadsheetImportationResponse = await axios.post(
                route("api.new-barcode-inputs.spreadsheet-importations.store"),
                formData
            );
            return postNewBarcodeInputSpreadsheetImportationResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while importing new barcode inputs from spreadsheet.');
        }
    };

    const postNewBarcodeInputClearance = async () => {
        try {
            const postNewBarcodeInputClearanceResponse = await axios.post(route("api.new-barcode-inputs.clearances.store"));
            return postNewBarcodeInputClearanceResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while clearing new barcode inputs.');
        }
    };

    const putNewBarcodeInput = async (newBarcodeInputId, newBarcodeInput) => {
        try {
            const putNewBarcodeInputResponse = await axios.put(route('api.new-barcode-inputs.update', {
                new_barcode_input: newBarcodeInputId,
            }), newBarcodeInput);
            return putNewBarcodeInputResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating new barcode input.');
        }
    };

    const deleteNewBarcodeInput = async (newBarcodeInputId) => {
        try {
            const deleteNewBarcodeInputResponse = await axios.delete(route('api.new-barcode-inputs.destroy', {
                new_barcode_input: newBarcodeInputId,
            }));
            return deleteNewBarcodeInputResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting new barcode input.');
        }
    };
    
    return {
        newBarcodeInputs,
        totalRecords,
        getNewBarcodeInputs,
        postNewBarcodeInput,
        postNewBarcodeInputMovements,
        postNewBarcodeInputSpreadsheetImportation,
        postNewBarcodeInputClearance,
        putNewBarcodeInput,
        deleteNewBarcodeInput,
    };
}
