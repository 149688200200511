<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-note-pencil me-2"></i>
            {{ !sampleDetail ? 'Create' : 'Update' }} Detail
        </legend>
        <div class="row g-3 mb-md-1 mb-2">
            <div class="col-lg-6">
                <FormInput
                    type="text"
                    v-model="form.MATERIAL"
                    label="Material"
                    id-prefix="material"
                    disabled
                    :errors="errors.MATERIAL"
                    @keydown-enter="focusnext('colorFormInput')"
                />
            </div>
            <div class="col-lg-6">
                <FormInput
                    type="text"
                    v-model="form.COLOR"
                    label="Color"
                    id-prefix="color"
                    disabled
                    :errors="errors.COLOR"
                    @keydown-enter="focusnext('selectMaterialAndColorButton')"
                />
            </div>
        </div>
        <div class="text-end mb-3 mt-2">
            <button
                type="button"
                class="btn btn-primary"
                id="selectMaterialAndColorButton"
                @click="
                    (_) => {
                        isSelectMaterialPopupOpen = true;
                    }
                "
                @keydown.enter.prevent="
                    focusnext('detailTotalQuantityFormInput')
                ">
                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
            </button>
        </div>
        <FormInput
            type="text"
            v-model="form.material.UNIT"
            label="Unit of Measure"
            id-prefix="unitOfMeasure"
            class="mb-md-1 mb-2 align-items-center"
            disabled
            is-horizontal
            label-class="col-md-5"
            input-container-class="col-md-7 col-12"
        />
        <FormInput
            type="number"
            step=".01"
            v-model="form.QTY"
            label="Quantity"
            id-prefix="quantity"
            class="mb-md-1 mb-2 align-items-center"
            disabled
            :errors="errors.QTY"
            is-horizontal
            label-class="col-md-5"
            input-container-class="col-md-7 col-12"
        />
        <FormInput
            type="number"
            step=".01"
            v-model="totalQuantity"
            label="Total Quantity"
            id-prefix="detailTotalQuantity"
            class="mb-md-1 mb-2 align-items-center"
            @keydown-enter="
                (_) => {
                    if (!sampleDetail) {
                        focusnext('insertSampleDetailButton');
                    } else {
                        focusnext('updateSampleDetailButton');
                    }
                }
            "
            is-horizontal
            label-class="col-md-5"
            input-container-class="col-md-7 col-12"
        />
        <FormInput
            type="number"
            step=".01"
            v-model="form.material.PRICE"
            label="Original Unit Price"
            id-prefix="originalUnitPrice"
            class="mb-md-1 mb-2 align-items-center"
            disabled
            is-horizontal
            label-class="col-md-5"
            input-container-class="col-md-7 col-12"
        />
        <FormInput
            type="number"
            step=".01"
            v-model="totalPrice"
            label="Total Price"
            id-prefix="totalPrice"
            disabled
            class="mb-md-1 mb-2 align-items-center"
            is-horizontal
            label-class="col-md-5"
            input-container-class="col-md-7 col-12"
        />
        <hr class="my-4" />
        <div class="text-end">
            <button type="button"
                class="btn btn-outline-secondary me-2 px-2 mb-1"
                @click="reset">
                <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>
                Reset
            </button>
            <button type="submit"
                class="btn btn-primary me-2 px-2 mb-1"
                id="insertSampleDetailButton"
                :disabled="sampleDetail"
                @click="createDetail">
                <i class="icon ph-bold ph-file-plus me-2"></i>
                Insert
            </button>
            <button type="button"
                class="btn btn-primary me-2 px-2 mb-1"
                id="updateSampleDetailButton"
                :disabled="!sampleDetail"
                @click="updateDetail">
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>
                Update
            </button>
        </div>
    </fieldset>

    <MaterialSelectDialog
        :is-open="isSelectMaterialPopupOpen"
        @select="
            (selectedMaterial) => {
                form.material = selectedMaterial;
                form.MATERIAL = selectedMaterial.MATERIAL;
                form.COLOR = selectedMaterial.COLOR;
                isSelectMaterialPopupOpen = false;
            }
        "
        @close="
            (_) => {
                isSelectMaterialPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useSampleDetails } from "@/composables/data/sampleDetails";
import MaterialSelectDialog from "./MaterialSelectDialog.vue";
import FormInput from "@/components/utils/FormInput.vue";
import focusnext from "@/utils/focusnext";

const props = defineProps({
    sampleId: Number,
    sampleQuantity: Number,
});

const emit = defineEmits(["success"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { postSampleDetail, putSampleDetail } = useSampleDetails();

const sampleDetail = defineModel();

const form = ref({
    MATERIAL: null,
    COLOR: null,
    QTY: null,
    material: {
        UNIT: null,
        PRICE: null,
    },
});

const errors = ref({});

const totalQuantity = ref(null);

const totalPrice = computed(
    () => (totalQuantity.value ?? 0) * (form.value.material.PRICE ?? 0)
);

const isSelectMaterialPopupOpen = ref(false);

watch(sampleDetail, () => {
    form.value = Object.assign({
        MATERIAL: null,
        COLOR: null,
        QTY: null,
        material: null,
    }, sampleDetail.value);

    if (!form.value.material) {
        form.value.material = {
            UNIT: null,
            PRICE: null,
        };
    }

    totalQuantity.value = (form.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
});

watch(() => props.sampleQuantity, () => {
    totalQuantity.value = (form.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
});

watch(totalQuantity, () => {
    form.value.QTY = (totalQuantity.value ?? 0) / (props.sampleQuantity ?? 0);
});

function reset() {
    form.value = {
        MATERIAL: null,
        COLOR: null,
        QTY: null,
        material: {
            UNIT: null,
            PRICE: null,
        },
    };
    sampleDetail.value = null;
    totalQuantity.value = null;
    errors.value = {};
}

async function createDetail() {
    errors.value = {};

    loadingFlags.add("createDetail");
    try {
        const newSampleDetail = await postSampleDetail(
            props.sampleId,
            form.value
        );
        sampleDetail.value = newSampleDetail;
        toasts.add("SUCCESS", "Success", "Successfully saved sample detail.");
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createDetail");
}

async function updateDetail() {
    errors.value = {};

    loadingFlags.add("updateDetail");
    try {
        const newSampleDetail = await putSampleDetail(
            props.sampleId,
            sampleDetail.value.id,
            form.value
        );
        sampleDetail.value = newSampleDetail;
        toasts.add("SUCCESS", "Success", "Successfully updated sample detail.");
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateDetail");
}
</script>
