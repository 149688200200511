<template>
    <div class="row g-3">
        <div class="col-lg-4">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <FormSelect
                    v-model="paytype"
                    label="Paytype"
                    id-prefix="paytype"
                    :options="['WEEKLY', 'BIMONTHLY']"
                    class="mb-3"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <TimePeriodTable
                    show-from-current-date
                    :paytype="paytype"
                    :hide-properties="new Set(['PAYTYPE', 'STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    v-model="timePeriod"
                />
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clipboard-text me-2"></i>Reports
                </legend>
                <div class="d-grid gap-2">
                    <button type="button"
                        class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('encoded-hours-spreadsheet')">
                        <i class="icon ph-bold ph-keyboard me-2"></i>Encoded Hours Sheet
                    </button>
                    <button type="button"
                        class="btn btn-primary"
                        :disabled="!timePeriod"
                        @click="generateReport('with-disbursements-without-emphours-spreadsheet')">
                        <i class="icon ph-bold ph-wallet me-2"></i>W/ Disbursements W/O Hours
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i>Payslips in Time Period
                </legend>
                <PayslipsInTimePeriodTable
                    :time-period="timePeriod"
                    v-model="selectedPayslip"
                />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary mb-2"
                        :disabled="!selectedPayslip"
                        @click="
                            (_) => {
                                isViewUnpaidLoansPopupOpen = true;
                            }
                        ">
                        <i class="icon ph-bold ph-money me-2"></i>View Unpaid Loans
                    </button>
                </div>
            </fieldset>
        </div>
    </div>

    <ViewPayslipUnpaidLoansPopup
        :is-open="isViewUnpaidLoansPopupOpen"
        :payslip-id="selectedPayslip?.id"
        @close="
            (_) => {
                isViewUnpaidLoansPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref } from 'vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import PayslipsInTimePeriodTable from '@/components/utils/tables/PayslipsInTimePeriodTable.vue';
import ViewPayslipUnpaidLoansPopup from './ViewPayslipUnpaidLoansPopup.vue';

const paytype = ref('WEEKLY');
const timePeriod = ref(null);

const selectedPayslip = ref(null);

const isViewUnpaidLoansPopupOpen = ref(false);

function generateReport(report) {
    window.open(route(`api.time-periods.${report}.show`, {
        time_period: timePeriod.value.TIME_PERIOD
    }));
}
</script>
