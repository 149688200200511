<style scoped>
.container {
    max-width: 600px;
}
</style>

<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-upload-simple me-2"></i>Upload Vouchers
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 mb-3">
                <div class="modal-padding-container">
                    <form @submit.prevent="imageFileOnSubmit">
                        <div v-if="imageFiles" class="image-files-container">
                            <div v-for="(file, index) in imageFiles"
                                :key="index">
                                <p class="small">
                                    {{ file.file.name }} -
                                    <span v-if="file.status === 'Success'" class="badge bg-success">Success</span>
                                    <span v-if="file.status === 'Pending'" class="badge bg-warning">Pending</span>
                                    <span v-if="file.status === 'Uploading'" class="badge bg-info">Uploading</span>
                                    <span v-if="file.status === 'Failed'" class="badge bg-danger">Failed</span>
                                </p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="imageFileInput" class="form-label">Upload image</label>
                            <input
                                type="file"
                                required
                                ref="imageFileInput"
                                accept="image/jpeg"
                                @change="imageFileOnChange"
                                id="imageFileInput"
                                class="form-control form-control-sm"
                                multiple
                            />
                        </div>
                        <button type="submit"
                            class="btn btn-primary"
                            :disabled="isUploading">
                            <span v-if="isUploading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save Image
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    voucher: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const imageFileInput = ref(null);
const imageFiles = ref();

const isUploading = ref(false);

watch(() => props.isOpen, () => {
    imageFiles.value = null;
    imageFileInput.value = null;
})

function imageFileOnChange(event) {
    imageFiles.value = Array.from(event.target.files).map(file => ({
        file,
        status: 'Pending'
    }));
}

async function imageFileOnSubmit() {
    isUploading.value = true;
    try {
        for (const imageFile of imageFiles.value) {
            const formData = new FormData();
            formData.append("image", imageFile.file);

            imageFile.status = 'Uploading';

            const response = await axios.post(route('api.voucher.image.store'), formData);

            if (response.data.success) {
                imageFile.status = 'Success';
            }
            else {
                imageFile.status = 'Failed';
            }
        }
    } catch (e) {
        console.log(e);
    }
    isUploading.value = false;
}
</script>
