<template>
    <div class="row g-3">
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <FormSelect
                    v-model="paytype"
                    :options="['BIMONTHLY', 'WEEKLY']"
                    label="Select Paytype"
                    id-prefix="paytype"
                    is-horizontal
                    class="mb-2"
                    label-class="col-xl-4 col-md-5 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormSelect
                    v-model="departmentGroup"
                    :options="departmentGroups"
                    :option-to-string="option => option.name"
                    label="Select Department Group"
                    id-prefix="departmentGroup"
                    is-horizontal
                    class="mb-2"
                    label-class="col-xl-4 col-md-5 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-md-7 col-12"
                />
                <TimePeriodTable
                    v-model="timePeriod"
                    show-from-current-date
                    :filters="{
                        paytype: {
                            value: paytype ?? 'EMPTY',
                            matchMode: 'equals'
                        }
                    }"
                    :hide-properties="new Set(['STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                />
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i>Payslips in Time Period
                </legend>
                <PayslipsInTimePeriodTable
                    :time-period="timePeriod?.TIME_PERIOD"
                    v-model="selectedPayslip"
                    :department-group-id="departmentGroup?.id"
                />
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary me-1"
                        @click="generatePayslipsPDF"
                    >
                        Print All Payslips
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        :disabled="!selectedPayslip"
                        @click="generatePayslipOfEmployeePDF"
                    >
                        Print Selected Payslip
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDepartmentGroups } from '@/composables/data/departmentGroups';

import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import PayslipsInTimePeriodTable from '@/components/utils/tables/PayslipsInTimePeriodTable.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { departmentGroups, getDepartmentGroups } = useDepartmentGroups();

const departmentGroup = ref(null);

const paytype = ref(null);
const timePeriod = ref(null);

const selectedPayslip = ref(null);

async function fetchDepartmentGroups() {
    departmentGroups.value = null;

    if (!paytype.value) {
        return;
    }

    loadingFlags.add("fetchDepartmentGroups");
    try {
        await getDepartmentGroups({
            filters: {
                paytype: {
                    value: paytype.value,
                    matchMode: 'equals',
                },
            },
            rows: 0,
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchDepartmentGroups");
}

watch(paytype, () => {
    departmentGroup.value = null;
    timePeriod.value = null;
    fetchDepartmentGroups();
});

function generatePayslipsPDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: timePeriod.value.TIME_PERIOD,
        department_group_id: departmentGroup.value?.id,
    }));
}

function generatePayslipOfEmployeePDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: timePeriod.value.TIME_PERIOD,
        empid: selectedPayslip.value.employee.EMPID,
    }));
}
</script>
