<template>
    <div class="row mb-3">
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    Filters
                </legend>
                <FormInput
                    type="date"
                    v-model="filterForm.startDate"
                    label="Start Date"
                    id-prefix="startDate"
                    class="mb-2"
                />
                <FormInput
                    type="date"
                    v-model="filterForm.endDate"
                    label="End Date"
                    id-prefix="endDate"
                    class="mb-2"
                />
                <FormSelect
                    v-model="filterForm.paytype"
                    label="Paytype"
                    id-prefix="paytype"
                    :options="['WEEKLY', 'BIMONTHLY']"
                    @change="fetchDepartmentGroups"
                    class="mb-2"
                />
                <FormSelect
                    v-model="filterForm.departmentGroup"
                    label="Department Group"
                    id-prefix="departmentGroup"
                    :options="departmentGroups"
                    :option-to-string="option => option.name"
                    class="mb-2"
                />
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="applyFilters"
                    >
                        Apply Filters
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
    <fieldset>
        <legend>
            Retroactive Hours
        </legend>
        <RetroactiveHoursTable
            :start-date="filters.startDate"
            :end-date="filters.endDate"
            :paytype="filters.paytype"
            :department-group-id="filters.departmentGroup?.id"
            :refresh-flag="refreshFlag"
            v-model:filters="tableFilters"
            v-model:multi-sort-meta="tableSorts"
        />
        <div class="text-end">
            <button
                type="button"
                class="btn btn-sm btn-primary"
                @click="exportSpreadsheet"
            >
                Export Spreadsheet
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDepartmentGroups } from '@/composables/data/departmentGroups';

import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import RetroactiveHoursTable from './RetroactiveHoursTable.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { departmentGroups, getDepartmentGroups } = useDepartmentGroups();

const filterForm = ref({
    startDate: null,
    endDate: null,
    paytype: null,
    departmentGroup: null,
});

const refreshFlag = ref(false);

const filters = ref({});

const tableFilters = ref(null);
const tableSorts = ref(null);

async function fetchDepartmentGroups() {
    departmentGroups.value = null;

    if (!filterForm.value.paytype) {
        return;
    }

    loadingFlags.add("fetchDepartmentGroups");
    try {
        await getDepartmentGroups({
            filters: {
                paytype: {
                    value: filterForm.value.paytype,
                    matchMode: 'equals',
                },
            },
            rows: 0,
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchDepartmentGroups");
}

function applyFilters() {
    const requiredFilters = {
        startDate: 'start date',
        endDate: 'end date',
        paytype: 'paytype',
    };

    for (const [field, label] of Object.entries(requiredFilters)) {
        if (!filterForm.value[field]) {
            toasts.add("ERROR", "Error", `Please specify ${label}.`);
        }
    }

    filters.value = Object.assign({}, filterForm.value);

    refreshFlag.value = !refreshFlag.value;
}

function exportSpreadsheet() {
    window.open(route('api.employee-hours.retroactive-hours.spreadsheet.show', {
        start_date: filters.value.startDate,
        end_date: filters.value.endDate,
        paytype: filters.value.paytype,
        department_group_id: filters.value.departmentGroup?.id,
        filters: tableFilters.value,
        multiSortMeta: tableSorts.value,
    }));
}
</script>
