<template>
    <div class="row">
        <div class="col-xl-9">
            <fieldset class="mb-md-1 mb-3">
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i>Create Payroll
                </legend>
                <div class="row gx-4">
                    <div class="col-lg-5">
                        <div class="row mb-md-1 mb-2 align-items-center">
                            <label for="companyFilter" class="col-form-label col-sm-3 label-sm">Company</label>
                            <div class="col-sm-9">
                                <select v-model="companyFilter"
                                    id="companyFilter"
                                    class="form-select form-select-sm">
                                    <option :value="null">-- SELECT A COMPANY --</option>
                                    <option v-for="company in companyList"
                                        :value="company">
                                        {{ company.COMPANY_DESCRIPTION }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <FormSelect
                            v-model="timePeriodTypeFilter"
                            :options="[
                                'WEEKLY',
                                'BIMONTHLY - OFFICE',
                                'BIMONTHLY - SALES(1,2)',
                                'BIMONTHLY - SALES(3)'
                            ]"
                            label="Time Period"
                            id-prefix="timePeriod"
                            is-horizontal
                            class="mb-md-1 mb-2 align-items-center"
                            select-class="form-select-sm"
                            label-class="col-form-label col-sm-3 label-sm"
                            select-container-class="col-sm-9"
                            @change="timePeriodTypeFilterOnChange"
                        />
                        <div class="row mb-md-1 mb-2 align-items-center">
                            <label for="ptrDisplay" class="col-form-label col-sm-3 label-sm">PTR:</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    id="ptrDisplay"
                                    :value="ptrDisplay"
                                    class="form-control form-control-sm"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="row mb-3 gx-5">
                            <div class="col-lg-6">
                                <div class="row align-items-center mb-md-1 mb-2">
                                    <label for="fromTimePeriodFilter" class="form-label label-sm col-lg-2 col-sm-3">From</label>
                                    <div class="col-lg-10 col-sm-9">
                                        <select v-model="fromTimePeriodFilter"
                                            id="fromTimePeriodFilter"
                                            class="form-select form-select-sm">
                                            <option :value="null">-- SELECT FROM TIME PERIOD --</option>
                                            <option v-for="timePeriod in timePeriodsWithPaytype"
                                                :value="timePeriod">
                                                {{ timePeriod.TIME_PERIOD }} - {{ timePeriod.PAYTYPE }} - {{ timePeriod.START_DATE }} to
                                                {{ timePeriod.END_DATE }} - {{ timePeriod.STATUS }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row align-items-center mb-md-1 mb-2">
                                    <label for="toTimePeriodFilter" class="form-label col-lg-2 col-sm-3">To</label>
                                    <div class="col-lg-10 col-sm-9">
                                        <select v-model="toTimePeriodFilter"
                                            id="toTimePeriodFilter"
                                            class="form-select form-select-sm">
                                            <option :value="null">-- SELECT TO TIME PERIOD --</option>
                                            <option v-for="timePeriod in timePeriodsWithPaytype"
                                                :value="timePeriod">
                                                {{ timePeriod.TIME_PERIOD }} - {{ timePeriod.PAYTYPE }} - {{ timePeriod.START_DATE }} to
                                                {{ timePeriod.END_DATE }} - {{ timePeriod.STATUS }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-1">
                            <div class="col-md-4">
                                <button type="button"
                                    class="btn btn-primary btn-sm w-100 h-100 px-1"
                                    :disabled="exportPayrollToExcelLink == null"
                                    @click="exportPayrollToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export Payroll to Excel
                                </button>
                            </div>
                            <div class="col-md-4">
                                <button type="button"
                                    class="btn btn-primary btn-sm w-100 h-100 px-1"
                                    :disabled="exportBDOToExcelLink == null"
                                    @click="exportBDOToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export BDO to Excel
                                </button>
                            </div>
                            <div class="col-md-4">
                                <button type="button"
                                    class="btn btn-primary btn-sm w-100 h-100 px-1"
                                    :disabled="exportBPIToExcelLink == null"
                                    @click="exportBPIToExcelButtonOnClick">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export BPI to Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-4" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="fromTimePeriodFilter || toTimePeriodFilter">
                            <b>Time Period:</b> <b v-if="fromTimePeriodFilter">({{ fromTimePeriodFilter.TIME_PERIOD }}) {{ fromTimePeriodFilter.START_DATE }}</b>
                            to <b v-if="toTimePeriodFilter">({{ toTimePeriodFilter.TIME_PERIOD }}) {{ toTimePeriodFilter.END_DATE }}</b>
                        </p>
                        <div v-if="!isPayrollEntriesLoading" class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>AFLAG</th>
                                        <th>GL ID</th>
                                        <th>Class</th>
                                        <th>Acct Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="payrollEntries && payrollEntries.length > 0" v-for="payrollEntry in payrollEntries">
                                        <td>{{ payrollEntry.AFLAG }}</td>
                                        <td>{{ payrollEntry.GL_ID }}</td>
                                        <td>{{ payrollEntry.CLASS }}</td>
                                        <td>{{ payrollEntry.ACCTNAME }}</td>
                                        <td>{{ payrollEntry.AMOUNT1?.toFixed(2) }}</td>
                                        <td>{{ payrollEntry.AMOUNT2?.toFixed(2) }}</td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="6" class="text-center">
                                            <div class="text-center py-2">
                                                <i class="icon ph-bold ph-database me-2"></i>No entries yet.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="text-center mb-3">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row gx-5">
                            <div class="col-6">
                                <div class="row mb-md-1 mb-2">
                                    <label for="totalDebitDisplay" class="col-form-label col-lg-3 label-sm">Total Debit</label>
                                    <div class="col-lg-7">
                                        <input
                                            type="number"
                                            step=".01"
                                            id="totalDebitDisplay"
                                            :value="totalDebit"
                                            class="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-md-1 mb-2">
                                    <label for="totalCreditDisplay" class="col-form-label col-lg-3 label-sm">Total Credit</label>
                                    <div class="col-lg-7">
                                        <input
                                            type="number"
                                            step=".01"
                                            id="totalCreditDisplay"
                                            :value="totalCredit"
                                            class="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SearchVoucherFormModal
        id="searchVoucherFormModal"
        @select-voucher="(voucher) => (selectedVoucher = voucher)"
    />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";
import { useFlashMessages } from "../../../../composables/flashmessages";
import FormSelect from "@/components/utils/FormSelect.vue";
import moment from "moment";

const { addFlashMessage } = useFlashMessages();

const companyList = ref([]);

const companyFilter = ref(null);
const timePeriodTypeFilter = ref(null);
const openTimePeriod = ref(null);

const timePeriodsWithPaytype = ref(null);
const fromTimePeriodFilter = ref(null);
const toTimePeriodFilter = ref(null);

const isPayrollEntriesLoading = ref(false);
const payrollEntries = ref(null);
const totalDebit = ref(null);
const totalCredit = ref(null);

const exportPayrollToExcelLink = computed(() => {
    if (
        companyFilter.value == null ||
        fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null
    ) {
        return null;
    }

    return route('api.payroll.payroll-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag,
        COMPANY_CODE: companyFilter.value.COMPANY_CODE
    });
});

const exportBDOToExcelLink = computed(() => {
    if (
        companyFilter.value == null ||
        fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null
    ) {
        return null;
    }

    return route('api.payroll.bdo-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag
    });
});

const exportBPIToExcelLink = computed(() => {
    if (
        companyFilter.value == null ||
        fromTimePeriodFilter.value == null ||
        toTimePeriodFilter.value == null
    ) {
        return null;
    }

    return route('api.payroll.bpi-spreadsheet', {
        FROM_TIME_PERIOD: fromTimePeriodFilter.value.TIME_PERIOD,
        TO_TIME_PERIOD: toTimePeriodFilter.value.TIME_PERIOD,
        SFLAG: timePeriodFilterMapping[timePeriodTypeFilter.value].sflag
    });
});

const timePeriodFilterMapping = {
    "WEEKLY": {
        paytype: "WEEKLY",
        ptrDisplayStart: "PY/W/",
        sflag: "W",
        bdoflag: ""
    },
    "BIMONTHLY - OFFICE": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/A/",
        sflag: "O",
        bdoflag: ""
    },
    "BIMONTHLY - SALES(1,2)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "2"
    },
    "BIMONTHLY - SALES(3)": {
        paytype: "BIMONTHLY",
        ptrDisplayStart: "PY/S/",
        sflag: "S",
        bdoflag: "3"
    }
};

const ptrDisplay = computed(() => {
    if (openTimePeriod.value == null) {
        return;
    }

    return timePeriodFilterMapping[timePeriodTypeFilter.value].ptrDisplayStart +
        String(openTimePeriod.value.TIME_PERIOD).padStart(6, '0');
});

onMounted(async () => {
    try {
        const companyListResponse = await axios.get(route('api.companies.index'));
        companyList.value = companyListResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching companies.');
        scrollToTop();
    }
});

async function timePeriodTypeFilterOnChange() {
    try {
        const allTimePeriodsWithPaytypeResponse = await axios.get(
            route('api.time-periods.index' , {
                rows: 0,
                filters: {
                    PAYTYPE: {
                        value: timePeriodFilterMapping[timePeriodTypeFilter.value].paytype,
                        matchMode: 'equals',
                    },
                    START_DATE: {
                        value: moment.utc().format("YYYY-MM-DD"),
                        matchMode: 'lte',
                    },
                },
                multiSortMeta: [
                    {
                        field: 'TIME_PERIOD',
                        order: -1,
                    }
                ]
            }),
        );

        timePeriodsWithPaytype.value = allTimePeriodsWithPaytypeResponse.data.data;

        const openTimePeriodResponse = await axios.get(
            route('api.time-periods.index', {
                rows: 0,
                filters: {
                    PAYTYPE: {
                        value: timePeriodFilterMapping[timePeriodTypeFilter.value].paytype,
                        matchMode: 'equals',
                    },
                    START_DATE: {
                        value: moment.utc().format("YYYY-MM-DD"),
                        matchMode: 'lte',
                    },
                    STATUS: {
                        value: 'O',
                        matchMode: 'equals',
                    },
                },
                multiSortMeta: [
                    {
                        field: 'TIME_PERIOD',
                        order: -1,
                    }
                ]
            }),
        );

        const openTimePeriods = openTimePeriodResponse.data.data;
        if (openTimePeriods.length == 0) {
            addFlashMessage('WARNING', `There are no open time periods with this filter.`);
            return;
        } else if (openTimePeriods.length > 1) {
            addFlashMessage('WARNING', `Multiple time periods are open.
                Please check on time period maintenance.`);
            return;
        }

        openTimePeriod.value = openTimePeriodResponse.data.data[0];

        fromTimePeriodFilter.value = toTimePeriodFilter.value =
            timePeriodsWithPaytype.value.filter(timePeriod => timePeriod.TIME_PERIOD == openTimePeriod.value.TIME_PERIOD)[0];
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching open time period.');
        scrollToTop();
    }
}

function exportPayrollToExcelButtonOnClick() {
    window.open(exportPayrollToExcelLink.value, '_blank');
}

function exportBDOToExcelButtonOnClick() {
    window.open(exportBDOToExcelLink.value, '_blank');
}

function exportBPIToExcelButtonOnClick() {
    window.open(exportBPIToExcelLink.value, '_blank');
}
</script>
<style scoped>
thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}
</style>
