<template>
    <form @submit.prevent="createSample">
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.LEGACY_SAMPNO"
                    label="Legacy Sample No."
                    is-horizontal
                    id-prefix="legacySampNo"
                    class="mb-md-1 mb-2"
                    :errors="errors.LEGACY_SAMPNO"
                    @keydown-enter="focusnext('descriptionFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.DESCRIPTION"
                    label="Description"
                    is-horizontal
                    id-prefix="description"
                    class="mb-md-1 mb-2"
                    :errors="errors.DESCRIPTION"
                    @keydown-enter="focusnext('dateCreatedFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row mb-lg-0 mb-2">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.ACCOUNT_ID"
                    label="Account ID"
                    disabled
                    id-prefix="accountID"
                    class="mb-md-1 mb-2"
                    :form-text="sample.account?.ACCOUNT_NAME"
                    :errors="errors.ACCOUNT_ID"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
            <div class="col-lg-3">
                <button type="button"
                    class="btn btn-primary btn-sm"
                    @click="(_) => (isSelectingAccount = true)">
                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="date"
                    v-model="sample.DATE_CREATED"
                    label="Date Created"
                    is-horizontal
                    id-prefix="dateCreated"
                    class="mb-md-1 mb-2"
                    :errors="errors.DATE_CREATED"
                    @keydown-enter="focusnext('dateFinishedFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="date"
                    v-model="sample.DATE_FINISHED"
                    label="Date Finished"
                    is-horizontal
                    id-prefix="dateFinished"
                    class="mb-md-1 mb-2"
                    :errors="errors.DATE_FINISHED"
                    @keydown-enter="focusnext('quantityFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row mb-lg-0 mb-2">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.MEASURED_BY"
                    label="Measured By"
                    is-horizontal
                    disabled
                    id-prefix="measuredBy"
                    :form-text="`${sample.measured_by?.FNAME ?? ''} ${
                        sample.measured_by?.LNAME ?? ''
                    }`"
                    :errors="errors.MEASURED_BY"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
            <div class="col-lg-3">
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="(_) => { isSelectingMeasuredBy = true; }">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-lg-0 mb-2">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.SAMPLE_MAKER"
                    label="Sample Maker"
                    is-horizontal
                    disabled
                    id-prefix="sampleMaker"
                    :form-text="`${sample.sample_maker?.FNAME ?? ''} ${
                        sample.sample_maker?.LNAME ?? ''
                    }`"
                    :errors="errors.SAMPLE_MAKER"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
            <div class="col-lg-3">
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="(_) => { isSelectingSampleMaker = true; }">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="text"
                    v-model="sample.CREATED_BY"
                    label="Created By"
                    is-horizontal
                    disabled
                    id-prefix="createdBy"
                    class="mb-md-1 mb-2"
                    :errors="errors.CREATED_BY"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="number"
                    step=".01"
                    v-model="sample.LENGTH"
                    label="Length"
                    is-horizontal
                    class="mb-md-1 mb-2"
                    id-prefix="length"
                    :errors="errors.LENGTH"
                    @keydown-enter="focusnext('widthFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="number"
                    step=".01"
                    v-model="sample.WIDTH"
                    label="Width"
                    is-horizontal
                    class="mb-md-1 mb-2"
                    id-prefix="width"
                    :errors="errors.WIDTH"
                    @keydown-enter="focusnext('heightFormInput')"
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <FormInput
                    type="number"
                    step=".01"
                    v-model="sample.HEIGHT"
                    label="Height"
                    is-horizontal
                    id-prefix="height"
                    class="mb-md-1 mb-2"
                    :errors="errors.HEIGHT"
                    @keydown-enter="
                        (_) => {
                            if (!sample.SAMPLE_ID) focusnext('insertSampleButton');
                            else focusnext('updateSampleButton');
                        }
                    "
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
        </div>
        <hr class="my-4">
        <div class="text-end">
            <button type="button"
                class="btn btn-outline-secondary btn-sm me-2 mb-1"
                @click="reset">
                <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
            </button>
            <button type="submit"
                class="btn btn-primary btn-sm me-2 mb-1"
                id="insertSampleButton"
                :disabled="sample.SAMPLE_ID">
                <i class="icon ph-bold ph-file-plus me-2"></i>Insert
            </button>
            <button type="button"
                class="btn btn-primary btn-sm me-2 mb-1"
                id="updateSampleButton"
                :disabled="!sample.SAMPLE_ID"
                @click="updateSample">
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
            </button>
        </div>
    </form>

    <SelectAccount
        :is-open="isSelectingAccount"
        :on-close="(_) => (isSelectingAccount = false)"
        :on-select-account="
            (selectedAccount) => {
                sample.account = selectedAccount;
                sample.ACCOUNT_ID = selectedAccount.ACCOUNT_ID;
            }
        "
    />

    <!-- Measured By -->
    <SelectEmployee
        :is-open="isSelectingMeasuredBy"
        @close="(_) => { isSelectingMeasuredBy = false; }"
        @select="
            (selectedEmployee) => {
                isSelectingMeasuredBy = false;
                sample.measured_by = selectedEmployee;
                sample.MEASURED_BY = selectedEmployee.EMPID;
            }
        "
    />

    <!-- Sample Maker -->
    <SelectEmployee
        :is-open="isSelectingSampleMaker"
        @close="(_) => { isSelectingSampleMaker = false; }"
        @select="
            (selectedEmployee) => {
                isSelectingSampleMaker = false;
                sample.sample_maker = selectedEmployee;
                sample.SAMPLE_MAKER = selectedEmployee.EMPID;
            }
        "
    />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useLoggedInUser } from "@/composables/data/loggedInUser";
import { useSamples } from "@/composables/data/samples";
import FormInput from "@/components/utils/FormInput.vue";
import SelectAccount from "@/components/utils/SelectAccount.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import focusnext from "@/utils/focusnext";

const props = defineProps({
    selectedSample: Object,
    class: String,
});

const emit = defineEmits(["success", "reset", "error"]);

const { postSample, putSample } = useSamples();

const isSelectingAccount = ref(false);
const isSelectingMeasuredBy = ref(false);
const isSelectingSampleMaker = ref(false);

const { loggedInUser, getLoggedInUser } = useLoggedInUser();

const errors = ref({});

const sample = ref({
    LEGACY_SAMPNO: null,
    DESCRIPTION: null,
    ACCOUNT_ID: 0,
    DATE_CREATED: null,
    DATE_FINISHED: null,
    MEASURED_BY: 0,
    SAMPLE_MAKER: 0,
    CREATED_BY: null,
    LENGTH: null,
    WIDTH: null,
    HEIGHT: null,
});

onMounted(async () => {
    try {
        await getLoggedInUser();
        sample.value.CREATED_BY = loggedInUser.value.name_from_email;
    } catch (e) {
        emit("error", e.message);
    }
});

watch(
    () => props.selectedSample,
    () => {
        if (!props.selectedSample) {
            reset();
            return;
        }

        sample.value = props.selectedSample;
    }
);

function reset() {
    errors.value = {};
    sample.value = {
        LEGACY_SAMPNO: null,
        DESCRIPTION: null,
        ACCOUNT_ID: 0,
        DATE_CREATED: null,
        DATE_FINISHED: null,
        MEASURED_BY: 0,
        SAMPLE_MAKER: 0,
        CREATED_BY: loggedInUser.value.name_from_email,
        LENGTH: null,
        WIDTH: null,
        HEIGHT: null,
    };
    emit("reset");
}

async function createSample() {
    errors.value = {};

    try {
        const savedSample = await postSample(sample.value);
        sample.value = savedSample;
        emit("success", "Successfully updated sample.", sample.value);
    } catch (e) {
        emit("error", e.message);
        errors.value = e.errors;
    }
}

async function updateSample() {
    errors.value = {};

    try {
        const savedSample = await putSample(
            sample.value.SAMPLE_ID,
            sample.value
        );
        sample.value = savedSample;
        emit("success", "Successfully updated sample.", sample.value);
    } catch (e) {
        emit("error", e.message);
        errors.value = e.errors;
    }
}
</script>
