<template>
    <div class="row">
        <div class="col-md-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clock me-2"></i>Sales Leave Report
                </legend>
                <form @submit.prevent="generateReport">
                    <div class="row g-3 mb-3">
                        <div class="col-md-6">
                            <label for="fromTimePeriodChoice" class="form-label label-sm">From</label>
                            <select
                                v-model="fromTimePeriodChoice"
                                id="fromTimePeriodChoice"
                                class="form-select form-select-sm"
                            >
                                <option :value="null">-- SELECT START DATE --</option>
                                <option v-for="timePeriod in timePeriodChoices" :value="timePeriod">
                                    {{ timePeriod.TIME_PERIOD }} - {{ moment(timePeriod.START_DATE).format("MMM D, YYYY")}}
                                    to {{ moment(timePeriod.END_DATE).format("MMM D, YYYY") }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="toTimePeriodChoice" class="form-label">To</label>
                            <select
                                v-model="toTimePeriodChoice"
                                id="toTimePeriodChoice"
                                class="form-select form-select-sm"
                            >
                                <option :value="null">-- SELECT END DATE --</option>
                                <option v-for="timePeriod in timePeriodChoices" :value="timePeriod">
                                    {{ timePeriod.TIME_PERIOD }} - {{ moment(timePeriod.START_DATE).format("MMM D, YYYY") }}
                                    to {{ moment(timePeriod.END_DATE).format("MMM D, YYYY") }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-sm">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate Report
                    </button>
                </form>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import moment from 'moment';

import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";

const timePeriodChoices = ref(null);

const fromTimePeriodChoice = ref(null);
const toTimePeriodChoice = ref(null);

onMounted(async () => {
    try {
        const timePeriodsResponse = await axios.get(route('api.time-periods.index', {
            ORDER_DESC: 'TIME_PERIOD',
            PAYTYPE: 'BIMONTHLY',
            START_DATE_LTE: moment().format('YYYY-MM-DD'),
        }));
        timePeriodChoices.value = timePeriodsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while fetching time periods.');
        scrollToTop();
    }
});

function generateReport() {
    window.open(route('api.sales-leaves.spreadsheet') +
        `?from=${fromTimePeriodChoice.value.TIME_PERIOD}&to=${toTimePeriodChoice.value.TIME_PERIOD}`,
        '_blank');
}
</script>
