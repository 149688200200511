<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="ph ph-bell me-2"></i>View Event
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')" />
            </div>
            <hr />
            <div class="modal-container px-4">
                <template v-if="companyEvent">
                    <h5>{{ companyEvent.name }}</h5>
                    <div class="row g-3 mb-2">
                        <div class="col-3">
                            <b>When</b>
                        </div>
                        <div class="col-9">
                            {{ moment(companyEvent.event_date).format("dddd - MMMM D, YYYY") }}
                        </div>
                    </div>
                    <div class="row g-3 mb-2">
                        <div class="col-3">
                            <b>Event Type</b>
                        </div>
                        <div class="col-9">
                            {{ companyEventTypesNameMapping[companyEvent.type] }}
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-3">
                            <b>Description</b>
                        </div>
                        <div class="col-9">
                            {{ companyEvent.description }}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { useCompanyEventTypes } from '@/composables/data/companyEventTypes';

import moment from 'moment';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const companyEvent = defineModel();

const { companyEventTypes } = useCompanyEventTypes();

const companyEventTypesNameMapping = ref({});

onMounted(() => {
    companyEventTypesNameMapping.value = companyEventTypes?.reduce((mapping, type) => ({
        ...mapping,
        [type.value]: type.label
    }), {}) ?? {};
})
</script>

<style scoped>
.pop-up .container {
    width: 40%;
}

@media (max-with: 992px) {
    .pop-up .container {
        width: 60%;
    }
}

@media (max-with: 575.98px) {
    .pop-up .container {
        width: 80%;
    }
}
</style>
