import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useLoggedInUser() {
    const loggedInUser = ref(null);

    const getLoggedInUser = async (params) => {
        try {
            const userResponse = await axios.get(route('api.logged-in-user.index', params));
            loggedInUser.value = userResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching logged in user.');
        }
    };
    
    return { loggedInUser, getLoggedInUser };
}
