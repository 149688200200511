<template>
    <fieldset>
        <legend><i class="icon ph-bold ph-handbag me-2"></i> Same Style</legend>
        <DataTable
            lazy
            :loading="isLoading"
            :value="landedCosts"
            :total-records="landedCostsTotalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchLandedCosts();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchLandedCosts();
                }
            "
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COUNT"
                header="Count"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LANDED_COST"
                header="Landed Cost"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i> No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="isLoading"
                @click="fetchLandedCosts"
            >
                <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                >
                </span>
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Refresh
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLandedCosts } from "@/composables/data/landedCosts";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    style: String,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "COUNT",
    "LANDED_COST",
]);

const { landedCosts, landedCostsTotalRecords, getLandedCostsByStyle } = useLandedCosts();

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchLandedCosts() {
    landedCosts.value = null;

    if (!props.style) {
        return;
    }

    isLoading.value = true;
    try {
        await getLandedCostsByStyle(props.style, params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLandedCosts, 300);
}

onMounted(fetchLandedCosts);

watch(() => props.style, fetchLandedCosts);
</script>
