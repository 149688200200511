<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-archive-box me-2"></i>Inventory Done
        </legend>
        <DataTable
            :value="inventoryCheckCounts"
            paginator
            :rows="10"
            v-model:filters="filters"
            filterDisplay="row"
            v-model:selection="selectedInventoryCheckCount"
            selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="LOCATION"
                header="Location"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="INVENTORY_DONE"
                header="Inventory Done"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="description.DESCRIPTION"
                header="Description"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
                <template #body="{ data }">
                    {{ data.description?.DESCRIPTION }}
                </template>
            </Column>
            <Column
                field="TOTAL_QTY"
                header="Total Qty."
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer">
            <slot name="footer" />
        </template>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryCheckCounts } from "@/composables/data/inventoryCheckCounts";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
});

const { addFlashMessage } = useFlashMessages();

const { filters } = useDataTableParams([
    "LOCATION",
    "INVENTORY_DONE",
    "description.DESCRIPTION",
    "TOTAL_QTY",
]);

const { inventoryCheckCounts, getInventoryCheckCounts } =
    useInventoryCheckCounts();

const selectedInventoryCheckCount = defineModel();

watch(
    () => props.location,
    async () => {
        inventoryCheckCounts.value = null;

        if (!props.location) return;

        try {
            await getInventoryCheckCounts(props.location);
        } catch (e) {
            addFlashMessage("ERROR", e.message);
        }
    }
);
</script>
