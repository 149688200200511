<template>
    <div class="row g-3 my-3">
        <div class="col-md-12">
            <Card>
                <div class="row">
                    <div class="col-auto d-flex align-items-center">
                        <label
                            for="openTimePeriodDisplay"
                            class="form-label mb-0"
                        >
                            Description:
                        </label>
                    </div>
                    <div class="col">
                        <input
                            type="text"
                            v-model="description"
                            class="form-control"
                            disabled
                        />
                    </div>
                </div>
            </Card>
        </div>
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-list me-2"></i>1. Choose Activity
                </legend>
                <div>
                    <div class="form-check my-1">
                        <input
                            id="initialInventory"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('II')"
                            value="II"
                            v-model="selectedActivity"
                        />
                        <label for="initialInventory" class="form-check-label">
                            Initial Inventory
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="deliveryStore"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('DS')"
                            value="DS"
                            v-model="selectedActivity"
                        />
                        <label for="deliveryStore" class="form-check-label">
                            Delivery to store
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="pulloutStore"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('PS')"
                            value="PS"
                            v-model="selectedActivity"
                        />
                        <label for="pulloutStore" class="form-check-label">
                            Pullout from store
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="sales"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('SL')"
                            value="SL"
                            v-model="selectedActivity"
                        />
                        <label for="sales" class="form-check-label">
                            Sales
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="reconciliation"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('RC')"
                            value="RC"
                            v-model="selectedActivity"
                        />
                        <label for="reconciliation" class="form-check-label">
                            Reconciliation
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="newStock"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('NS')"
                            value="NS"
                            v-model="selectedActivity"
                        />
                        <label for="newStock" class="form-check-label">
                            New stock
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="return"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('RT')"
                            value="RT"
                            v-model="selectedActivity"
                        />
                        <label for="return" class="form-check-label">
                            Return
                        </label>
                    </div>
                    <div class="form-check my-1">
                        <input
                            id="replacement"
                            class="form-check-input"
                            type="radio"
                            name="activity"
                            @change="selectActivity('RP')"
                            value="RP"
                            v-model="selectedActivity"
                        />
                        <label for="replacement" class="form-check-label">
                            Replacement
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-map-pin-line me-2"></i>2. Select
                    Location
                </legend>
                <DataTable
                    :loading="isLocationsLoading"
                    lazy
                    :value="locations"
                    :total-records="locationsTotalRecords"
                    paginator
                    :rows="10"
                    @page="
                        (e) => {
                            onPage(e);
                            fetchLocations();
                        }
                    "
                    filter-display="row"
                    v-model:filters="filters"
                    @filter="tableOnFilter"
                    v-model:selection="selectedLocation"
                    selection-mode="single"
                    :pt="{
                        table: { class: 'table table-bordered table-hover' },
                    }"
                >
                    <Column
                        field="LOCATION"
                        header="Location"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DESCRIPTION"
                        header="Description"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Loading locations...
                    </template>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-gps-slash me-2"></i>No location found.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-md-12">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-chat-circle me-2"></i>
                    Comment Report
                </legend>
                <div class="mb-3">
                    <FormInput
                        type="date"
                        label="Comment Report Date:"
                        v-model="rptDate"
                        is-horizontal
                        id-prefix="rptDate"
                        class="mb-md-1 mb-2 align-items-center"
                        :errors="errors.REPORT_DATE"
                        label-class="col-xl-3 col-md-5 label-sm"
                        input-container-class="col-xl-4 col-md-7 col-12"
                        input-class="form-control-sm"
                    />
                    <FormInput
                        type="text"
                        label="Additional description:"
                        v-model="addText"
                        is-horizontal
                        id-prefix="addText"
                        class="mb-md-1 mb-2 align-items-center"
                        :errors="errors.COMMENT_TEXT"
                        :disabled="selectedActivity == ''"
                        label-class="col-xl-3 col-md-5 label-sm"
                        input-container-class="col-xl-4 col-md-7 col-12"
                        input-class="form-control-sm"
                    />
                    <div class="row mb-md-1 mb-2 align-items-center">
                        <label class="col-form-label col-xl-3 col-md-5 label-sm">
                            PAYEE TYPE
                        </label>
                        <div class="col-xl-4 col-md-7 col-12 position-relative"
                            ref="dropdownContainer">
                            <button
                                @click="isListVisible = !isListVisible"
                                class="dropdown-text d-flex justify-content-between w-100 form-control form-control-sm">
                                <span>{{ payeeTypeText }}</span>
                                <span><i class="fa-solid fa-angle-down"></i></span>
                            </button>
                            <ul v-if="isListVisible"
                                class="rounded-0 ps-2 ms-1 dropdown-list position-absolute top-100 start-0 list-group w-100">
                                <li @click="searchPayee('I')"
                                    class="ps-3 dropdown list-group-item list-group-item-action">
                                    INTERNAL
                                </li>
                                <li @click="searchPayee('E')"
                                    class="ps-3 dropdown list-group-item list-group-item-action">
                                    EXTERNAL
                                </li>
                            </ul>
                        </div>
                    </div>
                    <FormInput
                        type="text"
                        label="PAYEE ID"
                        v-model="payeeId"
                        is-horizontal
                        id-prefix="payeeId"
                        class="mb-md-1 mb-2 align-items-center"
                        disabled
                        label-class="col-xl-3 col-md-5 label-sm"
                        input-container-class="col-xl-4 col-md-7 col-12"
                        input-class="form-control-sm"
                    />
                    <div class="row mb-md-1 mb-2">
                        <div class="col offset-xl-3 offset-md-5">
                            <p class="ms-1">{{ payeeText }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col offset-xl-3 offset-md-5">
                            <button class="btn btn-primary ms-1 btn-sm"
                                :disabled="selectedLocation === null"
                                @click="submitComment">
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-plus-circle me-2"></i> Create New Comment
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectAccount
        :isOpen="isSelectAccountOpen"
        :onClose="() => closeSelectAccount()"
        :onSelectAccount="handleSelectAccount"
    />
    <SelectEmployee
        :isOpen="isSelectEmployeeOpen"
        @close="() => { isSelectEmployeeOpen = false; }"
        @select="(employee) => {
            isSelectEmployeeOpen = false;
            handleSelectEmployee(employee);
        }"
    />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import axios from "axios";
import Card from "@/components/utils/Card.vue";
import SelectAccount from "@/components/utils/SelectAccount.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import FormInput from "@/components/utils/FormInput.vue";
import { useLocations } from "@/composables/data/locations";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useFlashMessages } from "@/composables/flashmessages";
import scrollToTop from "@/utils/scrollToTop";
import handleFetchErrors from "@/utils/handleFetchErrors";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const { addFlashMessage } = useFlashMessages();

const description = ref("");
const rptDate = ref(new Date().toISOString().substr(0, 10));
const addText = ref("");
const payeeId = ref(0);
const payeeType = ref("E");
const payeeText = ref("NONE");
const payeeTypeText = ref("EXTERNAL");
const commentType = ref("");
const selectedActivity = ref("");
const selectedLocation = ref(null);
const isListVisible = ref(false);
const isSelectAccountOpen = ref(false);
const isSelectEmployeeOpen = ref(false);
const errors = ref({});
const isLoading = ref(false);
const locationWhere = ref(null);
const isLocationsLoading = ref(false);
const fetchTimeout = ref(null);

const { locations, locationsTotalRecords, getLocationsForComments } =
    useLocations();
const { params, filters, onPage, onFilter } = useDataTableParams([
    "LOCATION",
    "DESCRIPTION",
]);

watch(selectedActivity, () => {
    refreshDescription();
});

watch(selectedLocation, () => {
    refreshDescription();
});

watch(addText, () => {
    refreshDescription();
});

watch(payeeId, () => {
    refreshDescription();
});

function updateActivityName() {
    switch (selectedActivity.value) {
        case "II":
            locationWhere.value = `TYPE in ('S', 'H')`;
            commentType.value = "M";
            break;
        case "DS":
            locationWhere.value = `TYPE = 'S'`;
            commentType.value = "M";
            break;
        case "PS":
            locationWhere.value = `TYPE = 'H'`;
            commentType.value = "M";
            break;
        case "SL":
            locationWhere.value = `TYPE = 'P'`;
            commentType.value = "S";
            break;
        case "RC":
            locationWhere.value = `TYPE = 'R'`;
            commentType.value = "R";
            break;
        case "NS":
            locationWhere.value = `TYPE = 'H'`;
            commentType.value = "M";
            break;
        case "RT":
            locationWhere.value = `TYPE in ('S', 'H')`;
            commentType.value = "M";
            break;
        case "RP":
            locationWhere.value = `TYPE in ('S', 'H')`;
            commentType.value = "M";
            break;
        default:
            break;
    }
}

watch(locationWhere, fetchLocations);

function selectActivity(type) {
    selectedActivity.value = type;
    updateActivityName();
}

async function fetchLocations() {
    isLocationsLoading.value = true;
    locations.value = null;
    try {
        await getLocationsForComments(params.value, locationWhere.value);
    } catch (e) {
        handleFetchErrors(e, "Error while fetching locations.");
        scrollToTop();
    }
    isLocationsLoading.value = false;
}

function refreshDescription() {
    switch (selectedActivity.value) {
        case "II":
            description.value = "Initial Inventory";
            break;
        case "DS":
            description.value = "Delivery";
            break;
        case "PS":
            description.value = "Pull-out";
            break;
        case "SL":
            description.value = "Sales";
            break;
        case "RC":
            description.value = "Reconciliation";
            break;
        case "NS":
            description.value = "New Stock";
            break;
        case "RT":
            description.value = "Return";
            break;
        case "RP":
            description.value = "Replacement";
            break;
        default:
            break;
    }

    if (selectedLocation.value != null)
        description.value += " for " + selectedLocation.value.DESCRIPTION;
    if (payeeId.value != 0) description.value += ` [${payeeText.value}]`;
    if (selectedActivity.value != "")
        description.value += ` (${addText.value})`;
    else description.value = "";
}

const closeList = (event) => {
    const dropdownContainer = document.querySelector(".dropdownContainer");
    if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        isListVisible.value = false;
    }
};

onMounted(() => {
    document.addEventListener("click", closeList);
});

onBeforeUnmount(() => {
    document.removeEventListener("click", closeList);
});

//functions for search account
function openSelectAccount() {
    isSelectAccountOpen.value = true;
}

function closeSelectAccount() {
    isSelectAccountOpen.value = false;
}

function handleSelectAccount(selectedAccount) {
    payeeType.value = "E";
    payeeTypeText.value = "EXTERNAL";
    payeeText.value = selectedAccount.ACCOUNT_NAME;
    payeeId.value = selectedAccount.ACCOUNT_ID;
}

//functions for search Employee
function openSelectEmployee() {
    isSelectEmployeeOpen.value = true;
}

function closeSelectEmployee() {
    isSelectEmployeeOpen.value = false;
}

function handleSelectEmployee(selectedEmployee) {
    payeeType.value = "I";
    payeeTypeText.value = "INTERNAL";
    payeeText.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME;
    payeeId.value = selectedEmployee.EMPID;
}

function searchPayee(type) {
    isListVisible.value = false;
    switch (type) {
        case "E":
            openSelectAccount();
            break;
        case "I":
            openSelectEmployee();
            break;
        default:
            break;
    }
}

async function submitComment() {
    isLoading.value = true;
    try {
        const result = await axios.post(route("api.comments.store"), {
            COMMENT_TEXT: description.value,
            LOCATION: selectedLocation.value.LOCATION,
            REPORT_DATE: rptDate.value,
            COMMENT_TYPE: commentType.value,
            PAYEE_TYPE: payeeType.value,
            PAYEE_ID: payeeId.value,
        });
        addFlashMessage("SUCCESS", result.data[0]);
        clearData();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            addFlashMessage(
                "ERROR",
                `An error occurred while saving comment. Please contact your administrator.`
            );
        }
    }
    scrollToTop();
    isLoading.value = false;
}

function clearData() {
    locations.value = null;
    selectedLocation.value = null;
    selectedActivity.value = "";
    commentType.value = "";
    addText.value = "";
    payeeId.value = 0;
    payeeType.value = "E";
    payeeText.value = "NONE";
    payeeTypeText.value = "EXTERNAL";
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) clearTimeout(fetchTimeout.value);
    fetchTimeout.value = setTimeout(fetchLocations, 300);
}
</script>

<style>
    .dropdownContainer {
        margin-left: 0.35rem;
    }

    .dropdown {
        cursor: pointer;
        padding: 3px;
    }

    .dropdown:hover {
        background-color: #0d6efd;
        color: white;
    }

    .dropdown-list {
        z-index: 1;
    }

    .dropdown-text {
        background-color: #f9fafb;
        border: 1px solid #ced4da;
        color: black;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .form-check input,
    label {
        cursor: pointer;
    }
</style>
