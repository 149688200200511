<template>
    <Card :class="class">
        <div class="row g-1">
            <div class="col-sm-6">
                <button
                    type="button"
                    class="btn btn-primary px-1 btn-sm h-100 w-100"
                    disabled
                >
                    <i class="icon ph-bold ph-watch me-2"></i>Populate Absent
                </button>
            </div>
            <div class="col-sm-6">
                <button
                    type="button"
                    class="btn btn-primary px-1 btn-sm h-100 w-100"
                    :disabled="
                        employee === null
                        || (
                            employee.PAYTYPE != 'WEEKLY'
                            && !(
                                employee.PAYTYPE == 'BIMONTHLY'
                                && ['ADMIN', 'ADMIN - S', 'MAINTENANCE'].includes(employee.DEPT)
                            )
                        )
                    "
                    data-bs-toggle="modal"
                    :data-bs-target="'#' + showHoursModalId"
                >
                    <i class="icon ph-bold ph-clock-user me-2"></i>Show Hours
                </button>
            </div>
            <div class="col-sm-6">
                <button
                    type="button"
                    class="btn btn-primary px-1 btn-sm h-100 w-100"
                    disabled
                >
                    <i class="icon ph-bold ph-clock-afternoon me-2"></i>Work Hours
                </button>
            </div>
            <div class="col-sm-6">
                <button
                    type="button"
                    class="btn btn-primary px-1 btn-sm h-100 w-100"
                    :disabled="employee === null"
                    data-bs-toggle="modal"
                    :data-bs-target="'#' + enterEmployeeHoursDetailsModalId"
                    @click="(_) => { resetCoffeeBreakFlag = !resetCoffeeBreakFlag; }"
                >
                    <i class="icon ph-bold ph-clock-user me-2"></i>Enter Hours
                </button>
            </div>
            <div class="col-12">
                <button
                    type="button"
                    class="btn btn-success px-1 btn-sm w-100"
                    id="updateHoursButton"
                    :disabled="props.employee === null || props.isUpdateDisabled"
                    @click="updateHoursButtonOnClick"
                >
                    <i class="icon ph-bold ph-pencil-line me-2"></i> Update
                </button>
            </div>
        </div>
    </Card>
</template>

<script setup>
import Card from '../../../utils/Card.vue';

const props = defineProps({
    employee: Object,
    class: String,
    enterEmployeeHoursDetailsModalId: String,
    showHoursModalId: String,
    isUpdateDisabled: Boolean
});

const resetCoffeeBreakFlag = defineModel('resetCoffeeBreakFlag');

const emit = defineEmits(['update-button-click'])

function updateHoursButtonOnClick() {
    emit('update-button-click');
}
</script>
