<template>
    <form @submit.prevent="(_) => { emit('export', pulloutLetterDetails); }">
        <fieldset>
            <legend>
                <i class="icon ph-bold ph-info me-2"></i> Export SM Pullout Letter
            </legend>
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.vendor_code"
                required
                label="Vendor Code"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.scpoa_number"
                required
                label="SCPOA No."
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.store_code"
                required
                label="Store Code"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="date"
                v-model="pulloutLetterDetails.expected_pullout_date"
                required
                label="Expected Pullout Date"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.department_code"
                required
                label="Department Code"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.sub_department_code"
                required
                label="Sub Department Code"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.class_code"
                required
                label="Class Code"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                type="text"
                v-model="pulloutLetterDetails.box"
                required
                label="Box"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                input-class="form-control-sm"
                input-container-class="col-xl-8 col-md-7 col-12" />
            <hr class="my-4" />
            <div class="text-end">
                <button type="submit"
                    class="btn btn-primary btn-sm"
                    :disabled="disableExport">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Create SM
                    Pullout Letter
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    disableExport: Boolean,
});

const emit = defineEmits(["export"]);

const pulloutLetterDetails = ref({
    vendor_code: null,
    scpoa_number: null,
    store_code: null,
    expected_pullout_date: null,
    department_code: null,
    sub_department_code: null,
    class_code: null,
    box: null,
});
</script>
