<template>
    <Modal :id="id">
        <template #header>
            <h5 class="modal-title">
                <i class="icon ph-bold ph-printer me-2"></i>{{ title }}
            </h5>
        </template>
        <FormSelect
            v-model="selectedOption"
            label="Format"
            :options="options"
            :class="{ 'mb-5': selectedOption !== 'PDF' }"
        />
        <div class="px-3 mb-5" v-if="selectedOption === 'PDF'">
            <hr class="my-3" />
            <div class="row">
                <div class="col-sm-6 mb-2">
                    <FormInput
                        type="number"
                        required
                        label="Top:"
                        class="mb-3"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        v-model="margin.top"
                    />
                </div>
                <div class="col-sm-6 mb-2">
                    <FormInput
                        type="number"
                        required
                        label="Left:"
                        class="mb-3"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        v-model="margin.left"
                    />
                </div>
                <div class="col-sm-6 mb-sm-0 mb-2">
                    <FormInput
                        type="number"
                        required
                        label="Right:"
                        class="mb-3"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        v-model="margin.right"
                        disabled
                    />
                </div>
                <div class="col-sm-6 mb-sm-0 mb-2">
                    <FormInput
                        type="number"
                        required
                        label="Bottom:"
                        class="mb-3"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        v-model="margin.bottom"
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button"
                class="btn btn-primary"
                @click="printButtonOnClick">
                <i class="icon ph-bold ph-printer me-2"></i>Print
            </button>
        </template>
    </Modal>
</template>

<script setup>
import { ref } from "vue";

import Modal from "@/components/utils/Modal.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormInput from "@/components/utils/FormInput.vue";

const options = ['PDF', 'Spreadsheet'];

const props = defineProps({
    pdfProps: Object,
    pdfLink: String,
    spreadsheetLink: String,
    title: String,
    id: String
});

const margin = ref({
    top: 10,
    left: 25,
    right: 15,
    bottom: 20,
})

const selectedOption = ref(options[0]);

function printButtonOnClick() {
    if (selectedOption.value == options[0]) {
        window.open(route(props.pdfLink, {
            ...props.pdfProps,
            ...margin.value
        }), '_blank');
    } else if (selectedOption.value == options[1]) {
        window.open(props.spreadsheetLink, '_blank');
    }
}
</script>
