<style scoped>
#loansTableContainer {
    max-height: 300px;
    overflow-y: scroll;
}

#loansTableContainer thead th {
    background-color: var(--bs-card-bg);
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-3">
            <div class="row">
                <div class="col-xl-12 col-md-6">
                    <fieldset class="mb-md-1 mb-3">
                        <legend>
                            <i class="icon ph-bold ph-users me-2"></i>Select Employee
                        </legend>
                        <div class="row">
                            <div class="col-12">
                                <FormInput
                                    type="text"
                                    v-model="selectedEmployeeId"
                                    label="Employee ID"
                                    id-prefix="empid"
                                    disabled
                                    class="mb-md-1 mb-2"
                                    is-horizontal
                                    label-class="col-xl-4 col-lg-3 col-md-3 col-12 label-sm"
                                    input-class="form-control-sm"
                                    input-container-class="col-xl-8 col-lg-9 col-md-9 col-12"
                                />
                            </div>
                            <div class="col-12">
                                <FormInput
                                    type="text"
                                    v-model="selectedEmployeeDepartment"
                                    label="Department"
                                    id-prefix="department"
                                    disabled
                                    class="mb-md-1 mb-2"
                                    is-horizontal
                                    label-class="col-xl-4 col-lg-3 col-md-3 col-12 label-sm"
                                    input-class="form-control-sm"
                                    input-container-class="col-xl-8 col-lg-9 col-md-9 col-12"
                                />
                            </div>
                            <div class="col-12">
                                <FormInput
                                    type="text"
                                    v-model="selectedEmployeeFirstName"
                                    label="First Name"
                                    id-prefix="firstName"
                                    disabled
                                    class="mb-md-1 mb-2"
                                    is-horizontal
                                    label-class="col-xl-4 col-lg-3 col-md-3 col-12 label-sm"
                                    input-class="form-control-sm"
                                    input-container-class="col-xl-8 col-lg-9 col-md-9 col-12"
                                />
                            </div>
                            <div class="col-12">
                                <FormInput
                                    type="text"
                                    v-model="selectedEmployeeLastName"
                                    label="Last Name"
                                    id-prefix="lastName"
                                    disabled
                                    class="mb-md-1 mb-2"
                                    is-horizontal
                                    label-class="col-xl-4 col-lg-3 col-md-3 col-12 label-sm"
                                    input-class="form-control-sm"
                                    input-container-class="col-xl-8 col-lg-9 col-md-9 col-12"
                                />
                            </div>
                        </div>
                        <div class="text-end mt-3">
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                @click="(_) => { isSelectEmployeePopupOpen = true; }">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                            </button>
                        </div>
                    </fieldset>
                </div>
                <div class="col-xl-12 col-md-6">
                    <CreateLoanCard
                        :employee="selectedEmployee"
                        :no-of-weeks-on-paytype="noOfWeeksOnPaytype"
                        @voucher-image-view="viewVoucherImage"
                        @success="fetchLoans"
                    />
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <fieldset class="mb-md-1 mb-3">
                <legend>
                    <i class="icon ph-bold ph-money me-2"></i>Loans Table
                </legend>
                <div class="row justify-content-end g-3 mb-3">
                    <div class="col-xl-9">
                        <div class="row g-1">
                            <div class="col-lg-3">
                                <button type="button"
                                    class="btn btn-primary btn-sm mb-1 me-2 w-100 h-100 px-1"
                                    :disabled="!(
                                        selectedLoan &&
                                        selectedLoan.STATUS == 'O' &&
                                        ![ 'SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY' ].includes(selectedLoan.LOANTYPE)
                                    )"
                                    @click="closeLoan">
                                    <i class="icon ph-bold ph-x-circle me-2"></i>Close Loan
                                </button>
                            </div>
                            <div class="col-lg-3">
                                <button type="button"
                                    class="btn btn-primary btn-sm mb-1 me-2 w-100 h-100 px-1"
                                    :disabled="!(
                                        selectedLoan &&
                                        selectedLoan.STATUS == 'O' &&
                                        [ 'SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY' ].includes(selectedLoan.LOANTYPE)
                                    )"
                                    @click="closeGovtLoan">
                                    <i class="icon ph-bold ph-x-circle me-2"></i>Close Gov't Loan
                                </button>
                            </div>
                            <div class="col-lg-3">
                                <button type="button"
                                    class="btn btn-primary btn-sm mb-1 me-2 w-100 h-100 px-1"
                                    :disabled="!selectedEmployee"
                                    @click="exportToExcel">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Export to Excel
                                </button>
                            </div>
                            <div class="col-lg-3">
                                <button type="button"
                                    class="btn btn-primary btn-sm mb-1 w-100 h-100 px-1"
                                    :disabled="!selectedLoan"
                                    @click="viewVoucherImage(selectedLoan)">
                                    <i class="icon ph-bold ph-eye me-2"></i>View Voucher
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <select v-model="loanEmployeePaytypeFilter"
                            class="form-select form-select-sm"
                            @change="fetchAllEmployeeLoans">
                            <option :value="null">-- SELECT PAYTYPE --</option>
                            <option v-for="paytype in Object.keys(loanEmployeePaytypes)"
                                :value="paytype">
                                {{ paytype }}
                            </option>
                        </select>
                    </div>
                </div>
                <DataTable
                    :loading="loadingFlags.has('fetchLoans') || loadingFlags.has('fetchAllEmployeeLoans')"
                    :value="loans"
                    paginator
                    :rows="ROWS"
                    filter-display="row"
                    v-model:filters="filters"
                    selection-mode="single"
                    v-model:selection="selectedLoan"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column
                        field="LOANID"
                        header="Loan ID"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="LOANTYPE"
                        header="Type"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE"
                        header="Date"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="STATUS"
                        header="Status"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="INIT_AMT"
                        header="Initial Amt."
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="PRINCIPAL"
                        header="Principal"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="TOTAL_PAYMENT"
                        header="Total Payment"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="REMAIN_AMT"
                        header="Remain Amt."
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            columnHeaderContent: { style: 'width: 10px' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="AMT_PAYMENT"
                        header="Amt. per Payment"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="NUM_PAYMENTS"
                        header="# of Payments"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="VOUCHER"
                        header="Voucher"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DESCRIPTION"
                        header="Description"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DOCNO"
                        header="DOCNO"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="WEEKS_PASSED"
                        header="Weeks Passed"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DISCREPANCY"
                        header="Discrepancy"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CREATED_BY"
                        header="Created By"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CLOSURE_REASON"
                        header="Closure Reason"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            headerCell: { class: 'text-wrap align-top' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data yet.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>
    
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="_ => { isSelectEmployeePopupOpen = false; }"
        @select="
            employee => {
                isSelectEmployeePopupOpen = false;
                selectedEmployee = null;
                if (employee) {
                    selectedEmployee = employee;
                    fetchLoans();
                }
            }
        "
    />

    <VoucherImagesPopup
        :is-open="isVoucherImagesPopupOpen"
        :voucher-id="imageVoucherId"
        @close="(_) => { isVoucherImagesPopupOpen = false; }"
    />

    <GovtVoucherImagePopup
        :is-open="isGovtVoucherImagePopupOpen"
        :govt-voucher-id="imageVoucherId"
        @close="(_) => { isGovtVoucherImagePopupOpen = false; }"
    />
</template>

<script setup>
import { computed, ref } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useEmployeeLoans } from "@/composables/data/employeeLoans";
import { useToastsStore } from "@/stores/toasts";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import FormInput from "@/components/utils/FormInput.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import GovtVoucherImagePopup from "@/components/utils/popups/GovtVoucherImagePopup.vue";
import VoucherImagesPopup from "@/components/utils/popups/VoucherImagesPopup.vue";
import CreateLoanCard from "./CreateLoanCard.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import axios from "axios";

const ROWS = 15;
const { postLoanClosure, postGovtLoanClosure } = useEmployeeLoans();

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    'LOANID',
    'LOANTYPE',
    'DATE',
    'STATUS',
    'INIT_AMT',
    'PRINCIPAL',
    'TOTAL_PAYMENT',
    'REMAIN_AMT',
    'AMT_PAYMENT',
    'NUM_PAYMENTS',
    'VOUCHER',
    'DESCRIPTION',
    'DOCNO',
    'WEEKS_PASSED',
    'DISCREPANCY',
    'CREATED_BY',
    'CLOSURE_REASON',
], { rows: ROWS });

const loanEmployeePaytypes = {
    "WEEKLY": {
        PAYTYPE: "WEEKLY",
        STATUS: "O"
    },
    "BIMONTHLY (SALES)": {
        PAYTYPE: "BIMONTHLY",
        REPT_GROUP_NOT: "G",
        STATUS: "O"
    },
    "BIMONTHLY (NON-SALES)": {
        PAYTYPE: "BIMONTHLY",
        REPT_GROUP: "G",
        STATUS: "O"
    }
};

const isSelectEmployeePopupOpen = ref(false);

const selectedEmployee = ref(null);

const noOfWeeksOnPaytype = computed(() => {
    if (!selectedEmployee.value) {
        return 0;
    } else if (selectedEmployee.value.PAYTYPE === "WEEKLY") {
        return 1;
    } else if (selectedEmployee.value.PAYTYPE === "BIMONTHLY") {
        return 2;
    }
});

const selectedEmployeeId = computed(() => selectedEmployee.value?.EMPID);
const selectedEmployeeDepartment = computed(() => selectedEmployee.value?.DEPT);
const selectedEmployeeFirstName = computed(() => selectedEmployee.value?.FNAME);
const selectedEmployeeLastName = computed(() => selectedEmployee.value?.LNAME);

const loanEmployeePaytypeFilter = ref(null);

const loans = ref(null);

const selectedLoan = ref(null);

const imageVoucherId = ref(null);
const isVoucherImagesPopupOpen = ref(false);
const isGovtVoucherImagePopupOpen = ref(false);

async function fetchAllEmployeeLoans() {
    selectedEmployee.value = null;
    loans.value = null;

    loadingFlags.add('fetchAllEmployeeLoans');
    try {
        const loansResponse = await axios.get(route('api.loans.index',
            loanEmployeePaytypes[loanEmployeePaytypeFilter.value]));
        loans.value = loansResponse.data.data.map(loan => {
            const newLoan = Object.assign({}, loan);
            newLoan.AMT_PAYMENT = loan.AMT_WEEK;
            newLoan.NUM_PAYMENTS = loan.NUM_WEEKS;
            return newLoan;
        });
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchAllEmployeeLoans');
}

async function fetchLoans() {
    loadingFlags.add('fetchLoans');
    try {
        const loansResponse = await axios.get(route('api.employees.loans.index', {
            employee: selectedEmployee.value.EMPID,
            ORDER_DESC: 'STATUS,LOANID',
            INCLUDE_FIELDS: 'TOTAL_PAYMENT'
        }));
        loans.value = loansResponse.data.data.map(loan => {
            const newLoan = Object.assign({}, loan);
            newLoan.AMT_PAYMENT = loan.AMT_WEEK * noOfWeeksOnPaytype.value;
            newLoan.NUM_PAYMENTS = loan.NUM_WEEKS / noOfWeeksOnPaytype.value;
            return newLoan;
        });
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchLoans');
}

function exportToExcel() {
    window.open(route('api.employees.loans.spreadsheet.index', {
        employee: selectedEmployee.value.EMPID
    }), '_blank');
}

async function closeLoan() {
    const voucherId = window.prompt("Please input VOUCHER that contains final loan payment information.");

    if (!voucherId) {
        toasts.add('ERROR', 'Error', 'Blank voucher.');
        return;
    }

    loadingFlags.add('closeLoan');
    try {
        const closureResponse = await postLoanClosure(
            selectedEmployee.value.EMPID,
            selectedLoan.value.LOANID,
            voucherId
        );
        toasts.add('SUCCESS', 'Success', closureResponse.data.message);
        fetchLoans();
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('closeLoan');
}

async function closeGovtLoan() {
    const reason = window.prompt("Please enter reason for closing Gov't Loan: ");

    if (!reason) {
        toasts.add('ERROR', 'Error', 'Blank reason.');
        return;
    }

    loadingFlags.add('closeGovtLoan');
    try {
        const closureResponse = await postGovtLoanClosure(
            selectedEmployee.value.EMPID,
            selectedLoan.value.LOANID,
            reason
        );
        toasts.add('SUCCESS', 'Success', closureResponse.data.message);
        fetchLoans();
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('closeGovtLoan');
}

function viewVoucherImage(loan) {
    imageVoucherId.value = loan.VOUCHER;

    if (['SSS', 'SSS CALAMITY', 'PAGIBIG', 'PAGIBIG CALAMITY'].includes(loan.LOANTYPE)) {
        isGovtVoucherImagePopupOpen.value = true;
    } else {
        isVoucherImagesPopupOpen.value = true;
    }
}
</script>
