import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleRobinsonsSkus() {
    const styleRobinsonsSkus = ref(null);

    const getStyleRobinsonsSkus = async (style, params) => {
        try {
            const getStyleRobinsonsSkusResponse = await axios.get(route("api.styles.robinsons-skus.index", {
                style,
                ...params
            }));
            styleRobinsonsSkus.value = getStyleRobinsonsSkusResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Robinsons SKUs.');
        }
    };

    const postStyleRobinsonsSku = async (style, styleRobinsonsSku) => {
        try {
            const postStyleRobinsonsSkuResponse = await axios.post(route('api.styles.robinsons-skus.store', {
                style,
            }), styleRobinsonsSku);
            return postStyleRobinsonsSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting Robinsons SKU.');
        }
    };

    const putStyleRobinsonsSku = async (style, styleRobinsonsSkuId, styleRobinsonsSku) => {
        try {
            const putStyleRobinsonsSkuResponse = await axios.put(route('api.styles.robinsons-skus.update', {
                style,
                robinsons_sku: styleRobinsonsSkuId,
            }), styleRobinsonsSku);
            return putStyleRobinsonsSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating Robinsons SKU.');
        }
    };
    
    return {
        styleRobinsonsSkus,
        getStyleRobinsonsSkus,
        postStyleRobinsonsSku,
        putStyleRobinsonsSku,
    };
}
