import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyles() {
    const styles = ref(null);
    const style = ref(null);
    const totalRecords = ref(null);

    const getStyles = async (params) => {
        try {
            const stylesResponse = await axios.get(route('api.styles.index', params));
            styles.value = stylesResponse.data.data;
            totalRecords.value = stylesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching styles.');
        }
    };

    const getStyle = async (STYLE) => {
        try {
            const styleResponse = await axios.get(route('api.styles.show', {
                style: STYLE
            }));
            style.value = styleResponse.data.data;
            return style.value;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching styles.');
        }
    };

    const getStylesInLocation = async (location, params) => {
        try {
            const getStylesInLocationResponse = await axios.get(route('api.locations.used-styles.index', {
                location: location,
                ...params
            }));
            styles.value = getStylesInLocationResponse.data.data;
            totalRecords.value = getStylesInLocationResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching styles.');
        }
    };

    const postStyle = async (styleData) => {
        try {
            const postStyleResponse = await axios.post(route('api.styles.store'), styleData);
            return postStyleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting style.');
        }
    };

    const postStyleImage = async (style, formData) => {
        try {
            const postStyleImageResponse = await axios.post(route('api.styles.image.store', {
                style
            }), formData);
            return postStyleImageResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while uploading style image.');
        }
    };

    const putStyle = async (style, styleData) => {
        try {
            const putStyleResponse = await axios.put(route('api.styles.update', {
                style
            }), styleData);
            return putStyleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating style.');
        }
    };
    
    return {
        styles,
        totalRecords,
        style,
        getStyles,
        getStyle,
        getStylesInLocation,
        postStyle,
        postStyleImage,
        putStyle,
    };
}
