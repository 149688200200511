import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function usePersonalNotes() {
    const personalNotes = ref(null);
    const totalRecords = ref(null);
    const personalNote = ref(null);

    const getLoggedInUserPersonalNotes = async (params) => {
        try {
            const getLoggedInUserPersonalNotesResponse = await axios.get(route('api.logged-in-user.personal-notes.index', params));
            personalNotes.value = getLoggedInUserPersonalNotesResponse.data.data;
            totalRecords.value = getLoggedInUserPersonalNotesResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching personal notes.');
        }
    };

    const getLoggedInUserPersonalNote = async (personalNoteId) => {
        try {
            const getLoggedInUserPersonalNoteResponse = await axios.get(route('api.logged-in-user.personal-notes.show', {
                personal_note: personalNoteId
            }));
            personalNote.value = getLoggedInUserPersonalNoteResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching personal note.');
        }
    };

    const postLoggedInUserPersonalNote = async (personalNote) => {
        try {
            const postLoggedInUserPersonalNoteResponse = await axios.post(route('api.logged-in-user.personal-notes.store'), personalNote);
            return postLoggedInUserPersonalNoteResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving personal note.');
        }
    };

    const putLoggedInUserPersonalNote = async (personalNoteId, personalNote) => {
        try {
            const putLoggedInUserPersonalNoteResponse = await axios.put(route('api.logged-in-user.personal-notes.update', {
                personal_note: personalNoteId
            }), personalNote);
            return putLoggedInUserPersonalNoteResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating personal note.');
        }
    };

    const deleteLoggedInUserPersonalNote = async (personalNoteId) => {
        try {
            const deleteLoggedInUserPersonalNoteResponse = await axios.delete(route('api.logged-in-user.personal-notes.destroy', {
                personal_note: personalNoteId
            }));
            return deleteLoggedInUserPersonalNoteResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting personal note.');
        }
    };
    
    return {
        personalNotes,
        totalRecords,
        personalNote,
        getLoggedInUserPersonalNotes,
        getLoggedInUserPersonalNote,
        postLoggedInUserPersonalNote,
        putLoggedInUserPersonalNote,
        deleteLoggedInUserPersonalNote,
    };
}
