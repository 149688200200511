<template>
    <DataTable
        :loading="loadingFlags.has('fetchTabulationYearEquivalences')"
        lazy
        :value="tabulationYearEquivalences"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchTabulationYearEquivalences(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedTabulationYearEquivalence"
        data-key="id"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column
            field="id"
            header="ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="dept"
            header="Department"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="days_in_year"
            header="Days in Year"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="start_date"
            header="Start Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="end_date"
            header="End Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control" placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useTabulationYearEquivalences } from '@/composables/data/tabulationYearEquivalences';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "id",
    "dept",
    "days_in_year",
    "start_date",
    "end_date",
]);

const {
    tabulationYearEquivalences,
    totalRecords,
    getTabulationYearEquivalences
} = useTabulationYearEquivalences();

const selectedTabulationYearEquivalence = defineModel();

const fetchTimeout = ref(null);

async function fetchTabulationYearEquivalences() {
    tabulationYearEquivalences.value = null;

    loadingFlags.add('fetchTabulationYearEquivalences');
    try {
        await getTabulationYearEquivalences(params.value);
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchTabulationYearEquivalences');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchTabulationYearEquivalences, 300);
}

onMounted(fetchTabulationYearEquivalences);

watch(() => props.refreshFlag, fetchTabulationYearEquivalences);
</script>
