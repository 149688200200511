<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-clipboard-text me-2"></i>Select
                    Joborder
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 mb-3">
                <div class="modal-padding-container">
                    <JobordersTable v-model="selectedJoborder" />
                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="!selectedJoborder"
                            @click="
                                (_) => {
                                    emit('select', selectedJoborder);
                                }
                            ">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Joborder
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import JobordersTable from './tables/JobordersTable.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['select', 'close']);

const selectedJoborder = ref(null);
</script>
