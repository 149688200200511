<template>
    <div class="row mb-3">
        <div class="col-xl-2 col-lg-3 mb-3">
            <fieldset class="mb-3 h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-info me-2"></i>Details
                </legend>
                <div>
                    <FormInput
                        type="text"
                        v-model="timePeriodDisplay"
                        disabled
                        label="Time Period"
                        id-prefix="timePeriodDisplay"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-6 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="locationReconCommentDisplay"
                        disabled
                        label="Recon CMT"
                        id-prefix="reconCMTDisplay"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-6 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="locationReconCommentDate"
                        disabled
                        label="Recon DT"
                        id-prefix="reconDTDisplay"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-6 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="inventoryDoneDisplay"
                        disabled
                        label="Inventory Done"
                        id-prefix="inventoryDoneDisplay"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-6 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="locationDisplay"
                        disabled
                        label="Location"
                        id-prefix="locationDisplay"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-6 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-6 col-md-7 col-12"
                    />
                </div>

                <div class="text-end">
                    <hr class="my-3" />
                    <button
                        type="button"
                        class="btn btn-sm btn-primary px-1"
                        @click="(_) => { isSelectLocationReconPopupOpen = true; }">
                        <i class="icon ph-bold ph-clock me-2"></i>Change Time Period
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-7 col-lg-9 mb-1">
            <ReconRawFinalFullTableCard
                :location="reconParm?.LOCATION"
                :time-period="reconParm?.TIME_PERIOD"
                v-model="selectedStyleSummary"
                :selected-replacement-barcode-style="selectedReplacementBarcode?.STYLE"
                :selected-missing-barcode-style="selectedMissingBarcode?.STYLE"
                class="recon-investigation-table-card h-100">
            </ReconRawFinalFullTableCard>
        </div>
        <div class="col-xl-3 mb-1">
            <InventorySoldTableCard
                :location="reconParm?.LOCATION"
                :style="selectedStyleSummary?.STYLE"
                class="recon-investigation-table-card h-100"
            />
        </div>
        <div class="col-xl-9 mb-1">
            <div class="row mb-1">
                <div class="col-md-4">
                    <InventoryCheckInReconSameMonthTableCard
                        :location="reconParm?.LOCATION"
                        :time-period="reconParm?.TIME_PERIOD"
                        :inventory-done="reconParm?.INVENTORY_DATE"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="tableARefreshFlag"
                        v-model="selectedInventoryCheckFromTableA"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
                <div class="col-md-4">
                    <InventoryCheckNotInReconSameMonthTableCard
                        :location="reconParm?.LOCATION"
                        :time-period="reconParm?.TIME_PERIOD"
                        :inventory-done="reconParm?.INVENTORY_DATE"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="tableBRefreshFlag"
                        v-model="selectedInventoryCheckFromTableB"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
                <div class="col-md-4">
                    <InventoryCheckSameStyleDiffMonthNoBarcodeTableCard
                        :location="reconParm?.LOCATION"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="tableCRefreshFlag"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-md-4">
                    <ReconRawFullNotInStoreHistoricalTableCard
                        :location="reconParm?.LOCATION"
                        :time-period="reconParm?.TIME_PERIOD"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="table1RefreshFlag"
                        v-model="selectedReconRawFullFromTable1"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
                <div class="col-md-4">
                    <ReconRawFullInStoreInInvCheckTableCard
                        :location="reconParm?.LOCATION"
                        :time-period="reconParm?.TIME_PERIOD"
                        :inventory-done="reconParm?.INVENTORY_DATE"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="table2RefreshFlag"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
                <div class="col-md-4">
                    <ReconRawFullInStoreNotInInvCheckTableCard
                        :location="reconParm?.LOCATION"
                        :time-period="reconParm?.TIME_PERIOD"
                        :inventory-done="reconParm?.INVENTORY_DATE"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="table3RefreshFlag"
                        v-model="selectedReconRawFullFromTable3"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <InventoryDetailsTableCard
                        :bag-identifier="selectedBagIdentifier"
                        :location="reconParm?.LOCATION"
                        :refresh-flag="tableIRefreshFlag"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
                <div class="col-md-4">
                    <InventoryCheckDetailsTableCard
                        :bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                        :refresh-flag="tableIIRefreshFlag"
                    />
                </div>
                <div class="col-md-4">
                    <InventoryReconDeleteTableCard
                        :location="reconParm?.LOCATION"
                        :style="selectedStyleSummary?.STYLE"
                        :refresh-flag="tableIIIRefreshFlag"
                        v-model="selectedInventoryReconDeleteFromTableIII"
                        v-model:selected-bag-identifier="selectedBagIdentifier"
                        class="recon-investigation-table-card h-100"
                    />
                </div>
            </div>
        </div>
        <div class="col-xl-3 mb-1">
            <div class="mt-3">
                <div class="row g-1 investigate-controls">
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedInventoryCheckFromTableA"
                            @click="deleteDuplicateFromTableA">
                            Delete DUP from (A)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <!-- In Testing Mode on Old System -->
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            disabled>
                            Replace (3) with (B) (MISSING RETURN)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedReconRawFullFromTable1 || !selectedReconRawFullFromTable3"
                            @click="replaceMissingSaleRR">
                            Replace (1) with (3) (MISSING SALE/RR)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedReconRawFullFromTable1 || !selectedReconRawFullFromTable3"
                            @click="replaceMissingReturn">
                            Replace (3) with (1) (MISSING RETURN)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedReconRawFullFromTable3"
                            @click="addFromTable3ToInventoryCheck">
                            Add (3) to INVENTORY_CHECK
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedInventoryCheckFromTableB || !selectedReconRawFullFromTable1"
                            @click="replaceNOBCSaleWithAlreadySold">
                            Replace NO BC Sale (1) Already Sold (B) (DUPLICATE)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <!-- Disabled on old system -->
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            disabled>
                            ** CLEANUP ONLY ** Delete from (3)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedStyleSummary"
                            @click="(_) => { isAddNoBarcodeToInventoryCheckPopupOpen = true; }">
                            Add Style to INVENTORY_CHECK (NO BARCODE)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="displayExtraData">
                            Display Extra Data
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <!-- Not Used anymore in old system -->
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            disabled>
                            Replace (3) with (1) (MISSING RETURN) - Previous Month
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedInventoryReconDeleteFromTableIII"
                            @click="undeleteBarcodeFromInventoryReconDelete">
                            Undelete from (III) (NOT MISSING ANYMORE)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="(_) => { isUndeleteLostBarcodesPopupOpen = true; }">
                            Undelete MULTIPLE from (III) (NOT MISSING ANYMORE)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedReconRawFullFromTable3"
                            @click="(_) => { isBarcodePopupOpen = true; }">
                            Delete from (3) (MISSING)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="(_) => { isDeleteMissingBarcodesPopupOpen = true; }">
                            Delete MULTIPLE from (3) (MISSING)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            :disabled="!selectedReconRawFullFromTable1"
                            @click="deleteDuplicateNOBCSale">
                            Delete NO BC SALE from (1) (DUPLICATE)
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="exportMissingBarcodesSpreadsheet">
                            Export Missing Barcodes to Spreadsheet
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="exportExcessBarcodesSpreadsheet">
                            Export Excess Barcodes to Spreadsheet
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-3 d-grid">
                        <button type="button"
                            class="btn btn-sm btn-primary px-1"
                            @click="(_) => { isExportDiscrepancyPopupOpen = true; }">
                            Export DISCREPANCY
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <ReplacementBarcodesTableCard
                :location="reconParm?.LOCATION"
                v-model="selectedReplacementBarcode"
                class="recon-investigation-table-card">
                <template #footer>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary"
                            @click="exportReplacementBarcodesSpreadsheet">
                            <i class="icon ph-bold ph-file-xls me-2"></i>Export RR to Spreadsheet
                        </button>
                    </div>
                </template>
            </ReplacementBarcodesTableCard>
        </div>
        <div class="col-xl-6">
            <MissingBarcodesTableCard
                :location="reconParm?.LOCATION"
                v-model="selectedMissingBarcode"
                class="recon-investigation-table-card">
                <template #footer>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary"
                            @click="exportNOBCSpreadsheet">
                            <i class="icon ph-bold ph-file-xls me-2"></i>Export NOBC to Spreadsheet
                        </button>
                    </div>
                </template>
            </MissingBarcodesTableCard>
        </div>
    </div>

    <DeleteBarcodeFormPopup
        :is-open="isBarcodePopupOpen"
        :location="reconParm?.LOCATION"
        :time-period="reconParm?.TIME_PERIOD"
        :recon-raw-full="selectedReconRawFullFromTable3"
        @close="(_) => { isBarcodePopupOpen = false; }"
        @done="(message) => {
                refreshFlag = !refreshFlag;
                isBarcodePopupOpen = false;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }"
        @error="(message) => {
                isAddNoBarcodeToInventoryCheckPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }"
    />
    <AddNoBarcodeToInventoryCheckFormPopup
        :is-open="isAddNoBarcodeToInventoryCheckPopupOpen"
        :location="reconParm?.LOCATION"
        :inventoryDone="reconParm?.INVENTORY_DATE"
        :style="selectedStyleSummary?.STYLE"
        @close="(_) => { isAddNoBarcodeToInventoryCheckPopupOpen = false; }"
        @done="(message) => {
                refreshFlag = !refreshFlag;
                isAddNoBarcodeToInventoryCheckPopupOpen = false;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }"
        @error="(message) => {
                isAddNoBarcodeToInventoryCheckPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }"
    />
    <ExportDiscrepancyPerStorePopup
        :location="reconParm?.LOCATION"
        :is-open="isExportDiscrepancyPopupOpen"
        @close="(_) => { isExportDiscrepancyPopupOpen = false; }"
    />
    <DeleteMissingBarcodesFormPopup
        :is-open="isDeleteMissingBarcodesPopupOpen"
        :location="reconParm?.LOCATION"
        :time-period="reconParm?.TIME_PERIOD"
        :inventory-done="reconParm?.INVENTORY_DATE"
        @close="(_) => { isDeleteMissingBarcodesPopupOpen = false; }"
        @done="(message) => {
                refreshFlag = !refreshFlag;
                isDeleteMissingBarcodesPopupOpen = false;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }"
        @error="(message) => {
                isDeleteMissingBarcodesPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }"
    />
    <UndeleteLostBarcodesFormPopup
        :is-open="isUndeleteLostBarcodesPopupOpen"
        :location="reconParm?.LOCATION"
        :time-period="reconParm?.TIME_PERIOD"
        :inventory-done="reconParm?.INVENTORY_DATE"
        @close="(_) => { isUndeleteLostBarcodesPopupOpen = false; }"
        @done="(message) => {
                refreshFlag = !refreshFlag;
                isUndeleteLostBarcodesPopupOpen = false;
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }"
        @error="(message) => {
                isUndeleteLostBarcodesPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }"
    />
    <SelectLocationReconPopup
        :location="reconParm?.LOCATION"
        :is-open="isSelectLocationReconPopupOpen"
        @close="(_) => { isSelectLocationReconPopupOpen = false; }"
    />
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useReconParms } from "@/composables/data/reconParms";
import { useReconActions } from "@/composables/data/reconActions";
import FormInput from "@/components/utils/FormInput.vue";
import ReconRawFinalFullTableCard from "./ReconRawFinalFullTableCard.vue";
import InventoryCheckInReconSameMonthTableCard from "./InventoryCheckInReconSameMonthTableCard.vue";
import InventoryCheckNotInReconSameMonthTableCard from "./InventoryCheckNotInReconSameMonthTableCard.vue";
import InventoryCheckSameStyleDiffMonthNoBarcodeTableCard from "./InventoryCheckSameStyleDiffMonthNoBarcodeTableCard.vue";
import ReconRawFullNotInStoreHistoricalTableCard from "./ReconRawFullNotInStoreHistoricalTableCard.vue";
import ReconRawFullInStoreInInvCheckTableCard from "./ReconRawFullInStoreInInvCheckTableCard.vue";
import ReconRawFullInStoreNotInInvCheckTableCard from "./ReconRawFullInStoreNotInInvCheckTableCard.vue";
import InventoryDetailsTableCard from "./InventoryDetailsTableCard.vue";
import InventoryCheckDetailsTableCard from "./InventoryCheckDetailsTableCard.vue";
import InventoryReconDeleteTableCard from "./InventoryReconDeleteTableCard.vue";
import ReplacementBarcodesTableCard from "./ReplacementBarcodesTableCard.vue";
import MissingBarcodesTableCard from "./MissingBarcodesTableCard.vue";
import InventorySoldTableCard from "./InventorySoldTableCard.vue";
import DeleteBarcodeFormPopup from "./DeleteBarcodeFormPopup.vue";
import DeleteMissingBarcodesFormPopup from "./DeleteMissingBarcodesFormPopup.vue";
import UndeleteLostBarcodesFormPopup from "./UndeleteLostBarcodesFormPopup.vue";
import AddNoBarcodeToInventoryCheckFormPopup from "./AddNoBarcodeToInventoryCheckFormPopup.vue";
import ExportDiscrepancyPerStorePopup from "./ExportDiscrepancyPerStorePopup.vue";
import SelectLocationReconPopup from "./SelectLocationReconPopup.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { reconParm, getReconParm } = useReconParms();

const {
    postDuplicateDeletion,
    postMissingReturnReplacement,
    postMissingSaleRRReplacement,
    postNOBCSaleReplacementWithAlreadySold,
    postMissingItemAdditionToInventoryCheck,
    postBarcodeMissingUndeletion,
    postDuplicateNoBarcodeDeletion,
} = useReconActions();

const timePeriodDisplay = computed(() => reconParm.value?.TIME_PERIOD);
const locationReconCommentDisplay = computed(
    () => reconParm.value?.location?.RECON_COMMENT_ID
);
const locationReconCommentDate = computed(
    () => reconParm.value?.location?.reconComment?.COMMENT_RPT_DATE
);
const inventoryDoneDisplay = computed(() => reconParm.value?.INVENTORY_DATE);
const locationDisplay = computed(() => reconParm.value?.LOCATION);

const selectedStyleSummary = ref(null);

const selectedBagIdentifier = ref(null);

const selectedInventoryCheckFromTableA = ref(null);
const selectedInventoryCheckFromTableB = ref(null);
const selectedReconRawFullFromTable1 = ref(null);
const selectedReconRawFullFromTable3 = ref(null);
const selectedInventoryReconDeleteFromTableIII = ref(null);

const selectedReplacementBarcode = ref(null);
const selectedMissingBarcode = ref(null);

const tableARefreshFlag = ref(false);
const tableBRefreshFlag = ref(false);
const tableCRefreshFlag = ref(false);
const table1RefreshFlag = ref(false);
const table2RefreshFlag = ref(false);
const table3RefreshFlag = ref(false);
const tableIRefreshFlag = ref(false);
const tableIIRefreshFlag = ref(false);
const tableIIIRefreshFlag = ref(false);

const isBarcodePopupOpen = ref(false);
const isDeleteMissingBarcodesPopupOpen = ref(false);
const isUndeleteLostBarcodesPopupOpen = ref(false);
const isAddNoBarcodeToInventoryCheckPopupOpen = ref(false);
const isExportDiscrepancyPopupOpen = ref(false);

const isSelectLocationReconPopupOpen = ref(false);

onMounted(async () => {
    // Get recon_parm id from URL parameters
    const reconParmId = new URLSearchParams(window.location.search).get(
        "recon_parm"
    );
    await getReconParm(reconParmId, {
        include_relations: "location,location.reconComment",
    });
});

function refreshAll() {
    tableARefreshFlag.value = !tableARefreshFlag.value;
    tableBRefreshFlag.value = !tableBRefreshFlag.value;
    tableCRefreshFlag.value = !tableCRefreshFlag.value;
    table1RefreshFlag.value = !table1RefreshFlag.value;
    table2RefreshFlag.value = !table2RefreshFlag.value;
    table3RefreshFlag.value = !table3RefreshFlag.value;
    tableIRefreshFlag.value = !tableIRefreshFlag.value;
    tableIIRefreshFlag.value = !tableIIRefreshFlag.value;
    tableIIIRefreshFlag.value = !tableIIIRefreshFlag.value;
}

async function deleteDuplicateFromTableA() {
    try {
        const response = await postDuplicateDeletion(
            selectedInventoryCheckFromTableA.value.RAW,
            reconParm.value.LOCATION,
            reconParm.value.INVENTORY_DATE
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function replaceMissingReturn() {
    if (window.prompt('Type "REPLACE BC" to continue.') != "REPLACE BC") {
        return;
    }

    try {
        const response = await postMissingReturnReplacement(
            selectedReconRawFullFromTable1.value.id,
            selectedReconRawFullFromTable3.value.id
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function replaceMissingSaleRR() {
    if (window.prompt('Type "REPLACE" to continue.') != "REPLACE") {
        return;
    }

    try {
        const response = await postMissingSaleRRReplacement(
            selectedReconRawFullFromTable3.value.id,
            selectedReconRawFullFromTable1.value.id,
            reconParm.value.LOCATION
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function addFromTable3ToInventoryCheck() {
    if (window.prompt('Type "INSERT BC" to continue.') != "INSERT BC") {
        return;
    }

    try {
        const response = await postMissingItemAdditionToInventoryCheck(
            selectedReconRawFullFromTable3.value.id,
            reconParm.value.LOCATION,
            reconParm.value.INVENTORY_DATE
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function replaceNOBCSaleWithAlreadySold() {
    if (window.prompt('Type "REPLACE NO BC" to continue.') != "REPLACE NO BC") {
        return;
    }

    try {
        const response = await postNOBCSaleReplacementWithAlreadySold(
            selectedReconRawFullFromTable1.value.id,
            selectedInventoryCheckFromTableB.value.id
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

function displayExtraData() {
    window.open(
        route(
            "inventory.report-recon-full.investigation-form.display-extra-data",
            {
                recon_parm: reconParm.value.id,
                preselected_style: selectedStyleSummary.value?.STYLE,
            }
        )
    );
}

async function undeleteBarcodeFromInventoryReconDelete() {
    if (window.prompt('Type "DELETE BC" to continue.') != "DELETE BC") {
        return;
    }

    try {
        const response = await postBarcodeMissingUndeletion(
            selectedInventoryReconDeleteFromTableIII.value.STYLE,
            selectedInventoryReconDeleteFromTableIII.value.DATE,
            selectedInventoryReconDeleteFromTableIII.value.SERIAL
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

async function deleteDuplicateNOBCSale() {
    if (window.prompt('Type "DELETE NO BC" to continue.') != "DELETE NO BC") {
        return;
    }

    try {
        const response = await postDuplicateNoBarcodeDeletion(
            selectedReconRawFullFromTable1.value.id
        );
        addFlashMessage("SUCCESS", response.data.message);
        refreshAll();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}

function exportMissingBarcodesSpreadsheet() {
    window.open(
        route("api.locations.missing-barcodes-spreadsheet.show", {
            location: reconParm.value.LOCATION,
            TIME_PERIOD: reconParm.value.TIME_PERIOD,
            INVENTORY_DONE: reconParm.value.INVENTORY_DATE,
        })
    );
}

function exportExcessBarcodesSpreadsheet() {
    window.open(
        route("api.locations.excess-barcodes-spreadsheet.show", {
            location: reconParm.value.LOCATION,
            TIME_PERIOD: reconParm.value.TIME_PERIOD,
            INVENTORY_DONE: reconParm.value.INVENTORY_DATE,
        })
    );
}

function exportReplacementBarcodesSpreadsheet() {
    window.open(
        route("api.locations.replacement-barcodes-spreadsheet.show", {
            location: reconParm.value.LOCATION,
        })
    );
}

function exportNOBCSpreadsheet() {
    window.open(
        route("api.locations.nobc-spreadsheet.show", {
            location: reconParm.value.LOCATION,
        })
    );
}
</script>
