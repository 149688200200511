import { ref } from 'vue';

import { FilterMatchMode } from 'primevue/api';

export function useDataTableParams(fields, additionalParams, additionalFilters) {
    const filters = ref(
        {
            ...fields.reduce((filters, field) => ({
                ...filters,
                [field]: { value: null, matchMode: FilterMatchMode.CONTAINS }
            }), {}),
            ...additionalFilters
        }
    );

    const multiSortMeta = ref([]);

    const params = ref({
        first: 0,
        page: 0,
        rows: 10,
        filters: filters.value,
        multiSortMeta: multiSortMeta.value,
        ...additionalParams
    });

    const onPage = e => {
        params.value = {
            ...params.value,
            ...e,
            filters: filters.value,
            multiSortMeta: multiSortMeta.value,
        };
    };

    const onFilter = e => {
        params.value = {
            ...params.value,
            ...e,
            multiSortMeta: multiSortMeta.value,
            filters: filters.value,
            first: 0,
            page: 0,
        };
    };

    const onSort = e => {
        params.value = {
            ...params.value,
            ...e,
            filters: filters.value,
            multiSortMeta: multiSortMeta.value,
            first: 0,
            page: 0,
        };
    };

    return {
        params,
        filters,
        multiSortMeta,
        onPage,
        onFilter,
        onSort,
    };
}
