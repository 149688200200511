<template>
    <div class="row">
        <div class="col-lg-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select a Location
                </legend>
                <LocationTable
                    v-model="location"
                    @error="(message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }"
                />
            </fieldset>
        </div>
        <div class="col-lg-5 py-lg-5 py-3 px-3">
            <FormSelect
                label="Start Date:"
                v-model="startDate"
                :options="timePeriods"
                :option-to-string="(timePeriod) => timePeriod.START_DATE"
                :option-value="(timePeriod) => timePeriod.START_DATE"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                select-class="form-select-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
                id-prefix="startDate"
                :errors="errors.START_DATE"
            />
            <FormSelect
                label="End Date:"
                v-model="endDate"
                :options="timePeriods"
                :option-to-string="(timePeriod) => timePeriod.END_DATE"
                :option-value="(timePeriod) => timePeriod.END_DATE"
                class="mb-md-1 mb-2"
                is-horizontal
                label-class="col-xl-4 col-md-5 label-sm"
                select-class="form-select-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
                id-prefix="endDate"
                :errors="errors.END_DATE"
            />
            <div class="d-flex mt-4">
                <div class="p-1 w-50">
                    <button class="btn btn-primary btn-sm w-100 px-1"
                        :disabled="!location || !startDate || !endDate"
                        @click="setReportType('DELIVERY')">
                        <i class="icon ph-bold ph-truck me-2"></i>Delivery Reports
                    </button>
                </div>
                <div class="p-1 w-50">
                    <button class="btn btn-primary btn-sm w-100 px-1"
                        :disabled="!location || !startDate || !endDate"
                        @click="setReportType('PULL-OUT')">
                        <i class="icon ph-bold ph-arrow-up-right me-2"></i>Pull-out Reports
                    </button>
                </div>
            </div>
        </div>
        <div v-if="selectedReportType" class="col-12 mt-3">
            <fieldset>
                <legend>
                    <i :class="
                            selectedReportType === 'PULL-OUT'
                                ? 'icon ph-bold ph-arrow-up-right'
                                : selectedReportType === 'DELIVERY'
                                    ? 'icon ph-bold ph-truck'
                                    : 'icon ph-bold ph-file'
                        "
                        class="me-2"></i>{{ selectedReportType }} REPORTS
                </legend>
                <DataTable
                    :loading="isLoading"
                    lazy
                    :value="comments"
                    :total-records="totalRecords"
                    paginator
                    :rows="10"
                    @page="(e) => {
                        onPage(e);
                        fetchReports();
                    }"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="COMMENT_ID" header="COMMENT_ID" />
                    <Column field="COMMENT_RPT_DATE_ORIG" header="COMMENT_RPT_DATE_ORIG" />
                    <Column field="COMMENT_RPT_DATE" header="COMMENT_RPT_DATE" />
                    <Column field="COMMENT_TEXT" header="COMMENT_TEXT" />
                    <Column v-if="selectedReportType === 'PULL-OUT'" field="PREV_LOC" header="PREV_LOC" />
                    <Column field="QTY" header="QTY" />
                    <Column field="TOTAL_AMOUNT" header="TOTAL_AMOUNT" />
                    <Column field="CREATED_BY" header="CREATED_BY" />
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import LocationTable from "@/components/utils/tables/LocationTable";
import FormSelect from "@/components/utils/FormSelect.vue";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useTimePeriods } from "@/composables/data/timePeriods";
import { useComments } from "@/composables/data/comments";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const startDate = ref(null);
const endDate = ref(null);
const location = ref(null);
const selectedReportType = ref(null);
const isLoading = ref(false);
const errors = ref({});

const { params, onPage } = useDataTableParams([
    "COMMENT_ID",
    "COMMENT_RPT_DATE_ORIG",
    "COMMENT_RPT_DATE",
    "QTY",
    "TOTAL_AMOUNT",
    "COMMENT_TEXT",
    "CREATED_BY",
    "PREV_LOC",
]);

const { comments, totalRecords, getDeliveryPulloutReports } = useComments();
const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: "YEARLY",
    });
});

function setReportType(reportType) {
    selectedReportType.value = reportType;
    fetchReports();
}

async function fetchReports() {
    errors.value = {};
    isLoading.value = true;
    try {
        await getDeliveryPulloutReports(
            startDate.value,
            endDate.value,
            location.value.LOCATION,
            params.value,
            selectedReportType.value
        );
    } catch (e) {
        comments.value = [];
        console.log(e);
        if (e.status == 422) {
            errors.value = e.errors;
        } else {
            handleFetchErrors(e, "Error while fetching reports.");
            scrollToTop();
        }
    }
    isLoading.value = false;
}
</script>
