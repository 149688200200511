<template>
    <div class="row g-3">
        <div class="col-md-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Time Period
                </legend>
                <TimePeriodSelectTableCard
                    show-only-type="YEARLY"
                    v-model="selectedTimePeriod"
                />
            </fieldset>
        </div>
        <div class="col-md-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-star me-2"></i>Topseller Styles Report (by SM Price)
                </legend>
                <div class="row">
                    <div class="col-lg-9">
                        <FormInput
                            type="text"
                            v-model="topsellerStylesStyleFilter"
                            label="Style (filter)"
                            id-prefix="topsellerStylesStyleFilter"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                        />
                    </div>
                    <div class="col-lg-3 text-end mb-lg-0 mb-2">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="(_) => { isSelectStylePopupOpen = true; reportToSetStyle = 'STYLES'; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="topsellerStylesPriceFilter"
                            label="SM Price (filter)"
                            id-prefix="topsellerStylesPriceFilter"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                        />
                    </div>
                </div>
                <div class="text-end">
                    <hr class="my-3" />
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedTimePeriod"
                        @click="generateTopsellerStylesReport">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate
                    </button>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Topseller Locations Report
                </legend>
                <div class="row">
                    <div class="col-lg-9">
                        <FormInput
                            type="text"
                            v-model="topsellerLocationsStyleFilter"
                            label="Style (filter)"
                            id-prefix="topsellerLocationsStyleFilter"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                        />
                    </div>
                    <div class="col-lg-3 text-end">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="
                                (_) => {
                                    isSelectStylePopupOpen = true;
                                    reportToSetStyle = 'LOCATIONS';
                                }
                            ">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="text-end">
                    <hr class="my-3" />
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedTimePeriod"
                        @click="generateTopsellerLocationsReport">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectStyle
        :is-open="isSelectStylePopupOpen"
        @close="(_) => { isSelectStylePopupOpen = false; }"
        @select="
            (selectedStyle) => {
                if (reportToSetStyle == 'STYLES')
                    topsellerStylesStyleFilter = selectedStyle.STYLE;
                else if (reportToSetStyle == 'LOCATIONS')
                    topsellerLocationsStyleFilter = selectedStyle.STYLE;
                isSelectStylePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput";
import TimePeriodSelectTableCard from "@/components/utils/TimePeriodSelectTableCard";
import SelectStyle from "@/components/utils/style/SelectStyle.vue";

const topsellerStylesStyleFilter = ref(null);
const topsellerStylesPriceFilter = ref(null);

const topsellerLocationsStyleFilter = ref(null);

const isSelectStylePopupOpen = ref(false);

const selectedTimePeriod = ref(null);

const reportToSetStyle = ref(null);

function generateTopsellerStylesReport() {
    window.open(
        route(
            "api.time-periods.style-bestsellers-by-sold-date-sm-price-spreadsheet.show",
            {
                time_period: selectedTimePeriod.value.TIME_PERIOD,
                style: topsellerStylesStyleFilter.value,
                price: topsellerStylesPriceFilter.value,
            }
        )
    );
}

function generateTopsellerLocationsReport() {
    window.open(
        route("api.time-periods.location-bestsellers-spreadsheet.show", {
            time_period: selectedTimePeriod.value.TIME_PERIOD,
            style: topsellerLocationsStyleFilter.value,
        })
    );
}
</script>
