<template>
    <fieldset>
        <legend><i class="icon ph-bold ph-info me-2"></i>Details</legend>
        <div class="row g-3 mb-3">
            <div class="col-md-4 mb-2">
                <FormInput
                    type="number"
                    v-model="sampleId"
                    label="Sample ID"
                    id-prefix="sampleId"
                    disabled
                    input-class="form-control-sm"
                />
            </div>
            <div class="col-md-4 mb-2">
                <FormInput
                    type="number"
                    step=".01"
                    v-model="sampleQuantity"
                    label="Sample Qty."
                    id-prefix="totalQuantity"
                    input-class="form-control-sm"
                />
            </div>
            <div class="col-md-4 mb-2">
                <FormInput
                    type="number"
                    step=".01"
                    v-model="detailsTotalPrice"
                    label="Total Price"
                    id-prefix="totalPrice"
                    input-class="form-control-sm"
                    disabled
                />
            </div>
            <div class="col-md-12 mb-4">
                <FormInput
                    type="text"
                    v-model="sampleDescription"
                    label="Description"
                    id-prefix="description"
                    input-class="form-control-sm"
                    disabled
                />
            </div>
        </div>
        <DataTable
            :value="sample?.details"
            paginator
            :rows="10"
            selection-mode="single"
            v-model:selection="selectedSampleDetail"
            data-key="id"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="MATERIAL" header="Material" />
            <Column field="COLOR" header="Color" />
            <Column field="QTY" header="Unit Qty." />
            <Column header="Total Qty.">
                <template #body="{ data }">
                    {{ (data.QTY * sampleQuantity).toFixed(2) }}
                </template>
            </Column>
            <Column field="material.UNIT" header="Unit" />
            <Column field="material.PRICE" header="Unit Price" />
            <Column header="Total Price">
                <template #body="{ data }">
                    {{ ((data.material?.PRICE ?? 0) * data.QTY * sampleQuantity).toFixed(2) }}
                </template>
            </Column>
            <Column field="CREATED_BY" header="Created By" />
        </DataTable>
        <div class="text-end">
            <button type="button" class="btn btn-primary"
                @click="exportSpreadsheet">
                Export to Spreadsheet
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { computed, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useSamples } from "@/composables/data/samples";
import FormInput from "@/components/utils/FormInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    sampleId: Number,
    refreshFlag: null,
    class: String,
});

const emit = defineEmits(["sample-quantity-change"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { sample, getSample } = useSamples();

const sampleId = computed(() => sample.value?.SAMPLE_ID);
const sampleDescription = computed(() => sample.value?.DESCRIPTION);

const sampleQuantity = defineModel('sampleQuantity', { default: 0 });

const detailsTotalPrice = computed(
    () => (sample.value?.details.reduce(
        (totalPrice, sampleDetail) =>
            totalPrice + ((sampleDetail.material?.PRICE ?? 0) * sampleDetail.QTY * sampleQuantity.value),
        0
    ) ?? 0).toFixed(2)
);

const selectedSampleDetail = defineModel();

async function fetchSample() {
    sample.value = null;

    if (!props.sampleId) {
        return
    };

    loadingFlags.add("fetchSample");
    try {
        await getSample(props.sampleId, {
            include_relations: "details.material",
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchSample");
}

watch(() => [props.sampleId, props.refreshFlag], fetchSample);

function exportSpreadsheet() {
    window.open(route('api.samples.spreadsheet.show', {
        sample: props.sampleId,
        sample_quantity: sampleQuantity.value
    }));
}
</script>
