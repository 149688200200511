<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-gear me-2"></i>Mass Generate Store Summary
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="() => emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div>
                        <FormSelect label="From:"
                            v-model="startTimePeriod"
                            class="mb-1"
                            :options="timePeriods"
                            :option-to-string="(timePeriods) => timePeriods.TIME_PERIOD + ' | ' + timePeriods.START_DATE"
                            id-prefix="start-time-period"
                            select-class="form-select-sm"
                            label-class="col-xl-4 col-md-5 label-sm"
                            select-container-class="col-xl-8 col-md-7 col-12"
                            is-horizontal
                            :errors="errors.TIME_PERIOD_FROM"
                        />
                        <FormSelect
                            label="To:"
                            v-model="endTimePeriod"
                            class="mb-1"
                            :options="timePeriods"
                            :option-to-string="(timePeriods) => timePeriods.TIME_PERIOD + ' | ' + timePeriods.END_DATE"
                            id-prefix="end-time-period"
                            select-class="form-select-sm"
                            label-class="col-xl-4 col-md-5 label-sm"
                            select-container-class="col-xl-8 col-md-7 col-12"
                            is-horizontal
                            :errors="errors.TIME_PERIOD_TO"
                        />
                    </div>
                    <hr class="my-3" />
                    <div class="text-end">
                        <button class="btn btn-primary btn-sm"
                            :disabled="!startTimePeriod || !endTimePeriod"
                            @click="massGenerate">
                            <span v-if="isLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-pencil-line me-2"></i>UPDATE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import { useTimePeriods } from "@/composables/data/timePeriods";

const startTimePeriod = ref(null);
const endTimePeriod = ref(null);
const errors = ref({});
const message = ref();

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["success", "error", "close"]);

const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

const params = ref({
    filters: { PAYTYPE: { value: "MONTHLY", matchMode: null } },
});

onMounted(async () => {
    await getTimePeriodsLatest(params.value);
});

const isLoading = ref(false);
async function massGenerate() {
    errors.value = {};
    try {
        console.log(startTimePeriod.value, endTimePeriod.value);
        isLoading.value = true;
        const response = await axios.post(
            route("api.recon.mass-generate", {
                TIME_PERIOD_FROM: startTimePeriod.value.TIME_PERIOD,
                TIME_PERIOD_TO: endTimePeriod.value.TIME_PERIOD,
            })
        );
        message.value = response.data.message;
        isLoading.value = false;
    } catch (e) {
        console.log(e);
        errors.value = e.response.data.errors;
        isLoading.value = false;
    }
}
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
