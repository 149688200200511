import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useDepartments() {
    const departments = ref(null);
    const totalRecords = ref(null);

    const getDepartments = async (params) => {
        try {
            const getDepartmentsResponse = await axios.get(route('api.departments.index', params));
            departments.value = getDepartmentsResponse.data.data;
            totalRecords.value = getDepartmentsResponse.data.meta?.total ?? departments.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching departments.');
        }
    };
    
    return {
        departments,
        totalRecords,
        getDepartments,
    };
}
