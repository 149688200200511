<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">View Loan Payments for LOAN: {{ loan?.LOANID }}</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <DataTable
                :value="loan?.payments"
                paginator
                :rows="10"
                :pt="{ table: { class: 'table table-bordered table-hover' }}">
                <Column field="LOANID" header="Loan ID" />
                <Column field="DATE" header="Payment Date" />
                <Column field="PAYMENT" header="Amount" />
                <Column field="STATUS" header="Status" />
                <Column field="CREATED_BY" header="Created By" />
                <template #empty>
                    <div class="text-center">
                        No payments.
                    </div>
                </template>
                <template #footer>
                    {{ loan?.payments?.length ?? 0 }} total payments
                </template>
            </DataTable>
        </Card>
    </div>
</template>

<script setup>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import Card from "@/components/utils/Card.vue";

const props = defineProps({
    loan: Object,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
}

.pop-up .card {
    width: 50%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
