<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-chart-bar me-2"></i>Daily Sales Report
        </legend>
        <div class="row mb-4">
            <div class="col-xl-7">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <FormInput
                            label="Start Date:"
                            v-model="startDate"
                            type="date"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            id-prefix="startDate"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-md-6">
                        <FormInput
                            label="End Date:"
                            v-model="endDate"
                            type="date"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            id-prefix="endDate"
                        />
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-md-6 sales-report-column">
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Prev. Location:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            disabled
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            v-model="hardFilters.PREV_LOC.value"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingLocation = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { hardFilters.PREV_LOC.value = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Style:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="hardFilters.STYLE.value"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingStyle = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { hardFilters.STYLE.value = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Color:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="hardFilters.COLOR.value"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingColor = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { hardFilters.COLOR.value = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col select-reset-main-container">
                        <FormInput
                            label="Employee ID:"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                            disabled
                            v-model="hardFilters.EMPID.value"
                        />
                    </div>
                    <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                        <button class="btn btn-primary btn-sm mb-1 me-2 px-2"
                            @click="(_) => { isSelectingEmployee = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                        <button class="btn btn-primary btn-sm mb-1 px-2"
                            @click="(_) => { hardFilters.EMPID.value = null; }">
                            <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-6">
                <FormInput
                    label="Price:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="hardFilters.PRICE.value"
                />
                <FormInput
                    label="Receipt No:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="hardFilters.RECEIPT_NO.value"
                />
                <FormInput
                    label="Sale type:"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    label-class="col-xl-3 col-md-5 label-sm align-items-center"
                    input-container-class="col-xl-9 col-md-7 col-12"
                    v-model="hardFilters.SALE_TYPE.value"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-primary btn-sm mb-2 px-2" @click="fetchInventoryRecords">
                    <i class="icon ph-bold ph-book-open me-2"></i>Display Sales Report
                </button>
            </div>
            <div class="col-12">
                <DataTable
                    :loading="loadingFlags.has('fetchInventoryRecords')"
                    lazy
                    :value="inventoryRecords"
                    :total-records="inventoryTotalRecords"
                    paginator
                    :rows="10"
                    @page="(e) => { onPage(e); fetchInventoryRecords(); }"
                    sort-mode="multiple"
                    v-model:multi-sort-meta="multiSortMeta"
                    @sort="(e) => { onSort(e); fetchInventoryRecords(); }"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="STYLE" header="STYLE" sortable />
                    <Column field="PREV_LOC" header="PREV_LOC" sortable />
                    <Column field="SOLD_DT" header="SOLD_DT" sortable />
                    <Column header="BARCODE" :pt="{ bodyCell: { class: 'barcode' } }">
                        <template #body="{ data }">
                            {{ constructBarcode(data.STYLE, data.DATE, data.SERIAL) }}
                        </template>
                    </Column>
                    <Column field="COLOR" header="COLOR" sortable />
                    <Column field="PRICE" header="PRICE" sortable />
                    <Column field="EMPID" header="EMPID" sortable />
                    <Column field="RECEIPT_NO" header="RECEIPT_NO" sortable />
                    <Column field="SALE_TYPE" header="SALE_TYPE" sortable />
                    <Column field="DISCOUNT" header="DISCOUNT" sortable />
                    <Column field="SELL_TO_ID" header="SELL_TO_ID" sortable />
                    <Column field="CREATED_BY" header="CREATED_BY" sortable />
                    <Column field="SEQ_NO" header="SEQ_NO" sortable />
                    <Column field="COMMENT_ID" header="COMMENT_ID" sortable />
                    <Column field="SELL_TO_TYPE" header="SELL_TO_TYPE" sortable />
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Loading report...
                    </template>
                    <template #footer>
                        {{ inventoryTotalRecords ?? 0 }} total records
                    </template>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary"
                :disabled="!inventoryTotalRecords"
                @click="exportSpreadsheet">
                Export Spreadsheet
            </button>
        </div>
    </fieldset>
    <SelectLocation
        :is-open="isSelectingLocation"
        @select="handleSelectLocation"
        @close="(_) => { isSelectingLocation = false; }"
    />
    <SelectStyle
        :is-open="isSelectingStyle"
        @select="handleSelectStyle"
        @close="(_) => { isSelectingStyle = false; }"
    />
    <SelectColor
        :is-open="isSelectingColor"
        @select="handleSelectColor"
        @close="(_) => { isSelectingColor = false; }"
    />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        @close="(_) => { isSelectingEmployee = false; }"
        @select="(employee) => {
            handleSelectEmployee(employee);
            isSelectingEmployee = false;
        }"
    />
</template>

<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput.vue";
import SelectLocation from "@/components/utils/popups/SelectLocationPopup.vue";
import SelectColor from "@/components/utils/SelectColor";
import SelectStyle from "@/components/utils/style/SelectStyle";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { constructBarcode } from "@/utils/barcodes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const isSelectingLocation = ref(false);
const isSelectingStyle = ref(false);
const isSelectingColor = ref(false);
const isSelectingEmployee = ref(false);

const { params, multiSortMeta, onPage, onSort } = useDataTableParams([
    "STYLE",
    "PREV_LOC",
    "SOLD_DT",
    "COLOR",
    "PRICE",
    "EMPID",
    "RECEIPT_NO",
    "SALE_TYPE",
    "DISCOUNT",
    "SELL_TO_ID",
    "CREATED_BY",
    "SEQ_NO",
    "COMMENT_ID",
    "SELL_TO_TYPE",
]);

const {
    inventoryRecords,
    inventoryTotalRecords,
    getInventoryRecords,
} = useInventory();

const fetchTimeout = ref(null);

const startDate = ref(null);
const endDate = ref(null);

const hardFilters = ref({
    PREV_LOC: {
        value: null,
        matchMode: 'equals',
    },
    STYLE: {
        value: null,
        matchMode: 'equals',
    },
    COLOR: {
        value: null,
        matchMode: 'equals',
    },
    EMPID: {
        value: null,
        matchMode: 'equals',
    },
    PRICE: {
        value: null,
        matchMode: 'equals',
    },
    RECEIPT_NO: {
        value: null,
        matchMode: 'equals',
    },
    SALE_TYPE: {
        value: null,
        matchMode: 'equals',
    },
});

function handleSelectLocation(selectedLocation) {
    hardFilters.value.PREV_LOC.value = selectedLocation.LOCATION;
    isSelectingLocation.value = false;
}

function handleSelectColor(selectedColor) {
    hardFilters.value.COLOR.value = selectedColor.COLOR;
    isSelectingColor.value = false;
}

function handleSelectStyle(selectedStyle) {
    hardFilters.value.STYLE.value = selectedStyle.STYLE;
    isSelectingStyle.value = false;
}

function handleSelectEmployee(selectedEmployee) {
    hardFilters.value.EMPID.value = selectedEmployee.EMPID;
    isSelectingEmployee.value = false;
}

async function fetchInventoryRecords() {
    inventoryRecords.value = null;

    if (!startDate.value) {
        toasts.add("ERROR", "Error", "Start Date is required.");
        return;
    }

    loadingFlags.add('fetchInventoryRecords');
    try {
        await getInventoryRecords({
            ...params.value,
            hardFilters: {
                ...hardFilters.value,
                'comment.LOCATION': {
                    value: 'SOLD',
                    matchMode: 'equals',
                },
                SOLD_DT: !endDate.value ? {
                    value: startDate.value,
                    matchMode: 'gte',
                } : {
                    value: [startDate.value, endDate.value],
                    matchMode: 'between',
                },
            },
        });
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchInventoryRecords');
}

function exportSpreadsheet() {
    window.open(route('api.inventory.spreadsheet.show', {
        fields: [
            "BARCODE",
            "STYLE",
            "DATE",
            "SERIAL",
            "SEQ_NO",
            "PREV_LOC",
            "SOLD_DT",
            "COLOR",
            "PRICE",
            "EMPID",
            "RECEIPT_NO",
            "SALE_TYPE",
            "DISCOUNT",
            "SELL_TO_ID",
            "CREATED_BY",
            "COMMENT_ID",
            "SELL_TO_TYPE",
        ],
        title: "SalesReport",
        filters: {
            ...hardFilters.value,
            'comment.LOCATION': {
                value: 'SOLD',
                matchMode: 'equals',
            },
            SOLD_DT: !endDate.value ? {
                value: startDate.value,
                matchMode: 'gte',
            } : {
                value: [startDate.value, endDate.value],
                matchMode: 'between',
            },
        },
    }));
}
</script>
