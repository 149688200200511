import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useLeaveHistories() {
    const leaveHistories = ref(null);
    const totalRecords = ref(null);

    const getEmployeeLeaveHistories = async (employeeId, params) => {
        try {
            const getEmployeeLeaveHistoriesResponse = await axios.get(route('api.employees.leave-histories.index', {
                employee: employeeId,
                ...params,
            }));
            leaveHistories.value = getEmployeeLeaveHistoriesResponse.data.data;
            totalRecords.value = getEmployeeLeaveHistoriesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching employee leave histories.');
        }
    };

    const postEmployeeLeaveHistory = async (employeeId, leaveHistory) => {
        try {
            const postEmployeeLeaveHistoryResponse = await axios.post(route('api.employees.leave-histories.store', {
                employee: employeeId,
            }), leaveHistory);
            return postEmployeeLeaveHistoryResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while creating employee leave history.');
        }
    };

    return {
        leaveHistories,
        totalRecords,
        getEmployeeLeaveHistories,
        postEmployeeLeaveHistory,
    };
}
