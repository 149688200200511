<template>
    <Card>
        <template #header>
            Recon Generations for Main Warehouse
        </template>
        <FormInput type="text"
            disabled
            v-model="location"
            label="Location"
            id-prefix="location"
            class="mb-3" />
        <DataTable
            lazy
            :value="reconMainWarehouseInfoGenerations"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchReconMainWarehouseInfoGenerations(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            selection-mode="single"
            v-model:selection="selectedReconMainWarehouseInfoGeneration"
            :pt="{ table: { class: 'table table-bordered table-hover' }}">
            <Column field="user.name" header="Generated By" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="inventory_date" header="Inventory Date" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="status" header="Status" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
                <template #body="{ data }">
                    <span v-if="data.status == 'PENDING'" class="badge bg-secondary">
                        PENDING
                    </span>
                    <span v-else-if="data.status == 'RUNNING'" class="badge bg-primary">
                        RUNNING
                    </span>
                    <span v-else-if="data.status == 'DONE'" class="badge bg-success">
                        DONE
                    </span>
                    <span v-else-if="data.status == 'FAILED'" class="badge bg-danger">
                        FAILED
                    </span>
                </template>
            </Column>
            <Column field="created_at" header="Generated On" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No Recon Generations yet.
                </div>
            </template>
        </DataTable>
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-primary"
                    @click="_ => { isSelectInventoryCheckDescriptionPopupOpen = true; }">
                    Generate
                </button>
            </div>
        </template>
    </Card>

    <SelectInventoryCheckDescriptionPopup
        location="MAIN_1"
        :is-open="isSelectInventoryCheckDescriptionPopupOpen"
        @close="_ => { isSelectInventoryCheckDescriptionPopupOpen = false }"
        @select="generateReconMainWarehouseInfo" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useReconMainWarehouseInfoGenerations } from '@/composables/data/reconMainWarehouseInfoGenerations';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectInventoryCheckDescriptionPopup from '@/components/utils/popups/SelectInventoryCheckDescriptionPopup.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const location = computed(() => "MAIN_1");

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    'user.name',
    'inventory_date',
    'status',
    'created_at',
]);

const {
    reconMainWarehouseInfoGenerations,
    totalRecords,
    getReconMainWarehouseInfoGenerations,
    postReconMainWarehouseInitialization,
} = useReconMainWarehouseInfoGenerations();

const isSelectInventoryCheckDescriptionPopupOpen = ref(false);

const fetchTimeout = ref(false);

const selectedReconMainWarehouseInfoGeneration = defineModel();

async function fetchReconMainWarehouseInfoGenerations() {
    loadingFlags.add("fetchReconMainWarehouseInfoGenerations");
    try {
        await getReconMainWarehouseInfoGenerations({
            ...params.value,
            multiSortMeta: [
                {
                    field: 'id',
                    order: -1,
                },
            ],
        });
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("fetchReconMainWarehouseInfoGenerations");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReconMainWarehouseInfoGenerations, 300);
}

onMounted(fetchReconMainWarehouseInfoGenerations);

async function generateReconMainWarehouseInfo(selectedInventoryCheckDescription) {
    loadingFlags.add('generateReconMainWarehouseInfo');
    try {
        const response = await postReconMainWarehouseInitialization(selectedInventoryCheckDescription.INVENTORY_DONE);
        toasts.add('SUCCESS', 'Success', response.data.message);
        fetchReconMainWarehouseInfoGenerations();
        isSelectInventoryCheckDescriptionPopupOpen.value = false;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('generateReconMainWarehouseInfo');
}
</script>
