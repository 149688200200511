<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-storefront me-2"></i>Store Summary
        </legend>
        <div class="row g-3">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-4 offset-lg-8">
                        <button class="btn btn-primary btn-sm w-100 mb-1"
                            :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                            @click="generateRecon">
                            <span v-if="isLoading"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-gear me-2"></i>GENERATE
                        </button>
                    </div>
                    <div class="col-lg-8">
                        <div class="row justify-content-end">
                            <div class="col mb-1">
                                <div class="row">
                                    <label class="col-xl-4 col-lg-5 label-sm align-items-center">TIME_PERIOD</label>
                                    <div class="col-xl-8 col-lg-7 col-12">
                                        <input
                                            class="form-control form-control-sm"
                                            type="text"
                                            v-model="timePeriod.TIME_PERIOD"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                                <button class="btn btn-primary btn-sm mb-1 px-2"
                                    @click="(_) => { isSelectTimePeriodPopupOpen = true; }">
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-lg-0 mb-3 d-flex justify-content-lg-center align-items-center">
                        <p class="mb-0">
                            {{ timePeriod.START_DATE }} -
                            {{ timePeriod.END_DATE }}
                        </p>
                    </div>
                    <div class="col-lg-8">
                        <div class="row justify-content-end">
                            <div class="col mb-1">
                                <div class="row">
                                    <label class="col-xl-4 col-lg-5 label-sm align-items-center">Location</label>
                                    <div class="col-xl-8 col-lg-7 col-12">
                                        <input
                                            class="form-control form-control-sm"
                                            type="text"
                                            v-model="location.LOCATION"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto text-end mt-auto mb-lg-0 mb-1">
                                <button class="btn btn-primary btn-sm mb-1 px-2"
                                    @click="(_) => { isSelectLocationPopupOpen = true; }">
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex flex-column justify-content-lg-center align-items-lg-center mb-lg-0 mb-3">
                        <p class="mb-0" v-if="location.LOCATION_KEY2">
                            {{ location.LOCATION_KEY2 }}
                        </p>
                        <p class="mb-0">
                            {{ location.DESCRIPTION }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <Card>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        @click="openCommissionReportPDF"
                        :disabled="!timePeriod.TIME_PERIOD">
                        Commission Report 1
                    </button>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                        @click="commissionLocker">
                        {{ commissionLockerCaption }}
                    </button>
                    <button class="btn btn-primary btn-sm w-100 mb-1"
                        @click="redirectToUpdateStoreSummary">
                        Update Summary (CO)
                    </button>
                </Card>
            </div>
        </div>
        <hr class="my-3" />
        <div class="row">
            <div class="col-lg-5">
                <div class="row mb-xl-1 mb-2 align-items-center justify-content-center">
                    <div class="col-6">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                            @click="totalSalesUpdate">
                            Total Sales (current month)
                        </button>
                    </div>
                    <div class="col-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="mainInformation.TOTAL_SALES"
                        />
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6 d-flex align-items-center">
                        <label class="label-sm">Total Sales (same month, -1 year)</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="mainInformation.TOTAL_SALES_1"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-xl-6">
                        <label class="label-sm">Total Sales (same month, -2 year)</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="mainInformation.TOTAL_SALES_2"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6 d-flex align-items-center">
                        <label class="label-sm">Number Selling Areas</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="mainInformation.SELLING_AREA_COUNT"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6 d-flex align-items-center">
                        <label class="label-sm">Selling Area Charges</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="mainInformation.SELLING_AREA_CHARGES"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="row mb-xl-1 mb-2 align-items-center justify-content-center">
                            <div class="col-6">
                                <button class="btn btn-primary btn-sm px-1 w-100"
                                    :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                    @click="quotaUpdate">
                                    Base (update):
                                </button>
                            </div>
                            <div class="col-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.QUOTA"
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Quota</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.QUOTA2"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Sales Change PCT</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.SALES_CHANGE_PCT"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Sales Commission PCT</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.SALES_COMMISSION_PCT"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Sales Commission AMT</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.SALES_COMMISSION_AMT"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2 align-items-center justify-content-center">
                            <div class="col-6">
                                <button class="btn btn-primary btn-sm px-1 w-100"
                                    :disabled="!timePeriod.TIME_PERIOD || !location.LOCATION"
                                    @click="salesMissingAmountUpdate">
                                    Missing AMT(update)
                                </button>
                            </div>
                            <div class="col-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.SALES_MISSING_AMT"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Quota 1 (FIN)</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.QUOTA1_FIN"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2">
                            <div class="col-xl-6">
                                <label class="label-sm">Quota 2 (FIN)</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="mainInformation.QUOTA2_FIN"
                                    disabled
                                />
                            </div>
                        </div>
                        <Card class="mt-2">
                            <div class="row mb-1">
                                <div class="col-xl-6">
                                    <label class="label-sm">Quota (17% INC):</label>
                                </div>
                                <div class="col-xl-6">
                                    <input
                                        class="form-control form-control-sm"
                                        v-model="mainInformation.QUOTA_DAW"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 text-center mt-3">
                                    <p class="small mb-0">
                                        {{ commissionLabel }}
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <MainInfoTable
                    :location="location"
                    :time-period="timePeriod"
                    ref="mainInfoTable"
                />
            </div>
        </div>
        <hr class="my-3" />
        <div class="row">
            <div class="col-lg-5">
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6">
                        <label class="label-sm">OTR Location</label>
                    </div>
                    <div class="col-xl-6">
                        <select class="form-control form-select-sm"
                            v-model="location2">
                            <option  v-for="location in location2List"
                                :value="location">
                                {{ location.LOCATION }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2 align-items-center justify-content-center">
                    <div class="col-6">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION"
                            @click="otherTotalSalesUpdate">
                            OTR Sales (update)
                        </button>
                    </div>
                    <div class="col-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="otherInformation.TOTAL_SALES"
                        />
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6">
                        <label class="label-sm">OTR # Of Selling Areas</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="otherInformation.SELLING_AREA_COUNT"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-6">
                        <label class="label-sm">OTR Selling Area Charges</label>
                    </div>
                    <div class="col-xl-6">
                        <input
                            class="form-control form-control-sm"
                            v-model="otherInformation.SELLING_AREA_CHARGES"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row mb-xl-1 mb-2">
                    <div class="col-xl-8">
                        <div class="row mb-3">
                            <div class="col-12 text-center">
                                <small>You must enter Sales Amount manually for OTR</small>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-xl-6">
                                <label class="label-sm">OTR Sales Commission AMT</label>
                            </div>
                            <div class="col-xl-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="otherInformation.SALES_COMMISSION_AMT"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-xl-1 mb-2 align-items-center justify-content-center">
                            <div class="col-6">
                                <button class="btn btn-primary btn-sm w-100 px-1"
                                    :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION"
                                    @click="otherSalesMissingAmountUpdate">
                                    OTR Sales Missing Amt (update):
                                </button>
                            </div>
                            <div class="col-6">
                                <input
                                    class="form-control form-control-sm"
                                    v-model="otherInformation.SALES_MISSING_AMT"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <Card>
                            <label class="label-sm">Quota (15% INC):</label>
                            <input
                                class="form-control form-control-sm mb-2"
                                v-model="otherInformation.QUOTA"
                            />
                            <button class="btn btn-primary btn-sm w-100"
                                :disabled="!timePeriod.TIME_PERIOD || !location2.LOCATION "
                                @click="otherQuotaUpdate">
                                Update Quota OTR
                            </button>
                        </Card>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <OtherInfoTable
                    :location2="location2"
                    :time-period="timePeriod"
                    ref="otherInfoTable"
                />
            </div>
        </div>
        <hr class="my-3" />
        <div class="row">
            <div class="col-6">
                <button class="btn btn-primary btn-sm mb-1"
                    @click="() => (isUploadingQuota2 = true)">
                    <i class="icon ph-bold ph-upload-simple me-2"></i>Upload New Quota2
                </button>
                <p>Upload .xlsx, .xls, or .csv for quota2.</p>
            </div>
            <div class="col-6">
                <button class="btn btn-primary btn-sm"
                    @click="() => (isMassGenerating = true)">
                    <i class="icon ph-bold ph-gear me-2"></i>Mass Generate
                </button>
                <p>Update total sales for all branches.</p>
            </div>
        </div>
    </fieldset>

    <SelectTimePeriodPopup
        :is-open="isSelectTimePeriodPopupOpen"
        @select="handleSelectTimePeriod"
        @close="(_) => { isSelectTimePeriodPopupOpen = false; }"
    />
    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        @select="handleSelectLocation"
        @close="(_) => { isSelectLocationPopupOpen = false; }"
    />
    <MassGenerateStoreSummaryPopup
        :is-open="isMassGenerating"
        @close="(_) => { isMassGenerating = false; }"
    />
    <UploadQuota2Popup
        :is-open="isUploadingQuota2"
        @close="(_) => { isUploadingQuota2 = false; }"
        @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
        @success="(message) => { addFlashMessage('SUCCESS', message); scrollToTop(); }"
    />
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, watch } from "vue";
import Card from "@/components/utils/Card.vue";
import SelectTimePeriodPopup from "@/components/utils/popups/SelectTimePeriodPopup.vue";
import SelectLocationPopup from "@/components/utils/popups/SelectLocationPopup.vue";
import MassGenerateStoreSummaryPopup from "./MassGenerateStoreSummaryPopup.vue";
import UploadQuota2Popup from "./UploadQuota2Popup.vue";
import OtherInfoTable from "./OtherInfoTable.vue";
import MainInfoTable from "./MainInfoTable.vue";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import { useFlashMessages } from "@/composables/flashmessages";
const { addFlashMessage } = useFlashMessages();

const commissionLockerCaption = ref("LOCK COMMISSION");
const jan2014TimePeriod = ref(500037);

const isSelectTimePeriodPopupOpen = ref(false);
const isSelectLocationPopupOpen = ref(false);

const timePeriod = ref({
    TIME_PERIOD: null,
    START_DATE: null,
    END_DATE: null,
});

const location = ref({
    LOCATION: null,
    LOCATION_KEY2: null,
    DESCRIPTION: null,
});

const mainInformation = ref({
    QUOTA: null,
    QUOTA2: null,
    QUOTA1_FIN: null,
    QUOTA2_FIN: null,
    SALES_CHANGE_PCT: null,
    SALES_COMMISSION_AMT: null,
    SALES_COMMISSION_PCT: null,
    SALES_MISSING_AMT: null,
    SELLING_AREA_CHARGES: null,
    SELLING_AREA_COUNT: null,
    TOTAL_SALES: null,
    TOTAL_SALES_1: null,
    TOTAL_SALES_2: null,
});
const mainInfoTable = ref(null);
const commissionLabel = ref("WITH COMMISSION");

const location2List = ref([]);
const location2 = ref({
    LOCATION: null,
    DESCRIPTION: null,
});
const otherInformation = ref({
    QUOTA: null,
    SALES_COMMISSION_AMT: null,
    SALES_MISSING_AMT: null,
    SELLING_AREA_CHARGES: null,
    SELLING_AREA_COUNT: null,
    TOTAL_SALES: null,
    TOTAL_SALES_1: null,
    TOTAL_SALES_2: null,
});
const otherInfoTable = ref(null);

const isUploadingQuota2 = ref(false);
const isMassGenerating = ref(false);

onMounted(() => {
    const items = document
        .querySelector("#store-summary")
        .getAttribute("data-item");
    const parsedTimePeriod = JSON.parse(items);
    timePeriod.value = {
        TIME_PERIOD: parsedTimePeriod.time_period,
        START_DATE: parsedTimePeriod.start_date,
        END_DATE: parsedTimePeriod.end_date,
    };
    getQuotaLabel();
});

const quotaLabel = ref(null);
function getQuotaLabel() {
    if (timePeriod.value.TIME_PERIOD < jan2014TimePeriod.value) {
        quotaLabel.value = "QUOTA (15% INC):";
    } else {
        quotaLabel.value = "QUOTA (17% INC):";
    }
}

watch(timePeriod, () => {
    getQuotaLabel();
    if (timePeriod.value.TIME_PERIOD && location.value.LOCATION) {
        updateInformation();
        commissionLockerStatus();
    }
});

watch(location, () => {
    commissionLockerStatus();
    updateInformation();
});

function updateInformation() {
    getMainInformation();
    getLocation2();
}

watch(location2, () => {
    getOtherInformation();
});

async function getLocation2() {
    if (!location.value.LOCATION_KEY2) {
        otherInformation.value = {
            QUOTA: null,
            SALES_COMMISSION_AMT: null,
            SALES_MISSING_AMT: null,
            SELLING_AREA_CHARGES: null,
            SELLING_AREA_COUNT: null,
            TOTAL_SALES: null,
            TOTAL_SALES_1: null,
            TOTAL_SALES_2: null,
        };
        location2List.value = [];
        location2.value = {
            LOCATION: null,
            DESCRIPTION: null,
        };
        return;
    }

    try {
        const response = await axios.get(
            route("api.locations.mega", {
                LOCATION_KEY2: location.value.LOCATION_KEY2,
                LOCATION: location.value.LOCATION,
            })
        );

        location2List.value = response.data.data;
        location2.value = response.data.data[0];
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching location mega.");
    }
}

async function getMainInformation() {
    try {
        const response = await axios.get(
            route("api.recon-store-summary-with-quota", {
                TIME_PERIOD: timePeriod.value.TIME_PERIOD,
                LOCATION: location.value.LOCATION,
            })
        );

        if (response.data.data?.summary) {
            mainInformation.value = response.data.data.summary;
            commissionLabel.value = response.data.data.commission_label;
        } else {
            mainInformation.value = {
                QUOTA: null,
                QUOTA2: null,
                QUOTA1_FIN: null,
                QUOTA2_FIN: null,
                SALES_CHANGE_PCT: null,
                SALES_COMMISSION_AMT: null,
                SALES_COMMISSION_PCT: null,
                SALES_MISSING_AMT: null,
                SELLING_AREA_CHARGES: null,
                SELLING_AREA_COUNT: null,
                TOTAL_SALES: null,
                TOTAL_SALES_1: null,
                TOTAL_SALES_2: null,
                QUOTA_DAW: null,
            };
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching main information");
    }
}

async function getOtherInformation() {
    if (!location2.value || !location2.value.LOCATION) {
        return;
    }

    try {
        const response = await axios.get(
            route("api.recon-store-summary.show", {
                TIME_PERIOD: timePeriod.value.TIME_PERIOD,
                LOCATION: location2.value.LOCATION,
            })
        );
        otherInformation.value = response.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching mega information.");
    }
}

function openCommissionReportPDF() {
    window.open(
        route("api.reports.accounting.commission-report", {
            TIME_PERIOD: timePeriod.value.TIME_PERIOD,
            LOCATION: location.value.LOCATION,
        })
    );
}

const isLoading = ref(false);
async function generateRecon() {
    try {
        isLoading.value = true;
        const response = await axios.post(
            route("api.recon.generate-store-summary", {
                TIME_PERIOD: timePeriod.value.TIME_PERIOD,
                LOCATION: location.value.LOCATION,
            })
        );
        addFlashMessage("SUCCESS", response.data.message);
        getMainInformation();
        mainInfoTable.value.getMainInfoTable();
        getOtherInformation();
        otherInfoTable.value.getOtherInfoTable();
        scrollToTop();
        isLoading.value = false;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while generating recon.");
        isLoading.value = false;
    }
}

async function commissionLockerStatus() {
    try {
        const response = await axios.get(
            route("api.commission-locker.show", {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location.value.LOCATION,
            })
        );

        commissionLockerCaption.value =
            response.data[0].length > 0
                ? "UNLOCK COMMISSION"
                : "LOCK COMMISSION";
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching commission locker.");
    }
}

const LOCK_COMMISSION = "LOCK COMMISSION";
const UNLOCK_COMMISSION = "UNLOCK COMMISSION";

async function commissionLocker() {
    try {
        if (commissionLockerCaption.value === LOCK_COMMISSION) {
            const response = await axios.post(
                route("api.commission-locker.store"),
                {
                    TIMEPERIOD: timePeriod.value.TIME_PERIOD,
                    LOCATION: location.value.LOCATION,
                }
            );

            addFlashMessage("SUCCESS", response.data.message);
            commissionLockerCaption.value = UNLOCK_COMMISSION;
        } else {
            const response = await axios.delete(
                route("api.commission-locker.destroy", {
                    time_period: timePeriod.value.TIME_PERIOD,
                    location: location.value.LOCATION,
                })
            );

            addFlashMessage("SUCCESS", response.data.message);
            commissionLockerCaption.value = LOCK_COMMISSION;
        }
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating commission locker.");
        scrollToTop();
    }
}

async function totalSalesUpdate() {
    try {
        const response = await axios.post(
            route("api.location-mega.store-update", {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location.value.LOCATION,
            }),
            {
                SALES: mainInformation.value.TOTAL_SALES,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating total sales");
        scrollToTop();
    }
}

async function quotaUpdate() {
    try {
        const response = await axios.post(
            route("api.location-quota.store-update", {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location.value.LOCATION,
            }),
            {
                QUOTA: mainInformation.value.QUOTA,
                TOTAL_SALES_1: mainInformation.value.TOTAL_SALES_1,
                TOTAL_SALES_2: mainInformation.value.TOTAL_SALES_2,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating quota.");
        scrollToTop();
    }
}

async function salesMissingAmountUpdate() {
    try {
        const response = await axios.post(
            route("api.inventory-missing.store-update", {
                date: timePeriod.value.START_DATE,
                location: location.value.LOCATION,
            }),
            {
                MISSING_AMT: mainInformation.value.SALES_MISSING_AMT,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating missing amount.");
        scrollToTop();
    }
}

async function otherTotalSalesUpdate() {
    try {
        const response = await axios.post(
            route("api.location-mega.store-update", {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location2.value.LOCATION,
            }),
            {
                SALES: otherInformation.value.TOTAL_SALES,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating mega location total sales");
        scrollToTop();
    }
}

async function otherSalesMissingAmountUpdate() {
    try {
        const response = await axios.post(
            route("api.inventory-missing.store-update", {
                date: timePeriod.value.START_DATE,
                location: location2.value.LOCATION,
            }),
            {
                MISSING_AMT: otherInformation.value.SALES_MISSING_AMT,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(
            e,
            "Error while updating mega location missing amount."
        );
        scrollToTop();
    }
}

async function otherQuotaUpdate() {
    try {
        const response = await axios.post(
            route("api.location-quota.store-update", {
                time_period: timePeriod.value.TIME_PERIOD,
                location: location2.value.LOCATION,
            }),
            {
                QUOTA: otherInformation.value.QUOTA,
                TOTAL_SALES_1: otherInformation.value.TOTAL_SALES_1,
                TOTAL_SALES_2: otherInformation.value.TOTAL_SALES_2,
            }
        );

        addFlashMessage("SUCCESS", response.data.message);
        scrollToTop();
        generateRecon();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while updating mega location quota.");
        scrollToTop();
    }
}

function handleSelectTimePeriod(selectedTimePeriod) {
    timePeriod.value = {
        TIME_PERIOD: selectedTimePeriod.TIME_PERIOD,
        START_DATE: selectedTimePeriod.START_DATE,
        END_DATE: selectedTimePeriod.END_DATE,
    };
    isSelectTimePeriodPopupOpen.value = false;
}

function handleSelectLocation(selectedLocation) {
    location.value = {
        LOCATION: selectedLocation.LOCATION,
        LOCATION_KEY2: selectedLocation.LOCATION_KEY2,
        DESCRIPTION: selectedLocation.DESCRIPTION,
    };
    isSelectLocationPopupOpen.value = false;
}

function redirectToUpdateStoreSummary() {
    window.open(
        route("catalog.update-store-summary", {
            time_period: timePeriod.value.TIME_PERIOD,
            location: location.value.LOCATION,
        })
    );
}
</script>
