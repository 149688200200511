import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useReconMainResolvedItems() {
    const reconMainResolvedItems = ref(null);
    const totalRecords = ref(null);

    const getReconMainResolvedItems = async (params) => {
        try {
            const getReconMainResolvedItemsResponse = await axios.get(route('api.recon-main-resolved-items.index', params));
            reconMainResolvedItems.value = getReconMainResolvedItemsResponse.data.data;
            totalRecords.value = getReconMainResolvedItemsResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while getting recon main resolved items.');
        }
    };
    
    return {
        reconMainResolvedItems,
        totalRecords,
        getReconMainResolvedItems,
    };
}
