<template>
    <div>
        <div v-if="isLoading"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-if="isToastSuccess"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow">
            <div class="w-50 toast show bg-success"
                role="alert"
                aria-live="assertive"
                aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Success!</h6>
                    <button @click="hideToast()"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close">
                    </button>
                </div>
                <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                </div>
            </div>
        </div>
        <div v-if="isToastError"
            class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow">
            <div class="w-50 toast show bg-danger"
                role="alert"
                aria-live="assertive"
                aria-atomic="true">
                <div class="toast-header">
                    <h6 class="me-auto text-black m-0">Error!</h6>
                    <button @click="hideToast()"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close">
                    </button>
                </div>
                <div class="toast-body text-white p-4 fs-1.4">
                    {{ toastMessage }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-7">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-receipt me-2"></i>Create Voucher
                    </legend>
                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <button @click="setVoucher('CS')"
                                type="button"
                                class="btn btn-primary w-100 btn-sm">
                                New Cash AP
                            </button>
                        </div>
                        <div class="col-md-4 mb-2">
                            <button @click="setVoucher('CK')"
                                type="button"
                                class="btn btn-primary w-100 btn-sm">
                                New Check AP
                            </button>
                        </div>
                        <div class="col-md-4 mb-2">
                            <button @click="setVoucher('AR')"
                                type="button"
                                class="btn btn-primary w-100 btn-sm">
                                New AR
                            </button>
                        </div>
                        <div class="col-md-4 mb-2">
                            <button @click="setVoucher('CC')"
                                type="button"
                                class="btn btn-primary w-100 btn-sm"
                                :disabled="!isAdmin">
                                New DISB
                            </button>
                        </div>
                        <div class="col-md-4 mb-2">
                            <button @click="setVoucher('AJ')"
                                type="button"
                                class="btn btn-primary w-100 btn-sm">
                                New ADJ
                            </button>
                        </div>
                    </div>
                    <hr class="my-4" />
                     <div v-if="isAdmin">
                        <div class="row">
                            <div class="col-md-8 mb-md-1 mb-2">
                                <div class="row">
                                    <label for="PAYEE_TYPE"
                                        class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                        Payee Type
                                    </label>
                                    <div class="dropdownContainer position-relative col col-xl-8 col-lg-9 col-md-8 col-12 mx-0"
                                        ref="dropdownContainer">
                                        <button @click="isListVisible = !isListVisible"
                                            class="dropdown-text text-dark form-control form-control-sm w-100 d-flex justify-content-between">
                                            <span>{{ payeeTypeText }}</span>
                                            <span class="me-2"><i class="fa-solid fa-angle-down"></i></span>
                                        </button>
                                        <ul v-if="isListVisible"
                                            class="dropdown-list px-2 ms-1 rounded-0 position-absolute top-100 start-0 list-group w-100">
                                            <li @click="searchPayee('I')"
                                                class="dropdown px-2 list-group-item list-group-item-action">
                                                INTERNAL
                                            </li>
                                            <li @click="searchPayee('E')"
                                                class="dropdown px-2 list-group-item list-group-item-action">
                                                EXTERNAL
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 mb-md-1 mb-2">
                                <div class="row">
                                    <label for="PAYEE_ID"
                                        class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                        Payee ID
                                    </label>
                                    <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                        <div class="row">
                                            <div class="col" v-if="payeeText">
                                                <p class="m-0 w-100 p-1">{{ payeeText }}</p>
                                            </div>
                                            <div :class="payeeText ? 'col-auto' : 'col'">
                                                <input v-model="payeeId"
                                                    type="text"
                                                    class="form-control form-control-sm"
                                                    :class="payeeText ? 'w-auto' : 'w-100'"
                                                    required
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="COMPANY"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Company
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <select id="company"
                                        v-model="company"
                                        name="COMPANY"
                                        class="form-select form-select-sm"
                                        required>
                                        <option v-for="company in companies"
                                            :value="company.COMPANY_CODE">
                                            {{ company.COMPANY_DESCRIPTION }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="VOUCHER_ID"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Voucher #
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input v-model="voucherId"
                                        type="text"
                                        class="form-control form-control-sm"
                                        required
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="VOUCHER_DATE"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Voucher Date
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input v-model="voucherDate"
                                        type="date"
                                        class="form-control form-control-sm"
                                        required
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="VOUCHER_TYPE"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Voucher Type
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input v-model="voucherTypeText"
                                        type="text"
                                        class="form-control form-control-sm"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-md-1 mb-2">
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                @click="openSelectAccount('VOUCHER')"
                                :disabled="voucherIdType === 'CS'">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search Bank
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="VOUCHER_BANK"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Voucher Bank
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <div class="row">
                                        <div class="col">
                                            <input v-model="accountId"
                                                type="number"
                                                class="form-control form-control-sm"
                                                disabled
                                            />
                                        </div>
                                        <div class="col">
                                            <input v-model="accountName"
                                                type="text"
                                                class="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="CHECK_NUMBER"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Check #
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input id="check-number"
                                        v-model="checkNumber"
                                        type="text"
                                        class="form-control form-control-sm"
                                        :required="voucherIdType === 'CK'"
                                        @keyup.enter="focusNextInput('voucher-amount')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 my-auto">
                            <p class="mb-0">(values will be calculated)</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="AMOUNT"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Amount
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input id="voucher-amount"
                                        v-model="voucherAmount"
                                        @keyup.enter="computedAmount"
                                        type="text"
                                        class="form-control form-control-sm"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-xl-8 offset-xl-4 mb-md-1 mb-2">
                                    <p class="text-center">
                                        {{ isNaN(voucherAmount) || voucherAmount == "" || voucherAmount == "0" || voucherAmount == "0.00"
                                            ? "0.00"
                                            : `${Number(voucherAmount)
                                                    .toFixed(2)
                                                    .replace(
                                                        /\d(?=(\d{3})+\.)/g,
                                                        "$&,"
                                                    )} (${computedAmountInWords})`
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="DESCRIPTION"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Description
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input id="description"
                                        @keyup.enter="focusNextInput('memo')"
                                        v-model="description"
                                        type="text"
                                        class="form-control form-control-sm"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="MEMO"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Memo
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <textarea id="memo"
                                        v-model="memo"
                                        class="form-control form-control-sm">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="!isAdmin">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="PAYEE_TYPE"
                                    class="col-xl-4 col-lg-3 col-md-4 label-sm">
                                    Payee Type
                                </label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <div class="dropdownContainer position-relative w-100 ms-0"
                                        ref="dropdownContainer">
                                        <button @click="isListVisible = !isListVisible"
                                            class="dropdown-text text-dark form-control form-control-sm w-100 btn-sm d-flex justify-content-between">
                                            <span>{{ payeeTypeText }}</span>
                                            <span><i class="fa-solid fa-angle-down"></i></span>
                                        </button>
                                        <ul v-if="isListVisible"
                                            class="dropdown-list rounded-0 position-absolute top-100 start-0 list-group w-100">
                                            <li @click="searchPayee('I')"
                                                class="dropdown list-group-item list-group-item-action">
                                                INTERNAL
                                            </li>
                                            <li @click="searchPayee('E')"
                                                class="dropdown list-group-item list-group-item-action">
                                                EXTERNAL
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-md-1 mb-2">
                            <div class="row">
                                <label for="PAYEE_ID" class="label-sm col-auto">Payee ID</label>
                                <div class="col">
                                    <input v-model="payeeId"
                                        type="text"
                                        class="form-control form-control-sm"
                                        required
                                        disabled
                                    />
                                </div>
                                <div class="col-8 offset-3" v-if="payeeText">
                                    <p class="m-0 p-1">{{ payeeText }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="PAYEE_DATE" class="col-xl-4 col-lg-3 col-md-4 label-sm">Payee Date</label>
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12">
                                    <input v-model="payeeDate"
                                        type="date"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-md-1 mb-2">
                            <div class="row">
                                <label for="PREPARED_BY" class="col-xl-4 col-lg-3 col-md-4 label-sm" >Prepared By</label >
                                <div class="col col-xl-8 col-lg-9 col-md-8 col-12" >
                                    <input :value="username"
                                        type="text"
                                        class="form-control form-control-sm"
                                        required
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-3" />
                    <button type="submit"
                        @click="submitForm"
                        class="btn btn-primary btn-sm">
                        <i class="icon ph-bold ph-receipt me-2"></i>Create Voucher
                    </button>
                </fieldset>
            </div>
        </div>
        <SelectAccount
            :isOpen="isSelectAccountOpen"
            :onClose="() => closeSelectAccount()"
            :onSelectAccount="handleSelectAccount"
            :voucherType="voucherIdType"
        />
        <SelectEmployee
            :isOpen="isSelectEmployeeOpen"
            @close="() => closeSelectEmployee()"
            @select="(employee) => {
                handleSelectEmployee(employee);
                isSelectEmployeeOpen = false;
            }"
        />
    </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { computed } from 'vue';
import numberToWords from 'number-to-words';
import axios from 'axios';

import SelectAccount from '../../utils/SelectAccount.vue';
import SelectEmployee from '../../utils/SelectEmployee.vue';

    const voucherIdType = ref('');
    const voucherIdCodeType = ref('');
    const company = ref('');
    const companies = ref([]);
    const voucherId = ref('');
    const voucherDate = ref('');
    const voucherTypeText = ref('');
    const accountId = ref(0);
    const accountName = ref('');
    const checkNumber = ref('');
    const voucherAmount = ref('');
    const description = ref('');
    const memo = ref('');
    const payeeType = ref('');
    const payeeId = ref('');
    const payeeText = ref('');
    const payeeDate = ref('');
    const username = ref('');
    const glId = ref('');
    const selectAccountType = ref('');
    const isSelectAccountOpen = ref(false);
    const isSelectEmployeeOpen = ref(false);
    const isLoading = ref(false);
    const isToastSuccess = ref(false);
    const isToastError = ref(false);
    const toastMessage = ref("");
    const isListVisible = ref(false);
    const payeeTypeText = ref('');
    const isPayee = ref(false);
    const isAdmin = ref(false);

    async function submitForm() {
        try {
            if (voucherIdType.value === 'AJ') {
                glId.value = 13;
            } else {
                glId.value = await selectAccountGL(accountId.value);
            }

            if (company.value == '') {
                isToastError.value = true;
                toastMessage.value = 'Enter Company';
                return;
            }
            if (voucherTypeText.value == 'CHECK' && accountId.value == 0 && voucherIdCodeType.value != 'AR') {
                isToastError.value = true;
                toastMessage.value = 'Check voucher requires bank information.';
                return;
            }
            if (voucherTypeText.value == 'CHECK' && checkNumber.value == '' && voucherIdCodeType.value != 'AR') {
                isToastError.value = true;
                toastMessage.value = 'Check voucher requires check number.';
                return;
            }

            const formDataVoucher = {
                VOUCHER_ID: voucherId.value,
                VOUCHER_DATE: voucherDate.value,
                VOUCHER_AMT: parseFloat(removeCommas(voucherAmount.value)).toFixed(2),
                DESCRIPTION: description.value,
                MEMO: memo.value,
                PAYEE_TYPE: payeeType.value,
                PAYEE_ID: payeeId.value,
                PREPARED_BY: username.value,
                LAST_UPDATED_BY: username.value,
                GL_ID: glId.value,
                PAYEE_DATE: payeeDate.value,
                CHECK_BANK: accountId.value !== '' ? accountId.value : 0,
                CHECK_NUM: checkNumber.value !== '' ? checkNumber.value : null,
            }

            const response = await axios.post(route('api.vouchers.store'), formDataVoucher);
            if (response.status === 200 || response.status === 201) {
                isToastSuccess.value = true;
                toastMessage.value = `Voucher ${voucherId.value} created.`;
                resetForm();
            }
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    async function selectAccountGL(id) {
        try {
            const response = await axios.get(route('api.accounts.index'), {
                params: {
                    accountId: id
                }
            });;
            return response.data.data[0].ACCOUNT_GL;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function resetForm() {
        voucherIdType.value = '';
        company.value = '';
        voucherId.value = '';
        voucherTypeText.value = '';
        voucherIdCodeType.value = '';
        accountId.value = '';
        accountName.value = '';
        checkNumber.value = '';
        voucherAmount.value = '';
        description.value = '';
        memo.value = '';
        payeeType.value = '';
        payeeTypeText.value = '';
        payeeId.value = '';
        payeeText.value = '';
        payeeDate.value = '';
        selectAccountType.value = '';
        isSelectAccountOpen.value = false;
        isSelectEmployeeOpen.value = false;
    }

    function getCurrentDate() {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        return formattedDate;
    }

    function getCurrentYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    function setupInitialValues () {
        voucherDate.value = getCurrentDate();
        username.value = "/" + document.querySelector('#create-voucher').getAttribute('data-username');
    }

    onMounted(() => {
        setupInitialValues();
        getCompanies();
        checkDisbursementPermission();
    });

    async function checkDisbursementPermission() {
        try {
            const response = await axios.get(route('api.voucher.disbursementPermission'));
            isAdmin.value = response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async function getCompanies() {
        try {
            const response = await axios.get(route('api.companies.index'));
            companies.value = response.data.data;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    //Generate voucher id
    watch([company, voucherIdCodeType], () => {
        if (company.value != '' && voucherIdCodeType.value != '') {
            generateVoucherId();
        }
    });

    async function generateVoucherId() {
        const voucherCode = company.value + "-" + voucherIdCodeType.value + getCurrentYear();
        try {
            const response = await axios.get(route('api.voucher.max'), {
                params: {
                    voucherCode: voucherCode,
                },
            });
            const paddedCount = String(response.data.maxVoucherId + 1).padStart(5, '0');
            voucherId.value = voucherCode + "-" + paddedCount;
        } catch (error) {
            isToastError.value = true;
            toastMessage.value = error.response.data.message;
        }
    }

    function setVoucher(type) {
        if (company.value != '' || type === 'CC') {
            const voucherTitle = document.getElementById('create-voucher-title');
            voucherIdType.value = type;
            switch (type) {
                case 'CS':
                    accountId.value = 0;
                    accountName.value = '"CASH"'
                    voucherTypeText.value = 'CASH';
                    voucherIdCodeType.value = type;
                    voucherTitle.innerText = 'Create Cash Voucher';
                    focusNextInput('check-number');
                break;
                case 'CK':
                    voucherTitle.innerText = 'Create Check Voucher';
                    openSelectAccount('VOUCHER');
                    break;
                case 'AR':
                    voucherTitle.innerText = 'Create AR Voucher';
                    openSelectAccount('VOUCHER');
                    break;
                case 'AJ':
                    voucherTypeText.value = 'CASH';
                    accountId.value = 0;
                    accountName.value = '"CASH"';
                    voucherIdCodeType.value = type;
                    voucherTitle.innerText = 'Create Adjustment Voucher';
                    focusNextInput('check-number');
                break;
                case 'CC':
                    voucherTitle.innerText = 'Create Disbursement Voucher';
                    openSelectEmployee();
                break;
                default:
                break;
            }
        } else {
            isToastError.value = true;
            toastMessage.value = 'Please select a company.';
        }
    }

    function openSelectAccount(type) {
        selectAccountType.value = type;
        isSelectAccountOpen.value = true;
    }

    function closeSelectAccount() {
        isSelectAccountOpen.value = false;
    }

    function handleSelectAccount(selectedAccount, voucherType) {
        if (selectAccountType.value == 'VOUCHER') {
            if (selectedAccount.ACCOUNT_NAME === 'CASH') {
                voucherTypeText.value = 'CASH';
            } else {
                voucherTypeText.value = 'CHECK';
            }
            voucherIdCodeType.value = voucherType;
            accountId.value = selectedAccount.ACCOUNT_ID;
            accountName.value = selectedAccount.ACCOUNT_NAME;
            focusNextInput('check-number');
            return;
        }
        isPayee.value = false;
        payeeType.value = 'E';
        payeeTypeText.value = 'EXTERNAL';
        payeeId.value = selectedAccount.ACCOUNT_ID;
        payeeText.value = selectedAccount.ACCOUNT_NAME;
    };

    function openSelectEmployee() {
        isSelectEmployeeOpen.value = true;
    }

    function closeSelectEmployee() {
        isSelectEmployeeOpen.value = false;
    }

    async function handleSelectEmployee(selectedEmployee) {
        if (isAdmin.value) {
            try {
                company.value = selectedEmployee.COMPANY_CODE;
                voucherTypeText.value = 'CHECK';
                voucherIdCodeType.value = 'CC';
                switch (selectedEmployee.PAYMENT_FLAG) {
                    case '4':
                        getBank(689);
                        break;
                    case '2':
                        getBank(1);
                        break;
                    default:
                        break;
                }
                focusNextInput('check-number');
            } catch (error) {
                isToastError.value = true;
                toastMessage.value = error.response.data.message;
            }
        }
        payeeType.value = 'I';
        payeeTypeText.value = 'INTERNAL';
        payeeId.value = selectedEmployee.EMPID;
        payeeText.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
    };

    async function getBank(id) {
        const response = await axios.get(route('api.accounts.show', { id: id }));
        accountId.value = response.data.data.ACCOUNT_ID;
        accountName.value = response.data.data.ACCOUNT_NAME;
    }

    function searchPayee(type) {
        isListVisible.value = false;
        switch (type) {
            case 'E':
                openSelectAccount('PAYEE');
                isPayee.value = true;
                break;
            case 'I':
                openSelectEmployee();
                break;
            case '':
                isToastError.value = true;
                toastMessage.value = 'Please select a payee type.';
                break;
            default:
                break;
        }
    }

    const computedAmount = () => {
        try {
            const result = eval(voucherAmount.value);
            if (isNaN(result) || !isFinite(result)) {
                return NaN;
            }
            voucherAmount.value = result.toFixed(2);
            focusNextInput('description')
        } catch (error) {
            return NaN;
        }
    };

    const formatAmountInWords = (amount) => {
        try {
            const [wholePart, decimalPart] = amount.split('.');
            const wholeWords = numberToWords.toWords(parseInt(wholePart));
            return `${wholeWords} and ${decimalPart == undefined || decimalPart == "" ? 0 : decimalPart}/100 pesos only`;
        } catch (error) {
            return '0.00';
        }
    };

    const computedAmountInWords = computed(() => {
        try {
            return `${formatAmountInWords(voucherAmount.value)}`;
        } catch (error) {
            return '0.00';
        }
    });

    function removeCommas(amountString) {
        return parseFloat(amountString.replace(/,/g, ''));
    }

    onMounted(() => {
        document.addEventListener('click', closeList);
    });

    const closeList = (event) => {
        const dropdownContainer = document.querySelector('.dropdownContainer');
        if (dropdownContainer && !dropdownContainer.contains(event.target)) {
            isListVisible.value = false;
        }
    };

    onBeforeUnmount(() => {
        document.removeEventListener('click', closeList);
    });

    function hideToast() {
        isToastError.value = false;
        isToastSuccess.value = false;
    }

    function focusNextInput(nextInputId) {
        // Find the next input field using ID
        const nextInput = document.querySelector('#' + nextInputId);

        if (nextInput) {
            nextInput.focus();
        }
    };
</script>

<style scoped>
section {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
}

.voucher-main-card {
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}

.dropdown-list {
    z-index: 1;
}

.dropdown-text {
    background-color: #f9fafb;
    border: 1px solid #ced4da;
}
</style>
